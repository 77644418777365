import React, { useContext, useState, useEffect } from "react";
import { GlobalContext } from "../../Context/GlobalState";
import { Button, Dropdown, Modal } from "react-bootstrap";
import { Link, Redirect, useHistory } from "react-router-dom";
import { Trans } from "react-i18next";
import userAvtar from "../../../assets/images/user.png";
import pemoreLogo from "../../../assets/images/company/logo_square.png";
import "react-toastify/dist/ReactToastify.css";
import { BASE_URL_API_TRANXT_UAT_API } from "../../utils/Constants";
import { twoDecimalPlaces } from "../../utils/utilFuctions";
import { CLIENT_BASIC_AUTH } from "../../utils/Client";
import { billpunchbasicauth } from "../../utils/Credentials";
import {
  Adminflowmodal,
  KeyStatusModal,
  Newusermodal,
} from "../SalaryManagement/Modal";

const Navbar = () => {
  const history = useHistory();
  const {
    user,
    userparent,
    removeUserInfo,
    wallet,
    setUserInfo,
    setTransactionLoaderState,
  } = useContext(GlobalContext);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [profile, setProfile] = useState({});
  const [Wallet, setWallet] = useState(null);
  const [Balance, setBalance] = useState({});
  const [Employeewallet, setEmployeewallet] = useState({});

  const { password, username } = CLIENT_BASIC_AUTH;

  const handleReload = () => {
    getWallet();
    getemployeeWallet();
  };
  const getWallet = async () => {
    try {
      setTransactionLoaderState(true);
      var myHeaders = new Headers();

      myHeaders.append("Content-Type", "application/json");

      const raw = JSON.stringify({
        firmid: user.firm_id,
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/GetFirmWalletBalance`,
        requestOptions
      );
      const responseData = await res.json();

      if (
        responseData.status === "0" &&
        responseData.data &&
        responseData.data.length > 0
      ) {
        setWallet(responseData.data[0]);
        console.log(setWallet);
      } else {
        setWallet(null);
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setTransactionLoaderState(false);
    }
  };
  const getemployeeWallet = async () => {
    try {
      setTransactionLoaderState(true);
      var myHeaders = new Headers();

      myHeaders.append("Content-Type", "application/json");

      const raw = JSON.stringify({
        firmid: user.firm_id,
        empid: user.userid,
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/SuperAdmin/GetEmployeeWalletBalance`,
        requestOptions
      );
      const responseData = await res.json();

      if (
        responseData.status === "0" &&
        responseData.data &&
        responseData.data.length > 0
      ) {
        setEmployeewallet(responseData.data[0]);
        console.log(setEmployeewallet);
      } else {
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setTransactionLoaderState(false);
    }
  };

  useEffect(() => {
    if (user && user.us_type === "A") {
      getWallet();
    } else if (user && user.us_type === "E") {
      getemployeeWallet();
    } else if (user && user.us_type == "H") {
      getemployeeWallet();
    }
  }, [user]);
  useEffect(() => {
    getWallet();
    getemployeeWallet();
    // getAllVirtualPayments();
  }, []);

  function toggleOffcanvas() {
    document.querySelector(".sidebar-offcanvas").classList.toggle("active");
  }

  function toggleRightSidebar() {
    document.querySelector(".right-sidebar").classList.toggle("open");
  }
  const dateFormatter = (date) => {
    return `${date.getFullYear()}-${
      date.getMonth() > 9 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)
    }-${date.getDate() > 9 ? date.getDate() : "0" + date.getDate()}`;
  };
  const login = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Cookie",
      ".AspNetCore.Identity.Application=CfDJ8JsnH411h-hPvOBmxZ5uxqP6rp2ytIifypwWZTazYqnFXDYohzj3JhFUoUEyEcGcfpXoK2-0ijf3BfPQJXY7Nk86kTqeOrMMhiG8TINrH5E2C_nKDsku7O4JS7yDc2tSweTku1oPbP1jYCQ7aXuXI4rWYFu_iikYclB72RfTjJLAF64UAjWZ_va2rZZ_wvLHItEF2zvWTMqKayPDzIc44DwsgDYpPhaj4mDx1kNNk1OiItKIvPzVRU3DVcNiC2PckNQw2lVRod5mMErEu_LUI728kGjTcARb2vYmffdusRK-1f2thw0FOBPimLZTJq16HFGx8wBKNEgVSZJD-IwAym8IFHQgw-_FdhFBBzIdj4ovd8cbLbqBBIF_dJmVvCHL5a08sEcFeJcoa_HSYFlQDMF57fvS_EOVvnAe8SQz5l2BUuPk8Zwq0S1aePx6e0xY09i9l-g5zMPZgvX-cALm-TtrvhVKUkvnM2gXYabjX0po2NmepBzeSwioEcsuOZpTFGfcDwKlIXKclnPYGvVuVlSS1aJRFpKBzJu0SpbFIwKKab7W9nYwU752JuiZFIrZKJCa2G8kRo7P3qPHKAMFNbwfCNEVIbB5A_GwBU2pzhTVUeLiHcPkvFgxcGn1m_nzmxqivZyTey5K7Qvhhv38mESKZytL-ZOmLU-3Bggomr9d"
    );

    var raw = JSON.stringify({
      email: username,
      password: password,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      setTransactionLoaderState(true);
      const result = await fetch(
        "https://apitranxt.paynnow.com/api/Account/Login",
        requestOptions
      );
      if (result.ok) {
        const body = await result.text();
        const data = JSON.parse(body);
        if (data.userName != undefined) {
          sessionStorage.setItem("userName", data.userName);
        }
        if (data.role != undefined) {
          sessionStorage.setItem("role", data.role);
        }
        if (data.accessToken != undefined && data.userId != undefined) {
          sessionStorage.setItem("userId", data.userId);
          // setToken(data.accessToken);
          getWalletBalance(data.accessToken);
          // getAccountInfo();
        }
        if (data.refreshToken != undefined) {
          sessionStorage.setItem("refreshToken", data.refreshToken);
        }
        sessionStorage.setItem(
          "basic_auth",
          JSON.stringify({ username: username, password })
        );
        // setRedirect(true);
      } else {
        // setInvalid(true);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      setTransactionLoaderState(false);
      console.log(error);
    }
  };

  useEffect(() => {
    login();
  }, []);
  const getWalletBalance = async (accessToken) => {
    // const accessToken = sessionStorage.getItem("accessToken");
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${accessToken}`);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Cookie",
      ".AspNetCore.Identity.Application=CfDJ8Knu5GvxZz1PqShq3i6d-iwOgwEj6EBN0jpchgFhuiV_O6Tcuf6QLM3cjarWosB3o17JTbrLuvcOmIJGLw0OwV-JJr2mhON-MIsfNGVplhmoJMIoQQrIyv6dzlCW8_eOvZw0ahT_icJcGsoHfR-1FLppZoY2SWzX8NUi9aabidVqbnsJjig4BpI2oPpnu73FH5iMh2Kfuz2xWcPSdx08C7D_6Ygts4I9XapyCvm0OVKguJ3fWPFHBgoY-PSnQ4KNzeh-OI4TzaffhkWCBwgBLtA6ybohQ1cqY5c2Y2EPo-sgAft8xvCDg9pibGVqcUdtEkqHj-BKKBdLijXEW-P7Q9e-T-wL07LWdnOnMPF9YCns79c8kBgyHTiVi82lFdqqIZtzZnhJsZoVhf9vsGaD37-6zhe4sDOkUJf4WCLur6gGQB8LQrfK9RZg30X0OvMv7ZCOtC4SKxgXiyk44NtkqY9oP_cVSb00F_E80WkQhk1A4nUfPalYivPP3EPF45TtK2hlhfdvdw_G1rMLrDiTbaIj-yvW5831ualRbsVUhVSHiUZzLTshMjUg-r_o5FNk4OSR2EyJm2l1WGwLdtZccqzvuyG_UzV9XiIYA658Nqy0iQX-ckKK4yR0yLPGXkmyx1MzMh9RjVPJwsRmhnIKqPE4eOFQNdJDYowJQhEQIMZV"
    );

    var raw = JSON.stringify({
      fromDate: dateFormatter(startDate),
      toDate: dateFormatter(endDate),
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    try {
      setTransactionLoaderState(true);
      const res = await fetch(
        "https://apitranxt.paynnow.com/api/Payments/GetUserledger",
        requestOptions
      );
      console.log("response", res);
      if (res.status == 401) {
        // removeMyAccountInfo();
      } else if (res.ok) {
        const data = await res.json();
        console.log(data);
        if (data && data.length > 0) {
          const dataLoad = data.map((acc, i) => {
            if (i == 0) {
              const ccB = twoDecimalPlaces(
                Number(acc.opening_bal) +
                  Number(acc.today_topup) +
                  Number(acc.today_refund) -
                  Number(acc.payout_today) -
                  Number(acc.payout_fee) -
                  Number(acc.payin_fee) +
                  Number(acc.settlement_today)
              );
              setBalance({ paymentAPIBalance: ccB });
            }
            return;
            // {
            //     ...acc,
            //     upi: `${acc.beneAccountNo}@yesbankltd`,
            //     date: new Date(acc.createdOn),
            //     closingBal: twoDecimalPlaces(Number(acc.opening_bal) + Number(acc.today_topup) + Number(acc.today_refund) - Number(acc.payout_today) - Number(acc.payout_fee) - Number(acc.payin_fee) + Number(acc.settlement_today))
            // };
          });
          dataLoad.sort(function (a, b) {
            return new Date(b.date) - new Date(a.date);
          });
          console.log(dataLoad);
          // setData(dataLoad);
          //  setDataToDisplay(dataLoad);
        }
      }
      setTransactionLoaderState(false);
      // setPayments(JSON.parse(body).data);
    } catch (error) {
      setTransactionLoaderState(false);
      console.log("401 catched", error);
    }
  };
  const Logout = () => {
    const callbackUrl = `${window.location.origin}`;
    const redirectUrl = `https://accounts.bumppy.com/?callback=${callbackUrl}`;
    window.location.href = redirectUrl;
  };

  console.log(user);

  if (!user) {
    window.location.href = `https://accounts.bumppy.com/?callback=${window.location.origin}`;
  }

  const [newuser, setnewuser] = useState(false);
  // useEffect(() => {
  //   if (user?.is_firstLogin === "Y") {
  //     setnewuser(true);
  //   }
  // }, [user]);
  const [firmDetails, setFirmDetails] = useState(null);
  const basicdetail = async () => {
    setTransactionLoaderState(true);
  
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");
  
      var raw = JSON.stringify({
        firmid: user.firm_id,
        parentid: userparent.parentAccountId,
      });
  
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
  
      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/SuperAdmin/GetFirmBasicDetails`,
        requestOptions
      );
      const data = await res.json();
  
      if (data.status === "0" && data.msg === "Success") {
        setFirmDetails(data.data[0]); 
      } else {
        console.log("Error: ", data.msg);
      }
      
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
      setTransactionLoaderState(false);
    }
  };
  useEffect(() => {
    if (user && userparent) {
      basicdetail(); 
    }
  }, [user, userparent]);
  useEffect(() => {
    if (firmDetails && firmDetails.is_firstLogin === "Y") {
      setnewuser(true); 
    }
  }, [firmDetails]);
  return (
    <GlobalContext.Consumer>
      {(state) =>
        state.user == null || state.user.us_type == "" ? (
          <Redirect to={`/`} />
        ) : (
          <nav className="navbar p-0 fixed-top d-flex flex-row">
            <div className="navbar-menu-wrapper flex-grow d-flex align-items-stretch">
              <button
                className="navbar-toggler align-self-center"
                type="button"
              >
                <Link>
                  <img
                    src={require("../../../assets/images/logoblack.png")}
                    alt="logo"
                    style={{
                      height: "2rem",
                      paddingRight: "1rem",
                      width: "auto",
                    }}
                  />
                </Link>
              </button>

              <ul className="navbar-nav navbar-nav-right">
                <Dropdown alignRight as="li" className="nav-item">
                  <Dropdown.Item className="dropdown-item preview-item">
                    <div className="navbar-profile">
                      <span
                        style={{
                          fontWeight: "600",
                          fontSize: "1.2rem",
                          flexDirection: "row",
                        }}
                      >
                         
                        <div>
                          {user.us_type === "A" && (
                            <div>
                              <span style={{ paddingRight: "2px" }}>
                                {" "}
                                Firm Bal : ₹
                              </span>
                              {Wallet
                                ? Wallet.wl_balance !== undefined
                                  ? parseFloat(Wallet.wl_balance).toFixed(2)
                                  : "Loading..."
                                : "Loading..."}
                              <span
                                className="menu-icon"
                                onClick={handleReload}
                              >
                                <i className="mdi mdi-reload"></i>
                              </span>
                            </div>
                          )}
                          {user.us_type === "E" && (
                            <div>
                              <span style={{ paddingRight: "2px" }}>
                                {" "}
                                Emp Bal : ₹
                              </span>
                              {Employeewallet
                                ? Employeewallet.wl_balance !== undefined
                                  ? parseFloat(
                                      Employeewallet.wl_balance
                                    ).toFixed(2)
                                  : "Loading..."
                                : "Loading..."}
                              <span
                                className="menu-icon"
                                onClick={handleReload}
                              >
                                <i className="mdi mdi-reload"></i>
                              </span>
                            </div>
                          )}
                          {user.us_type === "H" && (
                            <div>
                              <span style={{ paddingRight: "2px" }}>
                                {" "}
                                Emp Bal : ₹
                              </span>
                              {Employeewallet
                                ? Employeewallet.wl_balance !== undefined
                                  ? parseFloat(
                                      Employeewallet.wl_balance
                                    ).toFixed(2)
                                  : "Loading..."
                                : "Loading..."}
                              <span
                                className="menu-icon"
                                onClick={handleReload}
                              >
                                <i className="mdi mdi-reload"></i>
                              </span>
                            </div>
                          )}
                        </div>
                      </span>
                    </div>
                  </Dropdown.Item>
                </Dropdown>

                <Dropdown alignRight as="li" className="nav-item">
                  <Dropdown.Toggle
                    as="a"
                    className="nav-link cursor-pointer no-caret"
                  >
                    <div className="navbar-profile">
                      <img
                        className="img-xs rounded-circle"
                        src={state.user.profile_url}
                        alt="profile"
                        style={{
                          width: "2.5rem",
                          height: "2.5rem",
                          objectFit: "cover",
                          borderRadius: "50%",
                        }}
                      />

                      <p className="mb-0 d-none d-sm-block navbar-profile-name text-dark">
                        <Trans>
                          {state.user == null ? (
                            ""
                          ) : (
                            <div className="d-flex flex-column justify-content-center">
                              <span
                                style={{
                                  fontWeight: "600",
                                  fontSize: "0.8rem",
                                }}
                                className="text-right"
                              >
                                <strong style={{ fontWeight: "800" }}>
                                  {state.user.username}
                                </strong>
                              </span>
                              <strong
                                style={{ fontWeight: "800" }}
                                className="text-right"
                              >
                                {`${state.user.organization}`}
                              </strong>

                              <div className="d-flex justify-content-end mt-2">
                                <button
                                  className="bg-danger p-1"
                                  style={{
                                    color: "#fff",
                                    border: "1px solid red",
                                    borderRadius: ".4rem",
                                  }}
                                  onClick={Logout}
                                >
                                  Logout
                                </button>
                              </div>
                            </div>
                          )}
                        </Trans>
                      </p>
                    </div>
                  </Dropdown.Toggle>
                </Dropdown>
              </ul>

              <button
                className="navbar-toggler navbar-toggler-right d-lg-none align-self-center"
                type="button"
                onClick={toggleOffcanvas}
              >
                <span className="mdi mdi-format-line-spacing"></span>
              </button>
            </div>
          <Newusermodal showModal={newuser} setShowModal={setnewuser} />

          </nav>
        )
      }
    </GlobalContext.Consumer>
  );
};

export default Navbar;
