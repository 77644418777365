import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import step1Img from "../../../assets/images/F_1.gif";
import step2Img from "../../../assets/images/FD_1.png";
import step5Img from "../../../assets/images/F_2.gif";
import step3Img from "../../../assets/images/F_2.png";
import step4Img from "../../../assets/images/F_4.png";
import Adddept from "../../../assets/images/F_3.png";

import {
  Badge,
  Button,
  Card,
  Col,
  Form,
  FormControl,
  FormLabel,
  InputGroup,
  Modal,
  Row,
  Table,
  Tooltip,
  OverlayTrigger,
  Carousel,
} from "react-bootstrap";
import Select from "react-select";
import * as XLSX from "xlsx";
import {
  BASE_URL_APISERVICES,
  BASE_URL_API_TRANXT_SERVICE_API,
  BASE_URL_API_TRANXT_UAT_API,
  BASE_URL_API_TRANXT_UAT_API_GOSEO,
} from "../../utils/Constants";
import { inWords, twoDecimalPlaces, uniqueID2 } from "../../utils/utilFuctions";
import {
  FaAngleDown,
  FaAngleUp,
  FaCaretDown,
  FaCaretRight,
  FaFlag,
  FaImage,
  FaTimes,
} from "react-icons/fa";
import swal from "sweetalert";
import { GlobalContext } from "../../Context/GlobalState";
import DatePicker from "react-date-picker";
import {
  IoIosArrowDown,
  IoIosArrowUp,
  IoMdInformationCircleOutline,
} from "react-icons/io";
import { billpunchbasicauth } from "../../utils/Credentials";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { MdEdit, MdOutlineDeleteForever } from "react-icons/md";
import { IoInformationCircleOutline } from "react-icons/io5";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
export const Authorblogmodal = ({
  Authormodal,
  setAuthormodal,
  GetAuthorsBlog,
}) => {
  const [newAuthor, setNewAuthor] = useState({
    p_name: "",
    l_name: "",
    p_username: "",
    p_email: "",
    p_bio: "",
    p_profile_image: "",
    p_website_url: "",
    p_social_links: "",
  });
  const [errors, setErrors] = useState({});
  const { user, setTransactionLoaderState } = useContext(GlobalContext);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewAuthor({ ...newAuthor, [name]: value });
  };

  const validateForm = () => {
    let isValid = true;
    const newErrors = {};

    if (!newAuthor.p_name) {
      newErrors.p_name = "First name is required";
      isValid = false;
    }

    if (!newAuthor.l_name) {
      newErrors.l_name = "Last name is required";
      isValid = false;
    }
    if (!newAuthor.p_username) {
      newErrors.p_username = "Username is required";
      isValid = false;
  } else if (!/^(?=.*[a-zA-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{3,}$/.test(newAuthor.p_username)) {
      newErrors.p_username = "Username must contain at least one letter, one number, and one special character.";
      isValid = false;
  }
    if (!newAuthor.p_email) {
      newErrors.p_email = "Email is required";
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(newAuthor.p_email)) {
      newErrors.p_email = "Invalid email format";
      isValid = false;
    }

    if (!newAuthor.p_bio) {
      newErrors.p_bio = "Bio is required";
      isValid = false;
    }

    if (!newAuthor.p_profile_image) {
      newErrors.p_profile_image = "Profile image URL is required";
      isValid = false;
    }

    if (!newAuthor.p_website_url) {
      newErrors.p_website_url = "Website URL is required";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleSubmit = async () => {
    if (!validateForm()) return;

    const slug = (newAuthor.p_name + "-" + newAuthor.l_name)
      .toLowerCase()
      .replace(/\s+/g, "-");
    const mergedName = `${newAuthor.p_name} ${newAuthor.l_name}`.trim();

    try {
      const raw = JSON.stringify({
        p_name: mergedName,
        p_username: newAuthor.p_username,
        p_email: newAuthor.p_email,
        p_bio: newAuthor.p_bio,
        p_profile_image: newAuthor.p_profile_image,
        p_website_url: newAuthor.p_website_url,
        p_social_links: "string",
        slug: slug,
      });

      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API_GOSEO}/api/Seo/CreateAuthors`,
        requestOptions
      );
      const data = await res.json();

      if (data.status === "0") {
        const result = data.result[0];
        if (result && result.result === "Y") {
          swal("Success", result.message, "success");
          GetAuthorsBlog();
          setAuthormodal(false);
        } else {
          swal("Error", "Unexpected result format", "error");
        }
      } else {
        swal("Error", data.msg, "error");
      }
    } catch (error) {
      console.error("Error during submission:", error);
      swal("Error", "Something went wrong. Please try again.", "error");
    }
  };

  return (
    <Modal show={Authormodal} onHide={() => setAuthormodal(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Add New Author</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form noValidate>
          <div className="row m-0 p-0">
            <Form.Group className="col">
              <Form.Label>First Name</Form.Label>
              <Form.Control
                type="text"
                name="p_name"
                value={newAuthor.p_name}
                onChange={handleInputChange}
                placeholder="Enter First name"
                isInvalid={!!errors.p_name} 
              />
              <Form.Control.Feedback type="invalid">
                {errors.p_name}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="col">
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                type="text"
                name="l_name"
                value={newAuthor.l_name}
                onChange={handleInputChange}
                placeholder="Enter Last name"
                isInvalid={!!errors.l_name} 
              />
              <Form.Control.Feedback type="invalid">
                {errors.l_name}
              </Form.Control.Feedback>
            </Form.Group>
          </div>
          <div className="row m-0 p-0">
            <Form.Group className="col">
              <Form.Label>Username</Form.Label>
              <Form.Control
                type="text"
                name="p_username"
                value={newAuthor.p_username}
                onChange={handleInputChange}
                placeholder="Enter author's username"
                isInvalid={!!errors.p_username} 
              />
              <Form.Control.Feedback type="invalid">
                {errors.p_username}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="col">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                name="p_email"
                value={newAuthor.p_email}
                onChange={handleInputChange}
                placeholder="Enter author's email"
                isInvalid={!!errors.p_email} 
              />
              <Form.Control.Feedback type="invalid">
                {errors.p_email}
              </Form.Control.Feedback>
            </Form.Group>
          </div>
          <div className="row m-0 p-0">
            <Form.Group className="col">
              <Form.Label>Bio</Form.Label>
              <textarea
                name="p_bio"
                value={newAuthor.p_bio}
                onChange={handleInputChange}
                className="form-control"
                placeholder="Enter author's bio"
                isInvalid={!!errors.p_bio} 
              />
              <Form.Control.Feedback type="invalid">
                {errors.p_bio}
              </Form.Control.Feedback>
            </Form.Group>
          </div>
          <div className="row m-0 p-0">
            <Form.Group className="col">
              <Form.Label>Profile Image</Form.Label>
              <Form.Control
                type="text"
                name="p_profile_image"
                value={newAuthor.p_profile_image}
                onChange={handleInputChange}
                placeholder="Enter profile image URL"
                isInvalid={!!errors.p_profile_image} 
              />
              <Form.Control.Feedback type="invalid">
                {errors.p_profile_image}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="col">
              <Form.Label>Website URL</Form.Label>
              <Form.Control
                type="url"
                name="p_website_url"
                value={newAuthor.p_website_url}
                onChange={handleInputChange}
                placeholder="Enter website URL"
                isInvalid={!!errors.p_website_url} 
              />
              <Form.Control.Feedback type="invalid">
                {errors.p_website_url}
              </Form.Control.Feedback>
            </Form.Group>
          </div>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setAuthormodal(false)}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
          Add Author
        </Button>
      </Modal.Footer>
    </Modal>
  );
};


export const Uploadimageblog = ({
  showModal,
  handleCloseModal,
  setContent,
  uploadedImageUrl,
  handleImageUpload,
}) => {
  const [imageData, setImageData] = useState({
    title: "",
    alt: "",
    description: "",
  });
  const handleFormSubmit = (e) => {
    e.preventDefault();
    setContent((prevContent) => [
      ...prevContent,
      {
        type: "uploadedImage",
        src: uploadedImageUrl,
        ...imageData,
      },
    ]);
    handleCloseModal();
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setImageData({
      ...imageData,
      [name]: value,
    });
  };

  return (
    <Modal show={showModal} onHide={handleCloseModal}>
      <Modal.Header closeButton>
        <Modal.Title>Uploaded Images</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleFormSubmit}>
          <div className="form-group">
            <Form.Label htmlFor="imageTitle">Upload Image </Form.Label>
            <Form.Control
              type="file"
              id="image-upload"
              accept="image/*"
              multiple
              onChange={handleImageUpload}
            />
          </div>

          {uploadedImageUrl && (
            <div className="row m-0 p-0">
              <div className="col-2 ">
                <img
                  src={uploadedImageUrl}
                  alt="Uploaded Preview"
                  style={{
                    width: "3rem",
                    height: "3rem",
                    borderRadius: "50%",
                    objectFit: "cover",
                    border: "2px solid #ddd",
                  }}
                />
              </div>
              <div className="col d-flex align-items-center">
                <h6 className="">URL: {uploadedImageUrl}</h6>
              </div>
            </div>
          )}

          <div className="form-group">
            <Form.Label htmlFor="imageTitle">Image Title</Form.Label>
            <Form.Control
              type="text"
              name="title"
              value={imageData.title}
              onChange={handleInputChange}
              className="form-control"
              placeholder="Enter image title"
            />
          </div>

          <div className="form-group">
            <Form.Label htmlFor="imageAlt">Image Alt Text</Form.Label>
            <Form.Control
              type="text"
              name="alt"
              value={imageData.alt}
              onChange={handleInputChange}
              className="form-control"
              placeholder="Enter image alt text"
            />
          </div>

          <div className="form-group">
            <Form.Label htmlFor="imageDescription">
              Image Description
            </Form.Label>
            <textarea
              name="description"
              value={imageData.description}
              onChange={handleInputChange}
              className="form-control"
              placeholder="Enter image description"
            />
          </div>

          <button type="submit" className="btn btn-primary mt-3">
            Save
          </button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export const Uploadfeatureimageblog = ({
  featureModal,
  handleClosefeatureModal,
  setContent,
  uploadedfeatureImageUrl,
  setSubmitted,
  handlefeatureImageUpload,
  setFeatureimageData,
  featureimageData,
}) => {
  const handlefeatureInputChange = (e) => {
    const { name, value } = e.target;
    setFeatureimageData({
      ...featureimageData,
      [name]: value,
    });
  };
  const handlefeatureFormSubmit = (e) => {
    e.preventDefault();
    setContent((prevContent) => [
      ...prevContent,
      {
        // type: "uploadedImage",
        src: uploadedfeatureImageUrl,
        ...featureimageData,
      },
    ]);
    setSubmitted(true);
    handleClosefeatureModal();
  };
  return (
    <Modal show={featureModal} onHide={handleClosefeatureModal}>
      <Modal.Header closeButton>
        <Modal.Title>Uploaded Feature Images</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handlefeatureFormSubmit}>
          <div className="form-group">
            <Form.Label htmlFor="imageTitle">Upload Image </Form.Label>
            <Form.Control
              type="file"
              id="image-upload"
              accept="image/*"
              multiple
              onChange={handlefeatureImageUpload}
            />
          </div>

          {uploadedfeatureImageUrl && (
            <div className="row m-0 p-0">
              <div className="col-2 ">
                <img
                  src={uploadedfeatureImageUrl}
                  alt="Uploaded Preview"
                  style={{
                    width: "3rem",
                    height: "3rem",
                    borderRadius: "50%",
                    objectFit: "cover",
                    border: "2px solid #ddd",
                  }}
                />
              </div>
              <div className="col d-flex align-items-center">
                <h6 className="">URL: {uploadedfeatureImageUrl}</h6>
              </div>
            </div>
          )}

          <div className="form-group">
            <Form.Label htmlFor="imageTitle">Image Title</Form.Label>
            <Form.Control
              type="text"
              name="title"
              value={featureimageData.title}
              onChange={handlefeatureInputChange}
              className="form-control"
              placeholder="Enter image title"
            />
          </div>

          <div className="form-group">
            <Form.Label htmlFor="imageAlt">Image Alt Text</Form.Label>
            <Form.Control
              type="text"
              name="alt"
              value={featureimageData.alt}
              onChange={handlefeatureInputChange}
              className="form-control"
              placeholder="Enter image alt text"
            />
          </div>

          <div className="form-group">
            <Form.Label htmlFor="imageDescription">
              Image Description
            </Form.Label>
            <textarea
              name="description"
              value={featureimageData.description}
              onChange={handlefeatureInputChange}
              className="form-control"
              placeholder="Enter image description"
            />
          </div>

          <button type="submit" className="btn btn-primary mt-3">
            Save
          </button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};
export const EditTaskModal = ({
  showModal,
  handleClose,
  selectedTask,
  setSelectedTask,
  pk_project_id,
  pk_work_id,
  getTask,
}) => {
  console.log(pk_project_id);
  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const { password, username } = billpunchbasicauth;
  const [members, setMembers] = useState([]);

  const fetchMembers = async () => {
    try {
      const myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      const raw = JSON.stringify({ workid: pk_work_id });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/GetWorkEmployee`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0" && data.data) {
        const membersData = data.data.map((emp) => ({
          value: emp.empid,
          label: emp.fullname,
        }));
        setMembers(membersData);
      } else {
        setMembers([]);
      }
    } catch (error) {
      console.log("error", error);
      setMembers([]);
    }
  };

  const handleSave = async () => {
    try {
      const myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      const selectedEmployee = members.find(
        (member) => member.value === selectedTask.task_empid
      );
      const taskassigne = selectedEmployee
        ? selectedEmployee.label
        : "Not Assigned";

      // Directly format dates
      const formattedStartDate = new Date(selectedTask.task_startdate)
        .toISOString()
        .split("T")[0];
      const formattedEndDate = new Date(selectedTask.task_enddate)
        .toISOString()
        .split("T")[0];

      const raw = JSON.stringify({
        task_id: selectedTask.pk_taskid,
        project_id: pk_project_id,
        taskname: selectedTask.taskname,
        taskpriority: selectedTask.taskpriority,
        taskdesc: selectedTask.task_description,
        taskassigne: taskassigne,
        task_empid: selectedTask.task_empid,
        task_startdate: formattedStartDate,
        task_enddate: formattedEndDate,
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/SuperAdmin/EditTaskEmployee`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "1") {
        swal("Error", data.result, "error");
      } else {
        swal("Success", data.result[0].message, "success");
        setTransactionLoaderState(false);
        getTask();
      }
      handleClose();
    } catch (error) {
      console.log("Error:", error);
    }
  };

  const formatDateForInput = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = `0${date.getMonth() + 1}`.slice(-2); // months are 0-indexed
    const day = `0${date.getDate()}`.slice(-2);
    return `${year}-${month}-${day}`;
  };
  useEffect(() => {
    fetchMembers();
  }, [pk_work_id]);

  return (
    <Modal show={showModal} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Edit Task</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {selectedTask && (
          <Form>
            <div className="row m-0 p-0">
              <Form.Group className="col" controlId="formtaskname">
                <Form.Label>Task Name</Form.Label>
                <Form.Control
                  type="text"
                  value={selectedTask.taskname}
                  onChange={(e) =>
                    setSelectedTask({
                      ...selectedTask,
                      taskname: e.target.value,
                    })
                  }
                />
              </Form.Group>
              <Form.Group className="col" controlId="formTaskAssignee">
                <Form.Label>Assignee</Form.Label>
                <Form.Control
                  as="select"
                  value={selectedTask.task_empid}
                  onChange={(e) =>
                    setSelectedTask({
                      ...selectedTask,
                      task_empid: e.target.value,
                    })
                  }
                >
                  <option value="">Select Employee</option>
                  {members.map((member) => (
                    <option key={member.value} value={member.value}>
                      {member.label}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </div>
            <div className="row m-0 p-0">
              <Form.Group className="col" controlId="formstrtTaskDate">
                <Form.Label>Start Date</Form.Label>
                <Form.Control
                  type="date"
                  value={
                    selectedTask.task_startdate
                      ? formatDateForInput(selectedTask.task_startdate)
                      : ""
                  }
                  onChange={(e) =>
                    setSelectedTask({
                      ...selectedTask,
                      task_startdate: e.target.value,
                    })
                  }
                />
              </Form.Group>
              <Form.Group className="col" controlId="formendTaskDate">
                <Form.Label>End Date</Form.Label>
                <Form.Control
                  type="date"
                  value={
                    selectedTask.task_enddate
                      ? formatDateForInput(selectedTask.task_enddate)
                      : ""
                  }
                  onChange={(e) =>
                    setSelectedTask({
                      ...selectedTask,
                      task_enddate: e.target.value,
                    })
                  }
                />
              </Form.Group>
            </div>
            <div className="row m-0 p-0">
              <Form.Group className="col" controlId="formTaskStatus">
                <Form.Label>Status</Form.Label>
                <Form.Control
                  as="select"
                  value={selectedTask.status}
                  onChange={(e) =>
                    setSelectedTask({
                      ...selectedTask,
                      status: e.target.value,
                    })
                  }
                >
                  <option value="O">Overdue</option>
                  <option value="P">Pending</option>
                  <option value="C">Complete</option>
                </Form.Control>
              </Form.Group>
            </div>
            <div className="row m-0 p-0">
              <Form.Group className="col" controlId="formTaskDescription">
                <Form.Label>Description</Form.Label>
                <Form.Control
                  type="text"
                  as="textarea"
                  rows={2}
                  value={selectedTask.task_description}
                  onChange={(e) =>
                    setSelectedTask({
                      ...selectedTask,
                      task_description: e.target.value,
                    })
                  }
                />
              </Form.Group>
            </div>
          </Form>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSave}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export const AssignEmployeeofficeitemModal = ({
  showModal,
  handleCloseModal,
  selectedItemId,
  Gettabledata,
  selectedItemIdForModal,
}) => {
  console.log(selectedItemId);
  console.log(selectedItemIdForModal);
  const [assignmentDate, setAssignmentDate] = useState("");
  const [assignedEmployee, setAssignedEmployee] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [employees, setEmployees] = useState([]);
  const [designation, setDesignation] = useState("");

  const { password, username } = billpunchbasicauth;
  const { user, setTransactionLoaderState } = useContext(GlobalContext);

  const getEmployees = async () => {
    setTransactionLoaderState(true);
    try {
      const myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({ firmId: user.firm_id }),
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/getEmployee`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        setEmployees(
          data.data.map((employee) => ({
            value: employee.empid,
            label: `${employee.firstname} ${employee.lastname}`,
            designation: employee.designation,
          }))
        );
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
      setTransactionLoaderState(false);
    }
  };

  useEffect(() => {
    getEmployees();
  }, []);

  const handleEmployeeChange = (selectedOption) => {
    setAssignedEmployee(selectedOption);
    if (selectedOption) {
      setDesignation(selectedOption.designation);
    } else {
      setDesignation("");
    }
  };

  const handleAssignEmployee = async () => {
    setTransactionLoaderState(true);
    try {
      const requestBody = {
        firmid: user.firm_id,
        device_id: selectedItemId,
        empid: assignedEmployee ? assignedEmployee.value : "",
        inventory_id: selectedItemIdForModal,
      };

      const myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify(requestBody),
        redirect: "follow",
      };

      const response = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/SuperAdmin/AssignEmployeeInventory`,
        requestOptions
      );

      const data = await response.json();
      console.log("API Response:", data);

      if (data.status === "0" && data.result[0].result === "Y") {
        swal(
          "Success",
          data.result[0].message || "Item added successfully",
          "success"
        );
        handleCloseModal();
        Gettabledata();
      } else {
        swal("Error", data.result[0].message || "Unknown error", "error");
      }
    } catch (error) {
      console.error("Error:", error);
      swal("Error", "Failed to update item", "error");
    } finally {
      setTransactionLoaderState(false);
    }
  };

  const customStyles = {
    menu: (provided) => ({
      ...provided,
      maxHeight: 200,
    }),
    menuList: (provided) => ({
      ...provided,
      maxHeight: 200,
    }),
  };

  return (
    <Modal show={showModal} onHide={handleCloseModal}>
      <Modal.Header closeButton>
        <Modal.Title>Assign Employee</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <div className="row">
            <Form.Group className="col" controlId="formEmployee">
              <Form.Label>Select Employee</Form.Label>
              <Select
                value={assignedEmployee}
                onChange={handleEmployeeChange}
                options={employees}
                placeholder="Select an employee"
                styles={customStyles}
              />
            </Form.Group>
            <Form.Group className="col">
              <Form.Label>Designation</Form.Label>
              <Form.Control type="text" value={designation} readOnly />
            </Form.Group>
          </div>
          <div className="row">
            <Form.Group controlId="formDate" className="col">
              <Form.Label>Assignment Date</Form.Label>
              <Form.Control
                type="date"
                value={assignmentDate}
                onChange={(e) => setAssignmentDate(e.target.value)}
              />
            </Form.Group>
          </div>
          <Button
            size="sm ml-2"
            variant="primary"
            onClick={handleAssignEmployee}
          >
            Assign
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export const Officeinventoryitemlist = ({
  showModal,
  handleCloseModal,
  selectedItemId,
  Gettabledata,
}) => {
  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const { password, username } = billpunchbasicauth;
  const [formData, setFormData] = useState({
    itemName: "",
    companyName: "",
    serialNumber: "",
    deviceId: "",
    productId: "",
    defects:"",
    password:"",

  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSave = async () => {
    setTransactionLoaderState(true);
    try {
      const requestBody = {
        firmid: user.firm_id,
        fk_deviceid: selectedItemId,
        item_name: formData.itemName,
        company_name: formData.companyName,
        product_serial: formData.serialNumber,
        serial: formData.productId,
        deviceid: formData.deviceId,
        defects:formData.defects,
        password:formData.password
      };

      const myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify(requestBody),
        redirect: "follow",
      };

      const response = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/SuperAdmin/AddInternalInventory`,
        requestOptions
      );

      const data = await response.json();
      console.log("API Response:", data);

      if (data.status === "0" && data.result[0].result === "Y") {
        swal(
          "Success",
          data.result[0].message || "Item added successfully",
          "success"
        );
        handleCloseModal();
        Gettabledata();
      } else {
        swal("Error", data.result[0].message || "Unknown error", "error");
      }
    } catch (error) {
      console.error("Error:", error);
      swal("Error", "Failed to update item", "error");
    } finally {
      setTransactionLoaderState(false);
    }
  };

  return (
    <Modal show={showModal} onHide={handleCloseModal}>
      <Modal.Header closeButton>
        <Modal.Title>Add Item</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <div className="row m-0 p-0">
            <Form.Group className="col" controlId="formItemName">
              <Form.Label>Item Name</Form.Label>
              <Form.Control
                type="text"
                name="itemName"
                value={formData.itemName}
                onChange={handleChange}
                placeholder="Enter Item Name"
              />
            </Form.Group>
            <Form.Group className="col" controlId="formCompanyName">
              <Form.Label>Company Name</Form.Label>
              <Form.Control
                type="text"
                name="companyName"
                value={formData.companyName}
                onChange={handleChange}
                placeholder="Enter Company Name"
              />
            </Form.Group>
          </div>
          <div className="row m-0 p-0">
            <Form.Group className="col" controlId="formSerialNumber">
              <Form.Label>Serial Number</Form.Label>
              <Form.Control
                type="text"
                name="serialNumber"
                value={formData.serialNumber}
                onChange={handleChange}
                placeholder="Enter Serial Number"
              />
            </Form.Group>
            <Form.Group className="col" controlId="formProductId">
              <Form.Label>Product ID</Form.Label>
              <Form.Control
                type="text"
                name="productId"
                value={formData.productId}
                onChange={handleChange}
                placeholder="Enter Product Id"
              />
            </Form.Group>
          </div>
          <div className="row m-0 p-0">
            <Form.Group className="col" controlId="formDeviceId">
              <Form.Label>Device ID</Form.Label>
              <Form.Control
                type="text"
                name="deviceId"
                value={formData.deviceId}
                onChange={handleChange}
                placeholder="Enter Device Id"
              />
            </Form.Group>
            <Form.Group className="col" controlId="formdefects">
              <Form.Label>Defects </Form.Label>
              <Form.Control
                type="text"
                name="defects"
                value={formData.defects}
                onChange={handleChange}
                placeholder="Enter defects"
              />
            </Form.Group>
          </div>
          <div className="row m-0 p-0">
            <Form.Group className="col" controlId="formpassword">
              <Form.Label>Password </Form.Label>
              <Form.Control
                type="text"
                name="password"
                value={formData.password}
                onChange={handleChange}
                placeholder="Enter password"
              />
            </Form.Group>
          </div>
          <Button className="ml-3" variant="primary" onClick={handleSave}>
            Save
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export const Adminassignemployeeworkspace = ({
  isOpen,
  onClose,
  selectedWorkspaceId,
  getWorkspacedata,
  selectedProject,
}) => {
  console.log(selectedProject);
  const { password, username } = billpunchbasicauth;
  const [employees, setEmployees] = useState([]);
  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const [selectedEmployeeId, setSelectedEmployeeId] = useState(null);
  const [employeetable, setemployeetable] = useState([]);

  const handleEmployeeChange = (selectedOption) => {
    setSelectedEmployeeId(selectedOption.value);
  };

  const getEmployees = async () => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({
          firmId: user.firm_id,
        }),
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/getEmployee`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        setEmployees(
          data.data.map((employee) => ({
            value: employee.empid,
            label: `${employee.firstname} ${employee.lastname}`,
          }))
        );
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
      setTransactionLoaderState(false);
    }
  };

  const Getemployeetable = async () => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({ workid: selectedWorkspaceId }),
      };
      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/GetEmployeeWorkspace`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        setemployeetable(data.data);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
      setTransactionLoaderState(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!selectedEmployeeId) {
      swal("Error", "No employee selected", "error");
      return;
    }

    try {
      setTransactionLoaderState(true);
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({
          workid: selectedWorkspaceId,
          empid: selectedEmployeeId,
          projectid: selectedProject,
        }),
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/AddEmployeeWorkspace`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "1") {
        swal("Error", data.result, "error");
        Getemployeetable();
        getWorkspacedata();
      } else {
        swal("Success", data.result, "success");
        Getemployeetable();
        getWorkspacedata();
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setTransactionLoaderState(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      Getemployeetable();
    }
  }, [isOpen, selectedWorkspaceId]);

  useEffect(() => {
    getEmployees();
    Getemployeetable();
  }, []);

  return (
    <Modal show={isOpen} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Select Employee</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <Form.Group className="col" controlId="Employeename">
            <Form.Label>Select Employee</Form.Label>
            <Select options={employees} onChange={handleEmployeeChange} />
          </Form.Group>
          <div className="col d-flex align-items-center">
            <Button variant="success" onClick={handleSubmit}>
              Submit
            </Button>
          </div>
        </div>
        <div>
          <table className="table">
            <thead>
              <tr>
                <th
                  style={{
                    borderBottom: "1px solid #f2f4f2",
                    color: "#000",
                    padding: "8px",
                    textAlign: "left",
                    background: "#fff",
                  }}
                >
                  ID
                </th>
                <th
                  style={{
                    borderBottom: "1px solid #f2f4f2",
                    color: "#000",
                    padding: "8px",
                    textAlign: "left",
                    background: "#fff",
                  }}
                >
                  Employee
                </th>
              </tr>
            </thead>
            <tbody>
              {employeetable.length === 0 ? (
                <tr>
                  <td colSpan="5" style={{ textAlign: "center" }}>
                    No Employee found.
                  </td>
                </tr>
              ) : (
                employeetable.map((empdata, index) => (
                  <tr key={empdata.empid}>
                    <td
                      style={{
                        textAlign: "left",
                        border: "none",
                        fontSize: ".9rem",
                      }}
                    >
                      {index + 1}
                    </td>
                    <td
                      style={{
                        textAlign: "left",
                        border: "none",
                        fontSize: ".9rem",
                      }}
                    >
                      {empdata.full_name}
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
        {/* Additional buttons */}
      </Modal.Footer>
    </Modal>
  );
};

export const UpdateSalaryModal = ({ show, handleClose, pk_s_id }) => {
  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const [formData, setFormData] = useState({
    basicPay: "",
    hra: "",
    travelAllowance: "",
    medicalAllowance: "",
    otherAllowance: "",
    totalSalary: "",
  });
  const { password, username } = billpunchbasicauth;

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleSubmit = async () => {
    setTransactionLoaderState(true);
    try {
      const requestBody = {
        pk_s_id: "string",
        fk_emp_id: "string",
        basicpay: "string",
        hra: "string",
        travel_allowance: "string",
        medical_allowance: "string",
        other_allowance: "string",
        totalsalary: "string",
      };
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify(requestBody),
        redirect: "follow",
      };

      const response = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/updateSalaryDetails`,
        requestOptions
      );
      const data = await response.json();
      if (data.status === "0") {
        swal("Success", data.result, "success");
        handleClose();
      } else {
        swal("Error", data.result, "error");
      }
      handleClose();
    } catch (error) {
      console.error("Error:", error);
      swal("Error", "Failed to update salary", "error");
    } finally {
      setTransactionLoaderState(false);
    }
  };
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Update Salary</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="form-group">
          <label htmlFor="basicPay">Basic Pay</label>
          <input
            type="text"
            className="form-control"
            id="basicPay"
            name="basicPay"
            value={formData.basicPay}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="hra">HRA</label>
          <input
            type="text"
            className="form-control"
            id="hra"
            name="hra"
            value={formData.hra}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="travelAllowance">Travel Allowance</label>
          <input
            type="text"
            className="form-control"
            id="travelAllowance"
            name="travelAllowance"
            value={formData.travelAllowance}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="medicalAllowance">Medical Allowance</label>
          <input
            type="text"
            className="form-control"
            id="medicalAllowance"
            name="medicalAllowance"
            value={formData.medicalAllowance}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="otherAllowance">Other Allowance</label>
          <input
            type="text"
            className="form-control"
            id="otherAllowance"
            name="otherAllowance"
            value={formData.otherAllowance}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="totalSalary">Total Salary</label>
          <input
            type="text"
            className="form-control"
            id="totalSalary"
            name="totalSalary"
            value={formData.totalSalary}
            onChange={handleInputChange}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button onClick={handleSubmit}>Submit</Button>
      </Modal.Footer>
    </Modal>
  );
};

//Super Admin
export const Addfirmsmodal = ({ show, closeModal, Getfirms }) => {
  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const [formData, setFormData] = useState({
    Firmname: "",
    Firmowner: "",
    Firmstrdate: "",
    Firmgst: "",
    FirmAddress: "",
    Firmmember: "",
    Firmtype: "",
    Firmmail: "",
    Firmmobile: "",
  });
  const { password, username } = billpunchbasicauth;

  const [errors, setErrors] = useState({});
  const validateForm = (e) => {
    e.preventDefault();
    let isValid = true;
    const errors = {};

    // Validation rules
    if (!formData.Firmname) {
      errors.Firmname = "Firm name is required";
      isValid = false;
    }

    if (!formData.Firmowner) {
      errors.Firmowner = "Firm owner is required";
      isValid = false;
    }

    if (!formData.Firmstrdate) {
      errors.Firmstrdate = "Firm start date is required";
      isValid = false;
    }

    if (!formData.Firmgst) {
      errors.Firmgst = "Firm GST is required";
      isValid = false;
    }

    if (!formData.FirmAddress) {
      errors.FirmAddress = "Firm address is required";
      isValid = false;
    }

    if (!formData.Firmmember) {
      errors.Firmmember = "Firm members are required";
      isValid = false;
    }

    if (!formData.Firmtype) {
      errors.Firmtype = "Firm type is required";
      isValid = false;
    }

    if (!formData.Firmmail) {
      errors.Firmmail = "Firm email is required";
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(formData.Firmmail)) {
      errors.Firmmail = "Invalid email address";
      isValid = false;
    }

    if (!formData.Firmmobile) {
      errors.Firmmobile = "Firm mobile is required";
      isValid = false;
    } else if (!/^\d{10}$/.test(formData.Firmmobile)) {
      errors.Firmmobile = "Invalid mobile number";
      isValid = false;
    }

    setErrors(errors);
    if (Object.keys(errors).length == 0) {
      handleSubmit();
    }
  };

  // Function to handle form field changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    setTransactionLoaderState(true);

    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        firm_name: formData.Firmname,
        firm_owner_name: formData.Firmowner,
        firm_startdate: formData.Firmstrdate,
        firm_gst: formData.Firmgst,
        firm_address: formData.FirmAddress,
        firm_status: "Y",
        firm_members: formData.Firmmember,
        firm_type: formData.Firmtype,
        firm_email: formData.Firmmail,
        firm_mobile: formData.Firmmobile,
      });
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/SuperAdmin/AddFirmsSuperAdmin`,
        requestOptions
      );
      const data = await res.json();
      if (data.responseCode === "00") {
        swal("Success", data.responseMessage, "success");
        closeModal();
        Getfirms();
      } else {
        closeModal();
        swal("Error", data.responseMessage, "warning");
        Getfirms();
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
    }
    setTransactionLoaderState(false);
  };

  return (
    <>
      <Modal show={show} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>Add Dependency</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={validateForm}>
            <Form.Group controlId="Firmname">
              <Form.Label>Firm Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter firm name"
                name="Firmname"
                value={formData.Firmname}
                onChange={handleChange}
                isInvalid={!!errors.Firmname}
              />
              <Form.Control.Feedback type="invalid">
                {errors.Firmname}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="Firmowner">
              <Form.Label>Firm Owner</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter firm owner"
                name="Firmowner"
                value={formData.Firmowner}
                onChange={handleChange}
                isInvalid={!!errors.Firmowner}
              />
              <Form.Control.Feedback type="invalid">
                {errors.Firmowner}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="Firmstrdate">
              <Form.Label>Firm Start Date</Form.Label>
              <Form.Control
                type="date"
                name="Firmstrdate"
                value={formData.Firmstrdate}
                onChange={handleChange}
                isInvalid={!!errors.Firmstrdate}
              />
              <Form.Control.Feedback type="invalid">
                {errors.Firmstrdate}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="Firmgst">
              <Form.Label>Firm GST</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter firm GST"
                name="Firmgst"
                value={formData.Firmgst}
                onChange={handleChange}
                isInvalid={!!errors.Firmgst}
              />
              <Form.Control.Feedback type="invalid">
                {errors.Firmgst}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="FirmAddress">
              <Form.Label>Firm Address</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="Enter firm address"
                name="FirmAddress"
                value={formData.FirmAddress}
                onChange={handleChange}
                isInvalid={!!errors.FirmAddress}
              />
              <Form.Control.Feedback type="invalid">
                {errors.FirmAddress}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="Firmmember">
              <Form.Label>Firm Members</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter firm members"
                name="Firmmember"
                value={formData.Firmmember}
                onChange={handleChange}
                isInvalid={!!errors.Firmmember}
              />
              <Form.Control.Feedback type="invalid">
                {errors.Firmmember}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="Firmtype">
              <Form.Label>Firm Type</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter firm type"
                name="Firmtype"
                value={formData.Firmtype}
                onChange={handleChange}
                isInvalid={!!errors.Firmtype}
              />
              <Form.Control.Feedback type="invalid">
                {errors.Firmtype}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="Firmmail">
              <Form.Label>Firm Email</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter firm email"
                name="Firmmail"
                value={formData.Firmmail}
                onChange={handleChange}
                isInvalid={!!errors.Firmmail}
              />
              <Form.Control.Feedback type="invalid">
                {errors.Firmmail}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="Firmmobile">
              <Form.Label>Firm Mobile</Form.Label>
              <Form.Control
                type="tel"
                placeholder="Enter firm mobile"
                name="Firmmobile"
                value={formData.Firmmobile}
                onChange={handleChange}
                isInvalid={!!errors.Firmmobile}
              />
              <Form.Control.Feedback type="invalid">
                {errors.Firmmobile}
              </Form.Control.Feedback>
            </Form.Group>

            <Button variant="primary" type="submit">
              Submit
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

// ADMIN
export const Assignemployee = ({
  isOpen,
  onClose,
  selectedWorkspaceId,
  getWorkspacedata,
}) => {
  const { password, username } = billpunchbasicauth;

  const [employees, setEmployees] = useState([]);
  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const [selectedEmployeeId, setSelectedEmployeeId] = useState(null);
  const [employeetable, setemployeetable] = useState([]);

  const handleEmployeeChange = (selectedOption) => {
    setSelectedEmployeeId(selectedOption.value);
  };

  const getEmployees = async () => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({
          firmId: user.firm_id,
        }),
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/getEmployee`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        setEmployees(
          data.data.map((employee) => ({
            value: employee.empid,
            label: `${employee.firstname} ${employee.lastname}`,
          }))
        );
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
      setTransactionLoaderState(false);
    }
  };
  const Getemployeetable = async () => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({ workid: selectedWorkspaceId }),
      };
      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/GetEmployeeWorkspace`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        setemployeetable(data.data);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
      setTransactionLoaderState(false);
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!selectedEmployeeId) {
      swal("Error", "No employee selected", "error");
      return;
    }

    try {
      setTransactionLoaderState(true);
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({
          workid: selectedWorkspaceId,
          empid: selectedEmployeeId,
        }),
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/AddEmployeeWorkspace`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "1") {
        swal("Error", data.result, "error");
        Getemployeetable();
        getWorkspacedata();
      } else {
        swal("Success", data.result, "success");
        Getemployeetable();
        getWorkspacedata();
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setTransactionLoaderState(false);
    }
  };
  useEffect(() => {
    if (isOpen) {
      Getemployeetable();
    }
  }, [isOpen, selectedWorkspaceId]);
  useEffect(() => {
    getEmployees();
    Getemployeetable();
  }, []);

  return (
    <Modal show={isOpen} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Select Employee</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <Form.Group className="col" controlId="Employeename">
            <Form.Label>Select Employee</Form.Label>
            <Select options={employees} onChange={handleEmployeeChange} />
          </Form.Group>
          <div className="col d-flex align-items-center">
            {" "}
            <Button variant="success" onClick={handleSubmit}>
              Submit
            </Button>
          </div>
        </div>
        <div>
          <table className="table">
            <thead>
              <tr>
                <th
                  style={{
                    borderBottom: "1px solid #f2f4f2",
                    color: "#000",
                    padding: "8px",
                    textAlign: "left",
                    background: "#fff",
                  }}
                >
                  ID
                </th>
                <th
                  style={{
                    borderBottom: "1px solid #f2f4f2",
                    color: "#000",
                    padding: "8px",
                    textAlign: "left",
                    background: "#fff",
                  }}
                >
                  Employee
                </th>
              </tr>
            </thead>
            <tbody>
              {employeetable.length === 0 ? (
                <tr>
                  <td colSpan="5" style={{ textAlign: "center" }}>
                    No Employee found.
                  </td>
                </tr>
              ) : (
                employeetable.map((empdata) => (
                  <tr key={empdata.empid}>
                    <td
                      style={{
                        textAlign: "left",
                        border: "none",
                        fontSize: ".9rem",
                      }}
                    >
                      {empdata.empid}
                    </td>
                    <td
                      style={{
                        textAlign: "left",
                        border: "none",
                        fontSize: ".9rem",
                      }}
                    >
                      {empdata.full_name}
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
        {/* Additional buttons */}
      </Modal.Footer>
    </Modal>
  );
};
export const Credential = ({ show, empid, onHide, selectedEmail }) => {
  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const [loginId, setLoginId] = useState("");
  const [password1, setPassword] = useState("");
  const [logindata, setlogindata] = useState(null);
  const { password, username } = billpunchbasicauth;

  const loginidemp = JSON.parse(sessionStorage.getItem("loginidemp"));
  console.log(loginidemp, "loginidemp");
  const Getlogindata = async () => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        empid: empid,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/GetUsernamePass`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        setlogindata(data.data[0]);
        setLoginId(data.data[0].us_loginid);
        setPassword(data.data[0].us_password);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
    }
  };
  const handleSubmit = async (e) => {
    console.log("Selected Email:", selectedEmail);
    e.preventDefault();
    setTransactionLoaderState(true);

    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        email: "shivani.sengar@bumppy.com",
        to: selectedEmail,
        subject: "Login Credential",
        body: `Welcome to Billpunch!\nWe're excited to have you on board. Below you will find your login credentials:\nLogin ID: ${loginId}\nPassword: ${password1} \nBest regards\nBillpunch `,
      });
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `https://anlookuat.remittpe.com/api/Email/sendEmail`,
        requestOptions
      );
      const data = await res.json();
      if (data.responseCode === "00") {
        swal("Success", data.responseMessage, "success");
        onHide();
      } else {
        onHide();
        swal("Error", data.responseMessage, "warning");
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
    }
    setTransactionLoaderState(false);
  };
  useEffect(() => {
    if (show) {
      Getlogindata(empid);
    }
  }, [show, empid]);
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Send Login Credential</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="formLoginId">
            <Form.Label>Login ID</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter login ID"
              value={loginId}
              onChange={(e) => setLoginId(e.target.value)}
            />
          </Form.Group>

          <Form.Group controlId="formPassword">
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="text"
              placeholder="Password"
              value={password1}
              onChange={(e) => setPassword(e.target.value)}
            />
          </Form.Group>

          <Button variant="primary" type="submit" onClick={handleSubmit}>
            Send Credential
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};
export const Expensecategoryadminmodal = ({
  showModal,
  closeModal,
  fetchExpenseCategories,
}) => {
  const [Project, setProject] = useState([]);
  const { password, username } = billpunchbasicauth;
  const initialFormData = {
    project: "",
    category: "",
  };
  const [formData, setFormData] = useState(initialFormData);
  const { user, setTransactionLoaderState } = useContext(GlobalContext);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "project") {
      setFormData((prevFormData) => ({
        ...prevFormData,
        project: value,
      }));
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };
  const fetchproject = async () => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        firmid: user.firm_id,
        created_by: "Admin",
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/GetFirmProjects
          `,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        setProject(data.data);
      }
    } catch (error) {
      console.log("error", error);
    }
    setTransactionLoaderState(false);
  };
  const handleSubmit = async (e) => {
    console.log("abhi", handleSubmit);
    try {
      setTransactionLoaderState(true);
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");
      var raw = JSON.stringify({
        firmid: user.firm_id,
        empid: "0",
        type: "Admin",
        projectid: formData.project,
        category_name: formData.category,
      });

      console.log("Request sending:", raw);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
      console.log("sending data to abhijeet");
      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/SuperAdmin/AddExpenseCategoriesEmp`,
        requestOptions
      );
      console.log("Api url");
      const data = await res.json();
      console.log("nishant", data);
      if (data.status === "1") {
        swal("Error", data.msg, "error");
        setTransactionLoaderState(false);
        fetchExpenseCategories();
        closeModal();
      } else {
        swal("Success", data.msg, "success");
        setTransactionLoaderState(false);
        fetchExpenseCategories();
        closeModal();
      }
      console.log("Form submission completed.");
    } catch (error) {
      console.log("Error:", error);
    }
  };
  useEffect(() => {
    fetchproject();
  }, []);
  useEffect(() => {
    if (!showModal) {
      setFormData(initialFormData);
    }
  }, [showModal]);
  return (
    <Modal
      show={showModal}
      onHide={closeModal}
      className="d-flex align-items-center"
    >
      <Modal.Header closeButton>
        <Modal.Title>Add Category</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <div className="row">
            <Form.Group className="col">
              <Form.Label> Select Project</Form.Label>
              <Form.Control
                as="select"
                name="project"
                value={formData.project}
                onChange={handleInputChange}
              >
                <option value="">Select Project</option>
                {Project.length === 0 ? (
                  <option>No data found</option>
                ) : (
                  Project.map((project) => (
                    <option
                      key={project.pk_project_id}
                      value={project.pk_project_id}
                    >
                      {project.project_name}
                    </option>
                  ))
                )}
              </Form.Control>
            </Form.Group>
            <Form.Group className="col">
              <Form.Label>Expense Name</Form.Label>
              <Form.Control
                type="text"
                name="category"
                value={formData.category}
                onChange={handleInputChange}
                placeholder="Category Name"
              />
            </Form.Group>{" "}
          </div>

          <button
            className="btn btn-success d-flex align-items-center"
            type="button"
            style={{
              height: "2rem",
              borderRadius: ".3rem",
              fontSize: ".8rem",
            }}
            onClick={handleSubmit}
          >
            Submit
          </button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};
export const Expensecategoryhrmodal = ({
  showModal,
  closeModal,
  fetchExpenseCategories,
}) => {
  const [Project, setProject] = useState([]);
  const { password, username } = billpunchbasicauth;
  const initialFormData = {
    project: "",
    category: "",
  };
  const [formData, setFormData] = useState(initialFormData);
  const { user, setTransactionLoaderState } = useContext(GlobalContext);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "project") {
      setFormData((prevFormData) => ({
        ...prevFormData,
        project: value,
      }));
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };
  const fetchproject = async () => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        firmid: user.firm_id,
        created_by: "Admin",
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/GetFirmProjects
          `,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        setProject(data.data);
      }
    } catch (error) {
      console.log("error", error);
    }
    setTransactionLoaderState(false);
  };
  const handleSubmit = async (e) => {
    console.log("abhi", handleSubmit);
    try {
      setTransactionLoaderState(true);
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");
      var raw = JSON.stringify({
        firmid: user.firm_id,
        empid: "0",
        type: "Admin",
        projectid: formData.project,
        category_name: formData.category,
      });

      console.log("Request sending:", raw);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
      console.log("sending data to abhijeet");
      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/SuperAdmin/AddExpenseCategoriesEmp`,
        requestOptions
      );
      console.log("Api url");
      const data = await res.json();
      console.log("nishant", data);
      if (data.status === "1") {
        swal("Error", data.msg, "error");
        setTransactionLoaderState(false);
        fetchExpenseCategories();
        closeModal();
      } else {
        swal("Success", data.msg, "success");
        setTransactionLoaderState(false);
        fetchExpenseCategories();
        closeModal();
      }
      console.log("Form submission completed.");
    } catch (error) {
      console.log("Error:", error);
    }
  };
  useEffect(() => {
    fetchproject();
  }, []);
  useEffect(() => {
    if (!showModal) {
      setFormData(initialFormData);
    }
  }, [showModal]);
  return (
    <Modal
      show={showModal}
      onHide={closeModal}
      className="d-flex align-items-center"
    >
      <Modal.Header closeButton>
        <Modal.Title>Add Category</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <div className="row">
            <Form.Group className="col">
              <Form.Label> Select Project</Form.Label>
              <Form.Control
                as="select"
                name="project"
                value={formData.project}
                onChange={handleInputChange}
              >
                <option value="">Select Project</option>
                {Project.length === 0 ? (
                  <option>No data found</option>
                ) : (
                  Project.map((project) => (
                    <option
                      key={project.pk_project_id}
                      value={project.pk_project_id}
                    >
                      {project.project_name}
                    </option>
                  ))
                )}
              </Form.Control>
            </Form.Group>
            <Form.Group className="col">
              <Form.Label>Expense Name</Form.Label>
              <Form.Control
                type="text"
                name="category"
                value={formData.category}
                onChange={handleInputChange}
                placeholder="Category Name"
              />
            </Form.Group>{" "}
          </div>

          <button
            className="btn btn-success d-flex align-items-center"
            type="button"
            style={{
              height: "2rem",
              borderRadius: ".3rem",
              fontSize: ".8rem",
            }}
            onClick={handleSubmit}
          >
            Submit
          </button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};
export const Expensecategoryemployeemodal = ({
  showModal,
  closeModal,
  fetchExpenseCategories,
}) => {
  const [Project, setProject] = useState([]);
  const { password, username } = billpunchbasicauth;
  const initialFormData = {
    project: "",
    category: "",
  };
  const [formData, setFormData] = useState(initialFormData);
  const { user, setTransactionLoaderState } = useContext(GlobalContext);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "project") {
      setFormData((prevFormData) => ({
        ...prevFormData,
        project: value,
      }));
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };
  const fetchproject = async () => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        firmid: user.firm_id,
        created_by: "Employee",
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/GetFirmProjects
          `,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        setProject(data.data);
      }
    } catch (error) {
      console.log("error", error);
    }
    setTransactionLoaderState(false);
  };
  const handleSubmit = async (e) => {
    console.log("abhi", handleSubmit);
    try {
      setTransactionLoaderState(true);
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");
      var raw = JSON.stringify({
        firmid: user.firm_id,
        empid: user.userid,
        type: "Emp",
        projectid: formData.project,
        category_name: formData.category,
      });

      console.log("Request sending:", raw);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
      console.log("sending data to abhijeet");
      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/SuperAdmin/AddExpenseCategoriesEmp`,
        requestOptions
      );
      console.log("Api url");
      const data = await res.json();
      console.log("nishant", data);
      if (data.status === "1") {
        swal("Error", data.msg, "error");
        setTransactionLoaderState(false);
        fetchExpenseCategories();
        closeModal();
      } else {
        swal("Success", data.msg, "success");
        setTransactionLoaderState(false);
        fetchExpenseCategories();
        closeModal();
      }
      console.log("Form submission completed.");
    } catch (error) {
      console.log("Error:", error);
    }
  };
  useEffect(() => {
    fetchproject();
  }, []);
  useEffect(() => {
    if (!showModal) {
      setFormData(initialFormData);
    }
  }, [showModal]);
  return (
    <Modal
      show={showModal}
      onHide={closeModal}
      className="d-flex align-items-center"
    >
      <Modal.Header closeButton>
        <Modal.Title>Add Category</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <div className="row">
            <Form.Group className="col">
              <Form.Label> Select Project</Form.Label>
              <Form.Control
                as="select"
                name="project"
                value={formData.project}
                onChange={handleInputChange}
              >
                <option value="">Select Project</option>
                {Project.length === 0 ? (
                  <option>No data found</option>
                ) : (
                  Project.map((project) => (
                    <option
                      key={project.pk_project_id}
                      value={project.pk_project_id}
                    >
                      {project.project_name}
                    </option>
                  ))
                )}
              </Form.Control>
            </Form.Group>
            <Form.Group className="col">
              <Form.Label>Expense Name</Form.Label>
              <Form.Control
                type="text"
                name="category"
                value={formData.category}
                onChange={handleInputChange}
                placeholder="Category Name"
              />
            </Form.Group>{" "}
          </div>

          <button
            className="btn btn-success d-flex align-items-center"
            type="button"
            style={{
              height: "2rem",
              borderRadius: ".3rem",
              fontSize: ".8rem",
            }}
            onClick={handleSubmit}
          >
            Submit
          </button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};
export const Addtaskadmin = ({
  show,
  closeModal,
  getSubsubTask,
  pk_work_id,
  pk_project_id,
}) => {
  const [Member, setMember] = useState([]);
  const [Category, setCategory] = useState([]);
  const [Platform, setPlatform] = useState([]);
  const [Employeeid, setEmployeeid] = useState(-1);
  const [platformId, setPlatformId] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const { password, username } = billpunchbasicauth;

  const [platformName, setPlatformName] = useState("");
  const [categoryName, setCategoryName] = useState("");
  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const [projectfield, setProjectfields] = useState({
    Taskname: "",
    Taskassign: "",
    Taskdesc: "",
    Taskstrt: "",
    Taskend: "",
    TaskPrio: "",
    Resource: "",
  });
  const [projecterror, setProjecterror] = useState({
    Taskname: "",
    Taskassign: "",
    Taskdesc: "",
    Taskstrt: "",
    Taskend: "",
    Resource: "",
  });
  const validateForm = (e) => {
    e.preventDefault();
    let errors = {};
    let formIsValid = true;

    if (!projectfield.Taskname.trim()) {
      errors.Taskname = "*Task name is required";
      formIsValid = false;
    }

    if (!projectfield.Taskdesc.trim()) {
      errors.Taskdesc = "*Task description is required";
      formIsValid = false;
    }

    if (!projectfield.Taskstrt) {
      errors.Taskstrt = "*Start date is required";
      formIsValid = false;
    }

    if (!projectfield.Taskend) {
      errors.Taskend = "*End date is required";
      formIsValid = false;
    }

    if (projectfield.Taskstrt && projectfield.Taskend) {
      const startDate = new Date(projectfield.Taskstrt);
      const endDate = new Date(projectfield.Taskend);
      if (startDate > endDate) {
        errors.Taskend = "*End date cannot be before start date";
        formIsValid = false;
      }
    }

    if (!taskEmpId) {
      errors.Taskassign = "*A task assignee must be selected";
      formIsValid = false;
    }

    if (!selectedMember) {
      errors.Resource = "*Resource is required";
      formIsValid = false;
    }

    if (!projectfield.Taskname.trim()) {
      errors.Taskname = "*Task name is required";
      formIsValid = false;
    }

    if (!projectfield.Taskdesc.trim()) {
      errors.Taskdesc = "*Task description is required";
      formIsValid = false;
    }

    if (!projectfield.TaskPrio) {
      errors.TaskPrio = "*Priority is required";
      formIsValid = false;
    }

    if (!categoryId) {
      errors.categoryName = "*Category is required";
      formIsValid = false;
    }

    if (!platformId) {
      errors.platformName = "*Platform is required";
      formIsValid = false;
    }
    setProjecterror(errors);
    if (Object.keys(errors).length == 0) {
      handleSubmit();
    }
  };
  const handleChange = async (e) => {
    const { name, value } = e.target;
    setProjectfields((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };
  const [members, setMembers] = useState([]);
  const [selectedMember, setSelectedMember] = useState("");
  const [taskAssignee, setTaskAssignee] = useState("");
  const [taskEmpId, setTaskEmpId] = useState("");

  const fetchMembers = async () => {
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      const raw = JSON.stringify({
        workid: pk_work_id,
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/GetWorkEmployee`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0" && data.data) {
        const membersData = data.data.map((emp) => ({
          value: emp.empid,
          label: emp.fullname,
        }));
        setMembers(membersData);
      } else {
        setMembers([]);
      }
    } catch (error) {
      console.log("error", error);
      setMembers([]); // Ensure reset on error too
    }
  };

  const handleMemberChange = (selectedOption) => {
    setTaskAssignee(selectedOption.label);
    setTaskEmpId(selectedOption.value);
    setSelectedMember(selectedOption);
  };

  const options = [
    {
      value: "Urgent",
      label: (
        <div>
          <FaFlag style={{ marginRight: "5px", color: "maroon" }} />
          Urgent
        </div>
      ),
    },
    {
      value: "High",
      label: (
        <div>
          <FaFlag style={{ marginRight: "5px", color: "#f4c430" }} />
          High
        </div>
      ),
    },
    {
      value: "Normal",
      label: (
        <div>
          <FaFlag style={{ marginRight: "5px", color: "blue" }} />
          Normal
        </div>
      ),
    },
    {
      value: "Low",
      label: (
        <div>
          <FaFlag style={{ marginRight: "5px", color: "grey" }} />
          Low
        </div>
      ),
    },
    {
      value: "Clear",
      label: (
        <div>
          {" "}
          <img
            src={require("../../../assets/images/flag_1.png")}
            style={{ marginRight: "5px", width: ".8rem" }}
          />
          Clear
        </div>
      ),
    },
  ];

  const handlePlatformSelection = (selectedPlatform) => {
    setPlatformName(selectedPlatform.label);
    setPlatformId(selectedPlatform.value);
  };

  const handleCategorySelection = (selectedCategory) => {
    setCategoryName(selectedCategory.label);
    setCategoryId(selectedCategory.value);
  };
  const handleSubmit = async (e) => {
    try {
      setTransactionLoaderState(true);

      const selectedEmployee = Member.find(
        (employee) => employee.value === Employeeid
      );
      console.log(selectedEmployee, "selectedEmployee");
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");
      var raw = JSON.stringify({
        spaceid: pk_work_id,
        projectid: pk_project_id,
        firmid: user.firm_id,
        taskname: projectfield.Taskname,
        taskpriority: projectfield.TaskPrio,
        taskdesc: projectfield.Taskdesc,
        taskassigne: taskAssignee,
        task_empid: taskEmpId,
        task_startdate: projectfield.Taskstrt,
        task_endate: projectfield.Taskend,
        status: "P",
        dependency_status: "N",
        platform: platformName,
        category: categoryName,
      });

      console.log("Request sending:", raw);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
      console.log("sending data to abhijeet");
      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/AddTaskEmployee`,
        requestOptions
      );
      console.log("Api url");
      const data = await res.json();
      console.log("nishant", data);
      if (data.status === "1") {
        swal("Error", data.result, "error");
        setTransactionLoaderState(false);
        getSubsubTask();
        closeModal();
      } else {
        swal("Success", data.result, "success");
        setTransactionLoaderState(false);
        getSubsubTask();
        closeModal();
      }
      console.log("Form submission completed.");
    } catch (error) {
      console.log("Error:", error);
    }
  };
  const Getcatogory = async () => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({
          taskid: "string",
        }),
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/GetCatTask`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        const categories = data.data.map((cat) => ({
          value: cat.pk_catid,
          label: cat.catname,
        }));
        setCategory(categories);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.error("error", error);
      setTransactionLoaderState(false);
    }
  };

  const Getplatform = async () => {
    setTransactionLoaderState(true);
    try {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          taskid: "string",
        }),
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/GetTaskPlatform`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        const platformdata = data.data.map((device) => ({
          value: device.pk_platformid,
          label: device.platform_name,
        }));
        setPlatform(platformdata);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.error("error", error);
      setTransactionLoaderState(false);
    }
  };

  useEffect(() => {
    Getcatogory();
    Getplatform();
    fetchMembers();
  }, []);
  const today = new Date().toISOString().split("T")[0];
  return (
    <>
      <Modal show={show} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title className="">Add Task</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="row m-0 p-0">
              <Form.Group className="col">
                <Form.Label> Select Resource</Form.Label>
                <Select
                  id="memberDropdown"
                  value={selectedMember}
                  onChange={handleMemberChange}
                  options={members}
                  placeholder="Select Member"
                />
                {projecterror.Resource && (
                  <div className="text-danger">{projecterror.Resource}</div>
                )}
              </Form.Group>
            </div>
            <div className="row m-0 p-0 ">
              <Form.Group className="col-6 m-0">
                <Form.Label> Task Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Task Name"
                  id="Taskname"
                  name="Taskname"
                  value={projectfield.Taskname}
                  onChange={handleChange}
                />
                {projecterror.Taskname && (
                  <div className="text-danger">{projecterror.Taskname}</div>
                )}
              </Form.Group>
              <Form.Group className="col">
                <Form.Label>Priority</Form.Label>
                <Select
                  options={options}
                  onChange={(option) =>
                    setProjectfields((prevFormData) => ({
                      ...prevFormData,
                      TaskPrio: option.value,
                    }))
                  }
                  placeholder=" Priority"
                />
                {projecterror.TaskPrio && (
                  <div className="text-danger">{projecterror.TaskPrio}</div>
                )}
              </Form.Group>
            </div>
            <div className="row m-0 p-0 ">
              <Form.Group className="col">
                <Form.Label>Category</Form.Label>
                <Select
                  options={Category}
                  onChange={handleCategorySelection}
                  placeholder="Category"
                />
                {projecterror.categoryName && (
                  <div className="text-danger">{projecterror.categoryName}</div>
                )}
              </Form.Group>
              <Form.Group className="col">
                <Form.Label>Platform</Form.Label>
                <Select
                  onChange={handlePlatformSelection}
                  options={Platform}
                  placeholder=" Platform"
                />
                {projecterror.platformName && (
                  <div className="text-danger">{projecterror.platformName}</div>
                )}
              </Form.Group>
            </div>
            <div className="row m-0 p-0">
              {" "}
              <Form.Group className="col-6 ">
                <Form.Label> Start Date</Form.Label>
                <Form.Control
                  type="date"
                  placeholder="Start Date"
                  id="Taskstrt"
                  name="Taskstrt"
                  value={projectfield.Taskstrt}
                  onChange={handleChange}
                />
                {projecterror.Taskstrt && (
                  <p className="text-danger">{projecterror.Taskstrt}</p>
                )}
              </Form.Group>
              <Form.Group className="col-6 ">
                <Form.Label> End Date</Form.Label>
                <Form.Control
                  type="date"
                  placeholder="End Date"
                  id="Taskend"
                  name="Taskend"
                  value={projectfield.Taskend}
                  onChange={handleChange}
                  min={today}
                />
                {projecterror.Taskend && (
                  <p className="text-danger">{projecterror.Taskend}</p>
                )}
              </Form.Group>
            </div>

            <div className="row m-0 p-0">
              <Form.Group className="col">
                <Form.Label>Description</Form.Label>
                <textarea
                  className="form-control"
                  type="textarea"
                  name="Taskdesc"
                  value={projectfield.Taskdesc}
                  onChange={handleChange}
                  placeholder="Project Description"
                />
                {projecterror.Taskdesc && (
                  <div className="text-danger">{projecterror.Taskdesc}</div>
                )}
              </Form.Group>
            </div>
            <div className="col-4 ">
              <button
                type="submit"
                // type="button"
                className="btn btn-primary btn-block d-flex justify-content-center align-items-center"
                style={{ height: "2rem", borderRadius: ".3rem" }}
                onClick={validateForm}
              >
                Add Task
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export const AddSalaryAdmin = ({ show, getSalaryDetails, onHide }) => {
  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const [selectedEmployee, setselectedEmployee] = useState("");
  const [Employeeid, setEmployeeid] = useState(-1);
  const [Member, setMember] = useState([]);
  const { password, username } = billpunchbasicauth;

  const [AddsalaryData, setAddSalaryData] = useState({
    basicPay: "",
    hra: "",
    travelAllowance: "",
    medicalAllowance: "",
    Total: "",
    Employeename: "",
    otherAllowance: "",
    date: "",
  });
  const [errors, setErrors] = useState({
    basicPay: "",
    hra: "",
    travelAllowance: "",
    medicalAllowance: "",
    otherAllowance: "",
    date: "",
  });
  const resetForm = () => {
    setselectedEmployee("");
    setEmployeeid(-1);
    setAddSalaryData({
      basicPay: "",
      hra: "",
      travelAllowance: "",
      medicalAllowance: "",
      otherAllowance: "",
      Total: "",
      Employeename: "",
      date: "",
    });
    setErrors({
      basicPay: "",
      hra: "",
      travelAllowance: "",
      medicalAllowance: "",
      otherAllowance: "",
      date: "",
    });
  };
  const validate = (e) => {
    e.preventDefault();
    console.log(" validate function");
    console.log(AddsalaryData);
    let errors = {};

    if (AddsalaryData.basicPay.trim() === "") {
      errors.basicPay = "*Please enter basic Pay";
    }
    if (AddsalaryData.hra.trim() === "") {
      errors.hra = "*Please enter house rent Allowance";
    }
    if (AddsalaryData.travelAllowance.trim() === "") {
      errors.travelAllowance = "*Please enter travel Allowance";
    }
    if (AddsalaryData.medicalAllowance.trim() === "") {
      errors.medicalAllowance = "*Please enter medical Allowance";
    }
    if (AddsalaryData.otherAllowance.trim() === "") {
      errors.otherAllowance = "*Please enter other Allowance";
    }

    setErrors(errors);
    if (Object.keys(errors).length === 0) {
      handleAddSalary();
    }
    console.log(handleAddSalary, "hlo");
  };

  const GetFirmEmployee = async (e) => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        firmId: user.firm_id,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/getEmployee`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        const mappedEmployees = data.data.map((employee) => ({
          value: employee.empid,
          label: `${employee.firstname} ${employee.lastname}`,
        }));
        setMember(mappedEmployees);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
    }
    setTransactionLoaderState(false);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;

    setAddSalaryData((prevData) => ({
      ...prevData,
      [name]: value.trim(),
    }));
  };

  useEffect(() => {
    const parsedBasicPay = parseFloat(AddsalaryData.basicPay) || 0;
    const parsedHRA = parseFloat(AddsalaryData.hra) || 0;
    const parsedTravelAllowance =
      parseFloat(AddsalaryData.travelAllowance) || 0;
    const parsedMedicalAllowance =
      parseFloat(AddsalaryData.medicalAllowance) || 0;
    const parsedotherAllowance = parseFloat(AddsalaryData.otherAllowance) || 0;

    const totalSalary =
      parsedBasicPay +
      parsedHRA +
      parsedTravelAllowance +
      parsedMedicalAllowance +
      parsedotherAllowance;
    setAddSalaryData((prevData) => ({
      ...prevData,
      Total: totalSalary.toString(),
    }));
  }, [
    AddsalaryData.basicPay,
    AddsalaryData.hra,
    AddsalaryData.travelAllowance,
    AddsalaryData.medicalAllowance,
    AddsalaryData.otherAllowance,
  ]);
  const empId = sessionStorage.getItem("currentEmpId");
  const handleAddSalary = async (e) => {
    setTransactionLoaderState(true);
    try {
      const selectedDate = new Date(AddsalaryData.date);
      const effectiveMonth = selectedDate.toLocaleString("default", {
        month: "long",
        year: "numeric",
      });
      const effectiveDate = selectedDate.toISOString().split("T")[0];

      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");
      var raw = JSON.stringify({
        fk_emp_id: empId,
        basicpay: AddsalaryData.basicPay,
        hra: AddsalaryData.hra,
        travel_allowance: AddsalaryData.travelAllowance,
        medical_allowance: AddsalaryData.medicalAllowance,
        other_allowance: AddsalaryData.otherAllowance,
        totalsalary: AddsalaryData.Total,
        status: "N",
        effective_month: effectiveMonth,
        effective_date: effectiveDate,
      });
      console.log("Request sending:", raw);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
      console.log("sending data to abhijeet");
      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/addSalarydetails`,
        requestOptions
      );
      console.log("Api url");
      const data = await res.json();
      console.log("nishant", data);
      if (data.status === "1") {
        swal("Error", data.result, "error");
        onHide();
        getSalaryDetails();
      } else {
        swal("Success", data.result, "success");
        onHide();
        getSalaryDetails();
      }
      setTransactionLoaderState(false);
      console.log("Form submission completed.");
    } catch (error) {
      console.log("Error:", error);
    }
    setTransactionLoaderState(false);
  };
  const handleEmployeeSelection = (selectedOption) => {
    setselectedEmployee(selectedOption);
    const selectedEmployeeId = selectedOption.value;
    const selectedEmployeeName = selectedOption.label; // Get the selected employee's name
    setEmployeeid(selectedEmployeeId);
    // Update the Employeename field in the state
    setAddSalaryData((prevData) => ({
      ...prevData,
      Employeename: selectedEmployeeName,
    }));
  };

  useEffect(() => {
    GetFirmEmployee();
  }, []);
  useEffect(() => {
    if (!show) {
      resetForm();
    }
  }, [show]);
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Add Salary Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form className="">
          {/* <Form.Group className="col" controlId="Employeename">
            <Form.Label>Select Employee</Form.Label>
            <Select
              options={[
                { value: "", label: "Please Select Employee" },
                ...Member,
              ]}
              value={selectedEmployee}
              onChange={handleEmployeeSelection}
            />
          </Form.Group> */}
          <div className="row m-0 p-0">
            <Form.Group className="col" controlId="basicPay">
              <Form.Label>Basic Pay</Form.Label>
              <Form.Control
                type="text"
                name="basicPay"
                value={AddsalaryData.basicPay}
                onChange={handleChange}
                placeholder="Enter Basic pay"
              />
              {errors.basicPay && (
                <div className="text-danger" style={{ fontSize: ".8rem" }}>
                  {errors.basicPay}
                </div>
              )}
            </Form.Group>
            <Form.Group className="col" controlId="hra">
              <Form.Label>House Rent Allowance</Form.Label>
              <Form.Control
                type="text"
                name="hra"
                value={AddsalaryData.hra}
                onChange={handleChange}
                placeholder="Enter House Rent Allowance"
              />
              {errors.hra && (
                <div className="text-danger" style={{ fontSize: ".8rem" }}>
                  {errors.hra}
                </div>
              )}
            </Form.Group>
          </div>

          <div className="row m-0 p-0">
            {" "}
            <Form.Group className="col" controlId="travelAllowance">
              <Form.Label>Travel Allowance</Form.Label>
              <Form.Control
                type="text"
                name="travelAllowance"
                value={AddsalaryData.travelAllowance}
                onChange={handleChange}
                placeholder="Enter Travel Allowance"
              />
              {errors.travelAllowance && (
                <div className="text-danger" style={{ fontSize: ".8rem" }}>
                  {errors.travelAllowance}
                </div>
              )}
            </Form.Group>
            <Form.Group className="col" controlId="medicalAllowance">
              <Form.Label>Medical Allowance</Form.Label>
              <Form.Control
                type="text"
                name="medicalAllowance"
                value={AddsalaryData.medicalAllowance}
                onChange={handleChange}
                placeholder="Enter Medical Allowance"
              />
              {errors.medicalAllowance && (
                <div className="text-danger" style={{ fontSize: ".8rem" }}>
                  {errors.medicalAllowance}
                </div>
              )}
            </Form.Group>
          </div>
          <div className="row m-0 p-0">
            <Form.Group className="col" controlId="medicalAllowance">
              <Form.Label>Other Allowance</Form.Label>
              <Form.Control
                type="text"
                name="otherAllowance"
                value={AddsalaryData.otherAllowance}
                onChange={handleChange}
                placeholder="Enter other Allowance"
              />
              {errors.otherAllowance && (
                <div className="text-danger" style={{ fontSize: ".8rem" }}>
                  {errors.otherAllowance}
                </div>
              )}
            </Form.Group>
            <Form.Group className="col" controlId="medicalAllowance">
              <Form.Label>Total Salary</Form.Label>
              <Form.Control
                readOnly
                type="text"
                name="Total"
                value={AddsalaryData.Total}
                onChange={handleChange}
                placeholder="Enter Total Salary"
              />
            </Form.Group>
          </div>

          <Form.Group className="col" controlId="medicalAllowance">
            <Form.Label> Date</Form.Label>
            <Form.Control
              type="date"
              name="date"
              value={AddsalaryData.date}
              onChange={handleChange}
              placeholder=" Date"
            />
          </Form.Group>
          <button
            type="submit"
            className="btn btn-primary mx-3"
            style={{
              color: "#fff",
              height: "2rem",
              border: "none",
              borderRadius: ".4rem",
              display: "flex",
              alignItems: "center",
              width: "7rem",
              fontSize: ".8rem",
            }}
            onClick={validate}
          >
            Add Salary
          </button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};
export const UpdateSalaryModalAdmin = ({
  show,
  onHide,
  empid,
  getEmployeeList,
}) => {
  const employeeName = sessionStorage.getItem("employeeName") || "";
  console.log("Retrieved Employee Name:", employeeName);
  const { setTransactionLoaderState } = useContext(GlobalContext);
  const [pk_s_id, setPkSId] = useState("");
  const { password, username } = billpunchbasicauth;

  const [salaryData, setSalaryData] = useState({
    basicPay: "",
    hra: "",
    travelAllowance: "",
    medicalAllowance: "",
    otherAllowance: "",
    Total: "",
  });
  const [errors, setErrors] = useState({
    basicPay: "",
    hra: "",
    travelAllowance: "",
    medicalAllowance: "",
    otherAllowance: "",
    Total: "",
  });
  const validate = (e) => {
    e.preventDefault();
    console.log(" validate function");
    console.log(salaryData);
    let errors = {};
    if (salaryData.basicPay.trim() == "") {
      errors.basicPay = "*Please enter basic Pay";
    }
    if (salaryData.hra.trim() == "") {
      errors.hra = "*Please enter house rent Allowance";
    }
    if (salaryData.travelAllowance.trim() == "") {
      errors.travelAllowance = "*Please enter travel Allowance";
    }
    if (salaryData.medicalAllowance.trim() == "") {
      errors.medicalAllowance = "*Please enter medical Allowance";
    }
    if (salaryData.otherAllowance.trim() == "") {
      errors.otherAllowance = "*Please enter other Allowance";
    }
    if (salaryData.Total.trim() == "") {
      errors.Total = "*Please enter Total Salary";
    }
    setErrors(errors);
    if (Object.keys(errors).length == 0) {
      handleSubmit();
    }
  };

  const handleChange = (e) => {
    setSalaryData({
      ...salaryData,
      [e.target.name]: e.target.value.trim(),
    });
  };
  useEffect(() => {
    const parsedBasicPay = parseFloat(salaryData.basicPay) || 0;
    const parsedHRA = parseFloat(salaryData.hra) || 0;
    const parsedTravelAllowance = parseFloat(salaryData.travelAllowance) || 0;
    const parsedMedicalAllowance = parseFloat(salaryData.medicalAllowance) || 0;
    const parsedotherAllowance = parseFloat(salaryData.otherAllowance) || 0;

    const totalSalary =
      parsedBasicPay +
      parsedHRA +
      parsedTravelAllowance +
      parsedMedicalAllowance +
      parsedotherAllowance;

    setSalaryData((prevData) => ({
      ...prevData,
      Total: totalSalary.toString(),
    }));
  }, [
    salaryData.basicPay,
    salaryData.hra,
    salaryData.travelAllowance,
    salaryData.medicalAllowance,
    salaryData.otherAllowance,
  ]);

  const empId = sessionStorage.getItem("currentEmpId");
  const getSalaryDetails = async () => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        empid: empId,
      });
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/getSalaryDetails`,
        requestOptions
      );
      const data = await res.json();

      if (data.status === "0" && data.data.length > 0) {
        const salaryDetails = data.data[0];
        setPkSId(salaryDetails.pk_s_id);

        setSalaryData({
          bankName: salaryDetails.bankname,
          ifsc: salaryDetails.bankifsc,
          accountNumber: salaryDetails.bankaccno,
          basicPay: salaryDetails.basicpay,
          hra: salaryDetails.hra,
          travelAllowance: salaryDetails.travel_allowance,
          medicalAllowance: salaryDetails.medical_allowance,
          otherAllowance: salaryDetails.other_allowance,
          Total: salaryDetails.totalsalary,
        });
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
    }
    setTransactionLoaderState(false);
  };

  const handleSubmit = async (e) => {
    try {
      const requestBody = {
        pk_s_id: pk_s_id,
        fk_emp_id: empid,
        basicpay: salaryData.basicPay,
        hra: salaryData.hra,
        travel_allowance: salaryData.travelAllowance,
        medical_allowance: salaryData.medicalAllowance,
        other_allowance: salaryData.otherAllowance,
        totalsalary: salaryData.Total,
      };
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify(requestBody);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/updateSalaryDetails`,
        requestOptions
      );

      const data = await res.json();
      if (data.status === "1") {
        swal("Error", data.result, "error");
        onHide();
        getEmployeeList();
      } else {
        swal("Success", data.result, "success");
        onHide();
        getEmployeeList();
      }
      console.log("Form submission completed.");
    } catch (error) {
      console.log("Error:", error);
    }
  };
  useEffect(() => {
    getSalaryDetails();
  }, [empid]);

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>
          <h6>Update Salary for {employeeName}</h6>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={validate}>
          <div className="row m-0 p-0">
            <Form.Group className="col-6" controlId="basicPay">
              <Form.Label>Basic Pay</Form.Label>
              <Form.Control
                type="text"
                name="basicPay"
                value={salaryData.basicPay}
                onChange={handleChange}
                placeholder="Enter Basic pay"
              />
              {errors.basicPay && (
                <div className="text-danger" style={{ fontSize: ".8rem" }}>
                  {errors.basicPay}
                </div>
              )}
            </Form.Group>{" "}
            <Form.Group className="col-6" controlId="hra">
              <Form.Label>House Rent Allowance</Form.Label>
              <Form.Control
                type="text"
                name="hra"
                value={salaryData.hra}
                onChange={handleChange}
                placeholder="Enter House Rent Allowance"
              />
              {errors.hra && (
                <div className="text-danger" style={{ fontSize: ".8rem" }}>
                  {errors.hra}
                </div>
              )}
            </Form.Group>
          </div>

          <div className="row m-0 p-0">
            <Form.Group className="col-6" controlId="travelAllowance">
              <Form.Label>Travel Allowance</Form.Label>
              <Form.Control
                type="text"
                name="travelAllowance"
                value={salaryData.travelAllowance}
                onChange={handleChange}
                placeholder="Enter Travel Allowance"
              />
              {errors.travelAllowance && (
                <div className="text-danger" style={{ fontSize: ".8rem" }}>
                  {errors.travelAllowance}
                </div>
              )}
            </Form.Group>
            <Form.Group className="col-6" controlId="medicalAllowance">
              <Form.Label>Medical Allowance</Form.Label>
              <Form.Control
                type="text"
                name="medicalAllowance"
                value={salaryData.medicalAllowance}
                onChange={handleChange}
                placeholder="Enter Medical Allowance"
              />
              {errors.medicalAllowance && (
                <div className="text-danger" style={{ fontSize: ".8rem" }}>
                  {errors.medicalAllowance}
                </div>
              )}
            </Form.Group>
          </div>

          <div className="row m-0 p-0">
            <Form.Group className="col-6" controlId="medicalAllowance">
              <Form.Label>Other Allowance</Form.Label>
              <Form.Control
                type="text"
                name="otherAllowance"
                value={salaryData.otherAllowance}
                onChange={handleChange}
                placeholder="Enter Other Allowance"
              />
              {errors.otherAllowance && (
                <div className="text-danger" style={{ fontSize: ".8rem" }}>
                  {errors.otherAllowance}
                </div>
              )}
            </Form.Group>
            <Form.Group className="col-6" controlId="medicalAllowance">
              <Form.Label>Total Salary</Form.Label>
              <Form.Control
                type="text"
                name="Total"
                value={salaryData.Total}
                onChange={handleChange}
                placeholder="Enter Total Salary"
              />
              {errors.Total && (
                <div className="text-danger" style={{ fontSize: ".8rem" }}>
                  {errors.Total}
                </div>
              )}
            </Form.Group>
          </div>
          <div className="row p-0 m-0">
            <Link className="mx-md-3 mx-sm-2 ">
              <button
                // type="submit"
                className="btn btn-primary d-flex align-items-center "
                style={{ height: "2rem", borderRadius: ".4rem" }}
                onClick={validate}
              >
                Update
              </button>
            </Link>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};
export const UpgradeSalaryModalAdmin = ({
  show,
  onHide,
  empid,
  getEmployeeList,
}) => {
  const employeeName = sessionStorage.getItem("employeeName") || "";
  console.log("Retrieved Employee Name:", employeeName);
  const { setTransactionLoaderState } = useContext(GlobalContext);
  const { password, username } = billpunchbasicauth;

  const [salaryData, setSalaryData] = useState({
    basicPay: "",
    hra: "",
    travelAllowance: "",
    medicalAllowance: "",
    otherAllowance: "",
    Total: "",
    pf: "",
    esic: "",
    tds: "",
    datetime: "",
  });
  const [errors, setErrors] = useState({
    basicPay: "",
    hra: "",
    travelAllowance: "",
    medicalAllowance: "",
    otherAllowance: "",
    Total: "",
    pf: "",
    esic: "",
    tds: "",
    datetime: "",
  });

  const handleChange = (e) => {
    setSalaryData({
      ...salaryData,
      [e.target.name]: e.target.value,
    });
  };

  const validate = (e) => {
    e.preventDefault();
    let errors = {};
    if (!salaryData.basicPay?.trim())
      errors.basicPay = "*Please enter basic Pay";
    if (!salaryData.hra?.trim())
      errors.hra = "*Please enter house rent Allowance";
    if (!salaryData.travelAllowance?.trim())
      errors.travelAllowance = "*Please enter travel Allowance";
    if (!salaryData.medicalAllowance?.trim())
      errors.medicalAllowance = "*Please enter medical Allowance";
    if (!salaryData.otherAllowance?.trim())
      errors.otherAllowance = "*Please enter other Allowance";
    if (!salaryData.Total?.trim()) errors.Total = "*Please enter Total Salary";
    if (!salaryData.pf?.trim()) errors.pf = "*Please enter EPF";
    if (!salaryData.esic?.trim()) errors.esic = "*Please enter ESIC amount";
    if (!salaryData.tds?.trim()) errors.tds = "*Please enter TDS";
    if (!salaryData.datetime?.trim())
      errors.datetime = "*Date and time required";

    setErrors(errors);
    if (Object.keys(errors).length === 0) {
      handleSubmit();
    }
  };

  // const handleChange = (e) => {
  //   setSalaryData({
  //     ...salaryData,
  //     [e.target.name]: e.target.value.trim(),
  //   });
  // };

  const getSalaryDetails = async () => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        empid: empid,
      });
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/getSalaryDetails`,
        requestOptions
      );
      const data = await res.json();

      if (data.status === "0" && data.data.length > 0) {
        const salaryDetails = data.data[0];

        setSalaryData({
          bankName: salaryDetails.bankname,
          ifsc: salaryDetails.bankifsc,
          accountNumber: salaryDetails.bankaccno,
          basicPay: salaryDetails.basicpay,
          hra: salaryDetails.hra,
          travelAllowance: salaryDetails.travel_allowance,
          medicalAllowance: salaryDetails.medical_allowance,
          otherAllowance: salaryDetails.other_allowance,
          Total: salaryDetails.totalsalary,
        });
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
    }
    setTransactionLoaderState(false);
  };

  const handleSubmit = async () => {
    const date = new Date(salaryData.datetime);
    const effectiveMonth =
      date instanceof Date && !isNaN(date)
        ? `${date.toLocaleString("default", {
            month: "long",
          })} ${date.getFullYear()}`
        : setTransactionLoaderState(true);
    try {
      const requestBody = {
        fk_emp_id: empid,
        basicpay: salaryData.basicPay,
        hra: salaryData.hra,
        travel_allowance: salaryData.travelAllowance,
        medical_allowance: salaryData.medicalAllowance,
        pf: salaryData.pf,
        other_allowance: salaryData.otherAllowance,
        esic: salaryData.esic,
        tds: salaryData.tds,
        totalsalary: salaryData.Total,
        effective_month: effectiveMonth,
        effective_date_time: salaryData.datetime,
      };

      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify(requestBody),
        redirect: "follow",
      };

      const response = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/UpgradeSalaryEmployees`,
        requestOptions
      );
      const data = await response.json();
      if (data.status === "0") {
        swal("Success", data.result, "success");
      } else {
        swal("Error", data.result, "error");
      }
      onHide();
      getEmployeeList();
    } catch (error) {
      console.error("Error:", error);
      swal("Error", "Failed to update salary", "error");
    } finally {
      setTransactionLoaderState(false);
    }
  };
  useEffect(() => {
    getSalaryDetails();
  }, [empid]);

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>
          <h6>Update Salary for {employeeName}</h6>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={validate}>
          <div className="row m-0 p-0">
            <Form.Group className="col-6" controlId="basicPay">
              <Form.Label>Basic Pay</Form.Label>
              <Form.Control
                type="text"
                name="basicPay"
                value={salaryData.basicPay}
                onChange={handleChange}
                placeholder="Enter Basic pay"
              />
              {errors.basicPay && (
                <div className="text-danger" style={{ fontSize: ".8rem" }}>
                  {errors.basicPay}
                </div>
              )}
            </Form.Group>{" "}
            <Form.Group className="col-6" controlId="hra">
              <Form.Label>House Rent Allowance</Form.Label>
              <Form.Control
                type="text"
                name="hra"
                value={salaryData.hra}
                onChange={handleChange}
                placeholder="Enter House Rent Allowance"
              />
              {errors.hra && (
                <div className="text-danger" style={{ fontSize: ".8rem" }}>
                  {errors.hra}
                </div>
              )}
            </Form.Group>
          </div>

          <div className="row m-0 p-0">
            <Form.Group className="col-6" controlId="travelAllowance">
              <Form.Label>Travel Allowance</Form.Label>
              <Form.Control
                type="text"
                name="travelAllowance"
                value={salaryData.travelAllowance}
                onChange={handleChange}
                placeholder="Enter Travel Allowance"
              />
              {errors.travelAllowance && (
                <div className="text-danger" style={{ fontSize: ".8rem" }}>
                  {errors.travelAllowance}
                </div>
              )}
            </Form.Group>
            <Form.Group className="col-6" controlId="medicalAllowance">
              <Form.Label>Medical Allowance</Form.Label>
              <Form.Control
                type="text"
                name="medicalAllowance"
                value={salaryData.medicalAllowance}
                onChange={handleChange}
                placeholder="Enter Medical Allowance"
              />
              {errors.medicalAllowance && (
                <div className="text-danger" style={{ fontSize: ".8rem" }}>
                  {errors.medicalAllowance}
                </div>
              )}
            </Form.Group>
          </div>

          <div className="row m-0 p-0">
            <Form.Group className="col-6" controlId="medicalAllowance">
              <Form.Label>Other Allowance</Form.Label>
              <Form.Control
                type="text"
                name="otherAllowance"
                value={salaryData.otherAllowance}
                onChange={handleChange}
                placeholder="Enter Other Allowance"
              />
              {errors.otherAllowance && (
                <div className="text-danger" style={{ fontSize: ".8rem" }}>
                  {errors.otherAllowance}
                </div>
              )}
            </Form.Group>
            <Form.Group className="col-6" controlId="medicalAllowance">
              <Form.Label>Total Salary</Form.Label>
              <Form.Control
                type="text"
                name="Total"
                value={salaryData.Total}
                onChange={handleChange}
                placeholder="Enter Total Salary"
              />
              {errors.Total && (
                <div className="text-danger" style={{ fontSize: ".8rem" }}>
                  {errors.Total}
                </div>
              )}
            </Form.Group>
          </div>
          <div className="row m-0 p-0">
            <Form.Group className="col-6" controlId="travelAllowance">
              <Form.Label>EPF</Form.Label>
              <Form.Control
                type="text"
                name="pf"
                value={salaryData.pf}
                onChange={handleChange}
                placeholder="Enter EPF amount"
              />
              {errors.pf && (
                <div className="text-danger" style={{ fontSize: ".8rem" }}>
                  {errors.pf}
                </div>
              )}
            </Form.Group>
            <Form.Group className="col-6" controlId="medicalAllowance">
              <Form.Label>ESIC</Form.Label>
              <Form.Control
                type="text"
                name="esic"
                value={salaryData.esic}
                onChange={handleChange}
                placeholder="Enter esic amount"
              />
              {errors.esic && (
                <div className="text-danger" style={{ fontSize: ".8rem" }}>
                  {errors.esic}
                </div>
              )}
            </Form.Group>
          </div>

          <div className="row m-0 p-0">
            <Form.Group className="col-6" controlId="medicalAllowance">
              <Form.Label>TDS</Form.Label>
              <Form.Control
                type="text"
                name="tds"
                value={salaryData.tds}
                onChange={handleChange}
                placeholder="Enter tds amount"
              />
              {errors.tds && (
                <div className="text-danger" style={{ fontSize: ".8rem" }}>
                  {errors.tds}
                </div>
              )}
            </Form.Group>
            <Form.Group className="col-6" controlId="medicalAllowance">
              <Form.Label>Effective Date</Form.Label>
              <Form.Control
                type="date"
                name="datetime"
                value={salaryData.datetime}
                onChange={handleChange}
                placeholder="Date"
              />
              {errors.datetime && (
                <div className="text-danger" style={{ fontSize: ".8rem" }}>
                  {errors.datetime}
                </div>
              )}
            </Form.Group>
          </div>
          <div className="row p-0 m-0">
            <Link className="mx-md-3 mx-sm-2 ">
              <button
                // type="submit"
                className="btn btn-primary d-flex align-items-center "
                style={{ height: "2rem", borderRadius: ".4rem" }}
                onClick={validate}
              >
                Update
              </button>
            </Link>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};
export const UpgradeSalaryModalHr = ({
  show,
  onHide,
  empid,
  getEmployeeList,
}) => {
  const { password, username } = billpunchbasicauth;

  const employeeName = sessionStorage.getItem("employeeName") || "";
  console.log("Retrieved Employee Name:", employeeName);
  const { setTransactionLoaderState } = useContext(GlobalContext);
  const [salaryData, setSalaryData] = useState({
    basicPay: "",
    hra: "",
    travelAllowance: "",
    medicalAllowance: "",
    otherAllowance: "",
    Total: "",
    pf: "",
    esic: "",
    tds: "",
    datetime: "",
  });
  const [errors, setErrors] = useState({
    basicPay: "",
    hra: "",
    travelAllowance: "",
    medicalAllowance: "",
    otherAllowance: "",
    Total: "",
    pf: "",
    esic: "",
    tds: "",
    datetime: "",
  });

  const handleChange = (e) => {
    setSalaryData({
      ...salaryData,
      [e.target.name]: e.target.value,
    });
  };

  const validate = (e) => {
    e.preventDefault();
    let errors = {};
    if (!salaryData.basicPay?.trim())
      errors.basicPay = "*Please enter basic Pay";
    if (!salaryData.hra?.trim())
      errors.hra = "*Please enter house rent Allowance";
    if (!salaryData.travelAllowance?.trim())
      errors.travelAllowance = "*Please enter travel Allowance";
    if (!salaryData.medicalAllowance?.trim())
      errors.medicalAllowance = "*Please enter medical Allowance";
    if (!salaryData.otherAllowance?.trim())
      errors.otherAllowance = "*Please enter other Allowance";
    if (!salaryData.Total?.trim()) errors.Total = "*Please enter Total Salary";
    if (!salaryData.pf?.trim()) errors.pf = "*Please enter EPF";
    if (!salaryData.esic?.trim()) errors.esic = "*Please enter ESIC amount";
    if (!salaryData.tds?.trim()) errors.tds = "*Please enter TDS";
    if (!salaryData.datetime?.trim())
      errors.datetime = "*Date and time required";

    setErrors(errors);
    if (Object.keys(errors).length === 0) {
      handleSubmit();
    }
  };

  // const handleChange = (e) => {
  //   setSalaryData({
  //     ...salaryData,
  //     [e.target.name]: e.target.value.trim(),
  //   });
  // };

  const getSalaryDetails = async () => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        empid: empid,
      });
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/getSalaryDetails`,
        requestOptions
      );
      const data = await res.json();

      if (data.status === "0" && data.data.length > 0) {
        const salaryDetails = data.data[0];

        setSalaryData({
          bankName: salaryDetails.bankname,
          ifsc: salaryDetails.bankifsc,
          accountNumber: salaryDetails.bankaccno,
          basicPay: salaryDetails.basicpay,
          hra: salaryDetails.hra,
          travelAllowance: salaryDetails.travel_allowance,
          medicalAllowance: salaryDetails.medical_allowance,
          otherAllowance: salaryDetails.other_allowance,
          Total: salaryDetails.totalsalary,
        });
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
    }
    setTransactionLoaderState(false);
  };

  const handleSubmit = async () => {
    const date = new Date(salaryData.datetime);
    const effectiveMonth =
      date instanceof Date && !isNaN(date)
        ? `${date.toLocaleString("default", {
            month: "long",
          })} ${date.getFullYear()}`
        : setTransactionLoaderState(true);
    try {
      const requestBody = {
        fk_emp_id: empid,
        basicpay: salaryData.basicPay,
        hra: salaryData.hra,
        travel_allowance: salaryData.travelAllowance,
        medical_allowance: salaryData.medicalAllowance,
        pf: salaryData.pf,
        other_allowance: salaryData.otherAllowance,
        esic: salaryData.esic,
        tds: salaryData.tds,
        totalsalary: salaryData.Total,
        effective_month: effectiveMonth,
        effective_date_time: salaryData.datetime,
      };

      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify(requestBody),
        redirect: "follow",
      };

      const response = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/UpgradeSalaryEmployees`,
        requestOptions
      );
      const data = await response.json();
      if (data.status === "0") {
        swal("Success", data.result, "success");
      } else {
        swal("Error", data.result, "error");
      }
      onHide();
      getEmployeeList();
    } catch (error) {
      console.error("Error:", error);
      swal("Error", "Failed to update salary", "error");
    } finally {
      setTransactionLoaderState(false);
    }
  };
  useEffect(() => {
    getSalaryDetails();
  }, [empid]);

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>
          <h6>Update Salary for {employeeName}</h6>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={validate}>
          <div className="row m-0 p-0">
            <Form.Group className="col-6" controlId="basicPay">
              <Form.Label>Basic Pay</Form.Label>
              <Form.Control
                type="text"
                name="basicPay"
                value={salaryData.basicPay}
                onChange={handleChange}
                placeholder="Enter Basic pay"
              />
              {errors.basicPay && (
                <div className="text-danger" style={{ fontSize: ".8rem" }}>
                  {errors.basicPay}
                </div>
              )}
            </Form.Group>{" "}
            <Form.Group className="col-6" controlId="hra">
              <Form.Label>House Rent Allowance</Form.Label>
              <Form.Control
                type="text"
                name="hra"
                value={salaryData.hra}
                onChange={handleChange}
                placeholder="Enter House Rent Allowance"
              />
              {errors.hra && (
                <div className="text-danger" style={{ fontSize: ".8rem" }}>
                  {errors.hra}
                </div>
              )}
            </Form.Group>
          </div>

          <div className="row m-0 p-0">
            <Form.Group className="col-6" controlId="travelAllowance">
              <Form.Label>Travel Allowance</Form.Label>
              <Form.Control
                type="text"
                name="travelAllowance"
                value={salaryData.travelAllowance}
                onChange={handleChange}
                placeholder="Enter Travel Allowance"
              />
              {errors.travelAllowance && (
                <div className="text-danger" style={{ fontSize: ".8rem" }}>
                  {errors.travelAllowance}
                </div>
              )}
            </Form.Group>
            <Form.Group className="col-6" controlId="medicalAllowance">
              <Form.Label>Medical Allowance</Form.Label>
              <Form.Control
                type="text"
                name="medicalAllowance"
                value={salaryData.medicalAllowance}
                onChange={handleChange}
                placeholder="Enter Medical Allowance"
              />
              {errors.medicalAllowance && (
                <div className="text-danger" style={{ fontSize: ".8rem" }}>
                  {errors.medicalAllowance}
                </div>
              )}
            </Form.Group>
          </div>

          <div className="row m-0 p-0">
            <Form.Group className="col-6" controlId="medicalAllowance">
              <Form.Label>Other Allowance</Form.Label>
              <Form.Control
                type="text"
                name="otherAllowance"
                value={salaryData.otherAllowance}
                onChange={handleChange}
                placeholder="Enter Other Allowance"
              />
              {errors.otherAllowance && (
                <div className="text-danger" style={{ fontSize: ".8rem" }}>
                  {errors.otherAllowance}
                </div>
              )}
            </Form.Group>
            <Form.Group className="col-6" controlId="medicalAllowance">
              <Form.Label>Total Salary</Form.Label>
              <Form.Control
                type="text"
                name="Total"
                value={salaryData.Total}
                onChange={handleChange}
                placeholder="Enter Total Salary"
              />
              {errors.Total && (
                <div className="text-danger" style={{ fontSize: ".8rem" }}>
                  {errors.Total}
                </div>
              )}
            </Form.Group>
          </div>
          <div className="row m-0 p-0">
            <Form.Group className="col-6" controlId="travelAllowance">
              <Form.Label>EPF</Form.Label>
              <Form.Control
                type="text"
                name="pf"
                value={salaryData.pf}
                onChange={handleChange}
                placeholder="Enter EPF amount"
              />
              {errors.pf && (
                <div className="text-danger" style={{ fontSize: ".8rem" }}>
                  {errors.pf}
                </div>
              )}
            </Form.Group>
            <Form.Group className="col-6" controlId="medicalAllowance">
              <Form.Label>ESIC</Form.Label>
              <Form.Control
                type="text"
                name="esic"
                value={salaryData.esic}
                onChange={handleChange}
                placeholder="Enter esic amount"
              />
              {errors.esic && (
                <div className="text-danger" style={{ fontSize: ".8rem" }}>
                  {errors.esic}
                </div>
              )}
            </Form.Group>
          </div>

          <div className="row m-0 p-0">
            <Form.Group className="col-6" controlId="medicalAllowance">
              <Form.Label>TDS</Form.Label>
              <Form.Control
                type="text"
                name="tds"
                value={salaryData.tds}
                onChange={handleChange}
                placeholder="Enter tds amount"
              />
              {errors.tds && (
                <div className="text-danger" style={{ fontSize: ".8rem" }}>
                  {errors.tds}
                </div>
              )}
            </Form.Group>
            <Form.Group className="col-6" controlId="medicalAllowance">
              <Form.Label>Effective Date </Form.Label>
              <Form.Control
                type="date"
                name="datetime"
                value={salaryData.datetime}
                onChange={handleChange}
                placeholder="Date"
              />
              {errors.datetime && (
                <div className="text-danger" style={{ fontSize: ".8rem" }}>
                  {errors.datetime}
                </div>
              )}
            </Form.Group>
          </div>
          <div className="row p-0 m-0">
            <Link className="mx-md-3 mx-sm-2 ">
              <button
                // type="submit"
                className="btn btn-primary d-flex align-items-center "
                style={{ height: "2rem", borderRadius: ".4rem" }}
                onClick={validate}
              >
                Update
              </button>
            </Link>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};
export const EditEmployeeModalAdmin = ({
  show,
  handleInputChange,
  onHide,
  employeeData,
  formData,
  setFormData,
  handleEditSubmit,
  errors,
  setErrors,
  validateForm,
}) => {
  const formatDateString = (date) => {
    if (!date) return "";
    const d = new Date(date);
    const year = d.getFullYear();
    const month = ("0" + (d.getMonth() + 1)).slice(-2);
    const day = ("0" + d.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
  };
  const [banks, setBanks] = useState([]);
  const [selectedBank, setSelectedBank] = useState({ bankName: "", ifsc: "" });
  const { password, username } = billpunchbasicauth;

  const { user } = useContext(GlobalContext);
  const { setTransactionLoaderState } = useContext(GlobalContext);
  const [departmentData, setDepartmentData] = useState([]);
  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "bankName") {
      const foundBank = banks.find((bank) => bank.bankName === value);
      setSelectedBank(foundBank || { bankName: "", ifsc: "" });
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };
  const getDepartmentList = async () => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({ firmid: user.firm_id });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/getEmployeeDepartment`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        setDepartmentData(data.data);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
    }
  };
  useEffect(() => {
    if (show && employeeData) {
      setFormData({
        firstname: employeeData.firstname,
        middlename: employeeData.middlename,
        lastname: employeeData.lastname,
        mobile: employeeData.mobile,
        email: employeeData.email,
        address: employeeData.address,
        designation: employeeData.designation,
        dob: employeeData.dob,
        joiningdate: employeeData.joiningdate,
        bankName: employeeData.bankname,
        ifscCode: employeeData.bankifsc,
        accountNumber: employeeData.bankaccno,
        department: employeeData.department,
        emp_type: employeeData.emp_type,
        workemail: employeeData.work_email,
        pan: employeeData.pan,
        qualification: employeeData.qualification,
      });
    }
  }, [employeeData]);

  useEffect(() => {
    getDepartmentList();
    // getBanks();
  }, []);

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Edit Employee</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={validateForm} className=" ">
          <div className="row m-0 p-0 ">
            {" "}
            <Form.Group className="col-6 " controlId="pincode">
              <Form.Label> First Name</Form.Label>
              <Form.Control
                type="text"
                className="form-control"
                placeholder="First Name"
                id="firstname"
                name="firstname"
                value={formData.firstname}
                onChange={handleChange}
              />
              {errors.firstname && (
                <div className="text-danger">{errors.firstname}</div>
              )}
            </Form.Group>
            <Form.Group className="col-6 ">
              <Form.Label> Middle Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Middle Name"
                id="middlename"
                name="middlename"
                value={formData.middlename}
                onChange={handleChange}
              />
              {errors.middlename && (
                <div className="text-danger">{errors.middlename}</div>
              )}
            </Form.Group>
          </div>
          <div className="row m-0 p-0 ">
            <Form.Group className="col-6" controlId="pincode">
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Last Name"
                className="form-control"
                id="lastname"
                name="lastname"
                value={formData.lastname}
                onChange={handleChange}
              />
              {errors.lastname && (
                <div className="text-danger">{errors.lastname}</div>
              )}
            </Form.Group>
            <Form.Group className="col-6 ">
              <Form.Label htmlFor="name">Mobile No</Form.Label>
              <Form.Control
                maxLength={10}
                type="text"
                className="form-control"
                placeholder="Mobile No"
                id="mobile"
                name="mobile"
                value={formData.mobile}
                onChange={handleChange}
              />
              {errors.mobile && (
                <div className="text-danger">{errors.mobile}</div>
              )}
            </Form.Group>
          </div>
          <div className="row m-0 p-0 ">
            <Form.Group className=" col-6 ">
              <Form.Label htmlFor="name">Email ID</Form.Label>
              <Form.Control
                type="email"
                className="form-control"
                placeholder="Email ID"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
              />
              {errors.email && (
                <div className="text-danger">{errors.email}</div>
              )}
            </Form.Group>
            <Form.Group className="col-6 " controlId="workemail">
              <label htmlFor="name">Work Email</label>
              <Form.Control
                type="text"
                id="workemail"
                name="workemail"
                placeholder="Enter Your workemail"
                value={formData.workemail}
                onChange={handleChange}
              />
              {errors.workemail && (
                <div className="text-danger">{errors.workemail}</div>
              )}
            </Form.Group>
          </div>
          <div className="row m-0 p-0 ">
            <Form.Group className="col-6" controlId="pincode">
              <Form.Label>Designation</Form.Label>
              <Form.Control
                type="text"
                className="form-control"
                id="designation"
                placeholder="Designation"
                name="designation"
                value={formData.designation}
                onChange={handleChange}
              />
              {errors.designation && (
                <div className="text-danger">{errors.designation}</div>
              )}
            </Form.Group>
            <Form.Group className="col-6" controlId="Department">
              <Form.Label>Department</Form.Label>
              <Form.Control
                as="select"
                name="department"
                value={formData.department}
                onChange={handleChange}
              >
                <option value="">Department</option>
                {departmentData.map((dept) => (
                  <option key={dept.id} value={dept.cat1}>
                    {dept.cat1}
                  </option>
                ))}
              </Form.Control>
              {errors.department && (
                <div className="text-danger">{errors.department}</div>
              )}
            </Form.Group>
          </div>

          <div className="row m-0 p-0 ">
            <Form.Group className="col-6" controlId="Emptype">
              <Form.Label>Employee Type</Form.Label>
              <Form.Control
                as="select"
                name="emp_type"
                value={formData.emp_type}
                onChange={handleChange}
              >
                <option value="">Employee Type</option>
                <option value=" Intern">Intern</option>
                <option value=" Full Time">Full Time</option>
                <option value="Contract Based">Contract Based</option>
              </Form.Control>
              {errors.emp_type && (
                <div className="text-danger">{errors.emp_type}</div>
              )}
            </Form.Group>
            <Form.Group className="col-6 ">
              <Form.Label htmlFor="name"> Address</Form.Label>
              <Form.Control
                type="text"
                className="form-control"
                id="address"
                name="address"
                placeholder="Enter Your address"
                value={formData.address}
                onChange={handleChange}
              />
              {errors.address && (
                <div className="text-danger">{errors.address}</div>
              )}
            </Form.Group>
          </div>
          <div className="row m-0 p-0 ">
            <Form.Group className="col-6 " controlId="pan">
              <label htmlFor="name">Your PAN Number </label>
              <Form.Control
                type="text"
                id="pan"
                name="pan"
                placeholder="Enter Your PAN"
                value={formData.pan}
                onChange={handleChange}
              />
              {errors.pan && <div className="text-danger">{errors.pan}</div>}
            </Form.Group>
            <Form.Group className="col-6 " controlId="ifscCode">
              <label htmlFor="name">Your Qualification</label>
              <Form.Control
                type="text"
                id="qualification"
                name="qualification"
                placeholder="Enter Your Bank qualification"
                value={formData.qualification}
                onChange={handleChange}
              />
              {errors.qualification && (
                <div className="text-danger">{errors.qualification}</div>
              )}
            </Form.Group>
          </div>
          <div className="row m-0 p-0 ">
            <Form.Group className="col-6 " controlId="bankName">
              <label htmlFor="name">Your Account Number </label>
              <Form.Control
                type="text"
                id="bankName"
                name="bankName"
                placeholder="Enter Your Bank Name"
                value={formData.bankName}
                onChange={handleChange}
              />
              {errors.bankName && (
                <div className="text-danger">{errors.bankName}</div>
              )}
            </Form.Group>
            <Form.Group className="col-6 " controlId="ifscCode">
              <label htmlFor="name">Enter Your Bank ifscCode</label>
              <Form.Control
                type="text"
                id="ifscCode"
                name="ifscCode"
                placeholder="Enter Your Bank ifscCode"
                value={formData.ifscCode}
                onChange={handleChange}
              />
              {errors.ifscCode && (
                <div className="text-danger">{errors.ifscCode}</div>
              )}
            </Form.Group>

            <Form.Group className="col-6" controlId="accountNumber">
              <Form.Label htmlFor="name"> Your Bank Name </Form.Label>

              <Form.Control
                type="text"
                placeholder="Bank Account Number"
                id="accountNumber"
                name="accountNumber"
                value={formData.accountNumber}
                onChange={handleChange}
              />
              {errors.accountNumber && (
                <div className="text-danger">{errors.accountNumber}</div>
              )}
            </Form.Group>
          </div>
          <div className="row m-0 p-0 ">
            <Form.Group className="col-6 ">
              <Form.Label htmlFor="name"> Data Of Birth</Form.Label>

              <FormControl
                type="date"
                value={formatDateString(formData.dob)}
                selected={formData.dob}
                onChange={(e) => {
                  const formattedDate = new Date(e.target.value);
                  setFormData({
                    ...formData,
                    dob: formattedDate,
                  });
                }}
                showYearDropdown
                dateFormatCalendar="MMMM yyyy"
                yearDropdownItemNumber={30}
                scrollableYearDropdown
                className="form-control"
                placeholderText="Date of Birth"
              />
              {/* {errors.dob && <div className="text-danger">{errors.dob}</div>} */}
            </Form.Group>
            <Form.Group className="col-6 " controlId="joiningdate">
              <Form.Label htmlFor="name"> Joining Date</Form.Label>

              <FormControl
                value={formatDateString(formData.joiningdate)}
                type="date"
                selected={formData.joiningdate}
                onChange={(e) => {
                  const formattedDate = new Date(e.target.value);
                  setFormData({
                    ...formData,
                    joiningdate: formattedDate,
                  });
                }}
                showYearDropdown
                dateFormatCalendar="MMMM yyyy"
                yearDropdownItemNumber={30}
                scrollableYearDropdown
                className="form-control"
                placeholderText="Joining Date"
              />
              {/* {errors.dob && <div className="text-danger">{errors.dob}</div>} */}
            </Form.Group>
          </div>
          <div className="row p-0 m-0">
            {" "}
            <Link className="mx-md-3">
              <button
                className="btn btn-primary d-flex align-items-center "
                style={{ height: "2rem", borderRadius: ".4rem" }}
                onClick={validateForm}
              >
                submit
              </button>
            </Link>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export const DocumentModalAdmin = ({
  show,
  onHide,
  getEmployeeList,
  selectedEmployeeId,
}) => {
  const employeeName = sessionStorage.getItem("employeeName") || "";
  const [aadharNumber, setAadharNumber] = useState("");
  const [panNumber, setPanNumber] = useState("");
  const [uannumber, setUannumber] = useState("");
  const [xcertificate, setXcertificate] = useState(null);
  const [xcertificatename, setXcertificatename] = useState("");
  const [xiicertificatename, setXiicertificatename] = useState("");
  const [highestqualificationname, setHighestqualificationname] = useState("");
  const [aadharname, setAadharname] = useState("");
  const [panname, setPanname] = useState("");
  const [xiicertificate, setXiicertificate] = useState(null);
  const [degreecertificate, setDegreecertificate] = useState(null);
  const [aadharcard, setAadharcard] = useState(null);
  const [pancard, setPancard] = useState(null);
  const [image, setImage] = useState(null);
  const { password, username } = billpunchbasicauth;
  const { user, setTransactionLoaderState } = useContext(GlobalContext);

  const convertToByteArray = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.onloadend = (evt) => {
        if (evt.target.readyState === FileReader.DONE) {
          const arrayBuffer = evt.target.result;
          const array = new Uint8Array(arrayBuffer);
          const binary = [...array]
            .map((byte) => String.fromCharCode(byte))
            .join("");
          const byteArray = window.btoa(binary);
          resolve(byteArray);
        }
      };
      reader.onerror = reject;
    });
  };

  const handleImageUpload = async (file, imageType, fileType) => {
    if (!file) {
      return;
    }

    if (file.size > 2 * 1000000) {
      swal("Invalid", "Image Size should be less than 2 MB", "error");
      return;
    }

    const byteArray = await convertToByteArray(file);

    const raw = JSON.stringify({
      firmid: user.firm_id,
      userid: user.userid,
      image_type: imageType,
      file_type: fileType,
      image_data: byteArray,
    });

    const myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Basic " + Buffer.from(username + ":" + password).toString("base64")
    );
    myHeaders.append("Content-Type", "application/json");

    const requestOptions = {
      method: "POST",
      body: raw,
      redirect: "follow",
      headers: myHeaders,
    };

    try {
      const response = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/SuperAdmin/ImageUpload`,
        requestOptions
      );
      const result = await response.json();

      console.log("API Response:", result);

      if (result.status === "0") {
        swal("Success", result.msg, "success").then(() => {
          getEmployeeList();
        });
      } else {
        swal("Error", result.msg, "error");
      }
    } catch (error) {
      console.log(error);
      swal("Error", "Failed to upload image", "error");
    } finally {
      setTransactionLoaderState(false);
    }
  };

  const handleSubmit = async () => {
    try {
      setTransactionLoaderState(true);
      const myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      const raw = JSON.stringify({
        empid: selectedEmployeeId,
        firmid: user.firm_id,
        xcertificate: xcertificatename,
        xiicertificate: xiicertificatename,
        degreecertificate: highestqualificationname,
        aadharcard: aadharname,
        pancard: panname,
        aadharnumber: aadharNumber,
        pannumber: panNumber,
        uannumber: uannumber,
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/addDocuments`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        onHide();
        getEmployeeList();
        swal("Success", data.result, "success");
      } else {
        getEmployeeList();
        onHide();
        swal("Error", data.msg, "error");
      }
    } catch (error) {
      console.error("Error during document upload:", error);
      swal("Error", "Error during document upload", "error");
    } finally {
      setTransactionLoaderState(false);
    }
  };

  return (
    <>
      <Modal show={show} onHide={onHide}>
        <Modal.Header closeButton>
          <Modal.Title>
            <h6> Upload Documents of {employeeName}</h6>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="mx-md-2">
            <div className="row m-0 p-0 ">
              <Form.Group className="col-6" controlId="aadharNumber">
                <div className="form-group">
                  <label htmlFor="aadharNumber">Aadhar Number</label>
                  <Form.Control
                    type="text"
                    name="aadharNumber"
                    value={aadharNumber}
                    onChange={(e) => setAadharNumber(e.target.value)}
                    style={{ borderRadius: "0px" }}
                  />
                </div>
              </Form.Group>

              <Form.Group className="col-6" controlId="panNumber">
                <div className="form-group">
                  <label htmlFor="panNumber">Pan Number</label>
                  <Form.Control
                    type="text"
                    name="panNumber"
                    value={panNumber}
                    onChange={(e) => setPanNumber(e.target.value)}
                    style={{ borderRadius: "0px" }}
                  />
                </div>
              </Form.Group>
            </div>
            <div className="row m-0 p-0 ">
              <Form.Group className="col-6" controlId="uannumber">
                <div className="form-group">
                  <label htmlFor="uannumber">UAN Number</label>
                  <Form.Control
                    type="text"
                    name="uannumber"
                    value={uannumber}
                    onChange={(e) => setUannumber(e.target.value)}
                    style={{ borderRadius: "0px" }}
                  />
                </div>
              </Form.Group>

              <Form.Group className="col-6" controlId="image">
                <div className="form-group">
                  <label htmlFor="image">X Marksheet</label>
                  <Form.Control
                    accept=".jpeg"
                    type="file"
                    name="X"
                    onChange={(e) => {
                      setXcertificatename("X" + uniqueID2());
                      setXcertificate(e.target.files[0]);
                      handleImageUpload(
                        e.target.files[0],
                        "X Marksheet",
                        "jpeg"
                      );
                    }}
                    style={{ borderRadius: "0px" }}
                  ></Form.Control>
                </div>
              </Form.Group>
            </div>

            <div className="row m-0 p-0 ">
              <Form.Group className="col-6" controlId="image">
                <div className="form-group">
                  <label htmlFor="image">XII Marksheet</label>
                  <Form.Control
                    accept=".jpeg"
                    type="file"
                    name="XII"
                    onChange={(e) => {
                      setXiicertificatename("XII" + uniqueID2());
                      setXiicertificate(e.target.files[0]);
                      handleImageUpload(
                        e.target.files[0],
                        "XII Marksheet",
                        "jpeg"
                      );
                    }}
                    style={{ borderRadius: "0px" }}
                  ></Form.Control>
                </div>
              </Form.Group>
              <Form.Group className="col-6" controlId="image">
                <div className="form-group">
                  <label htmlFor="image">Highest Qualification</label>
                  <Form.Control
                    accept=".jpeg"
                    type="file"
                    name="Highest"
                    onChange={(e) => {
                      setHighestqualificationname("Highest" + uniqueID2());
                      setDegreecertificate(e.target.files[0]);
                      handleImageUpload(
                        e.target.files[0],
                        "Highest Qualification",
                        "jpeg"
                      );
                    }}
                    style={{ borderRadius: "0px" }}
                  ></Form.Control>
                </div>
              </Form.Group>
            </div>

            <div className="row m-0 p-0 ">
              <Form.Group className="col-6" controlId="image">
                <div className="form-group">
                  <label htmlFor="image">Aadhar Card</label>
                  <Form.Control
                    accept=".jpeg"
                    type="file"
                    name="Aadhar"
                    onChange={(e) => {
                      setAadharname("Aadhar" + uniqueID2());
                      setAadharcard(e.target.files[0]);
                      handleImageUpload(
                        e.target.files[0],
                        "Aadhar Card",
                        "jpeg"
                      );
                    }}
                    style={{ borderRadius: "0px" }}
                  ></Form.Control>
                </div>
              </Form.Group>
              <Form.Group className="col-6" controlId="image">
                <div className="form-group">
                  <label htmlFor="image">Pan Card</label>
                  <Form.Control
                    accept=".jpeg"
                    type="file"
                    name="Pan"
                    onChange={(e) => {
                      setPanname("Pan" + uniqueID2());
                      setPancard(e.target.files[0]);
                      handleImageUpload(e.target.files[0], "Pan Card", "jpeg");
                    }}
                    style={{ borderRadius: "0px" }}
                  ></Form.Control>
                </div>
              </Form.Group>
            </div>

            <div className="row p-0 m-0">
              <Link className="mx-md-3 mx-sm-2 ">
                <button
                  // type="submit"
                  className="btn btn-primary d-flex align-items-center "
                  style={{ height: "2rem", borderRadius: ".4rem" }}
                  onClick={handleSubmit}
                >
                  Submit
                </button>
              </Link>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export const SalaryExpenseAdminModal = ({ isOpen, closeModal }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [expenseCategories, setExpenseCategories] = useState([]);
  const [Project, setProject] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const { password, username } = billpunchbasicauth;
  const initialFormData = {
    project: "",
    Expensename: "",
    category: "",
    expenseDate: "",
    Amount: "",
    description: "",
  };

  const initialErrors = {
    project: "",
    Expensename: "",
    category: "",
    expenseDate: "",
    Amount: "",
    description: "",
  };

  const [formData, setFormData] = useState(initialFormData);
  const [errors, setErrors] = useState(initialErrors);

  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const validate = (e) => {
    e.preventDefault();
    console.log("validate function");
    console.log(formData);

    let errors = {};
    const spaceRegex = /^\s+/; // Regular expression to check for leading spaces

    // if (formData.project.trim() === "") {
    //   errors.project = "*Please enter Project Name";
    // }

    if (spaceRegex.test(formData.Expensename)) {
      errors.Expensename = "*Expense Name should not start with a space";
    } else if (formData.Expensename.trim() === "") {
      errors.Expensename = "*Please enter Expense Name";
    }

    if (spaceRegex.test(formData.category)) {
      errors.category = "*Expense Type should not start with a space";
    } else if (formData.category.trim() === "") {
      errors.category = "*Please select Expense Type";
    }

    if (spaceRegex.test(formData.expenseDate)) {
      errors.expenseDate = "*Expense Date should not start with a space";
    } else if (formData.expenseDate.trim() === "") {
      errors.expenseDate = "*Please enter Expense Date";
    }

    if (spaceRegex.test(formData.Amount)) {
      errors.Amount = "*Amount should not start with a space";
    } else if (formData.Amount.trim() === "") {
      errors.Amount = "*Please enter Amount";
    }

    if (spaceRegex.test(formData.description)) {
      errors.description = "*Description should not start with a space";
    } else if (formData.description.trim() === "") {
      errors.description = "*Please enter Description";
    }

    setErrors(errors);
    console.log(errors);

    if (Object.keys(errors).length === 0) {
      handleSubmit();
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "project" && value === "createProject") {
      openaddprojectModal();
      return;
    }
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const fetchExpenseCategories = async () => {
    setIsLoading(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        firmid: user.firm_id,
        empid: "0",
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/GetAllFirmExpenseCategories
          `,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        setExpenseCategories(data.data);
      }
    } catch (error) {
      console.log("error", error);
    }
    setIsLoading(false);
  };

  const convertToByteArray = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.onloadend = (evt) => {
        if (evt.target.readyState === FileReader.DONE) {
          const arrayBuffer = evt.target.result;
          const array = new Uint8Array(arrayBuffer);
          const binary = [...array]
            .map((byte) => String.fromCharCode(byte))
            .join("");
          const byteArray = window.btoa(binary);
          resolve(byteArray);
        }
      };
      reader.onerror = reject;
    });
  };

  const handleSubmit = async (cardType) => {
    setTransactionLoaderState(true);
    if (!selectedFile) {
      swal("Error", "Please select a file for upload.", "warning");
      return;
    }

    const uploadedFile = selectedFile;
    const byteArray = await convertToByteArray(uploadedFile);

    let imageType;
    if (uploadedFile.type === "application/pdf") {
      imageType = ".pdf";
    } else if (uploadedFile.type.startsWith("image/jpeg")) {
      imageType = ".jpeg";
    } else {
      swal(
        "Error",
        "Unsupported file type. Please upload a PDF or JPEG file.",
        "warning"
      );
      return;
    }

    setFormData((prevFormData) => ({
      ...prevFormData,
      image_type: imageType,
    }));
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Basic " + Buffer.from(username + ":" + password).toString("base64")
    );
    myHeaders.append("Content-Type", "application/json");

    try {
      const byteArray = await convertToByteArray(uploadedFile);
      const fileData = byteArray;

      const fileType = uploadedFile.type.split("/")[1];

      const requestBody = {
        userid: "BILL PUNCH",
        image_type: "expense",
        file_type: fileType,
        image_data: fileData,
      };

      const response = await fetch(
        "https://bumppy.in/billpunch/api/v1/imageUpload.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        }
      );

      const responseData = await response.json();

      if (responseData.status === "0") {
        const imageUrl = responseData.image_url;
        setFormData((prevFormData) => ({
          ...prevFormData,
          imageUrl: imageUrl,
        }));

        // Now submit the form with imageUrl
        const raw = JSON.stringify({
          firmid: user.firm_id,
          empid: user.userid,
          expense_type: formData.category,
          expense_date: formData.expenseDate,
          expense_amount: formData.Amount,
          approved_by_hr: "P",
          approved_by_admin: "P",
          image_name: formData.Expensename,
          image_type: imageType,
          description: formData.description,
          image_bytedata: byteArray,
          project_id: formData.project,
          baseurl: imageUrl, // Include imageUrl here
        });

        const requestOptions = {
          method: "POST",
          body: raw,
          redirect: "follow",
          headers: myHeaders,
        };

        try {
          const response = await fetch(
            `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/insertExpenseDetails`,
            requestOptions
          );
          const result = await response.json();

          if (result.status === "0") {
            setTransactionLoaderState(false);
            swal("Success", result.msg, "success");
            closeModal(false);
            setFormData(initialFormData);
          } else {
            setTransactionLoaderState(false);
            swal("Failed", result.msg, "error");
            closeModal(false);
          }
        } catch (error) {
          console.log(error);
        }
      } else {
        swal("Error", responseData.msg, "error");
      }
    } catch (error) {
      console.error("Error uploading file:", error);
      swal("Error", "Failed to upload file.", "error");
    }
  };

  const handleFileChange = async (e) => {
    setTransactionLoaderState(true);
    const file = e.target.files[0];

    if (!file) {
      swal("Error", "Please select a file for upload.", "warning");
      return;
    }

    if (file.type !== "image/jpeg" && file.type !== "image/png") {
      swal(
        "Error",
        "Unsupported file type. Please upload a JPEG or PNG file.",
        "error"
      );
      return;
    }

    try {
      const byteArray = await convertToByteArray(file);
      const fileData = byteArray;

      const fileType = file.type.split("/")[1];

      const requestBody = {
        userid: "BILL PUNCH",
        image_type: "expense",
        file_type: fileType,
        image_data: fileData,
      };

      const response = await fetch(
        "https://bumppy.in/billpunch/api/v1/imageUpload.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        }
      );

      const responseData = await response.json();

      if (responseData.status === "0") {
        const imageUrl = responseData.image_url;
        setFormData((prevFormData) => ({
          ...prevFormData,
          imageUrl: imageUrl,
        }));
        setSelectedFile(file);

        swal("Success", responseData.msg, "success");
        setTransactionLoaderState(false);
      } else {
        swal("Error", responseData.msg, "error");
        setTransactionLoaderState(false);
      }
    } catch (error) {
      console.error("Error uploading file:", error);
      swal("Error", "Failed to upload file.", "error");
    }
  };

  const [addcategorymodal, setaddcategorymodal] = useState(false);
  const openExpensecatModal = () => {
    setaddcategorymodal(true);
  };

  const closeExpensecalModal = () => {
    setaddcategorymodal(false);
    setFormData({ ...formData, category: "" });
  };

  useEffect(() => {
    fetchExpenseCategories();
    fetchproject();
  }, []);
  useEffect(() => {
    if (!isOpen) {
      setFormData(initialFormData);
      setErrors(initialErrors);
    }
  }, [isOpen]);
  const fetchproject = async () => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        firmid: user.firm_id,
        created_by: "Admin",
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/GetFirmProjects
          `,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        setProject(data.data);
      }
    } catch (error) {
      console.log("error", error);
    }
    setTransactionLoaderState(false);
  };
  useEffect(() => {
    fetchproject();
  }, []);
  const [showaddprojectModal, setshowaddprojectModal] = useState(false);
  const openaddprojectModal = () => {
    setshowaddprojectModal(true);
  };

  const closeaddprojectModal = () => {
    setshowaddprojectModal(false);
  };
  return (
    <>
      <Expensecategoryadminmodal
        showModal={addcategorymodal}
        closeModal={closeExpensecalModal}
        fetchExpenseCategories={fetchExpenseCategories}
      />
      <AddProjectAdminModal
        show={showaddprojectModal}
        closeModal={closeaddprojectModal}
        getProjects={fetchproject}
      />
      <Modal show={isOpen} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>Add Expense Manually</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form className="mx-md-3 mx-sm-2">
            <div className="row m-0 p-1">
              {" "}
              <Form.Group className="col">
                <Form.Label> Select Project</Form.Label>

                <select
                  name="project"
                  className="form-control"
                  value={formData.project}
                  onChange={handleInputChange}
                >
                  <option value="">Select a Project</option>
                  <option value="createProject">+ Create Project</option>
                  {Project.map((project) => (
                    <option
                      key={project.pk_project_id}
                      value={project.pk_project_id}
                    >
                      {project.project_name}
                    </option>
                  ))}
                </select>
                {/* {errors.project && (
                <p className="text-danger">{errors.project}</p>
              )} */}
              </Form.Group>
            </div>
            <div className="row m-0 p-1">
              <Form.Group className="col">
                <Form.Label>Expense Name</Form.Label>
                <Form.Control
                  type="text"
                  name="Expensename"
                  value={formData.Expensename}
                  onChange={handleInputChange}
                  placeholder="Expense Name"
                />
                {errors.Expensename && (
                  <p className="text-danger">{errors.Expensename}</p>
                )}
              </Form.Group>{" "}
              <Form.Group className="col">
                <Form.Label>Expense Type</Form.Label>
                <Form.Control
                  as="select"
                  name="category"
                  value={formData.category}
                  onChange={(e) => {
                    handleInputChange(e);
                    if (e.target.value === "Add Category") {
                      openExpensecatModal();
                    }
                  }}
                >
                  <option value="">Category</option>
                  <hr />
                  <option>Add Category</option> <hr />
                  {isLoading ? (
                    <option>Loading...</option>
                  ) : (
                    expenseCategories.map((category) => (
                      <option
                        key={category.pk_expense_category_id}
                        value={category.category_name}
                      >
                        {category.category_name}
                      </option>
                    ))
                  )}
                </Form.Control>

                {errors.category && (
                  <p className="text-danger">{errors.category}</p>
                )}
              </Form.Group>
            </div>
            <div className="row m-0 p-1 ">
              <Form.Group className="col-6">
                <Form.Label> Amount:</Form.Label>
                <Form.Control
                  type="text"
                  name="Amount"
                  value={formData.Amount}
                  onChange={handleInputChange}
                  placeholder="Amount"
                />
                {errors.Amount && (
                  <p className="text-danger">{errors.Amount}</p>
                )}
              </Form.Group>
              <Form.Group className="col ">
                <Form.Label>Expense Date:</Form.Label>
                <Form.Control
                  type="date"
                  name="expenseDate"
                  value={formData.expenseDate}
                  onChange={handleInputChange}
                  placeholder=""
                />
                {errors.expenseDate && (
                  <p className="text-danger">{errors.expenseDate}</p>
                )}
              </Form.Group>
            </div>

            <div className="row m-0 p-1 ">
              <Form.Group className="col">
                <Form.Label>Description</Form.Label>
                <textarea
                  className="form-control"
                  type="textarea"
                  name="description"
                  value={formData.description}
                  onChange={handleInputChange}
                  placeholder="Description"
                />
                {errors.description && (
                  <p className="text-danger">{errors.description}</p>
                )}
              </Form.Group>
            </div>
            <div className="row m-0 p-1 ">
              <Form.Group className="col-8">
                <Form.Label>File Upload (PDF or JPEG)</Form.Label>
                <Form.Control
                  type="file"
                  accept=".pdf, .jpeg, .jpg"
                  onChange={handleFileChange}
                />
              </Form.Group>
            </div>

            <div className="row m-0 p-1 ">
              <div className="col-3">
                <button
                  className="btn btn-success d-flex align-items-center"
                  type="button"
                  style={{
                    height: "2rem",
                    borderRadius: ".3rem",
                    fontSize: ".8rem",
                  }}
                  onClick={validate}
                >
                  Submit
                </button>
              </div>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};
export const ProcessExpenseAdminModal = ({ isOpen, closeModal }) => {
  const months = [
    { name: "January", value: " 1" },
    { name: "February", value: "2" },
    { name: "March", value: "3" },
    { name: "April", value: "4" },
    { name: "May", value: "5" },
    { name: "June", value: "6" },
    { name: "July", value: "7" },
    { name: "August", value: "8" },
    { name: "September", value: "9" },
    { name: "October", value: "10" },
    { name: "November", value: "11" },
    { name: "December", value: "12" },
  ];
  const [selectedYear, setSelectedYear] = useState("2024");
  const [selectedMonth, setSelectedMonth] = useState("01");
  const [selectAll, setSelectAll] = useState(false);
  const [saleslist, setsaleslist] = useState();
  const [generate, setgenerate] = useState();
  const { password, username } = billpunchbasicauth;

  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const handleYearChange = (e) => {
    setSelectedYear(e.target.value);
  };

  const handleMonthChange = (e) => {
    setSelectedMonth(e.target.value);
  };
  const handleSelectAllChange = () => {
    const newSelectAllState = !selectAll;
    const updatedSalesList = saleslist.map((person) => ({
      ...person,
      selected: newSelectAllState,
    }));
    setsaleslist(updatedSalesList);
    setSelectAll(newSelectAllState);
  };

  const handleCheckboxChange = (empid) => {
    const updatedList = saleslist.map((person) => {
      if (person.empid === empid) {
        return { ...person, selected: !person.selected };
      }
      return person;
    });

    setsaleslist(updatedList);
  };
  const GenerateExpensePay = async () => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      const selectedMonthName =
        months.find((month) => month.value === selectedMonth)?.name || "";

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        redirect: "follow",
      };

      let selectedEmployees = saleslist;
      if (!selectAll) {
        selectedEmployees = saleslist.filter((person) => person.selected);
      }

      await Promise.all(
        selectedEmployees.map(async (selectedEmployee) => {
          const selectedCategory = expenseCategories.find(
            (category) =>
              category.pk_expense_category_id ===
              selectedEmployee.selectedCategory
          );

          const raw = JSON.stringify({
            empid: selectedEmployee.empid,
            agentonbord: counterMap[selectedEmployee.empid] || 0,
            month: selectedMonthName,
            expense_name: selectedCategory
              ? selectedCategory.category_name
              : "Travel",
          });

          const res = await fetch(
            `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/GenerateFixedPetrolExpense`,
            { ...requestOptions, body: raw }
          );
          const responseData = await res.json();

          if (responseData.status === "0") {
            setgenerate(responseData.data);
          }
        })
      );

      closeModal();

      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
      setTransactionLoaderState(false);
    }
  };

  // const GenerateExpensePay = async () => {
  //   setTransactionLoaderState(true);
  //   try {
  //     const selectedEmployee = saleslist.find((person) => person.selected);
  //     const selectedMonthName = months.find((month) => month.value === selectedMonth)?.name || '';

  //     const myHeaders = new Headers();
  //     myHeaders.append("Content-Type", "application/json");

  //     const selectedCategory = expenseCategories.find((category) => category.pk_expense_category_id === selectedEmployee.selectedCategory);

  //     const raw = JSON.stringify({
  //       empid: selectedEmployee.empid,
  //       agentonbord: counterMap[selectedEmployee.empid] || 0,
  //       month: selectedMonthName,
  //       expense_name: selectedCategory ? selectedCategory.category_name : '',
  //     });

  //     const requestOptions = {
  //       method: "POST",
  //       headers: myHeaders,
  //       body: raw,
  //       redirect: "follow",
  //     };

  //     const res = await fetch(
  //       `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/GenerateFixedPetrolExpense`,
  //       requestOptions
  //     );
  //     const responseData = await res.json();

  //     if (responseData.status === "0") {
  //       setgenerate(responseData.data);
  //       closeModal();

  //     }

  //     setTransactionLoaderState(false);
  //   } catch (error) {
  //     console.log("error", error);
  //     setTransactionLoaderState(false);
  //   }
  // };

  const Getsalespersonlist = async () => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      const raw = JSON.stringify({});

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/GetSalesPerson`,
        requestOptions
      );
      const responseData = await res.json();

      if (responseData.status === "0") {
        setsaleslist(responseData.data);
      }

      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
    } finally {
      setTransactionLoaderState(false);
    }
  };

  useEffect(() => {
    Getsalespersonlist();
    fetchExpenseCategories();
    GenerateExpensePay();
  }, []);

  const [expenseCategories, setExpenseCategories] = useState([]);
  const [counterMap, setCounterMap] = useState({});
  const fetchExpenseCategories = async () => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        firmid: user.firm_id,
        empid: "0",
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/GetAllFirmExpenseCategories
          `,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        setExpenseCategories(data.data);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
    }
    setTransactionLoaderState(false);
  };
  const incrementCounter = (empid) => {
    setCounterMap((prevMap) => ({
      ...prevMap,
      [empid]: (prevMap[empid] || 0) + 1,
    }));
  };

  const decrementCounter = (empid) => {
    setCounterMap((prevMap) => ({
      ...prevMap,
      [empid]: (prevMap[empid] || 0) - 1,
    }));
  };

  const [selectedCategory, setSelectedCategory] = useState("");
  const handleCategoryChange = (e, empid) => {
    const { value } = e.target;
    const updatedList = saleslist.map((person) => {
      if (person.empid === empid) {
        return { ...person, selectedCategory: value || "Travel" };
      }
      return person;
    });
    setsaleslist(updatedList);
  };

  return (
    <Modal show={isOpen} onHide={closeModal} className="expensepayrolmodal">
      <Modal.Header closeButton>
        <Modal.Title>Generate Expense </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row  px-2">
          <div className="col-md-2 col p-0">
            <select
              style={{
                width: "5rem",
                height: "2rem",
                borderRadius: ".4rem",
                border: "1px #ced4da solid",
              }}
              value={selectedYear}
              onChange={handleYearChange}
            >
              <option value="2023">2023</option>
              <option value="2024">2024</option>
              <option value="2025">2025</option>
              <option value="2026">2026</option>
            </select>
          </div>
          <div className="col-md-2 col mr-3 ">
            <select
              style={{
                width: "5rem",
                height: "2rem",
                borderRadius: ".4rem",
                border: "1px #ced4da solid",
              }}
              value={selectedMonth}
              onChange={handleMonthChange}
            >
              {months.map((month) => (
                <option key={month.value} value={month.value}>
                  {month.name}
                </option>
              ))}
            </select>
          </div>
          <div className="col"></div>
          <div className="col d-flex justify-content-end">
            <Button
              className="d-flex align-items-center"
              style={{ height: "2rem" }}
              onClick={GenerateExpensePay}
            >
              Generate
            </Button>
          </div>
        </div>
        <table
          className="my-table transparent-table text-center border-bottom"
          style={{
            width: "100%",
            overflowX: "auto",
            border: "0px",
            overflow: "auto",
          }}
        >
          <tr className="borderless-table text-center border-bottom">
            <th className="my-table transparent-table text-center border-bottom ">
              <input
                type="checkbox"
                checked={selectAll}
                onChange={handleSelectAllChange}
              />
            </th>
            <th
              className="my-table transparent-table text-center border-bottom "
              style={{}}
            >
              {" "}
              {"Sr"}
            </th>
            <th
              className="my-table transparent-table text-center border-bottom "
              style={{}}
            >
              Name
            </th>
            <th
              className="my-table transparent-table text-center border-bottom "
              style={{}}
            >
              {" "}
              Agent
              <br />
              Onboarding
            </th>
            <th
              className="my-table transparent-table text-center border-bottom "
              style={{}}
            >
              Type
            </th>
          </tr>
          <tbody>
            {saleslist && saleslist.length > 0 ? (
              saleslist.map((e, ind) => (
                <tr key={e.empid}>
                  <td className="text-center">
                    <input
                      type="checkbox"
                      checked={e.selected || false}
                      onChange={() => handleCheckboxChange(e.empid)}
                    />
                  </td>
                  <td
                    className="text-center border-bottom"
                    style={{
                      fontSize: "1rem",
                      fontFamily: "Poppins",
                      fontWeight: "400",
                      lineHeight: "24px",
                      letterSpacing: "0em",
                      textAlign: "center",
                    }}
                  >
                    {ind + 1}
                  </td>
                  <td
                    className="text-center border-bottom"
                    style={{
                      fontSize: "1rem",
                      fontFamily: "Poppins",
                      fontWeight: "400",
                      lineHeight: "24px",
                      letterSpacing: "0em",
                      textAlign: "center",
                    }}
                  >
                    {e.firstname}
                  </td>
                  <td
                    className="text-center border-bottom"
                    style={{
                      fontSize: "1.2rem",
                      fontFamily: "Poppins",
                      fontWeight: "400",
                      lineHeight: "24px",
                      letterSpacing: "0em",
                      textAlign: "center",
                    }}
                  >
                    <Badge
                      className="m-2"
                      style={{
                        border: "1px solid",
                        background: "#B5C18E",
                        color: "#",
                      }}
                      onClick={() => incrementCounter(e.empid)}
                    >
                      +
                    </Badge>
                    <Badge className="m-1" style={{ border: "1px solid" }}>
                      {" "}
                      {counterMap[e.empid] || 0}
                    </Badge>
                    <Badge
                      className="m-2"
                      style={{
                        border: "1px solid",
                        background: "#B5C18E",
                        color: "#",
                      }}
                      onClick={() => decrementCounter(e.empid)}
                    >
                      -
                    </Badge>
                  </td>
                  <td
                    className="text-center border-bottom"
                    style={{
                      fontSize: "1rem",
                      fontFamily: "Poppins",
                      fontWeight: "400",
                      lineHeight: "24px",
                      letterSpacing: "0em",
                      textAlign: "center",
                    }}
                  >
                    {" "}
                    <select
                      className=""
                      value={e.selectedCategory || ""}
                      onChange={(event) => handleCategoryChange(event, e.empid)}
                      style={{
                        height: "2rem",
                        fontSize: "1rem",
                        width: "9rem",
                        borderRadius: ".2rem",
                      }}
                    >
                      <option value="">Select Expense</option>
                      {expenseCategories.map((category) => (
                        <option
                          key={category.pk_expense_category_id}
                          value={category.pk_expense_category_id}
                        >
                          {category.category_name}
                        </option>
                      ))}
                    </select>
                  </td>
                </tr>
              ))
            ) : (
              <td
                className="text-center border-bottom"
                style={{
                  fontSize: "0.8rem",
                  fontFamily: "Poppins",
                  fontWeight: "400",
                  lineHeight: "24px",
                  letterSpacing: "0em",
                  textAlign: "center",
                }}
              >
                No data available
              </td>
            )}
          </tbody>
        </table>
      </Modal.Body>
    </Modal>
  );
};

export const BulkSalary = ({
  showBulkModal,
  setShowBulkModal,
  getEmployeegeneratedList,
  handleClose,
}) => {
  const months = [
    { name: "January", value: "1" },
    { name: "February", value: "2" },
    { name: "March", value: "3" },
    { name: "April", value: "4" },
    { name: "May", value: "5" },
    { name: "June", value: "6" },
    { name: "July", value: "7" },
    { name: "August", value: "8" },
    { name: "September", value: "9" },
    { name: "October", value: "10" },
    { name: "November", value: "11" },
    { name: "December", value: "12" },
  ];
  const { password, username } = billpunchbasicauth;

  const [data, setData] = useState([]);
  const [selectedEmployeeIds, setSelectedEmployeeIds] = useState([]);
  const [expandedEmployeeId, setExpandedEmployeeId] = useState(null);
  const [departmentData, setDepartmentData] = useState([]);
  const [department, setDepartment] = useState("");
  const [selectedYear, setSelectedYear] = useState("2024");
  const [selectedMonth, setSelectedMonth] = useState("01");
  const [showProcessAllModal, setShowProcessAllModal] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [generate, setGenerate] = useState();
  const { user, setTransactionLoaderState } = useContext(GlobalContext);

  const toggleEmployeeDetails = (employeeId) => {
    if (expandedEmployeeId === employeeId) {
      setExpandedEmployeeId(null);
    } else {
      setExpandedEmployeeId(employeeId);
    }
  };

  const handleYearChange = (e) => {
    setSelectedYear(e.target.value);
  };

  const handleMonthChange = (e) => {
    setSelectedMonth(e.target.value);
  };

  const getDepartmentList = async () => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({ firmid: user.firm_id });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/getEmployeeDepartment`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        setDepartmentData(data.data);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleSelectAllChange = () => {
    const updatedData = data.map((employee) => ({
      ...employee,
      selected: !selectAll,
    }));
    setData(updatedData);
    setSelectAll(!selectAll);

    const selectedEmployees = updatedData.filter(
      (employee) => employee.selected
    );
    setSelectedEmployeeIds(
      selectedEmployees.map((employee) => employee.employee_id)
    );
  };

  const getEmployeeList = async (month) => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        month: month,
        firmid: user.firm_id,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/GetAttendanceAll`,
        requestOptions
      );
      const apiData = await res.json();

      console.log("API Response:", apiData);

      if (apiData.status === "0" && apiData.data) {
        setData(
          apiData.data.map((employee) => ({ ...employee, selected: false }))
        );
      }

      setTransactionLoaderState(false);
    } catch (error) {
      console.log("Error fetching employee data:", error);
      setTransactionLoaderState(false);
    }
  };

  const generateSalary = async () => {
    setTransactionLoaderState(true);
    try {
      const selectedEmployees = filteredData.filter(
        (employee) => employee.selected
      );

      if (selectedEmployees.length === 0) {
        swal("No employees selected", "", "warning");
        setTransactionLoaderState(false);
        return;
      }

      const myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      const requestBody = {
        requests: selectedEmployees.map((employee) => ({
          empid: employee.employee_id,
          p_days: employee.total_present || "NA",
          ab_days: employee.total_absent || "NA",
          firmid: user.firm_id,
          month: selectedMonth,
          year: selectedYear,
        })),
      };
      console.log(requestBody);
      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify(requestBody),
        redirect: "follow",
      };

      console.log("Making API call with:", requestOptions);

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/generateEmployeeSalary`,
        requestOptions
      );

      const responseData = await res.json();
      console.log("API Response:", responseData);

      if (responseData.status === "0") {
        swal("Success", responseData.result, "success");
        setGenerate(responseData.data);
        getEmployeegeneratedList();
        handleClose();
      } else {
        swal("Error", responseData.msg, "error");
      }

      setTransactionLoaderState(false);
    } catch (error) {
      console.log("Error generating salary:", error);
      setTransactionLoaderState(false);
    }
  };

  const handleCheckboxChange = (employee_id) => {
    const updatedData = data.map((employee) => {
      if (employee.employee_id === employee_id) {
        return { ...employee, selected: !employee.selected };
      }
      return employee;
    });

    setData(updatedData);
    const selectedEmployees = updatedData.filter(
      (employee) => employee.selected
    );
    setSelectAll(selectedEmployees.length === data.length);
    setSelectedEmployeeIds(
      selectedEmployees.map((employee) => employee.employee_id)
    );
  };

  const handleProcessAllClick = () => {
    if (selectedEmployeeIds.length === 0) {
      swal("Please select at least one item to generate.", "", "warning");
    } else {
      setShowProcessAllModal(true);
    }
  };

  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    const filteredResults = data.filter((item) => {
      if (department === "") {
        return true;
      } else {
        return item.department === department;
      }
    });
    setFilteredData(filteredResults);
  }, [data, department]);

  useEffect(() => {
    getDepartmentList();
  }, []);

  useEffect(() => {
    getEmployeeList(selectedMonth);
  }, [selectedMonth]);

  return (
    <Modal
      show={showBulkModal}
      onHide={() => setShowBulkModal(false)}
      className="generate_modal"
    >
      <Modal.Header className="row m-0 " closeButton>
        <Modal.Title className="col">Generate Salary</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row  px-2">
          <div className="col-md-2 col p-0">
            <select
              style={{
                width: "5rem",
                height: "2rem",
                borderRadius: ".4rem",
                border: "1px #ced4da solid",
              }}
              value={selectedYear}
              onChange={handleYearChange}
            >
              <option value="2023">2023</option>
              <option value="2024">2024</option>
              <option value={"2025"}>2025</option>
              <option value={"2026"}>2026</option>
              <option value="2023">2027</option>
              <option value="2024">2028</option>
            </select>
          </div>
          <div className="col-md-2 col mr-3 ">
            <select
              style={{
                width: "5rem",
                height: "2rem",
                borderRadius: ".4rem",
                border: "1px #ced4da solid",
              }}
              value={selectedMonth}
              onChange={handleMonthChange}
            >
              {months.map((month) => (
                <option key={month.value} value={month.value}>
                  {month.name}
                </option>
              ))}
            </select>
          </div>
          <div className="col d-flex justify-content-end">
            <Button
              className="d-flex align-items-center"
              style={{ height: "2rem" }}
              onClick={generateSalary}
            >
              Generate
            </Button>
          </div>
        </div>
        <table
          className="my-table transparent-table text-center border-bottom"
          style={{
            width: "100%",
            overflowX: "auto",
            border: "0px",
            overflow: "auto",
          }}
        >
          <tr className="borderless-table text-center border-bottom">
            <th className="my-table transparent-table text-center border-bottom ">
              <input
                type="checkbox"
                checked={selectAll}
                onChange={handleSelectAllChange}
              />
            </th>
            <th
              className="my-table transparent-table text-center border-bottom "
              style={{
                fontFamily: "Poppins",
                fontSize: "16px",
                fontWeight: "700",
                lineHeight: "24px",
                letterSpacing: "0em",
                textAlign: "center",
              }}
            >
              {" "}
              {"Sr"}
            </th>
            <th
              className="my-table transparent-table text-center border-bottom "
              style={{
                fontFamily: "Poppins",
                fontSize: "16px",
                fontWeight: "700",
                lineHeight: "24px",
                letterSpacing: "0em",
                textAlign: "center",
              }}
            >
              Name
            </th>
            <th
              className="my-table transparent-table text-center border-bottom "
              style={{
                textAlign: "center",
              }}
            >
              Check Attendance
            </th>
          </tr>
          {filteredData.length > 0 ? (
            filteredData.map((e, ind) => (
              <React.Fragment key={e.employee_id}>
                <tr key={e.employee_id}>
                  <td className="text-center ">
                    <input
                      type="checkbox"
                      checked={e.selected}
                      onChange={() => handleCheckboxChange(e.employee_id)}
                    />
                  </td>
                  <td
                    className="text-center border-bottom"
                    style={{
                      fontSize: "0.8rem",
                      fontFamily: "Poppins",
                      fontWeight: "400",
                      lineHeight: "24px",
                      letterSpacing: "0em",
                      textAlign: "center",
                    }}
                  >
                    {" "}
                    {ind + 1}
                  </td>
                  <td
                    className="text-center border-bottom"
                    style={{
                      fontSize: "0.8rem",
                      fontFamily: "Poppins",
                      fontWeight: "400",
                      lineHeight: "24px",
                      letterSpacing: "0em",
                      textAlign: "center",
                    }}
                  >
                    <div>{e.employeename}</div>
                  </td>
                  <td
                    className="text-center border-bottom"
                    onClick={() => toggleEmployeeDetails(e.employee_id)}
                    style={{ cursor: "pointer" }}
                  >
                    {expandedEmployeeId === e.employee_id ? (
                      <FaCaretDown />
                    ) : (
                      <FaCaretRight />
                    )}
                  </td>
                </tr>
                {expandedEmployeeId === e.employee_id && (
                  <tr>
                    <td colSpan="4">
                      <div
                        className="card m-0 p-2"
                        style={{ background: "antiquewhite" }}
                      >
                        <div
                          className="row m-0 p-0"
                          style={{ width: "100%", margin: "0" }}
                        >
                          <>
                            <p
                              className="col-6 m-0 p-0"
                              style={{
                                fontSize: ".9rem",
                                fontWeight: "400",
                              }}
                            >
                              Govt. Holiday: {e.total_govtholidays || "NA"}
                            </p>

                            <p
                              style={{
                                fontSize: ".9rem",
                                fontWeight: "400",
                              }}
                              className="col-6 m-0 p-0"
                            >
                              Present Days: {e.total_present || "NA"}
                            </p>
                            <p
                              style={{
                                fontSize: ".9rem",
                                fontWeight: "400",
                              }}
                              className="col-6 m-0 p-0"
                            >
                              Approved Leave: {e.total_approvedleave || "NA"}
                            </p>
                            <p
                              style={{
                                fontSize: ".9rem",
                                fontWeight: "400",
                              }}
                              className="col-6 m-0 p-0"
                            >
                              Rejected Leave: {e.total_rejectedleave || "NA"}
                            </p>
                            <p
                              style={{
                                fontSize: ".9rem",
                                fontWeight: "400",
                              }}
                              className="col-6 m-0 p-0"
                            >
                              Total Leave: {e.total_absent || "NA"}
                            </p>
                          </>
                        </div>
                      </div>
                    </td>
                  </tr>
                )}
              </React.Fragment>
            ))
          ) : (
            <tr>
              <td colSpan="4" className="text-center">
                No data found.
              </td>
            </tr>
          )}
        </table>
      </Modal.Body>
    </Modal>
  );
};

export const AddBillFormAdmin = ({
  showModal,
  handleCloseModal,
  getAllPropertyBills,
}) => {
  const { password, username } = billpunchbasicauth;

  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const servicesOption = [
    {
      value: 1,
      label: "Broadband Postpaid",
    },
    {
      value: 2,
      label: "Cable TV",
    },
    {
      value: 3,
      label: "DTH",
    },
    {
      value: 4,
      label: "Education Fees",
    },
    {
      value: 5,
      label: "Electricity",
    },
    {
      value: 6,
      label: "Fastag",
    },
    {
      value: 7,
      label: "Gas",
    },
    {
      value: 8,
      label: "Health Insurance",
    },
    {
      value: 9,
      label: "Hospital",
    },
    {
      value: 10,
      label: "Housing Society",
    },
    {
      value: 11,
      label: "Insurance",
    },
    {
      value: 12,
      label: "Landline Postpaid",
    },
    {
      value: 13,
      label: "Life Insurance",
    },
    {
      value: 14,
      label: "Loan Repayment",
    },
    {
      value: 15,
      label: "LPG Gas",
    },
    {
      value: 16,
      label: "Mobile Postpaid",
    },
    {
      value: 17,
      label: "Municipal Services",
    },
    {
      value: 18,
      label: "Municipal Taxes",
    },
    {
      value: 19,
      label: "Subscription",
    },
    {
      value: 20,
      label: "Water",
    },
    {
      value: 21,
      label: "Clubs and Associations",
    },
    {
      value: 22,
      label: "Credit Card",
    },
    {
      value: 23,
      label: "Hospital and Pathology",
    },
    {
      value: 24,
      label: "Mobile Prepaid",
    },
    {
      value: 25,
      label: "Recurring Deposit",
    },
    {
      value: 26,
      label: "Travel Agency",
    },
  ];

  console.log("Service options:", servicesOption);

  const [service, setService] = useState("1");

  console.log("Service state:", service);
  const [CustP, setCustP] = useState([]);

  console.log("CustP state:", CustP);

  const [providers, setProviders] = useState([]);
  console.log("Providers state:", providers);

  const [formData, setFormData] = useState({
    Service: "1",
    Provider: "",
    ConsumerId: "",
    Mobile: "",
    Amount: "",
  });
  console.log("FormData state:", formData);

  const [billerResponse, setBillerResponse] = useState({
    msg: "",
    success: "",
    error: [],
  });
  console.log("BillerResponse state:", billerResponse);

  const [state, setState] = useState({
    provider: "",
    consumer: "",
    mobileNumber: "",
    custparams: "",
  });
  console.log("State state:", state);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    if (name === "Service") {
      setService(value);
    }

    console.log("Form data after change:", formData);
  };
  const { Profileinfo } = useContext(GlobalContext);
  console.log("User from context:", user);
  console.log("Profileinfo from context:", Profileinfo);

  const handleSubmit = async (e) => {
    console.log("Submit button clicked");
    setTransactionLoaderState(true);
    try {
      console.log("hellp");
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");
      var raw = JSON.stringify({
        firmid: user.firm_id,
        service: formData.Service.label,
        provider: state.provider.value,
        consumerid: CustP[0].value,
        paramsList: JSON.stringify(CustP),
        refno: "string",
        mobile: Profileinfo?.mobile,
        month: new Date().toISOString(),
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/addfirmBill`,
        requestOptions
      );

      const data = await res.json();
      console.log("nishant", data);
      if (data.status === "1") {
        swal("Error", data.msg, "error");
        handleCloseModal();
        getAllPropertyBills();
      } else {
        sessionStorage.setItem("params", JSON.stringify(CustP));
        swal("Success", data.msg, "success");
        handleCloseModal();
        getAllPropertyBills();
      }
      setTransactionLoaderState(false);
      console.log("Form submission completed.");
    } catch (error) {
      console.log("Error:", error);
    }
    setTransactionLoaderState(false);
  };

  const fetchAllProviders = async () => {
    console.log("Fetching providers started");
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic IHJham5lZXNoQGV4YW1wbGUuY29tIDogVGVzdEAxMjM0NQ=="
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        userName: "string",
        categoryId: formData.Service.value,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        "https://apitranxt.paynnow.com/api/Operator/GetAllOperators",
        requestOptions
      );
      const data = await res.json();
      if (data.responseCode === "00") {
        const modifiedProviders = data.data.map((e) => {
          return { ...e, label: e.billerName, value: e.billerId };
        });
        console.log("Fetched providers data:", data.data);
        console.log("Modified providers:", modifiedProviders);

        setProviders(modifiedProviders);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("Error fetching providers:", error);
    }
    setTransactionLoaderState(false);
  };
  console.log("Providers:", providers);

  console.log(providers);

  const fetchAllProviders2 = async () => {
    setTransactionLoaderState(true);
    var myHeaders = new Headers();

    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Cookie",
      ".AspNetCore.Identity.Application=CfDJ8PA2Dduz6XVGpbQn-N2Kqq5nxn_jDhpPRlBHLmNVLCibYXbTK71aALOCGXbw4fHmGhYSemNPyrCo-W6rHg7K58J-En6cgJpZ_RlVeTHZjRO-FBKIs4zFAbZbeYrgCNKW34Wbm5lDeVV2H497JoGEWo0O4ar5aLanS1NTFH0LmOimscy41s2tPRyPfVtXiPSkgXialxacSHvLEna7HTGsDYgNPejg9kViVhahKHVV_dmsspRhxuUQ8yLOGeamszLCs_o-lMC9Lj3AHZaIyu-vfV4cZpESP9oj4s69Wl0Yc85c6o_NTuttKXDgIJrem_5W1KRitMel6aSEdsmqpEiuNho56pF8iIOkxL6bb9S6CjYK-dhGj14Q8ZZDzCDnxdHOTIU3iCx0i_qitJLvxhH5kSo9kigZlsxkVspvOtSp-NMDLPHeM3pOeeY5rY5pjLlNp2IUT9fKiJR1IRxvOHf4O93Gho2vl6J8QpS_5fsI0c2yN5o_UwssJ57vdkWxtUonmx_mDPb0bO6z48lcGvJiyp-aZx77HCulXFRaiyqJAD5mmjhDMf4KGYyjc-cK4j1VwJRLu_6pCN6YdfrY5B6sjmLnWo5rV4kTHNulkdJKAtekwySG6WI0l9wjyEVZNVmdioWaFXJ4p6gjNecN3lHJsEi7n24V6IXJ8zdm1Rz3jztK"
    );

    var raw = JSON.stringify({
      userid: user.ReferId,
      ccategoryId: formData.Service.value,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    try {
      const res = await fetch(
        `${BASE_URL_APISERVICES}/api/Payment/bbps/getAllOnlineOperators`,
        requestOptions
      );
      const raw = await res.text();
      const data = JSON.parse(raw);
      console.log(data);
      if (data.data != null) {
        setProviders(
          data.data.map((pro) => {
            return { ...pro, value: pro.billerName, label: pro.billerName };
          })
        );
      }
      setTransactionLoaderState(false);
    } catch (error) {
      setTransactionLoaderState(false);
      console.log("error", error);
    }
  };

  useEffect(() => {
    fetchAllProviders();
  }, [formData.Service]);

  useEffect(() => {
    if (state.provider) {
      setCustP(
        state.provider.customerParams.map((param, i) => {
          return { ...param, id: i, value: "" };
        })
      );
    }

    console.log("CustP after state.provider change:", CustP);
  }, [state]);

  console.log("CustP after useEffect:", CustP);

  console.log("CustP JSON:", JSON.stringify(CustP));

  return (
    <>
      <div>
        <Modal show={showModal} onHide={handleCloseModal}>
          <Modal.Header closeButton>
            <Modal.Title className="mx-md-3">Add Bill</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form className="m-md-2 ">
              <div className="row">
                <Form.Group className="col-6">
                  <Form.Label style={{ color: "black" }}> Services</Form.Label>
                  <Select
                    options={servicesOption}
                    value={formData.Service}
                    onChange={(e) => {
                      setFormData({ ...formData, Service: e });
                    }}
                  ></Select>
                </Form.Group>

                <Form.Group className="col-6">
                  <Form.Label style={{ color: "black" }}>Provider</Form.Label>
                  <Select
                    options={providers}
                    value={state.provider}
                    onChange={(e) => {
                      setState({
                        ...state,
                        provider: e,
                      });
                      setFormData({
                        ...formData,
                        Provider: e,
                      });
                    }}
                  />
                </Form.Group>
              </div>
              <div className="row">
                {CustP.map((cust, i) => (
                  <Form.Group key={cust.id} className="col-6 px-md-3 m-0 px-0">
                    {cust.optional == "true" ? (
                      <Form.Label>{`${cust.paramName} (optional)`}</Form.Label>
                    ) : (
                      <Form.Label>
                        <span style={{ color: "red" }}>*</span>
                        {` ${cust.paramName}`}
                      </Form.Label>
                    )}
                    <Form.Control
                      type="text"
                      className="form-control rounded-0"
                      aria-label="Notes"
                      aria-describedby="basic-addon1"
                      placeholder={`Enter ${cust.paramName}`}
                      onChange={(e) => {
                        setBillerResponse({
                          ...billerResponse,
                          error: [],
                          msg: "",
                        });
                        const copy = [...CustP];
                        copy.forEach((ele) => {
                          if (ele.id == cust.id) {
                            ele.value = e.target.value;
                          }
                        });
                        setCustP(copy);
                      }}
                      value={cust.value}
                    />
                  </Form.Group>
                ))}
              </div>
              <div className="row">
                <Form.Group className="col-6">
                  <Form.Label style={{ color: "black" }}>Amount</Form.Label>
                  <input
                    type="text"
                    className="form-control "
                    id="Amount"
                    name="Amount"
                    value={formData.Amount}
                    onChange={handleChange}
                    placeholder="Enter Amount"
                  />
                </Form.Group>
                <div className="col-4  pt-md-3">
                  <button
                    // type="submit"
                    type="button"
                    className="btn btn-primary btn-block d-flex justify-content-center align-items-center"
                    style={{ height: "2.5rem", borderRadius: ".3rem" }}
                    onClick={handleSubmit}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </form>{" "}
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export const FetchBillModalAdmin = ({
  setFetchBillModal,
  fetchBillModal,
  payBill,
  billDetails,
  billerResponse,
  callback,
  billPayResponse,
  setAmount,
  amount,
  setTpin,
  Tpin,
  config,
}) => {
  const { password, username } = billpunchbasicauth;

  const [tpinShow, setTpinShow] = useState(false);
  const [PayClicked, setPayClicked] = useState(false);
  const openInNewTab = (data) => {
    localStorage.setItem("print", JSON.stringify(data));
    const CLIENT = "";
    const newWindow = window.open(
      `${CLIENT}/pages/bbps/print`,
      "",
      "height=600,width=800'"
    );
    if (newWindow) newWindow.opener = null;
  };

  const showTPinClick = () => {
    setTpinShow(!tpinShow);
  };
  return (
    <Modal show={fetchBillModal} onHide={setFetchBillModal}>
      <Modal.Header closeButton>
        <Modal.Title>Fetch Bill</Modal.Title>
      </Modal.Header>

      <Modal.Body
        style={{ width: "100%", display: "block" }}
        className="account m-0 p-0"
      >
        {billDetails && !billPayResponse && (
          <Card style={{ margin: "10px", padding: "10px" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <img
                src={require("../../../assets/images/BBPSSidebarLogo.png")}
                style={{
                  width: "auto",
                  height: "1.2rem",
                  backgroundColor: "whitesmoke",
                }}
              />
              <img
                onClick={() => {
                  openInNewTab(billDetails);
                }}
                style={{ height: "1.5rem" }}
                src={require("../../../assets/icons/print_1.png")}
              />
            </div>
            <table style={{ border: "none", fontFamily: "-webkit-body" }}>
              <tr style={{ background: "none", border: "none" }}>
                <td
                  style={{
                    paddingLeft: "1rem",
                    border: "none",
                    background: "none",
                    width: "50%",
                    fontSize: "0.8rem",
                  }}
                >
                  {" "}
                  Name
                </td>
                <td
                  style={{
                    paddingLeft: "1rem",
                    border: "none",
                    background: "none",
                    width: "50%",
                    fontSize: "0.8rem",
                  }}
                >
                  {" "}
                  {billDetails.billerResponse.customerName}
                </td>
              </tr>
              <tr style={{ background: "none" }}>
                <td
                  style={{
                    paddingLeft: "1rem",
                    border: "none",
                    background: "none",
                    width: "50%",
                    fontSize: "0.8rem",
                  }}
                >
                  {" "}
                  Bill Number
                </td>
                <td
                  style={{
                    paddingLeft: "1rem",
                    border: "none",
                    background: "none",
                    width: "50%",
                    fontSize: "0.8rem",
                  }}
                >
                  {" "}
                  {billDetails.billerResponse.billNumber}
                </td>
              </tr>
              <tr style={{ background: "none" }}>
                <td
                  style={{
                    paddingLeft: "1rem",
                    border: "none",
                    background: "none",
                    width: "50%",
                    fontSize: "0.8rem",
                  }}
                >
                  {" "}
                  Amount
                </td>
                <td
                  style={{
                    paddingLeft: "1rem",
                    border: "none",
                    background: "none",
                    width: "50%",
                    fontSize: "0.8rem",
                  }}
                >
                  {" "}
                  {amount}
                </td>
              </tr>

              {/* Additional Info */}
              {billDetails.additionalInfo &&
                billDetails.additionalInfo.length > 0 &&
                billDetails.additionalInfo.map((info) => (
                  <tr style={{ background: "none" }}>
                    <td
                      style={{
                        paddingLeft: "1rem",
                        border: "none",
                        background: "none",
                        width: "50%",
                        fontSize: "0.8rem",
                      }}
                    >
                      {info.name}
                    </td>
                    <td
                      style={{
                        paddingLeft: "1rem",
                        border: "none",
                        background: "none",
                        width: "50%",
                        fontSize: "0.8rem",
                      }}
                    >
                      {info.value}
                    </td>
                  </tr>
                ))}

              <tr style={{ background: "none" }}>
                <td
                  style={{
                    paddingLeft: "1rem",
                    border: "none",
                    background: "none",
                    width: "50%",
                    fontSize: "0.8rem",
                  }}
                >
                  {" "}
                  Bill date
                </td>
                <td
                  style={{
                    paddingLeft: "1rem",
                    border: "none",
                    background: "none",
                    width: "50%",
                    fontSize: "0.8rem",
                  }}
                >
                  {" "}
                  {billDetails.billerResponse.billDate}
                </td>
              </tr>

              <tr style={{ background: "none" }}>
                <td
                  style={{
                    paddingLeft: "1rem",
                    border: "none",
                    background: "none",
                    width: "50%",
                    fontSize: "0.8rem",
                  }}
                >
                  {" "}
                  Due Date.
                </td>
                <td
                  style={{
                    paddingLeft: "1rem",
                    border: "none",
                    background: "none",
                    width: "50%",
                    fontSize: "0.8rem",
                  }}
                >
                  {" "}
                  {billDetails.billerResponse.dueDate}
                </td>
              </tr>
              <tr style={{ background: "none" }}>
                <td
                  style={{
                    paddingLeft: "1rem",
                    border: "none",
                    background: "none",
                    width: "50%",
                    fontSize: "0.8rem",
                  }}
                >
                  {" "}
                  Reference Id
                </td>
                <td
                  style={{
                    paddingLeft: "1rem",
                    border: "none",
                    background: "none",
                    width: "50%",
                    fontSize: "0.8rem",
                  }}
                >
                  {" "}
                  {billDetails.refId}
                </td>
              </tr>

              {config.amountEditable && (
                <tr style={{ background: "none" }}>
                  <td
                    style={{
                      paddingLeft: "1rem",
                      border: "none",
                      background: "none",
                      width: "50%",
                      fontSize: "0.8rem",
                    }}
                  >
                    {" "}
                    Amount
                  </td>
                  <td
                    style={{
                      paddingLeft: "1rem",
                      border: "none",
                      background: "none",
                      width: "50%",
                      fontSize: "0.8rem",
                    }}
                  >
                    <Form.Control
                      type="number"
                      className="form-control rounded-0"
                      aria-label="Notes"
                      aria-describedby="basic-addon1"
                      placeholder={`Enter Amount`}
                      onChange={(e) => {
                        setAmount(e.target.value);
                      }}
                      value={amount}
                    />
                  </td>
                </tr>
              )}

              <tr style={{ background: "none" }}>
                <td
                  style={{
                    paddingLeft: "1rem",
                    border: "none",
                    background: "none",
                    width: "50%",
                    fontSize: "0.8rem",
                  }}
                >
                  {" "}
                  T Pin
                </td>
                <td
                  style={{
                    paddingLeft: "1rem",
                    border: "none",
                    background: "none",
                    width: "50%",
                    fontSize: "0.8rem",
                  }}
                >
                  <div className="input-group">
                    <div className="input-group-prepend bg-transparent">
                      <span className="input-group-text bg-transparent border-right-0 no-glow">
                        <i className="mdi mdi-lock-outline text-primary"></i>
                      </span>
                    </div>
                    <input
                      autocomplete="new-password"
                      autoCorrect="off"
                      autoSave="new-password"
                      type={tpinShow ? "text" : "password"}
                      className="form-control form-control-lg border-left-0 border-right-0 no-glow"
                      id="exampleInputPassword"
                      placeholder={`Enter T Pin`}
                      onChange={(e) => {
                        setTpin(e.target.value);
                      }}
                      value={Tpin}
                    />
                    <div className="input-group-prepend bg-transparent">
                      <span
                        className="input-group-text bg-transparent border-left-0 no-glow"
                        onClick={showTPinClick}
                        style={{ borderRadius: "4px" }}
                      >
                        <i
                          className={`mdi text-primary ${
                            tpinShow ? "mdi-eye" : "mdi-eye-off"
                          }`}
                        ></i>
                      </span>
                    </div>
                  </div>
                </td>
              </tr>
            </table>
            <div
              style={{
                padding: "10px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              {PayClicked ? (
                <Button className="rounded-0" variant="success">
                  {" "}
                  Paying ...
                </Button>
              ) : (
                <Button
                  className="rounded-0"
                  variant="success"
                  onClick={callback}
                >
                  {" "}
                  Pay Bill
                </Button>
              )}
            </div>
          </Card>
        )}
        {billDetails && billPayResponse && (
          <Card style={{ margin: "10px", padding: "10px" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <img
                src={require("../../../assets/images/BBPSSidebarLogo.png")}
                style={{
                  width: "auto",
                  height: "1.2rem",
                  backgroundColor: "whitesmoke",
                }}
              />
              <img
                onClick={() => {
                  openInNewTab(billDetails);
                }}
                style={{ height: "1.5rem" }}
                src={require("../../../assets/icons/print_1.png")}
              />
            </div>
            <table style={{ border: "none", fontFamily: "-webkit-body" }}>
              <tr style={{ background: "none", border: "none" }}>
                <td
                  style={{
                    paddingLeft: "1rem",
                    border: "none",
                    background: "none",
                    width: "50%",
                    fontSize: "0.8rem",
                  }}
                >
                  {" "}
                  Name
                </td>
                <td
                  style={{
                    paddingLeft: "1rem",
                    border: "none",
                    background: "none",
                    width: "50%",
                    fontSize: "0.8rem",
                  }}
                >
                  {" "}
                  {billDetails.billerResponse.customerName}
                </td>
              </tr>

              {billDetails.billerResponse.billNumber && (
                <tr style={{ background: "none" }}>
                  <td
                    style={{
                      paddingLeft: "1rem",
                      border: "none",
                      background: "none",
                      width: "50%",
                      fontSize: "0.8rem",
                    }}
                  >
                    {" "}
                    Bill Number
                  </td>
                  <td
                    style={{
                      paddingLeft: "1rem",
                      border: "none",
                      background: "none",
                      width: "50%",
                      fontSize: "0.8rem",
                    }}
                  >
                    {" "}
                    {billDetails.billerResponse.billNumber}
                  </td>
                </tr>
              )}

              <tr style={{ background: "none" }}>
                <td
                  style={{
                    paddingLeft: "1rem",
                    border: "none",
                    background: "none",
                    width: "50%",
                    fontSize: "0.8rem",
                  }}
                >
                  {" "}
                  Amount
                </td>
                <td
                  style={{
                    paddingLeft: "1rem",
                    border: "none",
                    background: "none",
                    width: "50%",
                    fontSize: "0.8rem",
                  }}
                >
                  {" "}
                  {amount}
                </td>
              </tr>

              <tr style={{ background: "none" }}>
                <td
                  style={{
                    paddingLeft: "1rem",
                    border: "none",
                    background: "none",
                    width: "50%",
                    fontSize: "0.8rem",
                  }}
                >
                  {" "}
                  Reference Id
                </td>
                <td
                  style={{
                    paddingLeft: "1rem",
                    border: "none",
                    background: "none",
                    width: "50%",
                    fontSize: "0.8rem",
                  }}
                >
                  {" "}
                  {billPayResponse.refId}
                </td>
              </tr>
              <tr style={{ background: "none" }}>
                <td
                  style={{
                    paddingLeft: "1rem",
                    border: "none",
                    background: "none",
                    width: "50%",
                    fontSize: "0.8rem",
                  }}
                >
                  Transaction Reference Id
                </td>
                <td
                  style={{
                    paddingLeft: "1rem",
                    border: "none",
                    background: "none",
                    width: "50%",
                    fontSize: "0.8rem",
                  }}
                >
                  {" "}
                  {billPayResponse.txnReferenceId}
                </td>
              </tr>

              <tr style={{ background: "none" }}>
                <td
                  style={{
                    paddingLeft: "1rem",
                    border: "none",
                    background: "none",
                    width: "50%",
                    fontSize: "0.8rem",
                  }}
                >
                  {" "}
                  Status
                </td>
                <td
                  style={{
                    paddingLeft: "1rem",
                    border: "none",
                    background: "none",
                    width: "50%",
                    fontSize: "0.8rem",
                  }}
                >
                  <span className="badge badge-pill badge-success p-3">
                    {billPayResponse.responseReason}
                  </span>
                </td>
              </tr>
            </table>
          </Card>
        )}
      </Modal.Body>
    </Modal>
  );
};

export const EditSalaryPtoceedAdmin = ({
  showModal,
  setShowModal,
  formData,
  handleChange,
  EDITSALARY,
  months,
  categories,
}) => {
  return (
    <Modal show={showModal} onHide={() => setShowModal(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Edit Employee Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <div className="row">
            <Form.Group className="col" controlId="employee">
              <Form.Label>Employee</Form.Label>
              <Form.Control
                type="text"
                name="employeeId"
                value={formData.employeeId}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group className="col" controlId="monthDropdown">
              <Form.Label>
                <span style={{ color: "red" }}>*</span>
                {" Select Month"}
              </Form.Label>
              <Form.Control
                as="select"
                name="month"
                value={formData.month}
                onChange={handleChange}
              >
                {months.map((month) => (
                  <option key={month.value} value={month.value}>
                    {month.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </div>
          <div className="row">
            <Form.Group className="col" controlId="categoryDropdown">
              <Form.Label>
                <span style={{ color: "red" }}>*</span>
                {` Select category`}
              </Form.Label>
              <Form.Control
                as="select"
                name="category"
                value={formData.category}
                onChange={handleChange}
              >
                {categories.map((category) => (
                  <option key={category} value={category}>
                    {category}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group className="col" controlId="amountInput">
              <Form.Label>Amount</Form.Label>
              <Form.Control
                type="text"
                name="amount"
                value={formData.amount}
                onChange={handleChange}
                placeholder="Enter Amount"
              />
            </Form.Group>
          </div>
          <div className="row">
            <Form.Group className="col-6 " controlId="amountInput">
              <Form.Label>EPF</Form.Label>
              <Form.Control
                type="text"
                name="pf"
                value={formData.pf}
                onChange={handleChange}
                placeholder="Enter EPF Amount"
              />
            </Form.Group>
          </div>
          <Button variant="primary" onClick={EDITSALARY}>
            Save
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export const ViewModalAdmin = ({ show, handleClose, clickedEmployee }) => {
  const [employee, setEmployee] = useState(null);
  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const { password, username } = billpunchbasicauth;
  const getEmployeeSalarySlip = async (month) => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({
          empid: clickedEmployee?.empId,
          month: month,
          firmid: user.firm_id,
        }),
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/GetEmployeeSalarySlip`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0" && data.data.length > 0) {
        const employeeData = data.data[0];
        setEmployee({
          ...employeeData,
          current_month: month,
        });
      } else {
        setEmployee(null);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
      setTransactionLoaderState(false);
    }
  };

  useEffect(() => {
    if (show && clickedEmployee) {
      getEmployeeSalarySlip(clickedEmployee.currentMonth);
    }
  }, [show, clickedEmployee]);

  const downloadPDF = () => {
    const input = document.getElementById("salaryslip-content");
    html2canvas(input, { scale: 2 }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");

      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

      const totalPageHeight = 297;
      const spaceToLeaveBelow = totalPageHeight * 0.25;
      const spaceToLeaveAbove = 10;
      const adjustedHeight =
        totalPageHeight - spaceToLeaveBelow - spaceToLeaveAbove;

      pdf.addImage(
        imgData,
        "PNG",
        0,
        spaceToLeaveAbove,
        pdfWidth,
        adjustedHeight
      );

      pdf.save("salary_slip.pdf");
    });
  };
  const totalDeduction = () => {
    console.log("Employee EPF:", employee?.epf, typeof employee?.epf);
    console.log("Employee ESIC:", employee?.esic, typeof employee?.esic);

    const epfAmount = parseFloat(employee?.epf);
    if (!isNaN(epfAmount)) {
      const epfDeduction = epfAmount * 2;
      let totalDeductionAmount = epfDeduction;

      const esicAmount = parseFloat(employee?.esic);
      if (!isNaN(esicAmount)) {
        totalDeductionAmount += esicAmount;
      }

      return totalDeductionAmount;
    }

    return 0;
  };
  return (
    <Modal show={show} onHide={handleClose} className="salaryslip-modal">
      <Modal.Body>
        <div id="salaryslip-content">
          <form className="m-5">
            <div className="card p-0" style={{ border: "1px solid" }}>
              <div className="d-flex justify-content-end p-2">
                <img
                  alt=""
                  src={require("../../../assets/images/pms/salary_logo.png")}
                  style={{ height: "2rem" }}
                />
              </div>
              <div>
                <h5 className="d-flex justify-content-center">
                  Bumppy Media Private Limited
                </h5>
                <h6 className="d-flex justify-content-center">
                  B-218,ITHUM Tower-B,2nd Floor, Sec-62, Noida, U.p-201301
                </h6>
              </div>
              <hr className="p-0 m-0" style={{ background: "#000" }} />
              <h6 className="d-flex justify-content-center">Salary Slip</h6>
              <hr className="p-0 m-0" style={{ background: "#000" }} />
              <div className="row">
                <div className="col">
                  <div
                    className="row m-0 p-0"
                    style={{ color: "#000", height: "1.7rem" }}
                  >
                    <p className="col px-0" style={{ color: "#000" }}>
                      Emp Code:
                    </p>
                    <p className="col  " style={{ color: "#000" }}>
                      {" "}
                      {employee?.emp_code || "NA"}
                    </p>
                  </div>
                  <div className="row m-0 p-0" style={{ color: "#000" }}>
                    <p className="col px-0" style={{ color: "#000" }}>
                      Name :
                    </p>
                    <p className="col  m-0 p-0" style={{ color: "#000" }}>
                      {" "}
                      {employee?.name || "NA"}
                    </p>
                  </div>
                  <div
                    className="row m-0 p-0"
                    style={{ color: "#000", height: "1.7rem" }}
                  >
                    <p className="col px-0" style={{ color: "#000" }}>
                      Designation:
                    </p>
                    <p className="col  m-0 p-0" style={{ color: "#000" }}>
                      {" "}
                      {employee?.designation || "NA"}{" "}
                    </p>
                  </div>
                  <div
                    className="row m-0 p-0"
                    style={{ color: "#000", height: "1.7rem" }}
                  >
                    <p className="col px-0" style={{ color: "#000" }}>
                      Department:
                    </p>
                    <p className="col  " style={{ color: "#000" }}>
                      {" "}
                      {employee?.department || "NA"}
                    </p>
                  </div>
                  <div
                    className="row m-0 p-0"
                    style={{ color: "#000", height: "1.7rem" }}
                  >
                    <p className="col px-0" style={{ color: "#000" }}>
                      Bank Account No:
                    </p>
                    <p className="col  m-0 p-0" style={{ color: "#000" }}>
                      {" "}
                      {employee?.bank_acc_no || "NA"}{" "}
                    </p>
                  </div>
                </div>
                <div className="col">
                  <div
                    className="row m-0 p-0"
                    style={{ color: "#000", height: "1.7rem" }}
                  >
                    <p className="col " style={{ color: "#000" }}>
                      Month:
                    </p>
                    <p className="col  " style={{ color: "#000" }}>
                      {" "}
                      {employee?.current_month || "NA"}
                    </p>
                  </div>
                  <div
                    className="row m-0 p-0"
                    style={{ color: "#000", height: "1.7rem" }}
                  >
                    <p className="col " style={{ color: "#000" }}>
                      {" "}
                      ESI No:
                    </p>
                    <p className="col  " style={{ color: "#000" }}>
                      {" "}
                      {employee?.esic || "NA"}{" "}
                    </p>
                  </div>
                  <div className="row m-0 p-0" style={{ color: "#000" }}>
                    <p className="col " style={{ color: "#000" }}>
                      UAN:
                    </p>
                    <p className="col  " style={{ color: "#000" }}>
                      {" "}
                      {employee?.uan || "NA"}{" "}
                    </p>
                  </div>
                  <div
                    className="row m-0 p-0"
                    style={{ color: "#000", height: "1.7rem" }}
                  >
                    <p className="col " style={{ color: "#000" }}>
                      Salary Mode:
                    </p>
                    <p className="col  " style={{ color: "#000" }}>
                      {" "}
                      Bank Transfer{" "}
                    </p>
                  </div>
                  <div
                    className="row m-0 p-0"
                    style={{ color: "#000", height: "1.7rem" }}
                  >
                    <p className="col " style={{ color: "#000" }}>
                      Bank Name:
                    </p>
                    <p className="col  " style={{ color: "#000" }}>
                      {" "}
                      {employee?.bank_name || "NA"}{" "}
                    </p>
                  </div>
                </div>
              </div>
              <hr className="m-0 p-0" style={{ background: "#000" }} />
              <table className="m-0" style={{ border: "none" }}>
                <thead>
                  <tr>
                    <th
                      style={{
                        background: "#fff",
                        color: "#000",
                        fontWeight: "400",
                        borderRight: "1px solid black",
                      }}
                      colSpan={2}
                    >
                      Standard Wages Amount
                    </th>
                    {/* <th style={{background:"#fff", color:"#000", fontWeight:"400", borderRight:"1px solid black"}}></th> */}
                    <th
                      style={{
                        background: "#fff",
                        color: "#000",
                        fontWeight: "400",
                        borderRight: "1px solid black",
                      }}
                    >
                      Earned Wages Amount
                    </th>
                    <th
                      style={{
                        background: "#fff",
                        color: "#000",
                        fontWeight: "400",
                        borderRight: "1px solid black",
                      }}
                    >
                      Deduction
                    </th>
                    <th
                      style={{
                        background: "#fff",
                        color: "#000",
                        fontWeight: "400",
                      }}
                    >
                      Amount
                    </th>
                  </tr>
                </thead>
                <tbody style={{ borderRight: "none", borderLeft: "none" }}>
                  <tr>
                    <td
                      style={{
                        border: "1px solid black",
                        fontSize: ".9rem",
                        borderLeft: "none",
                      }}
                    >
                      Basic
                    </td>
                    <td
                      style={{
                        fontSize: ".9rem",
                        border: "none",
                        borderTop: "1px solid",
                        borderRight: "1px solid ",
                      }}
                    >
                      {twoDecimalPlaces(employee?.basic || "NA")}
                    </td>
                    <td
                      style={{
                        border: "1px solid black",
                        fontSize: ".9rem",
                        borderTop: "1px solid",
                      }}
                    >
                      {twoDecimalPlaces(employee?.basic || "NA")}
                    </td>
                    <td
                      style={{
                        border: "1px solid black",
                        verticalAlign: "top",
                        fontSize: ".9rem",
                      }}
                    >
                      EPF
                    </td>
                    <td
                      style={{
                        border: "1px solid black",
                        fontSize: ".9rem",
                        borderRight: "none",
                      }}
                    >
                      {twoDecimalPlaces(employee?.epf)}
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        border: "1px solid black",
                        fontSize: ".9rem",
                        borderLeft: "none",
                      }}
                    >
                      HRA
                    </td>
                    <td
                      style={{ border: "1px solid black", fontSize: ".9rem" }}
                    >
                      {twoDecimalPlaces(employee?.hra || "NA")}
                    </td>
                    <td
                      style={{ border: "1px solid black", fontSize: ".9rem" }}
                    >
                      {twoDecimalPlaces(employee?.hra || "NA")}
                    </td>
                    <td
                      style={{
                        border: "1px solid black",
                        verticalAlign: "top",
                        fontSize: ".9rem",
                      }}
                    >
                      ESIC
                    </td>
                    <td
                      style={{
                        border: "1px solid black",
                        fontSize: ".9rem",
                        borderRight: "none",
                      }}
                    >
                      {employee?.esic || "NA"}
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        border: "1px solid black",
                        fontSize: ".9rem",
                        borderLeft: "none",
                      }}
                    >
                      Travel Allowance
                    </td>
                    <td
                      style={{ border: "1px solid black", fontSize: ".9rem" }}
                    >
                      {twoDecimalPlaces(employee?.t_allowance || "NA")}
                    </td>
                    <td
                      style={{ border: "1px solid black", fontSize: ".9rem" }}
                    >
                      {twoDecimalPlaces(employee?.t_allowance || "NA")}
                    </td>
                    <td
                      style={{
                        border: "1px solid black",
                        verticalAlign: "top",
                        fontSize: ".9rem",
                      }}
                    >
                      TDS
                    </td>
                    <td
                      style={{
                        border: "1px solid black",
                        fontSize: ".9rem",
                        borderRight: "none",
                      }}
                    >
                      {0}
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        border: "1px solid black",
                        fontSize: ".9rem",
                        borderLeft: "none",
                      }}
                    >
                      Med. Allowance
                    </td>
                    <td
                      style={{ border: "1px solid black", fontSize: ".9rem" }}
                    >
                      {" "}
                      {twoDecimalPlaces(employee?.m_allowance || "NA")}
                    </td>
                    <td
                      style={{ border: "1px solid black", fontSize: ".9rem" }}
                    >
                      {twoDecimalPlaces(employee?.m_allowance || "NA")}
                    </td>
                    <td
                      style={{
                        border: "1px solid black",
                        verticalAlign: "top",
                        fontSize: ".9rem",
                      }}
                    ></td>
                    <td
                      style={{
                        border: "1px solid black",
                        fontSize: ".9rem",
                        borderRight: "none",
                      }}
                    >
                      {}
                    </td>
                  </tr>

                  <tr>
                    <td
                      style={{
                        border: "1px solid black",
                        fontSize: ".9rem",
                        borderLeft: "none",
                      }}
                    >
                      Oth. Allowance
                    </td>
                    <td
                      style={{ border: "1px solid black", fontSize: ".9rem" }}
                    >
                      {twoDecimalPlaces(employee?.other_allowance || "NA")}
                    </td>
                    <td
                      style={{ border: "1px solid black", fontSize: ".9rem" }}
                    >
                      {twoDecimalPlaces(employee?.other_allowance || "NA")}
                    </td>
                    <td
                      style={{
                        border: "1px solid black",
                        verticalAlign: "top",
                        fontSize: ".9rem",
                      }}
                    ></td>
                    <td
                      style={{
                        border: "1px solid black",
                        fontSize: ".9rem",
                        borderRight: "none",
                      }}
                    ></td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        border: "1px solid black",
                        fontSize: ".9rem",
                        borderLeft: "none",
                      }}
                    >
                      Total
                    </td>
                    <td
                      style={{ border: "1px solid black", fontSize: ".9rem" }}
                    >
                      {twoDecimalPlaces(employee?.totalSalarywithpf || "NA")}
                    </td>
                    <td
                      style={{ border: "1px solid black", fontSize: ".9rem" }}
                    >
                      {twoDecimalPlaces(employee?.totalSalarywithpf || "NA")}
                    </td>
                    <td
                      style={{
                        border: "1px solid black",
                        verticalAlign: "top",
                        fontSize: ".9rem",
                      }}
                    >
                      Total Deduction
                    </td>
                    <td
                      style={{
                        border: "1px solid black",
                        fontSize: ".9rem",
                        borderRight: "none",
                      }}
                    >
                      {twoDecimalPlaces(totalDeduction())}
                    </td>
                  </tr>
                </tbody>

                <tfoot className="mb-md-2">
                  <tr>
                    <td
                      colspan="5"
                      style={{
                        border: "1px solid black",
                        borderRight: "none",
                        borderLeft: "none",
                      }}
                    >
                      <p
                        className="mb-2"
                        style={{ color: "#000", fontSize: ".8rem" }}
                      >
                        Total Payable Amount :{" "}
                        {twoDecimalPlaces(employee?.totalSalary || "NA")}
                      </p>
                      <p
                        className="mb-2"
                        style={{ color: "#000", fontSize: ".8rem" }}
                      >
                        Payable Amount in Words :{" "}
                        {employee?.totalSalary
                          ? inWords(Math.round(Number(employee.totalSalary)))
                          : "NA"}
                      </p>
                    </td>
                  </tr>
                </tfoot>
              </table>
              <hr className="mt-md-3" style={{ background: "#000" }} />
              <p className="px-1" style={{ color: "#000", fontSize: ".8rem" }}>
                This is a computer generated pay slip and does not required any
                signature or any company seal.
              </p>
            </div>
          </form>
        </div>
        <button
          style={{ height: "2rem", borderRadius: ".4rem" }}
          className="d-flex align-items-centerbtn btn-primary"
          onClick={downloadPDF}
        >
          Download
        </button>
      </Modal.Body>
    </Modal>
  );
};

export const AddProjectAdminModal = ({ show, closeModal, getProjects }) => {
  const [Member, setMember] = useState([]);
  const [Employeeid, setEmployeeid] = useState(-1);
  const { password, username } = billpunchbasicauth;

  const [selectedEmployee, setselectedEmployee] = useState("");
  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const [projectfield, setProjectfields] = useState({
    Projectname: "",
    Projectownership: "",
    Projectstartdate: "",
    Projectenddate: "",
    Projectdescription: "",
  });
  const [projecterror, setProjecterror] = useState({
    Projectname: "",
    Projectownership: "",
    Projectstartdate: "",
    Projectenddate: "",
    Projectdescription: "",
  });
  const resetForm = () => {
    setProjectfields({
      Projectname: "",
      Projectownership: "",
      Projectstartdate: "",
      Projectenddate: "",
      Projectdescription: "",
    });
    setProjecterror({
      Projectname: "",
      Projectownership: "",
      Projectstartdate: "",
      Projectenddate: "",
      Projectdescription: "",
    });
    setselectedEmployee("");
  };
  const validate = (e) => {
    e.preventDefault();
    let errors = {};
    const spaceRegex = /^\s+/;

    if (spaceRegex.test(projectfield.Projectname)) {
      errors.Projectname = "*Project Name should not start with a space";
    } else if (projectfield.Projectname.trim() === "") {
      errors.Projectname = "*Please enter Project Name";
    }

    if (!selectedEmployee) {
      errors.Projectownership = "*Please select an employee";
    }

    if (spaceRegex.test(projectfield.Projectstartdate)) {
      errors.Projectstartdate = "*Date should not start with a space";
    } else if (projectfield.Projectstartdate.trim() === "") {
      errors.Projectstartdate = "*Please enter date";
    }

    if (spaceRegex.test(projectfield.Projectdescription)) {
      errors.Projectdescription = "*Description should not start with a space";
    } else if (projectfield.Projectdescription.trim() === "") {
      errors.Projectdescription = "*Please enter Description";
    }

    setProjecterror(errors);

    if (Object.keys(errors).length === 0) {
      handleSubmit();
      resetForm();
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProjectfields((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };
  const handleEmployeeSelection = (selectedOption) => {
    setselectedEmployee(selectedOption);
    setProjectfields((prevFormData) => ({
      ...prevFormData,
      Projectownership: selectedOption ? selectedOption.label : "",
      Employeeid: selectedOption ? selectedOption.value : -1,
    }));
  };

  const handleSubmit = async (e) => {
    console.log("abhi", handleSubmit);
    try {
      setTransactionLoaderState(true);
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");
      var raw = JSON.stringify({
        project_name: projectfield.Projectname,
        project_ownership: projectfield.Projectownership,
        project_startdate: projectfield.Projectstartdate,
        project_enddate: projectfield.Projectenddate,
        project_discription: projectfield.Projectdescription,
        fk_firmid: user.firm_id,
        ownership_empid: projectfield.Employeeid,
        created_by: "Admin",
      });

      console.log("Request sending:", raw);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
      console.log("sending data to abhijeet");
      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/InsertFirmProjects`,
        requestOptions
      );
      console.log("Api url");
      const data = await res.json();
      console.log("nishant", data);
      if (data.status === "1") {
        swal("Error", data.msg, "error");
        setTransactionLoaderState(false);
        getProjects();
        closeModal();
      } else {
        swal("Success", data.msg, "success");
        setTransactionLoaderState(false);
        getProjects();
        closeModal();
      }
      console.log("Form submission completed.");
    } catch (error) {
      console.log("Error:", error);
    }
  };
  const GetFirmEmployee = async (e) => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        firmId: user.firm_id,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/getEmployee`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        const mappedEmployees = data.data.map((employee) => ({
          value: employee.empid,
          label: `${employee.firstname} ${employee.lastname}`,
        }));
        setMember(mappedEmployees);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
    }
    setTransactionLoaderState(false);
  };
  useEffect(() => {
    GetFirmEmployee();
  }, []);
  const handleClose = () => {
    closeModal();
    resetForm();
  };
  const today = new Date().toISOString().split("T")[0];
  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="">Add Project</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={validate}>
            <div className="row">
              {" "}
              <Form.Group className="col-6 m-0">
                <Form.Label> Project Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Project Name"
                  id="Projectname"
                  name="Projectname"
                  value={projectfield.Projectname}
                  onChange={handleChange}
                />
                {projecterror.Projectname && (
                  <p className="text-danger">{projecterror.Projectname}</p>
                )}
              </Form.Group>
              <Form.Group className="col-6 m-0">
                <Form.Label> Owner Name</Form.Label>
                <Select
                  options={Member}
                  value={selectedEmployee}
                  onChange={handleEmployeeSelection}
                  placeholder="Select Name"
                />
                {projecterror.Projectownership && (
                  <p className="text-danger">{projecterror.Projectownership}</p>
                )}
              </Form.Group>
            </div>
            <div className="row">
              {" "}
              <Form.Group className="col-6 m-0">
                <Form.Label> Start Date</Form.Label>
                <Form.Control
                  type="date"
                  placeholder="Start Date"
                  id="Projectstartdate"
                  name="Projectstartdate"
                  value={projectfield.Projectstartdate}
                  onChange={handleChange}
                  // min={today}
                />
                {projecterror.Projectstartdate && (
                  <p className="text-danger">{projecterror.Projectstartdate}</p>
                )}
              </Form.Group>
              <Form.Group className="col-6 m-0">
                <Form.Label> End Date</Form.Label>
                <Form.Control
                  type="date"
                  placeholder="End Date"
                  id="Projectenddate"
                  name="Projectenddate"
                  value={projectfield.Projectenddate}
                  onChange={handleChange}
                  min={today}
                />
                {projecterror.Projectenddate && (
                  <p className="text-danger">{projecterror.Projectenddate}</p>
                )}
              </Form.Group>
            </div>
            <div className="row ">
              <Form.Group className="col">
                <Form.Label>Description</Form.Label>
                <textarea
                  className="form-control"
                  type="textarea"
                  name="Projectdescription"
                  value={projectfield.Projectdescription}
                  onChange={handleChange}
                  placeholder="Project Description"
                />
                {projecterror.Projectdescription && (
                  <p className="text-danger">
                    {projecterror.Projectdescription}
                  </p>
                )}
              </Form.Group>
            </div>
            <div className="col-4 ">
              <button
                // type="submit"
                type="button"
                className="btn btn-primary btn-block d-flex justify-content-center align-items-center"
                style={{ height: "2rem", borderRadius: ".3rem" }}
                onClick={validate}
              >
                Add Project
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};
export const AddProjectemployeeModal = ({ show, closeModal, getProjects }) => {
  const [Member, setMember] = useState([]);
  const [Employeeid, setEmployeeid] = useState(-1);
  const { password, username } = billpunchbasicauth;

  const [selectedEmployee, setselectedEmployee] = useState("");
  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const [projectfield, setProjectfields] = useState({
    Projectname: "",
    Projectownership: "",
    Projectstartdate: "",
    Projectenddate: "",
    Projectdescription: "",
  });
  const [projecterror, setProjecterror] = useState({
    Projectname: "",
    Projectownership: "",
    Projectstartdate: "",
    Projectenddate: "",
    Projectdescription: "",
  });
  const resetForm = () => {
    setProjectfields({
      Projectname: "",
      Projectownership: "",
      Projectstartdate: "",
      Projectenddate: "",
      Projectdescription: "",
    });
    setProjecterror({
      Projectname: "",
      Projectownership: "",
      Projectstartdate: "",
      Projectenddate: "",
      Projectdescription: "",
    });
    setselectedEmployee("");
  };
  const validate = (e) => {
    e.preventDefault();
    let errors = {};
    const spaceRegex = /^\s+/;

    if (spaceRegex.test(projectfield.Projectname)) {
      errors.Projectname = "*Project Name should not start with a space";
    } else if (projectfield.Projectname.trim() === "") {
      errors.Projectname = "*Please enter Project Name";
    }

    if (!selectedEmployee) {
      errors.Projectownership = "*Please select an employee";
    }

    if (spaceRegex.test(projectfield.Projectstartdate)) {
      errors.Projectstartdate = "*Date should not start with a space";
    } else if (projectfield.Projectstartdate.trim() === "") {
      errors.Projectstartdate = "*Please enter date";
    }

    if (spaceRegex.test(projectfield.Projectdescription)) {
      errors.Projectdescription = "*Description should not start with a space";
    } else if (projectfield.Projectdescription.trim() === "") {
      errors.Projectdescription = "*Please enter Description";
    }

    setProjecterror(errors);

    if (Object.keys(errors).length === 0) {
      handleSubmit();
      resetForm();
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProjectfields((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };
  const handleEmployeeSelection = (selectedOption) => {
    setselectedEmployee(selectedOption);
    setProjectfields((prevFormData) => ({
      ...prevFormData,
      Projectownership: selectedOption ? selectedOption.label : "",
      Employeeid: selectedOption ? selectedOption.value : -1,
    }));
  };

  const handleSubmit = async (e) => {
    console.log("abhi", handleSubmit);
    try {
      setTransactionLoaderState(true);
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");
      var raw = JSON.stringify({
        project_name: projectfield.Projectname,
        project_ownership: projectfield.Projectownership,
        project_startdate: projectfield.Projectstartdate,
        project_enddate: projectfield.Projectenddate,
        project_discription: projectfield.Projectdescription,
        fk_firmid: user.firm_id,
        ownership_empid: projectfield.Employeeid,
        created_by: "Employee",
      });

      console.log("Request sending:", raw);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
      console.log("sending data to abhijeet");
      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/InsertFirmProjects`,
        requestOptions
      );
      console.log("Api url");
      const data = await res.json();
      console.log("nishant", data);
      if (data.status === "1") {
        swal("Error", data.msg, "error");
        setTransactionLoaderState(false);
        getProjects();
        closeModal();
      } else {
        swal("Success", data.msg, "success");
        setTransactionLoaderState(false);
        getProjects();
        closeModal();
      }
      console.log("Form submission completed.");
    } catch (error) {
      console.log("Error:", error);
    }
  };
  const GetFirmEmployee = async (e) => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        firmId: user.firm_id,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/getEmployee`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        const mappedEmployees = data.data.map((employee) => ({
          value: employee.empid,
          label: `${employee.firstname} ${employee.lastname}`,
        }));
        setMember(mappedEmployees);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
    }
    setTransactionLoaderState(false);
  };
  useEffect(() => {
    GetFirmEmployee();
  }, []);
  const handleClose = () => {
    closeModal();
    resetForm();
  };
  const today = new Date().toISOString().split("T")[0];
  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="">Add Project</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={validate}>
            <div className="row">
              {" "}
              <Form.Group className="col-6 m-0">
                <Form.Label> Project Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Project Name"
                  id="Projectname"
                  name="Projectname"
                  value={projectfield.Projectname}
                  onChange={handleChange}
                />
                {projecterror.Projectname && (
                  <p className="text-danger">{projecterror.Projectname}</p>
                )}
              </Form.Group>
              <Form.Group className="col-6 m-0">
                <Form.Label> Owner Name</Form.Label>
                <Select
                  options={Member}
                  value={selectedEmployee}
                  onChange={handleEmployeeSelection}
                  placeholder="Select Name"
                />
                {projecterror.Projectownership && (
                  <p className="text-danger">{projecterror.Projectownership}</p>
                )}
              </Form.Group>
            </div>
            <div className="row">
              {" "}
              <Form.Group className="col-6 m-0">
                <Form.Label> Start Date</Form.Label>
                <Form.Control
                  type="date"
                  placeholder="Start Date"
                  id="Projectstartdate"
                  name="Projectstartdate"
                  value={projectfield.Projectstartdate}
                  onChange={handleChange}
                />
                {projecterror.Projectstartdate && (
                  <p className="text-danger">{projecterror.Projectstartdate}</p>
                )}
              </Form.Group>
              <Form.Group className="col-6 m-0">
                <Form.Label> End Date</Form.Label>
                <Form.Control
                  type="date"
                  placeholder="End Date"
                  id="Projectenddate"
                  name="Projectenddate"
                  value={projectfield.Projectenddate}
                  onChange={handleChange}
                  min={today}
                />
                {projecterror.Projectenddate && (
                  <p className="text-danger">{projecterror.Projectenddate}</p>
                )}
              </Form.Group>
            </div>
            <div className="row ">
              <Form.Group className="col">
                <Form.Label>Description</Form.Label>
                <textarea
                  className="form-control"
                  type="textarea"
                  name="Projectdescription"
                  value={projectfield.Projectdescription}
                  onChange={handleChange}
                  placeholder="Project Description"
                />
                {projecterror.Projectdescription && (
                  <p className="text-danger">
                    {projecterror.Projectdescription}
                  </p>
                )}
              </Form.Group>
            </div>
            <div className="col-4 ">
              <button
                // type="submit"
                type="button"
                className="btn btn-primary btn-block d-flex justify-content-center align-items-center"
                style={{ height: "2rem", borderRadius: ".3rem" }}
                onClick={validate}
              >
                Add Project
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};
export const AddWorkspaceadmin = ({ show, closeModal, getWorkspacedata }) => {
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    workspaceName: "",
    workspaceDesc: "",
    teamSize: "",
    workspaceDescription: "",
    colorCode: "",
    projectId: "",
  });

  const { password, username } = billpunchbasicauth;
  const history = useHistory();
  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const [projects, setProjects] = useState([]);

  const nextStep = () => {
    setStep(step + 1);
  };

  const prevStep = () => {
    setStep(step - 1);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "projectId" && value === "createProject") {
      openaddprojectModal();
      return;
    }
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleColorSelect = (color) => {
    setFormData({
      ...formData,
      colorCode: color,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (step === 1 && formData.workspaceName.trim() === "") {
      swal("Error", "Please enter workspace name.", "error");
      return;
    } else if (step === 2 && formData.colorCode === "") {
      swal("Error", "Please select an avatar color.", "error");
      return;
    } else if (step === 3 && formData.projectId === "") {
      swal("Error", "Please select a project.", "error");
      return;
    } else if (step === 4 && formData.teamSize === "") {
      swal("Error", "Please select team size.", "error");
      return;
    } else if (step === 5 && formData.workspaceDescription.trim() === "") {
      swal("Error", "Please enter workspace description.", "error");
      return;
    }

    if (step === 5) {
      console.log("Form submission started.");
      try {
        setStep(6);
        setTransactionLoaderState(true);
        var myHeaders = new Headers();
        myHeaders.append(
          "Authorization",
          "Basic " + Buffer.from(username + ":" + password).toString("base64")
        );
        myHeaders.append("Content-Type", "application/json");
        var raw = JSON.stringify({
          firmid: user.firm_id,
          name: formData.workspaceName,
          desc: formData.workspaceDescription,
          empid: user.userid,
          ownername: user.username,
          teamsize: formData.teamSize,
          colorcode: formData.colorCode,
          project_id: formData.projectId,
        });

        console.log("Request sending:", raw);

        var requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: raw,
          redirect: "follow",
        };

        console.log("Sending data to API.");
        const res = await fetch(
          `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/AddWorkSpace`,
          requestOptions
        );
        console.log("API URL");
        const data = await res.json();
        console.log("API response:", data);
        if (data.status === "1") {
          swal("Error", data.result, "error");
          setTransactionLoaderState(false);
        } else {
          swal("Success", data.result, "success");
          setTransactionLoaderState(false);
          closeModal();
          getWorkspacedata();
        }
        console.log("Form submission completed.");
      } catch (error) {
        console.log("Error:", error);
      }
    } else {
      nextStep();
    }
  };

  const fetchproject = async () => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        firmid: user.firm_id,
        created_by: "Admin",
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/GetFirmProjects
          `,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        setProjects(data.data);
      }
    } catch (error) {
      console.log("error", error);
    }
    setTransactionLoaderState(false);
  };
  useEffect(() => {
    fetchproject();
  }, []);
  const [showaddprojectModal, setshowaddprojectModal] = useState(false);
  const openaddprojectModal = () => {
    setshowaddprojectModal(true);
  };

  const closeaddprojectModal = () => {
    setshowaddprojectModal(false);
  };
  return (
    <>
      <AddProjectAdminModal
        show={showaddprojectModal}
        closeModal={closeaddprojectModal}
        getProjects={fetchproject}
      />
      <Modal show={show} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title className="">Add Workspace</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="card">
            <div className="card-body">
              <div className="row justify-content-center">
                <div className="col-md-8">
                  {/* Progress bar */}
                  <div className="progress mb-4">
                    <div
                      className="progress-bar"
                      role="progressbar"
                      style={{ width: `${(step - 1) * 20}%` }}
                      aria-valuenow={(step - 1) * 20}
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></div>
                  </div>
                </div>
              </div>

              {/* Steps rendering */}
              <form onSubmit={handleSubmit}>
                {step === 1 && (
                  <>
                    <h3 className="text-center mb-4">
                      Step 1: Name Your Workspace
                    </h3>
                    <div className="form-group">
                      <textarea
                        type="textarea"
                        name="workspaceName"
                        className="form-control"
                        value={formData.workspaceName}
                        onChange={handleChange}
                        placeholder="Enter workspace name"
                      />
                    </div>
                    <button type="submit" className="btn btn-primary">
                      Next
                    </button>
                  </>
                )}
                {step === 2 && (
                  <>
                    <h2 className="text-center mb-4">
                      Step 2: Create Your Avatar
                    </h2>
                    <div className="row">
                      <div className="col-3 avatar-container">
                        <div
                          className="avatar"
                          style={{
                            backgroundColor: formData.colorCode || "#FDBF60",
                            color: "#fff",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            borderRadius: "50%",
                            width: "100px",
                            height: "100px",
                            fontSize: "2rem",
                          }}
                        >
                          {formData.workspaceName.trim().charAt(0)}
                        </div>
                      </div>
                      <div className="col color-palette d-flex align-items-center">
                        <div
                          className="color-box"
                          style={{ backgroundColor: "#58A399" }}
                          onClick={() => handleColorSelect("#58A399")}
                        ></div>
                        <div
                          className="color-box"
                          style={{ backgroundColor: "#ACE2E1" }}
                          onClick={() => handleColorSelect("#ACE2E1")}
                        ></div>
                        <div
                          className="color-box"
                          style={{ backgroundColor: "#FF8E8F" }}
                          onClick={() => handleColorSelect("#FF8E8F")}
                        ></div>
                        <div
                          className="color-box"
                          style={{ backgroundColor: "#E1AFD1" }}
                          onClick={() => handleColorSelect("#E1AFD1")}
                        ></div>
                        <div
                          className="color-box"
                          style={{ backgroundColor: "#C5EBAA" }}
                          onClick={() => handleColorSelect("#C5EBAA")}
                        ></div>
                        <div
                          className="color-box"
                          style={{ backgroundColor: "#B7C9F2" }}
                          onClick={() => handleColorSelect("#B7C9F2")}
                        ></div>
                        <div
                          className="color-box"
                          style={{ backgroundColor: "#F2E18C" }}
                          onClick={() => handleColorSelect("#F2E18C")}
                        ></div>
                        <div
                          className="color-box"
                          style={{ backgroundColor: "#FDBF60" }}
                          onClick={() => handleColorSelect("#FDBF60")}
                        ></div>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between">
                      <button
                        type="button"
                        className="btn btn-secondary mr-2"
                        onClick={prevStep}
                      >
                        Previous
                      </button>
                      <button type="submit" className="btn btn-primary">
                        Next
                      </button>
                    </div>
                  </>
                )}
                {step === 3 && (
                  <>
                    <h2 className="text-center mb-4">Step 3: Assign Project</h2>
                    <div className="form-group">
                      <select
                        name="projectId"
                        className="form-control"
                        value={formData.projectId}
                        onChange={handleChange}
                      >
                        <option value="">Select a Project</option>
                        <option value="createProject">+ Create Project</option>
                        {projects.map((project) => (
                          <option
                            key={project.pk_project_id}
                            value={project.pk_project_id}
                          >
                            {project.project_name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="d-flex justify-content-between">
                      <button
                        type="button"
                        className="btn btn-secondary mr-2"
                        onClick={prevStep}
                      >
                        Previous
                      </button>
                      <button type="submit" className="btn btn-primary">
                        Next
                      </button>
                    </div>
                  </>
                )}
                {step === 4 && (
                  <>
                    <h2 className="text-center mb-4">Step 4: Team Size</h2>
                    <div className="form-group">
                      <select
                        name="teamSize"
                        className="form-control"
                        value={formData.teamSize}
                        onChange={handleChange}
                      >
                        <option value="">Select team size</option>
                        <option value="1-5">1-5</option>
                        <option value="6-10">6-10</option>
                        <option value="11-20">11-20</option>
                        <option value="21-50">21-50</option>
                        <option value="51-100">51-100</option>
                        <option value="101+">101+</option>
                      </select>
                    </div>
                    <div className="d-flex justify-content-between">
                      <button
                        type="button"
                        className="btn btn-secondary mr-2"
                        onClick={prevStep}
                      >
                        Previous
                      </button>
                      <button type="submit" className="btn btn-primary">
                        Next
                      </button>
                    </div>
                  </>
                )}
                {step === 5 && (
                  <>
                    <h2 className="text-center mb-4">
                      Step 5: Workspace Description
                    </h2>
                    <div className="form-group">
                      <textarea
                        type="textarea"
                        name="workspaceDescription"
                        className="form-control"
                        value={formData.workspaceDescription}
                        onChange={handleChange}
                        placeholder="Enter workspace description"
                      />
                    </div>
                    <div className="d-flex justify-content-between">
                      <button
                        type="button"
                        className="btn btn-secondary mr-2"
                        onClick={prevStep}
                      >
                        Previous
                      </button>
                      <button type="submit" className="btn btn-primary">
                        Submit
                      </button>
                    </div>
                  </>
                )}
                {step === 6 && (
                  <>
                    <h2 className="text-center mb-4">Submission In Progress</h2>
                    <div className="text-center">
                      <p>Please wait while your workspace is being created.</p>
                    </div>
                  </>
                )}
              </form>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export const AddBalanceleave = ({ show, closeModal, getbalanceleave }) => {
  const [Member, setMember] = useState([]);
  const { password, username } = billpunchbasicauth;

  const [leaveTypes, setLeaveTypes] = useState([]);
  const [selectedEmployee, setselectedEmployee] = useState("");
  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const [leavefield, setleavefields] = useState({
    Employee: "",
    leavetype: "",
    Leavefrom: "",
    Leaveto: "",
    PL: "",
    SL: "",
  });
  const [leaveerror, setleaveerror] = useState({
    Employee: "",
    leavetype: "",
    Leavefrom: "",
    Leaveto: "",
    PL: "",
    SL: "",
  });
  const resetForm = () => {
    setleavefields({
      Employee: "",
      leavetype: "",
      Leavefrom: "",
      Leaveto: "",
      PL: "",
      SL: "",
    });
    setleaveerror({
      Employee: "",
      leavetype: "",
      Leavefrom: "",
      Leaveto: "",
      PL: "",
      SL: "",
    });
    setselectedEmployee("");
  };
  const validate = (e) => {
    e.preventDefault();
    let errors = {};
    const spaceRegex = /^\s+/; // Regular expression to check for leading spaces

    if (!selectedEmployee) {
      errors.Employee = "*Please select an employee";
    }
    if (!leavefield.leavetype) {
      errors.leavetype = "*Please select a leave type";
    }
    if (spaceRegex.test(leavefield.Leavefrom)) {
      errors.Leavefrom = "*Date should not start with a space";
    } else if (leavefield.Leavefrom.trim() === "") {
      errors.Leavefrom = "*Please enter date";
    }
    if (spaceRegex.test(leavefield.Leaveto)) {
      errors.Leaveto = "*Date should not start with a space";
    } else if (leavefield.Leaveto.trim() === "") {
      errors.Leaveto = "*Please enter date";
    }

    setleaveerror(errors);

    if (Object.keys(errors).length === 0) {
      handleSubmit();
      resetForm();
    }
  };
  const handleSubmit = async (e) => {
    console.log("abhi", handleSubmit);
    try {
      setTransactionLoaderState(true);
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      const { Employee } = leavefield;

      const month = new Date().toLocaleString("default", { month: "long" });
      const selectedLeaveType = leaveTypes.find(
        (option) => option.value === leavefield.leavetype
      );

      myHeaders.append("Content-Type", "application/json");
      var raw = JSON.stringify({
        empid: Employee.value,
        leave_type: selectedLeaveType ? selectedLeaveType.label : "",
        leave_from: leavefield.Leavefrom,
        leave_to: leavefield.Leaveto,
        month: month,
      });

      console.log("Request sending:", raw);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
      console.log("sending data to abhijeet");
      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/AddLeaveBalance`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "1") {
        swal("Error", data.msg, "error");
        setTransactionLoaderState(false);
        closeModal();
        getbalanceleave();
      } else {
        swal("Success", data.msg, "success");
        setTransactionLoaderState(false);
        closeModal();
        getbalanceleave();
      }
      console.log("Form submission completed.");
    } catch (error) {
      console.log("Error:", error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setleavefields((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleEmployeeSelection = (selectedOption) => {
    setselectedEmployee(selectedOption);
    setleavefields((prevFormData) => ({
      ...prevFormData,
      Employee: selectedOption ? selectedOption : "",
      empid: selectedOption ? selectedOption.value : "",
    }));
  };

  const Getleave = async (e) => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({});

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/GetLeavetypes`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        const leaveTypeOptions = data.data.map((leave) => ({
          value: leave.pk_leave_type,
          label: leave.type,
        }));
        setLeaveTypes(leaveTypeOptions);
      } else {
        // Handle error response from API
        console.error("Error fetching leave types:", data.msg);
      }
    } catch (error) {
      console.error("Error fetching leave types:", error);
    }
  };
  const GetFirmEmployee = async (e) => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        firmId: user.firm_id,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/getEmployee`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        const mappedEmployees = data.data.map((employee) => ({
          value: employee.empid,
          label: `${employee.firstname} ${employee.lastname}`,
        }));
        setMember(mappedEmployees);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
    }
    setTransactionLoaderState(false);
  };
  useEffect(() => {
    GetFirmEmployee();
    Getleave();
  }, []);
  const handleClose = () => {
    closeModal();
    resetForm();
  };
  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="">Balance Leave</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={validate}>
            <div className="row">
              <Form.Group className="col m-0">
                <Form.Label> Employee</Form.Label>
                <Select
                  options={Member}
                  value={selectedEmployee}
                  onChange={handleEmployeeSelection}
                  placeholder="Select Name"
                />
                {leaveerror.Employee && (
                  <p className="text-danger">{leaveerror.Employee}</p>
                )}
              </Form.Group>

              <Form.Group className="col m-0">
                <Form.Label> Leave Type</Form.Label>
                <Select
                  options={leaveTypes}
                  value={leaveTypes.find(
                    (option) => option.value === leavefield.leavetype
                  )}
                  onChange={(selectedOption) =>
                    setleavefields((prevFormData) => ({
                      ...prevFormData,
                      leavetype: selectedOption.value,
                    }))
                  }
                  placeholder="Select leave type"
                />

                {leaveerror.leavetype && (
                  <p className="text-danger">{leaveerror.leavetype}</p>
                )}
              </Form.Group>
            </div>

            <div className="row">
              <Form.Label className="col-6">Current PL Balance :</Form.Label>
              <div className="col">
                <Form.Control
                  type="text"
                  name="PL"
                  value={leavefield.PL}
                  onChange={handleChange}
                  style={{ width: "4rem" }}
                />
              </div>
            </div>
            <div className="row">
              <Form.Label className="col-6">Current SL Balance :</Form.Label>
              <div className="col">
                <Form.Control
                  type="text"
                  name="SL"
                  value={leavefield.SL}
                  onChange={handleChange}
                  style={{ width: "4rem" }}
                />
              </div>
            </div>

            <div className="row">
              {" "}
              <Form.Group className="col-6 m-0">
                <Form.Label> Leave From</Form.Label>
                <Form.Control
                  type="date"
                  placeholder="Leave From"
                  id="Leavefrom"
                  name="Leavefrom"
                  value={leavefield.Leavefrom}
                  onChange={handleChange}
                />
                {leaveerror.Leavefrom && (
                  <p className="text-danger">{leaveerror.Leavefrom}</p>
                )}
              </Form.Group>
              <Form.Group className="col-6 m-0">
                <Form.Label> Leave To</Form.Label>
                <Form.Control
                  type="date"
                  placeholder="Leave To"
                  id="Leaveto"
                  name="Leaveto"
                  value={leavefield.Leaveto}
                  onChange={handleChange}
                />
                {leaveerror.Leaveto && (
                  <p className="text-danger">{leaveerror.Leaveto}</p>
                )}
              </Form.Group>
            </div>

            <div className="col-4 ">
              <button
                // type="submit"
                type="button"
                className="btn btn-primary btn-block d-flex justify-content-center align-items-center"
                style={{ height: "2rem", borderRadius: ".3rem" }}
                onClick={validate}
              >
                Submit
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};
export const AddResourceAdminModal = ({
  show,
  closeModal,
  getProjects,
  projectId,
}) => {
  const [Member, setMember] = useState([]);
  const [Employeeid, setEmployeeid] = useState(-1);
  const options = [
    { value: "app", label: "App" },
    { value: "web", label: "Web" },
  ];
  const { password, username } = billpunchbasicauth;

  const [selectedEmployee, setselectedEmployee] = useState("");
  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const [resourcefield, setresourcefields] = useState({
    Empname: "",
    empdesignaition: "",
    device: "",
  });
  const [resourceerror, setresourceerror] = useState({
    Empname: "",
    empdesignaition: "",
    device: "",
  });
  const validate = (e) => {
    e.preventDefault();
    let errors = {};
    const spaceRegex = /^\s+/;

    if (!selectedEmployee) {
      errors.Empname = "*Please select an employee";
    }

    if (spaceRegex.test(resourcefield.device)) {
      errors.device = "*Please select a device";
    } else if (resourcefield.device.trim() === "") {
      errors.device = "*Please select a device";
    }

    setresourceerror(errors);

    if (Object.keys(errors).length === 0) {
      handleSubmit();
    }
  };

  const [selectedType, setSelectedType] = useState(null);

  const handleTypeSelection = (selectedOption) => {
    setSelectedType(selectedOption);
    setresourcefields((prevFormData) => ({
      ...prevFormData,
      device: selectedOption.value,
    }));
  };
  const handleEmployeeSelection = (selectedOption) => {
    setselectedEmployee(selectedOption);
    const selectedEmployeeId = selectedOption ? selectedOption.value : -1;

    const selectedEmployeeDetails = Member.find(
      (employee) => employee.value === selectedEmployeeId
    );

    console.log("Selected Employee Details:", selectedEmployeeDetails);

    setresourcefields((prevFormData) => ({
      ...prevFormData,
      Empname: selectedOption.label,
      empdesignaition: selectedEmployeeDetails?.designation || "",
      Projectownership: selectedEmployeeId,
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setresourcefields((prevFormData) => ({
      ...prevFormData,
      [name]: value.trim(),
    }));
  };
  const handleSubmit = async (e) => {
    try {
      setTransactionLoaderState(true);
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");
      var raw = JSON.stringify({
        fk_project_id: projectId,
        fk_empid: selectedEmployee.value,
        projectdesig: resourcefield.empdesignaition,
        emp_name: selectedEmployee.label,
        device: selectedType.value,
        firmid: user.firm_id,
      });

      console.log("Request sending:", raw);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
      console.log("sending data to abhijeet");
      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/AddProjectResources`,
        requestOptions
      );
      console.log("Api url");
      const data = await res.json();
      if (data.status === "1") {
        swal("Error", data.msg, "error");
        setTransactionLoaderState(false);
        getProjects();
        closeModal();
      } else {
        swal("Success", data.msg, "success");
        setTransactionLoaderState(false);
        getProjects();
        closeModal();
      }
      console.log("Form submission completed.");
    } catch (error) {
      console.log("Error:", error);
    }
  };
  const GetFirmEmployee = async (e) => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        firmId: user.firm_id,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/getEmployee`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        const mappedEmployees = data.data.map((employee) => ({
          value: employee.empid,
          label: employee.lastname
            ? `${employee.firstname} ${employee.lastname}`
            : employee.firstname,
          designation: employee.designation || "NA",
        }));
        setMember(mappedEmployees);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
      setTransactionLoaderState(false);
    }
  };

  useEffect(() => {
    GetFirmEmployee();
  }, []);
  return (
    <>
      <Modal show={show} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title className="">Add Resource</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={validate}>
            <div className="row">
              {" "}
              <Form.Group className="col-6 m-0">
                <Form.Label> Employee Name</Form.Label>
                <Select
                  options={Member}
                  value={selectedEmployee}
                  onChange={handleEmployeeSelection}
                  placeholder="Select Name"
                />
                {resourceerror.Empname && (
                  <p className="text-danger">{resourceerror.Empname}</p>
                )}
              </Form.Group>
              <Form.Group className="col-6 m-0">
                <Form.Label> Designation</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Designation"
                  readOnly
                  id="empdesignaition"
                  name="empdesignaition"
                  value={resourcefield.empdesignaition} // Remove this line
                  onChange={handleChange} // Remove this line
                />
                {resourceerror.empdesignaition && (
                  <p className="text-danger">{resourceerror.empdesignaition}</p>
                )}
              </Form.Group>
            </div>

            <div className="row ">
              <Form.Group className="col">
                <Form.Label>Type</Form.Label>
                <Select
                  options={options}
                  value={selectedType}
                  onChange={handleTypeSelection}
                  placeholder="Select Type"
                />
                {resourceerror.device && (
                  <p className="text-danger">{resourceerror.device}</p>
                )}
              </Form.Group>
            </div>
            <div className="col-4 ">
              <button
                // type="submit"
                type="button"
                className="btn btn-primary btn-block d-flex justify-content-center align-items-center"
                style={{ height: "2rem", borderRadius: ".3rem" }}
                onClick={validate}
              >
                Add
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};
export const ViewResourceAdminModal = ({ show, closeModal, projectId }) => {
  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const [data, setData] = useState([]);
  const { password, username } = billpunchbasicauth;

  const resetModalState = () => {
    setData([]);
  };
  const getProjects = async (e) => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        projectid: projectId,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/GetProjectResources`,
        requestOptions
      );
      const data = await res.json();
      if (data.status == "0") {
        setData(data.data);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
    }
    setTransactionLoaderState(false);
  };
  useEffect(() => {
    if (show) {
      // Reset the state when the modal is opened
      resetModalState();
      // Fetch data
      getProjects();
    }
  }, [show, projectId]);

  return (
    <>
      <Modal show={show} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title className="">View Resource</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <table className="table table-bordered m-0 p-0 ">
            <thead>
              <tr style={{ borderBottom: "1px solid lightgrey" }}>
                <th
                  className="text-center"
                  style={{
                    color: "#0074D9",
                    background: "#fff",
                    border: "none",
                  }}
                >
                  Project
                </th>

                <th
                  className="text-center"
                  style={{
                    color: "#0074D9",
                    background: "#fff",
                    border: "none",
                  }}
                >
                  Ownership
                </th>
                <th
                  className="text-center"
                  style={{
                    color: "#0074D9",
                    background: "#fff",
                    border: "none",
                  }}
                >
                  Employee
                </th>

                <th
                  className="text-center"
                  style={{
                    color: "#0074D9",
                    background: "#fff",
                    border: "none",
                  }}
                >
                  Emp. desig.
                </th>
              </tr>
            </thead>
            <tbody>
              {data.length === 0 ? (
                <tr>
                  <td colSpan="4" className="text-center">
                    No data available
                  </td>
                </tr>
              ) : (
                data.map((item, index) => (
                  <tr
                    style={{ borderBottom: "1px solid lightgrey" }}
                    key={index}
                  >
                    <td
                      className="text-center align-middle"
                      style={{ border: "none" }}
                    >
                      {item.project_name || "NA"}
                    </td>
                    <td
                      className="text-center align-middle"
                      style={{ border: "none" }}
                    >
                      {item.project_ownership || "NA"}
                    </td>
                    <td
                      className="text-center align-middle"
                      style={{ border: "none" }}
                    >
                      {item.emp_name || "NA"}
                    </td>
                    <td
                      className="text-center align-middle"
                      style={{ border: "none" }}
                    >
                      {item.project_desig || "NA"}
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </Modal.Body>
      </Modal>
    </>
  );
};
export const CheckAttandanceAdmin = ({
  show,
  closeModal,
  projectId,
  months,
}) => {
  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const [data, setData] = useState([]);
  const { password, username } = billpunchbasicauth;

  const [searchQuery, setSearchQuery] = useState("");
  const [departmentData, setDepartmentData] = useState([]);
  const [department, setDepartment] = useState("");
  const resetModalState = () => {
    setData([]);
  };
  const getcheckattadancedata = async (e) => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        month: months,
        firmid: user.firm_id,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/GetAttendanceAll`,
        requestOptions
      );
      const data = await res.json();
      if (data.status == "0") {
        setData(data.data);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
    }
    setTransactionLoaderState(false);
  };
  const getDepartmentList = async () => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({ firmid: user.firm_id });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/getEmployeeDepartment`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        setDepartmentData(data.data);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
    }
  };
  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
  };
  useEffect(() => {
    if (show) {
      resetModalState();
      getcheckattadancedata();
    }
  }, [show, projectId]);
  useEffect(() => {
    getDepartmentList();
  }, []);
  const filteredData = data.filter((item) => {
    const fullName =
      `${item.employee_id} ${item.employee_name} ${item.month} ${item.total_present}`.toLowerCase();
    return fullName.includes(searchQuery.toLowerCase());
  });
  return (
    <>
      <Modal show={show} onHide={closeModal} className="chechattandance-modal">
        <Modal.Header closeButton>
          <Modal.Title className="">Attendance Monthly Report</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-4 col pb-md-2">
              <select
                style={{
                  width: "9rem",
                  height: "2rem",
                  borderRadius: ".2rem",
                  border: "1px #ced4da solid",
                }}
                value={department}
                onChange={(e) => setDepartment(e.target.value)}
              >
                <option value="">All Department</option>
                {departmentData.map((dept) => (
                  <option key={dept.id} value={dept.cat1}>
                    {dept.cat1}
                  </option>
                ))}
              </select>
            </div>
            <div className="col">
              <input
                style={{
                  background: "#fff",
                  borderRadius: ".4rem",
                  // width: "30% ",
                  border: "1px #ced4da solid",
                  paddingLeft: "10px",
                  height: "2rem",
                }}
                type="text"
                placeholder="Search"
                value={searchQuery}
                onChange={handleSearchInputChange}
              />
            </div>
          </div>
          <table className="table table-bordered m-0 p-0 ">
            <thead>
              <tr style={{ borderBottom: "1px solid lightgrey" }}>
                <th
                  className="text-center"
                  style={{
                    color: "#0074D9",
                    background: "#fff",
                    border: "none",
                  }}
                >
                  ID
                </th>

                <th
                  className="text-center"
                  style={{
                    color: "#0074D9",
                    background: "#fff",
                    border: "none",
                  }}
                >
                  Name
                </th>
                <th
                  className="text-center"
                  style={{
                    color: "#0074D9",
                    background: "#fff",
                    border: "none",
                  }}
                >
                  Month
                </th>

                <th
                  className="text-center"
                  style={{
                    color: "#0074D9",
                    background: "#fff",
                    border: "none",
                  }}
                >
                  Total Present
                </th>
              </tr>
            </thead>
            <tbody>
              {filteredData.length === 0 ? (
                <tr>
                  <td colSpan="4" className="text-center">
                    No data available
                  </td>
                </tr>
              ) : (
                filteredData.map((item, index) => (
                  <tr
                    style={{ borderBottom: "1px solid lightgrey" }}
                    key={index}
                  >
                    <td
                      className="text-center align-middle"
                      style={{ border: "none" }}
                    >
                      {item.employee_id || "NA"}
                    </td>
                    <td
                      className="text-center align-middle"
                      style={{ border: "none" }}
                    >
                      {item.employeename || "NA"}
                    </td>
                    <td
                      className="text-center align-middle"
                      style={{ border: "none" }}
                    >
                      {item.month || "NA"}
                    </td>
                    <td
                      className="text-center align-middle"
                      style={{ border: "none" }}
                    >
                      {item.total_present || "NA"}
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </Modal.Body>
      </Modal>
    </>
  );
};

export const BulkAttendanceAdmin = ({ show, closeModal }) => {
  const months = [
    { name: "January", value: " 1" },
    { name: "February", value: "2" },
    { name: "March", value: "3" },
    { name: "April", value: "4" },
    { name: "May", value: "5" },
    { name: "June", value: "6" },
    { name: "July", value: "7" },
    { name: "August", value: "8" },
    { name: "September", value: "9" },
    { name: "October", value: "10" },
    { name: "November", value: "11" },
    { name: "December", value: "12" },
  ];
  const [data, setData] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const [selectedEmployeeIds, setSelectedEmployeeIds] = useState([]);
  const { password, username } = billpunchbasicauth;

  const [expandedEmployeeId, setExpandedEmployeeId] = useState(null);
  const [departmentData, setDepartmentData] = useState([]);
  const [department, setDepartment] = useState("");
  const [selectedYear, setSelectedYear] = useState("2024");
  const [selectedMonth, setSelectedMonth] = useState("01");
  const [showProcessAllModal, setShowProcessAllModal] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [generate, setgenerate] = useState();

  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const toggleEmployeeDetails = (employeeId) => {
    if (expandedEmployeeId === employeeId) {
      setExpandedEmployeeId(null);
    } else {
      setExpandedEmployeeId(employeeId);
    }
  };
  const handleYearChange = (e) => {
    setSelectedYear(e.target.value);
  };

  const handleMonthChange = (e) => {
    setSelectedMonth(e.target.value);
  };
  const getDepartmentList = async () => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({ firmid: user.firm_id });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/getEmployeeDepartment`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        setDepartmentData(data.data);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
    }
  };

  const getEmployeeList = async (month) => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        month: month,
        firmid: user.firm_id,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/GetAttendanceAll`,
        requestOptions
      );
      const apiData = await res.json();

      console.log("API Response:", apiData);

      if (apiData.status === "0" && apiData.data) {
        setData(
          apiData.data.map((employee) => ({ ...employee, selected: false }))
        );
      }

      setTransactionLoaderState(false);
    } catch (error) {
      console.log("Error fetching employee data:", error);
      setTransactionLoaderState(false);
    }
  };

  const [filteredData, setFilteredData] = useState([]);

  const handleDepartmentChange = (selectedDepartment) => {
    setDepartment(selectedDepartment);
  };
  useEffect(() => {
    const filteredResults = data.filter((item) => {
      if (department === "") {
        return true;
      } else {
        return item.department === department;
      }
    });
    setFilteredData(filteredResults);
  }, [data, department]);
  useEffect(() => {
    getDepartmentList();
  }, []);
  useEffect(() => {
    getEmployeeList(selectedMonth);
  }, [selectedMonth]);
  return (
    <Modal show={show} onHide={closeModal} className="generate_modal">
      <Modal.Header className="row m-0 " closeButton>
        <Modal.Title className="col">Check Attendance</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row  px-2">
          <div className="col-md-2 col p-0">
            <select
              style={{
                width: "5rem",
                height: "2rem",
                borderRadius: ".4rem",
                border: "1px #ced4da solid",
              }}
              value={selectedYear}
              onChange={handleYearChange}
            >
              <option value="2023">2023</option>
              <option value="2024">2024</option>
              <option value={"2025"}>2025</option>
              <option value={"2026"}>2026</option>
            </select>
          </div>
          <div className="col-md-2 col mr-3 ">
            <select
              style={{
                width: "5rem",
                height: "2rem",
                borderRadius: ".4rem",
                border: "1px #ced4da solid",
              }}
              value={selectedMonth}
              onChange={handleMonthChange}
            >
              {months.map((month) => (
                <option key={month.value} value={month.value}>
                  {month.name}
                </option>
              ))}
            </select>
          </div>
          {/* <div className="col">
            <select
              style={{
                width: "9rem",
                height: "2rem",
                borderRadius: ".4rem",
                border: "1px #ced4da solid",
              }}
              value={department}
              onChange={(e) => handleDepartmentChange(e.target.value)}
            >
              <option value="">All Department</option>
              {departmentData.map((dept) => (
                <option key={dept.id} value={dept.cat1}>
                  {dept.cat1}
                </option>
              ))}
            </select>
          </div> */}
        </div>
        <table
          className="my-table transparent-table text-center border-bottom"
          style={{
            width: "100%",
            overflowX: "auto",
            border: "0px",
            overflow: "auto",
          }}
        >
          <tr className="borderless-table text-center border-bottom">
            <th
              className="my-table transparent-table text-center border-bottom "
              style={{
                fontFamily: "Poppins",
                fontSize: "16px",
                fontWeight: "700",
                lineHeight: "24px",
                letterSpacing: "0em",
                textAlign: "center",
              }}
            >
              {" "}
              {"Sr"}
            </th>
            <th
              className="my-table transparent-table text-center border-bottom "
              style={{
                fontFamily: "Poppins",
                fontSize: "16px",
                fontWeight: "700",
                lineHeight: "24px",
                letterSpacing: "0em",
                textAlign: "center",
              }}
            >
              Name
            </th>
            <th
              className="my-table transparent-table text-center border-bottom "
              style={{
                textAlign: "center",
              }}
            >
              Check Attendance
            </th>
          </tr>
          {filteredData.length > 0 ? (
            filteredData.map((e, ind) => (
              <React.Fragment key={e.employee_id}>
                <tr key={e.employee_id}>
                  <td
                    className="text-center border-bottom"
                    style={{
                      fontSize: "0.8rem",
                      fontFamily: "Poppins",
                      fontWeight: "400",
                      lineHeight: "24px",
                      letterSpacing: "0em",
                      textAlign: "center",
                    }}
                  >
                    {" "}
                    {ind + 1}
                  </td>
                  <td
                    className="text-center border-bottom"
                    style={{
                      fontSize: "0.8rem",
                      fontFamily: "Poppins",
                      fontWeight: "400",
                      lineHeight: "24px",
                      letterSpacing: "0em",
                      textAlign: "center",
                    }}
                  >
                    <div>{e.employeename}</div>
                  </td>
                  <td
                    className="text-center border-bottom"
                    onClick={() => toggleEmployeeDetails(e.employee_id)}
                    style={{ cursor: "pointer" }}
                  >
                    {expandedEmployeeId === e.employee_id ? (
                      <FaCaretDown />
                    ) : (
                      <FaCaretRight />
                    )}
                  </td>
                </tr>
                {expandedEmployeeId === e.employee_id && (
                  <tr>
                    <td colSpan="4">
                      <div
                        className="card m-0 p-2"
                        style={{ background: "antiquewhite" }}
                      >
                        <div
                          className="row m-0 p-0"
                          style={{ width: "100%", margin: "0" }}
                        >
                          <>
                            <p
                              style={{
                                fontSize: ".9rem",
                                fontWeight: "400",
                              }}
                              className="col-6 m-0 p-0"
                            >
                              Present Days: {e.total_present || "NA"}
                            </p>
                            <p
                              style={{
                                fontSize: ".9rem",
                                fontWeight: "400",
                              }}
                              className="col-6 m-0 p-0"
                            >
                              Approved Leave: {e.total_approvedleave || "NA"}
                            </p>
                            <p
                              style={{
                                fontSize: ".9rem",
                                fontWeight: "400",
                              }}
                              className="col-6 m-0 p-0"
                            >
                              Rejected Leave: {e.total_rejectedleave || "NA"}
                            </p>
                            <p
                              style={{
                                fontSize: ".9rem",
                                fontWeight: "400",
                              }}
                              className="col-6 m-0 p-0"
                            >
                              Total Leave: {e.total_absent || "NA"}
                            </p>
                          </>
                        </div>
                      </div>
                    </td>
                  </tr>
                )}
              </React.Fragment>
            ))
          ) : (
            <tr>
              <td
                colSpan="4"
                className="text-center"
                style={{ fontSize: "1rem", fontWeight: "300" }}
              >
                No data found.
              </td>
            </tr>
          )}
        </table>
      </Modal.Body>
    </Modal>
  );
};
export const WFHmodal = ({ show, closeModal, getAttandancedata }) => {
  const [Member, setMember] = useState([]);
  const [Employeeid, setEmployeeid] = useState(-1);
  const { password, username } = billpunchbasicauth;
  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const [selectedEmployee, setselectedEmployee] = useState("");
  const [wfhform, setwfhform] = useState({
    Employee: "",
    Date: "",
  });

  const GetFirmEmployee = async (e) => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        firmId: user.firm_id,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/getEmployee`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        const mappedEmployees = data.data.map((employee) => ({
          value: employee.empid,
          label: `${employee.firstname} ${employee.lastname}`,
        }));
        setMember(mappedEmployees);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
    }
    setTransactionLoaderState(false);
  };
  const handleEmployeeSelection = (selectedOption) => {
    setselectedEmployee(selectedOption);
    const selectedEmployeeId = selectedOption.value;
    const selectedEmployeeName = selectedOption.label;
    setEmployeeid(selectedEmployeeId);
  };
  const formatDate = (date) => {
    const [year, month, day] = date.split("-");
    return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
  };

  const handleChangeDate = (date) => {
    setwfhform({ ...wfhform, Date: date });
  };

  const handleaddwfh = async (e) => {
    e.preventDefault();
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      const requestBody = JSON.stringify({
        firmid: user.firm_id,
        empid: Employeeid,
        date: wfhform.Date,
        status: "Y",
        month: parseInt(wfhform.Date.split("-")[1], 10).toString(),
        year: wfhform.Date.split("-")[0],
        type: "WFH",
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: requestBody,
        redirect: "follow",
      };

      const response = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/AddWorkFromHome`,
        requestOptions
      );

      const data = await response.json();
      if (data.status === "0") {
        swal("Success", data.result, "success");
        closeModal();
        getAttandancedata();
      } else {
        swal("Error", data.result, "error");
      }
      setTransactionLoaderState(false);
    } catch (error) {
      swal("Error", error);
    }
    setTransactionLoaderState(false);
  };
  useEffect(() => {
    GetFirmEmployee();
  }, []);
  return (
    <Modal show={show} onHide={closeModal} className="generate_modal">
      <Modal.Header className="row m-0" closeButton>
        <Modal.Title className="col">Mark Work From Home</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleaddwfh}>
          <div className="row ">
            <Form.Group className="col" controlId="Employeename">
              <Form.Label>Select Employee</Form.Label>
              <Select
                options={[
                  { value: "", label: "Please Select Employee" },
                  ...Member,
                ]}
                value={selectedEmployee}
                onChange={handleEmployeeSelection}
              />
            </Form.Group>
            <Form.Group className="col" controlId="Date">
              <Form.Label>Select Date</Form.Label>
              <Form.Control
                type="date"
                value={wfhform.Date ? formatDate(wfhform.Date) : ""}
                onChange={(e) => handleChangeDate(e.target.value)}
                className="form-control"
              />
            </Form.Group>
          </div>

          <div className="px-2">
            {" "}
            <Button onClick={handleaddwfh} variant="primary" type="submit">
              Submit
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

// HR
export const WFHhrmodal = ({ show, closeModal, getAttandancedata }) => {
  const [Member, setMember] = useState([]);
  const [Employeeid, setEmployeeid] = useState(-1);
  const { password, username } = billpunchbasicauth;
  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const [selectedEmployee, setselectedEmployee] = useState("");
  const [wfhform, setwfhform] = useState({
    Employee: "",
    Date: "",
  });

  const GetFirmEmployee = async (e) => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        firmId: user.firm_id,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/getEmployee`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        const mappedEmployees = data.data.map((employee) => ({
          value: employee.empid,
          label: `${employee.firstname} ${employee.lastname}`,
        }));
        setMember(mappedEmployees);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
    }
    setTransactionLoaderState(false);
  };
  const handleEmployeeSelection = (selectedOption) => {
    setselectedEmployee(selectedOption);
    const selectedEmployeeId = selectedOption.value;
    const selectedEmployeeName = selectedOption.label;
    setEmployeeid(selectedEmployeeId);
  };
  const formatDate = (date) => {
    const [year, month, day] = date.split("-");
    return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
  };

  const handleChangeDate = (date) => {
    setwfhform({ ...wfhform, Date: date });
  };

  const handleaddwfh = async (e) => {
    e.preventDefault();
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      const requestBody = JSON.stringify({
        firmid: user.firm_id,
        empid: Employeeid,
        date: wfhform.Date,
        status: "Y",
        month: parseInt(wfhform.Date.split("-")[1], 10).toString(),
        year: wfhform.Date.split("-")[0],
        type: "WFH",
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: requestBody,
        redirect: "follow",
      };

      const response = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/AddWorkFromHome`,
        requestOptions
      );

      const data = await response.json();
      if (data.status === "0") {
        swal("Success", data.result, "success");
        closeModal();
        getAttandancedata();
      } else {
        swal("Error", data.result, "error");
      }
      setTransactionLoaderState(false);
    } catch (error) {
      swal("Error", error);
    }
    setTransactionLoaderState(false);
  };
  useEffect(() => {
    GetFirmEmployee();
  }, []);
  return (
    <Modal show={show} onHide={closeModal} className="generate_modal">
      <Modal.Header className="row m-0" closeButton>
        <Modal.Title className="col">Mark Work From Home</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleaddwfh}>
          <div className="row ">
            <Form.Group className="col" controlId="Employeename">
              <Form.Label>Select Employee</Form.Label>
              <Select
                options={[
                  { value: "", label: "Please Select Employee" },
                  ...Member,
                ]}
                value={selectedEmployee}
                onChange={handleEmployeeSelection}
              />
            </Form.Group>
            <Form.Group className="col" controlId="Date">
              <Form.Label>Select Date</Form.Label>
              <Form.Control
                type="date"
                value={wfhform.Date ? formatDate(wfhform.Date) : ""}
                onChange={(e) => handleChangeDate(e.target.value)}
                className="form-control"
              />
            </Form.Group>
          </div>

          <div className="px-2">
            {" "}
            <Button onClick={handleaddwfh} variant="primary" type="submit">
              Submit
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};
export const AddBillFormHR = ({
  showModal,
  handleCloseModal,
  getAllPropertyBills,
}) => {
  const { password, username } = billpunchbasicauth;

  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const servicesOption = [
    {
      value: 1,
      label: "Broadband Postpaid",
    },
    {
      value: 2,
      label: "Cable TV",
    },
    {
      value: 3,
      label: "DTH",
    },
    {
      value: 4,
      label: "Education Fees",
    },
    {
      value: 5,
      label: "Electricity",
    },
    {
      value: 6,
      label: "Fastag",
    },
    {
      value: 7,
      label: "Gas",
    },
    {
      value: 8,
      label: "Health Insurance",
    },
    {
      value: 9,
      label: "Hospital",
    },
    {
      value: 10,
      label: "Housing Society",
    },
    {
      value: 11,
      label: "Insurance",
    },
    {
      value: 12,
      label: "Landline Postpaid",
    },
    {
      value: 13,
      label: "Life Insurance",
    },
    {
      value: 14,
      label: "Loan Repayment",
    },
    {
      value: 15,
      label: "LPG Gas",
    },
    {
      value: 16,
      label: "Mobile Postpaid",
    },
    {
      value: 17,
      label: "Municipal Services",
    },
    {
      value: 18,
      label: "Municipal Taxes",
    },
    {
      value: 19,
      label: "Subscription",
    },
    {
      value: 20,
      label: "Water",
    },
    {
      value: 21,
      label: "Clubs and Associations",
    },
    {
      value: 22,
      label: "Credit Card",
    },
    {
      value: 23,
      label: "Hospital and Pathology",
    },
    {
      value: 24,
      label: "Mobile Prepaid",
    },
    {
      value: 25,
      label: "Recurring Deposit",
    },
    {
      value: 26,
      label: "Travel Agency",
    },
  ];

  console.log("Service options:", servicesOption);

  const [service, setService] = useState("1");

  console.log("Service state:", service);
  const [CustP, setCustP] = useState([]);

  console.log("CustP state:", CustP);

  const [providers, setProviders] = useState([]);
  console.log("Providers state:", providers);

  const [formData, setFormData] = useState({
    Service: "1",
    Provider: "",
    ConsumerId: "",
    Mobile: "",
    Amount: "",
  });
  console.log("FormData state:", formData);

  const [billerResponse, setBillerResponse] = useState({
    msg: "",
    success: "",
    error: [],
  });
  console.log("BillerResponse state:", billerResponse);

  const [state, setState] = useState({
    provider: "",
    consumer: "",
    mobileNumber: "",
    custparams: "",
  });
  console.log("State state:", state);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    if (name === "Service") {
      setService(value);
    }

    console.log("Form data after change:", formData);
  };
  const { Profileinfo } = useContext(GlobalContext);
  console.log("User from context:", user);
  console.log("Profileinfo from context:", Profileinfo);

  const handleSubmit = async (e) => {
    console.log("Submit button clicked");
    setTransactionLoaderState(true);
    try {
      console.log("hellp");
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");
      var raw = JSON.stringify({
        firmid: user.firm_id,
        service: formData.Service.label,
        provider: state.provider.value,
        consumerid: CustP[0].value,
        paramsList: JSON.stringify(CustP),
        refno: "string",
        mobile: Profileinfo?.mobile,
        month: new Date().toISOString(),
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/addfirmBill`,
        requestOptions
      );

      const data = await res.json();
      console.log("nishant", data);
      if (data.status === "1") {
        swal("Error", data.msg, "error");
        handleCloseModal();
        getAllPropertyBills();
      } else {
        sessionStorage.setItem("params", JSON.stringify(CustP));
        swal("Success", data.msg, "success");
        handleCloseModal();
        getAllPropertyBills();
      }
      setTransactionLoaderState(false);
      console.log("Form submission completed.");
    } catch (error) {
      console.log("Error:", error);
    }
    setTransactionLoaderState(false);
  };

  const fetchAllProviders = async () => {
    console.log("Fetching providers started");
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic IHJham5lZXNoQGV4YW1wbGUuY29tIDogVGVzdEAxMjM0NQ=="
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        userName: "string",
        categoryId: formData.Service.value,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        "https://apitranxt.paynnow.com/api/Operator/GetAllOperators",
        requestOptions
      );
      const data = await res.json();
      if (data.responseCode === "00") {
        const modifiedProviders = data.data.map((e) => {
          return { ...e, label: e.billerName, value: e.billerId };
        });
        console.log("Fetched providers data:", data.data);
        console.log("Modified providers:", modifiedProviders);

        setProviders(modifiedProviders);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("Error fetching providers:", error);
    }
    setTransactionLoaderState(false);
  };
  console.log("Providers:", providers);

  console.log(providers);

  const fetchAllProviders2 = async () => {
    setTransactionLoaderState(true);
    var myHeaders = new Headers();

    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Cookie",
      ".AspNetCore.Identity.Application=CfDJ8PA2Dduz6XVGpbQn-N2Kqq5nxn_jDhpPRlBHLmNVLCibYXbTK71aALOCGXbw4fHmGhYSemNPyrCo-W6rHg7K58J-En6cgJpZ_RlVeTHZjRO-FBKIs4zFAbZbeYrgCNKW34Wbm5lDeVV2H497JoGEWo0O4ar5aLanS1NTFH0LmOimscy41s2tPRyPfVtXiPSkgXialxacSHvLEna7HTGsDYgNPejg9kViVhahKHVV_dmsspRhxuUQ8yLOGeamszLCs_o-lMC9Lj3AHZaIyu-vfV4cZpESP9oj4s69Wl0Yc85c6o_NTuttKXDgIJrem_5W1KRitMel6aSEdsmqpEiuNho56pF8iIOkxL6bb9S6CjYK-dhGj14Q8ZZDzCDnxdHOTIU3iCx0i_qitJLvxhH5kSo9kigZlsxkVspvOtSp-NMDLPHeM3pOeeY5rY5pjLlNp2IUT9fKiJR1IRxvOHf4O93Gho2vl6J8QpS_5fsI0c2yN5o_UwssJ57vdkWxtUonmx_mDPb0bO6z48lcGvJiyp-aZx77HCulXFRaiyqJAD5mmjhDMf4KGYyjc-cK4j1VwJRLu_6pCN6YdfrY5B6sjmLnWo5rV4kTHNulkdJKAtekwySG6WI0l9wjyEVZNVmdioWaFXJ4p6gjNecN3lHJsEi7n24V6IXJ8zdm1Rz3jztK"
    );

    var raw = JSON.stringify({
      userid: user.ReferId,
      ccategoryId: formData.Service.value,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    try {
      const res = await fetch(
        `${BASE_URL_APISERVICES}/api/Payment/bbps/getAllOnlineOperators`,
        requestOptions
      );
      const raw = await res.text();
      const data = JSON.parse(raw);
      console.log(data);
      if (data.data != null) {
        setProviders(
          data.data.map((pro) => {
            return { ...pro, value: pro.billerName, label: pro.billerName };
          })
        );
      }
      setTransactionLoaderState(false);
    } catch (error) {
      setTransactionLoaderState(false);
      console.log("error", error);
    }
  };

  useEffect(() => {
    fetchAllProviders();
  }, [formData.Service]);

  useEffect(() => {
    if (state.provider) {
      setCustP(
        state.provider.customerParams.map((param, i) => {
          return { ...param, id: i, value: "" };
        })
      );
    }

    console.log("CustP after state.provider change:", CustP);
  }, [state]);

  console.log("CustP after useEffect:", CustP);

  console.log("CustP JSON:", JSON.stringify(CustP));

  return (
    <>
      <div>
        <Modal show={showModal} onHide={handleCloseModal}>
          <Modal.Header closeButton>
            <Modal.Title className="mx-md-3">Add Bill</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form className="m-md-2 ">
              <div className="row">
                <Form.Group className="col-6">
                  <Form.Label style={{ color: "black" }}> Services</Form.Label>
                  <Select
                    options={servicesOption}
                    value={formData.Service}
                    onChange={(e) => {
                      setFormData({ ...formData, Service: e });
                    }}
                  ></Select>
                </Form.Group>

                <Form.Group className="col-6">
                  <Form.Label style={{ color: "black" }}>Provider</Form.Label>
                  <Select
                    options={providers}
                    value={state.provider}
                    onChange={(e) => {
                      setState({
                        ...state,
                        provider: e,
                      });
                      setFormData({
                        ...formData,
                        Provider: e,
                      });
                    }}
                  />
                </Form.Group>
              </div>
              <div className="row">
                {CustP.map((cust, i) => (
                  <Form.Group key={cust.id} className="col-6 px-md-3 m-0 px-0">
                    {cust.optional == "true" ? (
                      <Form.Label>{`${cust.paramName} (optional)`}</Form.Label>
                    ) : (
                      <Form.Label>
                        <span style={{ color: "red" }}>*</span>
                        {` ${cust.paramName}`}
                      </Form.Label>
                    )}
                    <Form.Control
                      type="text"
                      className="form-control rounded-0"
                      aria-label="Notes"
                      aria-describedby="basic-addon1"
                      placeholder={`Enter ${cust.paramName}`}
                      onChange={(e) => {
                        setBillerResponse({
                          ...billerResponse,
                          error: [],
                          msg: "",
                        });
                        const copy = [...CustP];
                        copy.forEach((ele) => {
                          if (ele.id == cust.id) {
                            ele.value = e.target.value;
                          }
                        });
                        setCustP(copy);
                      }}
                      value={cust.value}
                    />
                  </Form.Group>
                ))}
              </div>
              <div className="row">
                <Form.Group className="col-6">
                  <Form.Label style={{ color: "black" }}>Amount</Form.Label>
                  <input
                    type="text"
                    className="form-control "
                    id="Amount"
                    name="Amount"
                    value={formData.Amount}
                    onChange={handleChange}
                    placeholder="Enter Amount"
                  />
                </Form.Group>
                <div className="col-4  pt-md-3">
                  <button
                    // type="submit"
                    type="button"
                    className="btn btn-primary btn-block d-flex justify-content-center align-items-center"
                    style={{ height: "2.5rem", borderRadius: ".3rem" }}
                    onClick={handleSubmit}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </form>{" "}
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export const FetchBillModalHR = ({
  setFetchBillModal,
  fetchBillModal,
  payBill,
  billDetails,
  billerResponse,
  callback,
  billPayResponse,
  setAmount,
  amount,
  setTpin,
  Tpin,
  config,
}) => {
  const { password, username } = billpunchbasicauth;

  const [tpinShow, setTpinShow] = useState(false);
  const [PayClicked, setPayClicked] = useState(false);
  const openInNewTab = (data) => {
    localStorage.setItem("print", JSON.stringify(data));
    const CLIENT = "";
    const newWindow = window.open(
      `${CLIENT}/pages/bbps/print`,
      "",
      "height=600,width=800'"
    );
    if (newWindow) newWindow.opener = null;
  };

  const showTPinClick = () => {
    setTpinShow(!tpinShow);
  };
  return (
    <Modal show={fetchBillModal} onHide={setFetchBillModal}>
      <Modal.Header closeButton>
        <Modal.Title>Fetch Bill</Modal.Title>
      </Modal.Header>

      <Modal.Body
        style={{ width: "100%", display: "block" }}
        className="account m-0 p-0"
      >
        {billDetails && !billPayResponse && (
          <Card style={{ margin: "10px", padding: "10px" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <img
                src={require("../../../assets/images/BBPSSidebarLogo.png")}
                style={{
                  width: "auto",
                  height: "1.2rem",
                  backgroundColor: "whitesmoke",
                }}
              />
              <img
                onClick={() => {
                  openInNewTab(billDetails);
                }}
                style={{ height: "1.5rem" }}
                src={require("../../../assets/icons/print_1.png")}
              />
            </div>
            <table style={{ border: "none", fontFamily: "-webkit-body" }}>
              <tr style={{ background: "none", border: "none" }}>
                <td
                  style={{
                    paddingLeft: "1rem",
                    border: "none",
                    background: "none",
                    width: "50%",
                    fontSize: "0.8rem",
                  }}
                >
                  {" "}
                  Name
                </td>
                <td
                  style={{
                    paddingLeft: "1rem",
                    border: "none",
                    background: "none",
                    width: "50%",
                    fontSize: "0.8rem",
                  }}
                >
                  {" "}
                  {billDetails.billerResponse.customerName}
                </td>
              </tr>
              <tr style={{ background: "none" }}>
                <td
                  style={{
                    paddingLeft: "1rem",
                    border: "none",
                    background: "none",
                    width: "50%",
                    fontSize: "0.8rem",
                  }}
                >
                  {" "}
                  Bill Number
                </td>
                <td
                  style={{
                    paddingLeft: "1rem",
                    border: "none",
                    background: "none",
                    width: "50%",
                    fontSize: "0.8rem",
                  }}
                >
                  {" "}
                  {billDetails.billerResponse.billNumber}
                </td>
              </tr>
              <tr style={{ background: "none" }}>
                <td
                  style={{
                    paddingLeft: "1rem",
                    border: "none",
                    background: "none",
                    width: "50%",
                    fontSize: "0.8rem",
                  }}
                >
                  {" "}
                  Amount
                </td>
                <td
                  style={{
                    paddingLeft: "1rem",
                    border: "none",
                    background: "none",
                    width: "50%",
                    fontSize: "0.8rem",
                  }}
                >
                  {" "}
                  {amount}
                </td>
              </tr>

              {/* Additional Info */}
              {billDetails.additionalInfo &&
                billDetails.additionalInfo.length > 0 &&
                billDetails.additionalInfo.map((info) => (
                  <tr style={{ background: "none" }}>
                    <td
                      style={{
                        paddingLeft: "1rem",
                        border: "none",
                        background: "none",
                        width: "50%",
                        fontSize: "0.8rem",
                      }}
                    >
                      {info.name}
                    </td>
                    <td
                      style={{
                        paddingLeft: "1rem",
                        border: "none",
                        background: "none",
                        width: "50%",
                        fontSize: "0.8rem",
                      }}
                    >
                      {info.value}
                    </td>
                  </tr>
                ))}

              <tr style={{ background: "none" }}>
                <td
                  style={{
                    paddingLeft: "1rem",
                    border: "none",
                    background: "none",
                    width: "50%",
                    fontSize: "0.8rem",
                  }}
                >
                  {" "}
                  Bill date
                </td>
                <td
                  style={{
                    paddingLeft: "1rem",
                    border: "none",
                    background: "none",
                    width: "50%",
                    fontSize: "0.8rem",
                  }}
                >
                  {" "}
                  {billDetails.billerResponse.billDate}
                </td>
              </tr>

              <tr style={{ background: "none" }}>
                <td
                  style={{
                    paddingLeft: "1rem",
                    border: "none",
                    background: "none",
                    width: "50%",
                    fontSize: "0.8rem",
                  }}
                >
                  {" "}
                  Due Date.
                </td>
                <td
                  style={{
                    paddingLeft: "1rem",
                    border: "none",
                    background: "none",
                    width: "50%",
                    fontSize: "0.8rem",
                  }}
                >
                  {" "}
                  {billDetails.billerResponse.dueDate}
                </td>
              </tr>
              <tr style={{ background: "none" }}>
                <td
                  style={{
                    paddingLeft: "1rem",
                    border: "none",
                    background: "none",
                    width: "50%",
                    fontSize: "0.8rem",
                  }}
                >
                  {" "}
                  Reference Id
                </td>
                <td
                  style={{
                    paddingLeft: "1rem",
                    border: "none",
                    background: "none",
                    width: "50%",
                    fontSize: "0.8rem",
                  }}
                >
                  {" "}
                  {billDetails.refId}
                </td>
              </tr>

              {config.amountEditable && (
                <tr style={{ background: "none" }}>
                  <td
                    style={{
                      paddingLeft: "1rem",
                      border: "none",
                      background: "none",
                      width: "50%",
                      fontSize: "0.8rem",
                    }}
                  >
                    {" "}
                    Amount
                  </td>
                  <td
                    style={{
                      paddingLeft: "1rem",
                      border: "none",
                      background: "none",
                      width: "50%",
                      fontSize: "0.8rem",
                    }}
                  >
                    <Form.Control
                      type="number"
                      className="form-control rounded-0"
                      aria-label="Notes"
                      aria-describedby="basic-addon1"
                      placeholder={`Enter Amount`}
                      onChange={(e) => {
                        setAmount(e.target.value);
                      }}
                      value={amount}
                    />
                  </td>
                </tr>
              )}

              <tr style={{ background: "none" }}>
                <td
                  style={{
                    paddingLeft: "1rem",
                    border: "none",
                    background: "none",
                    width: "50%",
                    fontSize: "0.8rem",
                  }}
                >
                  {" "}
                  T Pin
                </td>
                <td
                  style={{
                    paddingLeft: "1rem",
                    border: "none",
                    background: "none",
                    width: "50%",
                    fontSize: "0.8rem",
                  }}
                >
                  <div className="input-group">
                    <div className="input-group-prepend bg-transparent">
                      <span className="input-group-text bg-transparent border-right-0 no-glow">
                        <i className="mdi mdi-lock-outline text-primary"></i>
                      </span>
                    </div>
                    <input
                      autocomplete="new-password"
                      autoCorrect="off"
                      autoSave="new-password"
                      type={tpinShow ? "text" : "password"}
                      className="form-control form-control-lg border-left-0 border-right-0 no-glow"
                      id="exampleInputPassword"
                      placeholder={`Enter T Pin`}
                      onChange={(e) => {
                        setTpin(e.target.value);
                      }}
                      value={Tpin}
                    />
                    <div className="input-group-prepend bg-transparent">
                      <span
                        className="input-group-text bg-transparent border-left-0 no-glow"
                        onClick={showTPinClick}
                        style={{ borderRadius: "4px" }}
                      >
                        <i
                          className={`mdi text-primary ${
                            tpinShow ? "mdi-eye" : "mdi-eye-off"
                          }`}
                        ></i>
                      </span>
                    </div>
                  </div>
                </td>
              </tr>
            </table>
            <div
              style={{
                padding: "10px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              {PayClicked ? (
                <Button className="rounded-0" variant="success">
                  {" "}
                  Paying ...
                </Button>
              ) : (
                <Button
                  className="rounded-0"
                  variant="success"
                  onClick={callback}
                >
                  {" "}
                  Pay Bill
                </Button>
              )}
            </div>
          </Card>
        )}
        {billDetails && billPayResponse && (
          <Card style={{ margin: "10px", padding: "10px" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <img
                src={require("../../../assets/images/BBPSSidebarLogo.png")}
                style={{
                  width: "auto",
                  height: "1.2rem",
                  backgroundColor: "whitesmoke",
                }}
              />
              <img
                onClick={() => {
                  openInNewTab(billDetails);
                }}
                style={{ height: "1.5rem" }}
                src={require("../../../assets/icons/print_1.png")}
              />
            </div>
            <table style={{ border: "none", fontFamily: "-webkit-body" }}>
              <tr style={{ background: "none", border: "none" }}>
                <td
                  style={{
                    paddingLeft: "1rem",
                    border: "none",
                    background: "none",
                    width: "50%",
                    fontSize: "0.8rem",
                  }}
                >
                  {" "}
                  Name
                </td>
                <td
                  style={{
                    paddingLeft: "1rem",
                    border: "none",
                    background: "none",
                    width: "50%",
                    fontSize: "0.8rem",
                  }}
                >
                  {" "}
                  {billDetails.billerResponse.customerName}
                </td>
              </tr>

              {billDetails.billerResponse.billNumber && (
                <tr style={{ background: "none" }}>
                  <td
                    style={{
                      paddingLeft: "1rem",
                      border: "none",
                      background: "none",
                      width: "50%",
                      fontSize: "0.8rem",
                    }}
                  >
                    {" "}
                    Bill Number
                  </td>
                  <td
                    style={{
                      paddingLeft: "1rem",
                      border: "none",
                      background: "none",
                      width: "50%",
                      fontSize: "0.8rem",
                    }}
                  >
                    {" "}
                    {billDetails.billerResponse.billNumber}
                  </td>
                </tr>
              )}

              <tr style={{ background: "none" }}>
                <td
                  style={{
                    paddingLeft: "1rem",
                    border: "none",
                    background: "none",
                    width: "50%",
                    fontSize: "0.8rem",
                  }}
                >
                  {" "}
                  Amount
                </td>
                <td
                  style={{
                    paddingLeft: "1rem",
                    border: "none",
                    background: "none",
                    width: "50%",
                    fontSize: "0.8rem",
                  }}
                >
                  {" "}
                  {amount}
                </td>
              </tr>

              <tr style={{ background: "none" }}>
                <td
                  style={{
                    paddingLeft: "1rem",
                    border: "none",
                    background: "none",
                    width: "50%",
                    fontSize: "0.8rem",
                  }}
                >
                  {" "}
                  Reference Id
                </td>
                <td
                  style={{
                    paddingLeft: "1rem",
                    border: "none",
                    background: "none",
                    width: "50%",
                    fontSize: "0.8rem",
                  }}
                >
                  {" "}
                  {billPayResponse.refId}
                </td>
              </tr>
              <tr style={{ background: "none" }}>
                <td
                  style={{
                    paddingLeft: "1rem",
                    border: "none",
                    background: "none",
                    width: "50%",
                    fontSize: "0.8rem",
                  }}
                >
                  Transaction Reference Id
                </td>
                <td
                  style={{
                    paddingLeft: "1rem",
                    border: "none",
                    background: "none",
                    width: "50%",
                    fontSize: "0.8rem",
                  }}
                >
                  {" "}
                  {billPayResponse.txnReferenceId}
                </td>
              </tr>

              <tr style={{ background: "none" }}>
                <td
                  style={{
                    paddingLeft: "1rem",
                    border: "none",
                    background: "none",
                    width: "50%",
                    fontSize: "0.8rem",
                  }}
                >
                  {" "}
                  Status
                </td>
                <td
                  style={{
                    paddingLeft: "1rem",
                    border: "none",
                    background: "none",
                    width: "50%",
                    fontSize: "0.8rem",
                  }}
                >
                  <span className="badge badge-pill badge-success p-3">
                    {billPayResponse.responseReason}
                  </span>
                </td>
              </tr>
            </table>
          </Card>
        )}
      </Modal.Body>
    </Modal>
  );
};

export const EditEmployeeModalHR = ({
  show,
  handleInputChange,
  onHide,
  employeeData,
  formData,
  setFormData,
  handleEditSubmit,
  errors,
  setErrors,
  validateForm,
}) => {
  const formatDateString = (date) => {
    if (!date) return "";
    const d = new Date(date);
    const year = d.getFullYear();
    const month = ("0" + (d.getMonth() + 1)).slice(-2);
    const day = ("0" + d.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
  };
  const [banks, setBanks] = useState([]);
  const [selectedBank, setSelectedBank] = useState({ bankName: "", ifsc: "" });
  const { password, username } = billpunchbasicauth;

  const { user } = useContext(GlobalContext);
  const { setTransactionLoaderState } = useContext(GlobalContext);
  const [departmentData, setDepartmentData] = useState([]);
  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "bankName") {
      const foundBank = banks.find((bank) => bank.bankName === value);
      setSelectedBank(foundBank || { bankName: "", ifsc: "" });
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };
  const getDepartmentList = async () => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({ firmid: user.firm_id });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/getEmployeeDepartment`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        setDepartmentData(data.data);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
    }
  };
  useEffect(() => {
    if (show && employeeData) {
      setFormData({
        firstname: employeeData.firstname,
        middlename: employeeData.middlename,
        lastname: employeeData.lastname,
        mobile: employeeData.mobile,
        email: employeeData.email,
        address: employeeData.address,
        designation: employeeData.designation,
        dob: employeeData.dob,
        joiningdate: employeeData.joiningdate,
        bankName: employeeData.bankname,
        ifscCode: employeeData.bankifsc,
        accountNumber: employeeData.bankaccno,
        department: employeeData.department,
        emp_type: employeeData.emp_type,
        workemail: employeeData.work_email,
        pan: employeeData.pan,
        qualification: employeeData.qualification,
      });
    }
  }, [employeeData]);

  useEffect(() => {
    getDepartmentList();
    // getBanks();
  }, []);

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Edit Employee</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={validateForm} className=" ">
          <div className="row m-0 p-0 ">
            {" "}
            <Form.Group className="col-6 " controlId="pincode">
              <Form.Label> First Name</Form.Label>
              <Form.Control
                type="text"
                className="form-control"
                placeholder="First Name"
                id="firstname"
                name="firstname"
                value={formData.firstname}
                onChange={handleChange}
              />
              {errors.firstname && (
                <div className="text-danger">{errors.firstname}</div>
              )}
            </Form.Group>
            <Form.Group className="col-6 ">
              <Form.Label> Middle Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Middle Name"
                id="middlename"
                name="middlename"
                value={formData.middlename}
                onChange={handleChange}
              />
              {errors.middlename && (
                <div className="text-danger">{errors.middlename}</div>
              )}
            </Form.Group>
          </div>
          <div className="row m-0 p-0 ">
            <Form.Group className="col-6" controlId="pincode">
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Last Name"
                className="form-control"
                id="lastname"
                name="lastname"
                value={formData.lastname}
                onChange={handleChange}
              />
              {errors.lastname && (
                <div className="text-danger">{errors.lastname}</div>
              )}
            </Form.Group>
            <Form.Group className="col-6 ">
              <Form.Label htmlFor="name">Mobile No</Form.Label>
              <Form.Control
                maxLength={10}
                type="text"
                className="form-control"
                placeholder="Mobile No"
                id="mobile"
                name="mobile"
                value={formData.mobile}
                onChange={handleChange}
              />
              {errors.mobile && (
                <div className="text-danger">{errors.mobile}</div>
              )}
            </Form.Group>
          </div>
          <div className="row m-0 p-0 ">
            <Form.Group className=" col-6 ">
              <Form.Label htmlFor="name">Email ID</Form.Label>
              <Form.Control
                type="email"
                className="form-control"
                placeholder="Email ID"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
              />
              {errors.email && (
                <div className="text-danger">{errors.email}</div>
              )}
            </Form.Group>
            <Form.Group className="col-6 " controlId="workemail">
              <label htmlFor="name">Work Email</label>
              <Form.Control
                type="text"
                id="workemail"
                name="workemail"
                placeholder="Enter Your workemail"
                value={formData.workemail}
                onChange={handleChange}
              />
              {errors.workemail && (
                <div className="text-danger">{errors.workemail}</div>
              )}
            </Form.Group>
          </div>
          <div className="row m-0 p-0 ">
            <Form.Group className="col-6" controlId="pincode">
              <Form.Label>Designation</Form.Label>
              <Form.Control
                type="text"
                className="form-control"
                id="designation"
                placeholder="Designation"
                name="designation"
                value={formData.designation}
                onChange={handleChange}
              />
              {errors.designation && (
                <div className="text-danger">{errors.designation}</div>
              )}
            </Form.Group>
            <Form.Group className="col-6" controlId="Department">
              <Form.Label>Department</Form.Label>
              <Form.Control
                as="select"
                name="department"
                value={formData.department}
                onChange={handleChange}
              >
                <option value="">Department</option>
                {departmentData.map((dept) => (
                  <option key={dept.id} value={dept.cat1}>
                    {dept.cat1}
                  </option>
                ))}
              </Form.Control>
              {errors.department && (
                <div className="text-danger">{errors.department}</div>
              )}
            </Form.Group>
          </div>

          <div className="row m-0 p-0 ">
            <Form.Group className="col-6" controlId="Emptype">
              <Form.Label>Employee Type</Form.Label>
              <Form.Control
                as="select"
                name="emp_type"
                value={formData.emp_type}
                onChange={handleChange}
              >
                <option value="">Employee Type</option>
                <option value=" Intern">Intern</option>
                <option value=" Full Time">Full Time</option>
                <option value="Contract Based">Contract Based</option>
              </Form.Control>
              {errors.emp_type && (
                <div className="text-danger">{errors.emp_type}</div>
              )}
            </Form.Group>
            <Form.Group className="col-6 ">
              <Form.Label htmlFor="name"> Address</Form.Label>
              <Form.Control
                type="text"
                className="form-control"
                id="address"
                name="address"
                placeholder="Enter Your address"
                value={formData.address}
                onChange={handleChange}
              />
              {errors.address && (
                <div className="text-danger">{errors.address}</div>
              )}
            </Form.Group>
          </div>
          <div className="row m-0 p-0 ">
            <Form.Group className="col-6 " controlId="pan">
              <label htmlFor="name">Your PAN Number </label>
              <Form.Control
                type="text"
                id="pan"
                name="pan"
                placeholder="Enter Your PAN"
                value={formData.pan}
                onChange={handleChange}
              />
              {errors.pan && <div className="text-danger">{errors.pan}</div>}
            </Form.Group>
            <Form.Group className="col-6 " controlId="ifscCode">
              <label htmlFor="name">Your Qualification</label>
              <Form.Control
                type="text"
                id="qualification"
                name="qualification"
                placeholder="Enter Your Bank qualification"
                value={formData.qualification}
                onChange={handleChange}
              />
              {errors.qualification && (
                <div className="text-danger">{errors.qualification}</div>
              )}
            </Form.Group>
          </div>
          <div className="row m-0 p-0 ">
            <Form.Group className="col-6 " controlId="bankName">
              <label htmlFor="name">Your Account Number </label>
              <Form.Control
                type="text"
                id="bankName"
                name="bankName"
                placeholder="Enter Your Bank Name"
                value={formData.bankName}
                onChange={handleChange}
              />
              {errors.bankName && (
                <div className="text-danger">{errors.bankName}</div>
              )}
            </Form.Group>
            <Form.Group className="col-6 " controlId="ifscCode">
              <label htmlFor="name">Enter Your Bank ifscCode</label>
              <Form.Control
                type="text"
                id="ifscCode"
                name="ifscCode"
                placeholder="Enter Your Bank ifscCode"
                value={formData.ifscCode}
                onChange={handleChange}
              />
              {errors.ifscCode && (
                <div className="text-danger">{errors.ifscCode}</div>
              )}
            </Form.Group>

            <Form.Group className="col-6" controlId="accountNumber">
              <Form.Label htmlFor="name"> Your Bank Name </Form.Label>

              <Form.Control
                type="text"
                placeholder="Bank Account Number"
                id="accountNumber"
                name="accountNumber"
                value={formData.accountNumber}
                onChange={handleChange}
              />
              {errors.accountNumber && (
                <div className="text-danger">{errors.accountNumber}</div>
              )}
            </Form.Group>
          </div>
          <div className="row m-0 p-0 ">
            <Form.Group className="col-6 ">
              <Form.Label htmlFor="name"> Data Of Birth</Form.Label>

              <FormControl
                type="date"
                value={formatDateString(formData.dob)}
                selected={formData.dob}
                onChange={(e) => {
                  const formattedDate = new Date(e.target.value);
                  setFormData({
                    ...formData,
                    dob: formattedDate,
                  });
                }}
                showYearDropdown
                dateFormatCalendar="MMMM yyyy"
                yearDropdownItemNumber={30}
                scrollableYearDropdown
                className="form-control"
                placeholderText="Date of Birth"
              />
              {/* {errors.dob && <div className="text-danger">{errors.dob}</div>} */}
            </Form.Group>
            <Form.Group className="col-6 " controlId="joiningdate">
              <Form.Label htmlFor="name"> Joining Date</Form.Label>

              <FormControl
                value={formatDateString(formData.joiningdate)}
                type="date"
                selected={formData.joiningdate}
                onChange={(e) => {
                  const formattedDate = new Date(e.target.value);
                  setFormData({
                    ...formData,
                    joiningdate: formattedDate,
                  });
                }}
                showYearDropdown
                dateFormatCalendar="MMMM yyyy"
                yearDropdownItemNumber={30}
                scrollableYearDropdown
                className="form-control"
                placeholderText="Joining Date"
              />
              {/* {errors.dob && <div className="text-danger">{errors.dob}</div>} */}
            </Form.Group>
          </div>
          <div className="row p-0 m-0">
            {" "}
            <Link className="mx-md-3">
              <button
                className="btn btn-primary d-flex align-items-center "
                style={{ height: "2rem", borderRadius: ".4rem" }}
                onClick={validateForm}
              >
                submit
              </button>
            </Link>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};
export const ViewModalHR = ({ show, handleClose, employee }) => {
  const { password, username } = billpunchbasicauth;

  let printData = JSON.parse(sessionStorage.getItem("print"));
  if (!printData) {
    printData = {};
  }
  console.log(printData);
  const printReceipt = () => {
    window.print();
  };
  const NOTE =
    "This is Computer Generated receipt and does not require physical signature.";

  const openInNewTab = (data) => {
    const salarySlipData = {
      empCode: employee?.emp_code || "NA",
      name: employee?.name || "NA",
      designation: employee?.designation || "NA",
      department: employee?.department || "NA",
      bankAccNo: employee?.bank_acc_no || "NA",
      currentMonth: employee?.current_month || "NA",
      esic: employee?.esic || "NA",
      uan: employee?.uan || "NA",
      salaryMode: "Bank Transfer",
      bankName: employee?.bank_name || "NA",
      basic: employee?.basic || "NA",
      hra: employee?.hra || "NA",
      tAllowance: employee?.t_allowance || "NA",
      mAllowance: employee?.m_allowance || "NA",
      oAllowance: 0,
      totalSalaryWithPF: twoDecimalPlaces(employee?.totalSalarywithpf || "NA"),
      epf: twoDecimalPlaces(employee?.epf),
      esicDeduction: twoDecimalPlaces(employee?.esic || "NA"),
      tds: 0,
      totalDeduction: 0,
      totalPayableAmount: twoDecimalPlaces(employee?.totalSalary || "NA"),
      payableAmountInWords: inWords(Number(employee?.totalSalary || "NA")),
      note: "This is a computer-generated pay slip and does not require any signature or company seal.",
    };
    localStorage.setItem("print", JSON.stringify(salarySlipData));
    const newWindow = window.open(
      "/pages/SalarySliphr/Print",
      "",
      "height=600,width=800'"
    );
    if (newWindow) newWindow.opener = null;
  };

  return (
    <Modal show={show} onHide={handleClose} className="salaryslip-modal">
      <Modal.Body>
        <form>
          <div className="card pt-md-2" style={{ border: "1px solid" }}>
            <div className="d-flex justify-content-end">
              <img
                alt=""
                src={require("../../../assets/images/pms/salary_logo.png")}
                style={{ height: "2rem" }}
              />
            </div>
            <div>
              <h5 className="d-flex justify-content-center">
                Bumppy Media Private Limited
              </h5>
              <h6 className="d-flex justify-content-center">
                B-218,ITHUM Tower-B,2nd Floor, Sec-62, Noida, U.p-201301
              </h6>
            </div>
            <hr className="p-0 m-0" style={{ background: "#000" }} />
            <h6 className="d-flex justify-content-center">
              <b>Salary Slip</b>
            </h6>
            <hr className="p-0 m-0" style={{ background: "#000" }} />
            <div className="row">
              <div className="col">
                <div className="row m-0 p-0" style={{ color: "#000" }}>
                  <p className="col px-0" style={{ color: "#000" }}>
                    Emp Code:
                  </p>
                  <p className="col  " style={{ color: "#000" }}>
                    {" "}
                    {employee?.emp_code || "NA"}
                  </p>
                </div>
                <div className="row m-0 p-0" style={{ color: "#000" }}>
                  <p className="col px-0" style={{ color: "#000" }}>
                    Name :
                  </p>
                  <p className="col  " style={{ color: "#000" }}>
                    {" "}
                    {employee?.name || "NA"}
                  </p>
                </div>
                <div className="row m-0 p-0" style={{ color: "#000" }}>
                  <p className="col px-0" style={{ color: "#000" }}>
                    Designation:
                  </p>
                  <p className="col  " style={{ color: "#000" }}>
                    {" "}
                    {employee?.designation || "NA"}{" "}
                  </p>
                </div>
                <div className="row m-0 p-0" style={{ color: "#000" }}>
                  <p className="col px-0" style={{ color: "#000" }}>
                    Department:
                  </p>
                  <p className="col  " style={{ color: "#000" }}>
                    {" "}
                    {employee?.department || "NA"}
                  </p>
                </div>
                <div className="row m-0 p-0" style={{ color: "#000" }}>
                  <p className="col px-0" style={{ color: "#000" }}>
                    Bank Account No:
                  </p>
                  <p className="col  " style={{ color: "#000" }}>
                    {" "}
                    {employee?.bank_acc_no || "NA"}{" "}
                  </p>
                </div>
              </div>
              <div className="col">
                <div className="row m-0 p-0" style={{ color: "#000" }}>
                  <p className="col " style={{ color: "#000" }}>
                    Month:
                  </p>
                  <p className="col  " style={{ color: "#000" }}>
                    {" "}
                    {employee?.current_month || "NA"}
                  </p>
                </div>
                <div className="row m-0 p-0" style={{ color: "#000" }}>
                  <p className="col " style={{ color: "#000" }}>
                    {" "}
                    ESI No:
                  </p>
                  <p className="col  " style={{ color: "#000" }}>
                    {" "}
                    {employee?.esic || "NA"}{" "}
                  </p>
                </div>
                <div className="row m-0 p-0" style={{ color: "#000" }}>
                  <p className="col " style={{ color: "#000" }}>
                    UAN:
                  </p>
                  <p className="col  " style={{ color: "#000" }}>
                    {" "}
                    {employee?.uan || "NA"}{" "}
                  </p>
                </div>
                <div className="row m-0 p-0" style={{ color: "#000" }}>
                  <p className="col " style={{ color: "#000" }}>
                    Salary Mode:
                  </p>
                  <p className="col  " style={{ color: "#000" }}>
                    {" "}
                    Bank Transfer{" "}
                  </p>
                </div>
                <div className="row m-0 p-0" style={{ color: "#000" }}>
                  <p className="col " style={{ color: "#000" }}>
                    Bank Name:
                  </p>
                  <p className="col  " style={{ color: "#000" }}>
                    {" "}
                    {employee?.bank_name || "NA"}{" "}
                  </p>
                </div>
              </div>
            </div>
            <hr className="mt-md-1" style={{ background: "#000" }} />
            <table className="m-0" style={{ border: "1px solid black" }}>
              <thead>
                <tr>
                  <th>Standard Wages Amount</th>
                  <th></th>
                  <th>Earned Wages Amount</th>
                  <th>Deduction</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style={{ border: "1px solid black", fontSize: ".9rem" }}>
                    Basic
                  </td>
                  <td style={{ border: "1px solid black", fontSize: ".9rem" }}>
                    {employee?.basic || "NA"}
                  </td>
                  <td style={{ border: "1px solid black", fontSize: ".9rem" }}>
                    {employee?.basic || "NA"}
                  </td>
                  <td
                    style={{
                      border: "1px solid black",
                      verticalAlign: "top",
                      fontSize: ".9rem",
                    }}
                  >
                    EPF
                  </td>
                  <td style={{ border: "1px solid black", fontSize: ".9rem" }}>
                    {twoDecimalPlaces(employee?.epf)}
                  </td>
                </tr>
                <tr>
                  <td style={{ border: "1px solid black", fontSize: ".9rem" }}>
                    HRA
                  </td>
                  <td style={{ border: "1px solid black", fontSize: ".9rem" }}>
                    {employee?.hra || "NA"}
                  </td>
                  <td style={{ border: "1px solid black", fontSize: ".9rem" }}>
                    {employee?.hra || "NA"}
                  </td>
                  <td
                    style={{
                      border: "1px solid black",
                      verticalAlign: "top",
                      fontSize: ".9rem",
                    }}
                  >
                    ESIC
                  </td>
                  <td style={{ border: "1px solid black", fontSize: ".9rem" }}>
                    {employee?.esic || "NA"}
                  </td>
                </tr>
                <tr>
                  <td style={{ border: "1px solid black", fontSize: ".9rem" }}>
                    Travel Allowance
                  </td>
                  <td style={{ border: "1px solid black", fontSize: ".9rem" }}>
                    {employee?.t_allowance || "NA"}
                  </td>
                  <td style={{ border: "1px solid black", fontSize: ".9rem" }}>
                    {employee?.t_allowance || "NA"}
                  </td>
                  <td
                    style={{
                      border: "1px solid black",
                      verticalAlign: "top",
                      fontSize: ".9rem",
                    }}
                  >
                    TDS
                  </td>
                  <td style={{ border: "1px solid black", fontSize: ".9rem" }}>
                    {0}
                  </td>
                </tr>
                <tr>
                  <td style={{ border: "1px solid black", fontSize: ".9rem" }}>
                    Med. Allowance
                  </td>
                  <td style={{ border: "1px solid black", fontSize: ".9rem" }}>
                    {employee?.m_allowance || "NA"}
                  </td>
                  <td style={{ border: "1px solid black", fontSize: ".9rem" }}>
                    {employee?.m_allowance || "NA"}
                  </td>
                  <td
                    style={{
                      border: "1px solid black",
                      verticalAlign: "top",
                      fontSize: ".9rem",
                    }}
                  ></td>
                  <td style={{ border: "1px solid black", fontSize: ".9rem" }}>
                    {}
                  </td>
                </tr>

                <tr>
                  <td style={{ border: "1px solid black", fontSize: ".9rem" }}>
                    Oth. Allowance
                  </td>
                  <td style={{ border: "1px solid black", fontSize: ".9rem" }}>
                    0
                  </td>
                  <td style={{ border: "1px solid black", fontSize: ".9rem" }}>
                    0
                  </td>
                  <td
                    style={{
                      border: "1px solid black",
                      verticalAlign: "top",
                      fontSize: ".9rem",
                    }}
                  ></td>
                  <td
                    style={{ border: "1px solid black", fontSize: ".9rem" }}
                  ></td>
                </tr>
                <tr>
                  <td style={{ border: "1px solid black", fontSize: ".9rem" }}>
                    Total
                  </td>
                  <td style={{ border: "1px solid black", fontSize: ".9rem" }}>
                    {twoDecimalPlaces(employee?.totalSalarywithpf || "NA")}
                  </td>
                  <td style={{ border: "1px solid black", fontSize: ".9rem" }}>
                    {twoDecimalPlaces(employee?.totalSalarywithpf || "NA")}
                  </td>
                  <td
                    style={{
                      border: "1px solid black",
                      verticalAlign: "top",
                      fontSize: ".9rem",
                    }}
                  >
                    Total Deduction
                  </td>
                  <td style={{ border: "1px solid black", fontSize: ".9rem" }}>
                    {}
                  </td>
                </tr>
              </tbody>

              <tfoot className="mb-md-2">
                <tr>
                  <td colspan="5" style={{ border: "1px solid black" }}>
                    <p style={{ color: "#000" }}>
                      Total Payable Amount :{" "}
                      {twoDecimalPlaces(employee?.totalSalary || "NA")}
                    </p>
                    <p style={{ color: "#000" }}>
                      Payable Amount in Words :{" "}
                      {inWords(Number(employee?.totalSalary || "NA"))}
                    </p>
                  </td>
                </tr>
              </tfoot>
            </table>
            <hr className="mt-md-3" style={{ background: "#000" }} />
            <p style={{ color: "#000" }}>
              This is a computer generated pay slip and does not required any
              signature or any company seal.
            </p>
          </div>
        </form>
        <button
          style={{ height: "2rem", borderRadius: ".4rem" }}
          className="d-flex align-items-centerbtn btn-primary"
          onClick={openInNewTab}
        >
          Download
        </button>
      </Modal.Body>
    </Modal>
  );
};
export const BulkAttendanceHr = ({ show, closeModal }) => {
  const months = [
    { name: "January", value: " 1" },
    { name: "February", value: "2" },
    { name: "March", value: "3" },
    { name: "April", value: "4" },
    { name: "May", value: "5" },
    { name: "June", value: "6" },
    { name: "July", value: "7" },
    { name: "August", value: "8" },
    { name: "September", value: "9" },
    { name: "October", value: "10" },
    { name: "November", value: "11" },
    { name: "December", value: "12" },
  ];
  const [data, setData] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const [selectedEmployeeIds, setSelectedEmployeeIds] = useState([]);
  const { password, username } = billpunchbasicauth;

  const [expandedEmployeeId, setExpandedEmployeeId] = useState(null);
  const [departmentData, setDepartmentData] = useState([]);
  const [department, setDepartment] = useState("");
  const [selectedYear, setSelectedYear] = useState("2024");
  const [selectedMonth, setSelectedMonth] = useState("01");
  const [showProcessAllModal, setShowProcessAllModal] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [generate, setgenerate] = useState();

  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const toggleEmployeeDetails = (employeeId) => {
    if (expandedEmployeeId === employeeId) {
      setExpandedEmployeeId(null);
    } else {
      setExpandedEmployeeId(employeeId);
    }
  };
  const handleYearChange = (e) => {
    setSelectedYear(e.target.value);
  };

  const handleMonthChange = (e) => {
    setSelectedMonth(e.target.value);
  };
  const getDepartmentList = async () => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({ firmid: user.firm_id });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/getEmployeeDepartment`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        setDepartmentData(data.data);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
    }
  };

  const getEmployeeList = async (month) => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        month: month,
        firmid: user.firm_id,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/GetAttendanceAll`,
        requestOptions
      );
      const apiData = await res.json();

      console.log("API Response:", apiData);

      if (apiData.status === "0" && apiData.data) {
        setData(
          apiData.data.map((employee) => ({ ...employee, selected: false }))
        );
      }

      setTransactionLoaderState(false);
    } catch (error) {
      console.log("Error fetching employee data:", error);
      setTransactionLoaderState(false);
    }
  };

  const [filteredData, setFilteredData] = useState([]);

  const handleDepartmentChange = (selectedDepartment) => {
    setDepartment(selectedDepartment);
  };
  useEffect(() => {
    const filteredResults = data.filter((item) => {
      if (department === "") {
        return true;
      } else {
        return item.department === department;
      }
    });
    setFilteredData(filteredResults);
  }, [data, department]);
  useEffect(() => {
    getDepartmentList();
  }, []);
  useEffect(() => {
    getEmployeeList(selectedMonth);
  }, [selectedMonth]);
  return (
    <Modal show={show} onHide={closeModal} className="generate_modal">
      <Modal.Header className="row m-0 " closeButton>
        <Modal.Title className="col">Generate Salary</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row  px-2">
          <div className="col-md-2 col p-0">
            <select
              style={{
                width: "5rem",
                height: "2rem",
                borderRadius: ".4rem",
                border: "1px #ced4da solid",
              }}
              value={selectedYear}
              onChange={handleYearChange}
            >
              <option value="2023">2023</option>
              <option value="2024">2024</option>
              <option value={"2025"}>2025</option>
              <option value={"2026"}>2026</option>
            </select>
          </div>
          <div className="col-md-2 col mr-3 ">
            <select
              style={{
                width: "5rem",
                height: "2rem",
                borderRadius: ".4rem",
                border: "1px #ced4da solid",
              }}
              value={selectedMonth}
              onChange={handleMonthChange}
            >
              {months.map((month) => (
                <option key={month.value} value={month.value}>
                  {month.name}
                </option>
              ))}
            </select>
          </div>
          <div className="col">
            <select
              style={{
                width: "9rem",
                height: "2rem",
                borderRadius: ".4rem",
                border: "1px #ced4da solid",
              }}
              value={department}
              onChange={(e) => handleDepartmentChange(e.target.value)}
            >
              <option value="">All Department</option>
              {departmentData.map((dept) => (
                <option key={dept.id} value={dept.cat1}>
                  {dept.cat1}
                </option>
              ))}
            </select>
          </div>
        </div>
        <table
          className="my-table transparent-table text-center border-bottom"
          style={{
            width: "100%",
            overflowX: "auto",
            border: "0px",
            overflow: "auto",
          }}
        >
          <tr className="borderless-table text-center border-bottom">
            <th
              className="my-table transparent-table text-center border-bottom "
              style={{
                fontFamily: "Poppins",
                fontSize: "16px",
                fontWeight: "700",
                lineHeight: "24px",
                letterSpacing: "0em",
                textAlign: "center",
              }}
            >
              {" "}
              {"Sr"}
            </th>
            <th
              className="my-table transparent-table text-center border-bottom "
              style={{
                fontFamily: "Poppins",
                fontSize: "16px",
                fontWeight: "700",
                lineHeight: "24px",
                letterSpacing: "0em",
                textAlign: "center",
              }}
            >
              Name
            </th>
            <th
              className="my-table transparent-table border-bottom d-flex justify-content-start"
              style={
                {
                  // textAlign: "center",
                }
              }
            >
              Attendance
            </th>
          </tr>
          {filteredData.length > 0 ? (
            filteredData.map((e, ind) => (
              <React.Fragment key={e.employee_id}>
                <tr key={e.employee_id}>
                  <td
                    className="text-center border-bottom"
                    style={{
                      fontSize: "0.8rem",
                      fontFamily: "Poppins",
                      fontWeight: "400",
                      lineHeight: "24px",
                      letterSpacing: "0em",
                      textAlign: "center",
                    }}
                  >
                    {" "}
                    {ind + 1}
                  </td>
                  <td
                    className="text-center border-bottom"
                    style={{
                      fontSize: "0.8rem",
                      fontFamily: "Poppins",
                      fontWeight: "400",
                      lineHeight: "24px",
                      letterSpacing: "0em",
                      textAlign: "center",
                    }}
                  >
                    <div>{e.employeename}</div>
                  </td>
                  <td>
                    <div
                    // className="card m-0 p-2"
                    // style={{ background: "antiquewhite" }}
                    >
                      <>
                        <p
                          className="m-0 p-0"
                          style={{
                            fontSize: ".9rem",
                            fontWeight: "400",
                          }}
                        >
                          Present Days: {e.total_present || "NA"}
                        </p>

                        <p
                          className="m-0 p-0"
                          style={{
                            fontSize: ".9rem",
                            fontWeight: "400",
                          }}
                        >
                          Total Leave: {e.total_absent || "NA"}
                        </p>
                      </>
                    </div>
                  </td>
                </tr>

                <tr></tr>
              </React.Fragment>
            ))
          ) : (
            <tr>
              <td colSpan="4" className="text-center">
                No data found.
              </td>
            </tr>
          )}
        </table>
      </Modal.Body>
    </Modal>
  );
};
export const BulkSalaryhr = ({
  showBulkModal,
  setShowBulkModal,
  getEmployeegeneratedList,
  handleClose,
}) => {
  const months = [
    { name: "January", value: "1" },
    { name: "February", value: "2" },
    { name: "March", value: "3" },
    { name: "April", value: "4" },
    { name: "May", value: "5" },
    { name: "June", value: "6" },
    { name: "July", value: "7" },
    { name: "August", value: "8" },
    { name: "September", value: "9" },
    { name: "October", value: "10" },
    { name: "November", value: "11" },
    { name: "December", value: "12" },
  ];
  const { password, username } = billpunchbasicauth;

  const [data, setData] = useState([]);
  const [selectedEmployeeIds, setSelectedEmployeeIds] = useState([]);
  const [expandedEmployeeId, setExpandedEmployeeId] = useState(null);
  const [departmentData, setDepartmentData] = useState([]);
  const [department, setDepartment] = useState("");
  const [selectedYear, setSelectedYear] = useState("2024");
  const [selectedMonth, setSelectedMonth] = useState("01");
  const [showProcessAllModal, setShowProcessAllModal] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [generate, setGenerate] = useState();
  const { user, setTransactionLoaderState } = useContext(GlobalContext);

  const toggleEmployeeDetails = (employeeId) => {
    if (expandedEmployeeId === employeeId) {
      setExpandedEmployeeId(null);
    } else {
      setExpandedEmployeeId(employeeId);
    }
  };

  const handleYearChange = (e) => {
    setSelectedYear(e.target.value);
  };

  const handleMonthChange = (e) => {
    setSelectedMonth(e.target.value);
  };

  const getDepartmentList = async () => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({ firmid: user.firm_id });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/getEmployeeDepartment`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        setDepartmentData(data.data);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleSelectAllChange = () => {
    const updatedData = data.map((employee) => ({
      ...employee,
      selected: !selectAll,
    }));
    setData(updatedData);
    setSelectAll(!selectAll);

    const selectedEmployees = updatedData.filter(
      (employee) => employee.selected
    );
    setSelectedEmployeeIds(
      selectedEmployees.map((employee) => employee.employee_id)
    );
  };

  const getEmployeeList = async (month) => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        month: month,
        firmid: user.firm_id,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/GetAttendanceAll`,
        requestOptions
      );
      const apiData = await res.json();

      console.log("API Response:", apiData);

      if (apiData.status === "0" && apiData.data) {
        setData(
          apiData.data.map((employee) => ({ ...employee, selected: false }))
        );
      }

      setTransactionLoaderState(false);
    } catch (error) {
      console.log("Error fetching employee data:", error);
      setTransactionLoaderState(false);
    }
  };

  const generateSalary = async () => {
    setTransactionLoaderState(true);
    try {
      const selectedEmployees = filteredData.filter(
        (employee) => employee.selected
      );

      if (selectedEmployees.length === 0) {
        swal("No employees selected", "", "warning");
        setTransactionLoaderState(false);
        return;
      }

      const myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      const requestBody = {
        requests: selectedEmployees.map((employee) => ({
          empid: employee.employee_id,
          p_days: employee.total_present || "NA",
          ab_days: employee.total_absent || "NA",
          firmid: user.firm_id,
          month: selectedMonth,
          year: selectedYear,
        })),
      };

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify(requestBody),
        redirect: "follow",
      };

      console.log("Making API call with:", requestOptions);

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/generateEmployeeSalary`,
        requestOptions
      );

      const responseData = await res.json();
      console.log("API Response:", responseData);

      if (responseData.status === "0") {
        swal("Success", responseData.result, "success");
        setGenerate(responseData.data);
        getEmployeegeneratedList();
        handleClose();
      } else {
        swal("Error", responseData.msg, "error");
      }

      setTransactionLoaderState(false);
    } catch (error) {
      console.log("Error generating salary:", error);
      setTransactionLoaderState(false);
    }
  };

  const handleCheckboxChange = (employee_id) => {
    const updatedData = data.map((employee) => {
      if (employee.employee_id === employee_id) {
        return { ...employee, selected: !employee.selected };
      }
      return employee;
    });

    setData(updatedData);
    const selectedEmployees = updatedData.filter(
      (employee) => employee.selected
    );
    setSelectAll(selectedEmployees.length === data.length);
    setSelectedEmployeeIds(
      selectedEmployees.map((employee) => employee.employee_id)
    );
  };

  const handleProcessAllClick = () => {
    if (selectedEmployeeIds.length === 0) {
      swal("Please select at least one item to generate.", "", "warning");
    } else {
      setShowProcessAllModal(true);
    }
  };

  const [filteredData, setFilteredData] = useState([]);

  const handleDepartmentChange = (selectedDepartment) => {
    setDepartment(selectedDepartment);
  };

  useEffect(() => {
    const filteredResults = data.filter((item) => {
      if (department === "") {
        return true;
      } else {
        return item.department === department;
      }
    });
    setFilteredData(filteredResults);
  }, [data, department]);

  useEffect(() => {
    getDepartmentList();
  }, []);

  useEffect(() => {
    getEmployeeList(selectedMonth);
  }, [selectedMonth]);

  return (
    <Modal
      show={showBulkModal}
      onHide={() => setShowBulkModal(false)}
      className="generate_modal"
    >
      <Modal.Header className="row m-0 " closeButton>
        <Modal.Title className="col">Generate Salary</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row  px-2">
          <div className="col-md-2 col p-0">
            <select
              style={{
                width: "5rem",
                height: "2rem",
                borderRadius: ".4rem",
                border: "1px #ced4da solid",
              }}
              value={selectedYear}
              onChange={handleYearChange}
            >
              <option value="2023">2023</option>
              <option value="2024">2024</option>
              <option value={"2025"}>2025</option>
              <option value={"2026"}>2026</option>
            </select>
          </div>
          <div className="col-md-2 col mr-3 ">
            <select
              style={{
                width: "5rem",
                height: "2rem",
                borderRadius: ".4rem",
                border: "1px #ced4da solid",
              }}
              value={selectedMonth}
              onChange={handleMonthChange}
            >
              {months.map((month) => (
                <option key={month.value} value={month.value}>
                  {month.name}
                </option>
              ))}
            </select>
          </div>
          <div className="col">
            {/* <select
              style={{
                width: "9rem",
                height: "2rem",
                borderRadius: ".4rem",
                border: "1px #ced4da solid",
              }}
              value={department}
              onChange={(e) => handleDepartmentChange(e.target.value)}
            >
              <option value="">All Department</option>
              {departmentData.map((dept) => (
                <option key={dept.id} value={dept.cat1}>
                  {dept.cat1}
                </option>
              ))}
            </select> */}
          </div>
          <div className="col d-flex justify-content-end">
            <Button
              className="d-flex align-items-center"
              style={{ height: "2rem" }}
              onClick={generateSalary}
            >
              Generate
            </Button>
          </div>
        </div>
        <table
          className="my-table transparent-table text-center border-bottom"
          style={{
            width: "100%",
            overflowX: "auto",
            border: "0px",
            overflow: "auto",
          }}
        >
          <tr className="borderless-table text-center border-bottom">
            <th className="my-table transparent-table text-center border-bottom ">
              <input
                type="checkbox"
                checked={selectAll}
                onChange={handleSelectAllChange}
              />
            </th>
            <th
              className="my-table transparent-table text-center border-bottom "
              style={{
                fontFamily: "Poppins",
                fontSize: "16px",
                fontWeight: "700",
                lineHeight: "24px",
                letterSpacing: "0em",
                textAlign: "center",
              }}
            >
              {" "}
              {"Sr"}
            </th>
            <th
              className="my-table transparent-table text-center border-bottom "
              style={{
                fontFamily: "Poppins",
                fontSize: "16px",
                fontWeight: "700",
                lineHeight: "24px",
                letterSpacing: "0em",
                textAlign: "center",
              }}
            >
              Name
            </th>
            <th
              className="my-table transparent-table text-center border-bottom "
              style={{
                textAlign: "center",
              }}
            >
              Check Attendance
            </th>
          </tr>
          {filteredData.length > 0 ? (
            filteredData.map((e, ind) => (
              <React.Fragment key={e.employee_id}>
                <tr key={e.employee_id}>
                  <td className="text-center ">
                    <input
                      type="checkbox"
                      checked={e.selected}
                      onChange={() => handleCheckboxChange(e.employee_id)}
                    />
                  </td>
                  <td
                    className="text-center border-bottom"
                    style={{
                      fontSize: "0.8rem",
                      fontFamily: "Poppins",
                      fontWeight: "400",
                      lineHeight: "24px",
                      letterSpacing: "0em",
                      textAlign: "center",
                    }}
                  >
                    {" "}
                    {ind + 1}
                  </td>
                  <td
                    className="text-center border-bottom"
                    style={{
                      fontSize: "0.8rem",
                      fontFamily: "Poppins",
                      fontWeight: "400",
                      lineHeight: "24px",
                      letterSpacing: "0em",
                      textAlign: "center",
                    }}
                  >
                    <div>{e.employeename}</div>
                  </td>
                  <td
                    className="text-center border-bottom"
                    onClick={() => toggleEmployeeDetails(e.employee_id)}
                    style={{ cursor: "pointer" }}
                  >
                    {expandedEmployeeId === e.employee_id ? (
                      <FaCaretDown />
                    ) : (
                      <FaCaretRight />
                    )}
                  </td>
                </tr>
                {expandedEmployeeId === e.employee_id && (
                  <tr>
                    <td colSpan="4">
                      <div
                        className="card m-0 p-2"
                        style={{ background: "antiquewhite" }}
                      >
                        <div
                          className="row m-0 p-0"
                          style={{ width: "100%", margin: "0" }}
                        >
                          <>
                            <p
                              className="col-6 m-0 p-0"
                              style={{
                                fontSize: ".9rem",
                                fontWeight: "400",
                              }}
                            >
                              Govt. Holiday: {e.total_govtholidays || "NA"}
                            </p>

                            <p
                              style={{
                                fontSize: ".9rem",
                                fontWeight: "400",
                              }}
                              className="col-6 m-0 p-0"
                            >
                              Present Days: {e.total_present || "NA"}
                            </p>
                            <p
                              style={{
                                fontSize: ".9rem",
                                fontWeight: "400",
                              }}
                              className="col-6 m-0 p-0"
                            >
                              Approved Leave: {e.total_approvedleave || "NA"}
                            </p>
                            <p
                              style={{
                                fontSize: ".9rem",
                                fontWeight: "400",
                              }}
                              className="col-6 m-0 p-0"
                            >
                              Rejected Leave: {e.total_rejectedleave || "NA"}
                            </p>
                            <p
                              style={{
                                fontSize: ".9rem",
                                fontWeight: "400",
                              }}
                              className="col-6 m-0 p-0"
                            >
                              Total Leave: {e.total_absent || "NA"}
                            </p>
                          </>
                        </div>
                      </div>
                    </td>
                  </tr>
                )}
              </React.Fragment>
            ))
          ) : (
            <tr>
              <td colSpan="4" className="text-center">
                No data found.
              </td>
            </tr>
          )}
        </table>
      </Modal.Body>
    </Modal>
  );
};

export const AddSalaryHR = ({ show, getEmployeeList, onHide }) => {
  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const [selectedEmployee, setselectedEmployee] = useState("");
  const [Employeeid, setEmployeeid] = useState(-1);
  const [Member, setMember] = useState([]);
  const { password, username } = billpunchbasicauth;

  const [AddsalaryData, setAddSalaryData] = useState({
    basicPay: "",
    hra: "",
    travelAllowance: "",
    medicalAllowance: "",
    Total: "",
    Employeename: "",
    otherAllowance: "",
    date: "",
  });
  const [errors, setErrors] = useState({
    basicPay: "",
    hra: "",
    travelAllowance: "",
    medicalAllowance: "",
    otherAllowance: "",
    date: "",
  });
  const resetForm = () => {
    setselectedEmployee("");
    setEmployeeid(-1);
    setAddSalaryData({
      basicPay: "",
      hra: "",
      travelAllowance: "",
      medicalAllowance: "",
      otherAllowance: "",
      Total: "",
      Employeename: "",
    });
    setErrors({
      basicPay: "",
      hra: "",
      travelAllowance: "",
      medicalAllowance: "",
      otherAllowance: "",
    });
  };
  const validate = (e) => {
    e.preventDefault();
    console.log(" validate function");
    console.log(AddsalaryData);
    let errors = {};

    if (AddsalaryData.basicPay.trim() === "") {
      errors.basicPay = "*Please enter basic Pay";
    }
    if (AddsalaryData.hra.trim() === "") {
      errors.hra = "*Please enter house rent Allowance";
    }
    if (AddsalaryData.travelAllowance.trim() === "") {
      errors.travelAllowance = "*Please enter travel Allowance";
    }
    if (AddsalaryData.medicalAllowance.trim() === "") {
      errors.medicalAllowance = "*Please enter medical Allowance";
    }
    if (AddsalaryData.otherAllowance.trim() === "") {
      errors.otherAllowance = "*Please enter other Allowance";
    }

    setErrors(errors);
    if (Object.keys(errors).length === 0) {
      handleAddSalary();
    }
    console.log(handleAddSalary, "hlo");
  };

  const GetFirmEmployee = async (e) => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        firmId: user.firm_id,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/getEmployee`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        const mappedEmployees = data.data.map((employee) => ({
          value: employee.empid,
          label: `${employee.firstname} ${employee.lastname}`,
        }));
        setMember(mappedEmployees);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
    }
    setTransactionLoaderState(false);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;

    setAddSalaryData((prevData) => ({
      ...prevData,
      [name]: value.trim(),
    }));
  };

  useEffect(() => {
    const parsedBasicPay = parseFloat(AddsalaryData.basicPay) || 0;
    const parsedHRA = parseFloat(AddsalaryData.hra) || 0;
    const parsedTravelAllowance =
      parseFloat(AddsalaryData.travelAllowance) || 0;
    const parsedMedicalAllowance =
      parseFloat(AddsalaryData.medicalAllowance) || 0;
    const parsedotherAllowance = parseFloat(AddsalaryData.otherAllowance) || 0;

    const totalSalary =
      parsedBasicPay +
      parsedHRA +
      parsedTravelAllowance +
      parsedMedicalAllowance +
      parsedotherAllowance;
    setAddSalaryData((prevData) => ({
      ...prevData,
      Total: totalSalary.toString(),
    }));
  }, [
    AddsalaryData.basicPay,
    AddsalaryData.hra,
    AddsalaryData.travelAllowance,
    AddsalaryData.medicalAllowance,
    AddsalaryData.otherAllowance,
  ]);
  const empId = sessionStorage.getItem("currentEmpId");
  const handleAddSalary = async (e) => {
    setTransactionLoaderState(true);
    try {
      const selectedDate = new Date(AddsalaryData.date);
      const effectiveMonth = selectedDate.toLocaleString("default", {
        month: "long",
        year: "numeric",
      });
      const effectiveDate = selectedDate.toISOString().split("T")[0];

      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");
      var raw = JSON.stringify({
        fk_emp_id: empId,
        basicpay: AddsalaryData.basicPay,
        hra: AddsalaryData.hra,
        travel_allowance: AddsalaryData.travelAllowance,
        medical_allowance: AddsalaryData.medicalAllowance,
        other_allowance: AddsalaryData.otherAllowance,
        totalsalary: AddsalaryData.Total,
        status: "N",
        effective_month: effectiveMonth,
        effective_date: effectiveDate,
      });
      console.log("Request sending:", raw);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
      console.log("sending data to abhijeet");
      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/addSalarydetails`,
        requestOptions
      );
      console.log("Api url");
      const data = await res.json();
      console.log("nishant", data);
      if (data.status === "1") {
        swal("Error", data.result, "error");
        onHide();
        getEmployeeList();
      } else {
        swal("Success", data.result, "success");
        onHide();
        getEmployeeList();
      }
      setTransactionLoaderState(false);
      console.log("Form submission completed.");
    } catch (error) {
      console.log("Error:", error);
    }
    setTransactionLoaderState(false);
  };
  const handleEmployeeSelection = (selectedOption) => {
    setselectedEmployee(selectedOption);
    const selectedEmployeeId = selectedOption.value;
    const selectedEmployeeName = selectedOption.label; // Get the selected employee's name
    setEmployeeid(selectedEmployeeId);
    // Update the Employeename field in the state
    setAddSalaryData((prevData) => ({
      ...prevData,
      Employeename: selectedEmployeeName,
    }));
  };

  useEffect(() => {
    GetFirmEmployee();
  }, []);
  useEffect(() => {
    if (!show) {
      resetForm();
    }
  }, [show]);
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Add Salary Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form className="">
          {/* <Form.Group className="col" controlId="Employeename">
            <Form.Label>Select Employee</Form.Label>
            <Select
              options={[
                { value: "", label: "Please Select Employee" },
                ...Member,
              ]}
              value={selectedEmployee}
              onChange={handleEmployeeSelection}
            />
          </Form.Group> */}
          <div className="row m-0 p-0">
            <Form.Group className="col" controlId="basicPay">
              <Form.Label>Basic Pay</Form.Label>
              <Form.Control
                type="text"
                name="basicPay"
                value={AddsalaryData.basicPay}
                onChange={handleChange}
                placeholder="Enter Basic pay"
              />
              {errors.basicPay && (
                <div className="text-danger" style={{ fontSize: ".8rem" }}>
                  {errors.basicPay}
                </div>
              )}
            </Form.Group>
            <Form.Group className="col" controlId="hra">
              <Form.Label>House Rent Allowance</Form.Label>
              <Form.Control
                type="text"
                name="hra"
                value={AddsalaryData.hra}
                onChange={handleChange}
                placeholder="Enter House Rent Allowance"
              />
              {errors.hra && (
                <div className="text-danger" style={{ fontSize: ".8rem" }}>
                  {errors.hra}
                </div>
              )}
            </Form.Group>
          </div>

          <div className="row m-0 p-0">
            {" "}
            <Form.Group className="col" controlId="travelAllowance">
              <Form.Label>Travel Allowance</Form.Label>
              <Form.Control
                type="text"
                name="travelAllowance"
                value={AddsalaryData.travelAllowance}
                onChange={handleChange}
                placeholder="Enter Travel Allowance"
              />
              {errors.travelAllowance && (
                <div className="text-danger" style={{ fontSize: ".8rem" }}>
                  {errors.travelAllowance}
                </div>
              )}
            </Form.Group>
            <Form.Group className="col" controlId="medicalAllowance">
              <Form.Label>Medical Allowance</Form.Label>
              <Form.Control
                type="text"
                name="medicalAllowance"
                value={AddsalaryData.medicalAllowance}
                onChange={handleChange}
                placeholder="Enter Medical Allowance"
              />
              {errors.medicalAllowance && (
                <div className="text-danger" style={{ fontSize: ".8rem" }}>
                  {errors.medicalAllowance}
                </div>
              )}
            </Form.Group>
          </div>
          <div className="row m-0 p-0">
            <Form.Group className="col" controlId="medicalAllowance">
              <Form.Label>Other Allowance</Form.Label>
              <Form.Control
                type="text"
                name="otherAllowance"
                value={AddsalaryData.otherAllowance}
                onChange={handleChange}
                placeholder="Enter other Allowance"
              />
              {errors.otherAllowance && (
                <div className="text-danger" style={{ fontSize: ".8rem" }}>
                  {errors.otherAllowance}
                </div>
              )}
            </Form.Group>
            <Form.Group className="col" controlId="medicalAllowance">
              <Form.Label>Total Salary</Form.Label>
              <Form.Control
                readOnly
                type="text"
                name="Total"
                value={AddsalaryData.Total}
                onChange={handleChange}
                placeholder="Enter Total Salary"
              />
            </Form.Group>
          </div>

          <Form.Group className="col" controlId="medicalAllowance">
            <Form.Label> Date</Form.Label>
            <Form.Control
              type="date"
              name="date"
              value={AddsalaryData.date}
              onChange={handleChange}
              placeholder=" Date"
            />
          </Form.Group>
          <button
            type="submit"
            className="btn btn-primary mx-3"
            style={{
              color: "#fff",
              height: "2rem",
              border: "none",
              borderRadius: ".4rem",
              display: "flex",
              alignItems: "center",
              width: "7rem",
              fontSize: ".8rem",
            }}
            onClick={validate}
          >
            Add Salary
          </button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export const DocumentModalHR = ({
  show,
  onHide,
  getEmployeeList,
  selectedEmployeeId,
}) => {
  const employeeName = sessionStorage.getItem("employeeName") || "";
  const [aadharNumber, setAadharNumber] = useState("");
  const [panNumber, setPanNumber] = useState("");
  const [uannumber, setUannumber] = useState("");
  const [xcertificate, setXcertificate] = useState(null);
  const [xcertificatename, setXcertificatename] = useState("");
  const [xiicertificatename, setXiicertificatename] = useState("");
  const [highestqualificationname, setHighestqualificationname] = useState("");
  const [aadharname, setAadharname] = useState("");
  const [panname, setPanname] = useState("");
  const [xiicertificate, setXiicertificate] = useState(null);
  const [degreecertificate, setDegreecertificate] = useState(null);
  const [aadharcard, setAadharcard] = useState(null);
  const [pancard, setPancard] = useState(null);
  const [image, setImage] = useState(null);
  const { password, username } = billpunchbasicauth;
  const { user, setTransactionLoaderState } = useContext(GlobalContext);

  const convertToByteArray = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.onloadend = (evt) => {
        if (evt.target.readyState === FileReader.DONE) {
          const arrayBuffer = evt.target.result;
          const array = new Uint8Array(arrayBuffer);
          const binary = [...array]
            .map((byte) => String.fromCharCode(byte))
            .join("");
          const byteArray = window.btoa(binary);
          resolve(byteArray);
        }
      };
      reader.onerror = reject;
    });
  };

  const handleImageUpload = async (file, imageType, fileType) => {
    if (!file) {
      return;
    }

    if (file.size > 2 * 1000000) {
      swal("Invalid", "Image Size should be less than 2 MB", "error");
      return;
    }

    const byteArray = await convertToByteArray(file);

    const raw = JSON.stringify({
      firmid: user.firm_id,
      userid: user.userid,
      image_type: imageType,
      file_type: fileType,
      image_data: byteArray,
    });

    const myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Basic " + Buffer.from(username + ":" + password).toString("base64")
    );
    myHeaders.append("Content-Type", "application/json");

    const requestOptions = {
      method: "POST",
      body: raw,
      redirect: "follow",
      headers: myHeaders,
    };

    try {
      const response = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/SuperAdmin/ImageUpload`,
        requestOptions
      );
      const result = await response.json();

      console.log("API Response:", result);

      if (result.status === "0") {
        swal("Success", result.msg, "success").then(() => {
          getEmployeeList();
        });
      } else {
        swal("Error", result.msg, "error");
      }
    } catch (error) {
      console.log(error);
      swal("Error", "Failed to upload image", "error");
    } finally {
      setTransactionLoaderState(false);
    }
  };

  const handleSubmit = async () => {
    try {
      setTransactionLoaderState(true);
      const myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      const raw = JSON.stringify({
        empid: selectedEmployeeId,
        firmid: user.firm_id,
        xcertificate: xcertificatename,
        xiicertificate: xiicertificatename,
        degreecertificate: highestqualificationname,
        aadharcard: aadharname,
        pancard: panname,
        aadharnumber: aadharNumber,
        pannumber: panNumber,
        uannumber: uannumber,
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/addDocuments`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        onHide();
        getEmployeeList();
        swal("Success", data.result, "success");
      } else {
        getEmployeeList();
        onHide();
        swal("Error", data.msg, "error");
      }
    } catch (error) {
      console.error("Error during document upload:", error);
      swal("Error", "Error during document upload", "error");
    } finally {
      setTransactionLoaderState(false);
    }
  };

  return (
    <>
      <Modal show={show} onHide={onHide}>
        <Modal.Header closeButton>
          <Modal.Title>
            <h6> Upload Documents of {employeeName}</h6>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="mx-md-2">
            <div className="row m-0 p-0 ">
              <Form.Group className="col-6" controlId="aadharNumber">
                <div className="form-group">
                  <label htmlFor="aadharNumber">Aadhar Number</label>
                  <Form.Control
                    type="text"
                    name="aadharNumber"
                    value={aadharNumber}
                    onChange={(e) => setAadharNumber(e.target.value)}
                    style={{ borderRadius: "0px" }}
                  />
                </div>
              </Form.Group>

              <Form.Group className="col-6" controlId="panNumber">
                <div className="form-group">
                  <label htmlFor="panNumber">Pan Number</label>
                  <Form.Control
                    type="text"
                    name="panNumber"
                    value={panNumber}
                    onChange={(e) => setPanNumber(e.target.value)}
                    style={{ borderRadius: "0px" }}
                  />
                </div>
              </Form.Group>
            </div>
            <div className="row m-0 p-0 ">
              <Form.Group className="col-6" controlId="uannumber">
                <div className="form-group">
                  <label htmlFor="uannumber">UAN Number</label>
                  <Form.Control
                    type="text"
                    name="uannumber"
                    value={uannumber}
                    onChange={(e) => setUannumber(e.target.value)}
                    style={{ borderRadius: "0px" }}
                  />
                </div>
              </Form.Group>

              <Form.Group className="col-6" controlId="image">
                <div className="form-group">
                  <label htmlFor="image">X Marksheet</label>
                  <Form.Control
                    accept=".jpeg"
                    type="file"
                    name="X"
                    onChange={(e) => {
                      setXcertificatename("X" + uniqueID2());
                      setXcertificate(e.target.files[0]);
                      handleImageUpload(
                        e.target.files[0],
                        "X Marksheet",
                        "jpeg"
                      );
                    }}
                    style={{ borderRadius: "0px" }}
                  ></Form.Control>
                </div>
              </Form.Group>
            </div>

            <div className="row m-0 p-0 ">
              <Form.Group className="col-6" controlId="image">
                <div className="form-group">
                  <label htmlFor="image">XII Marksheet</label>
                  <Form.Control
                    accept=".jpeg"
                    type="file"
                    name="XII"
                    onChange={(e) => {
                      setXiicertificatename("XII" + uniqueID2());
                      setXiicertificate(e.target.files[0]);
                      handleImageUpload(
                        e.target.files[0],
                        "XII Marksheet",
                        "jpeg"
                      );
                    }}
                    style={{ borderRadius: "0px" }}
                  ></Form.Control>
                </div>
              </Form.Group>
              <Form.Group className="col-6" controlId="image">
                <div className="form-group">
                  <label htmlFor="image">Highest Qualification</label>
                  <Form.Control
                    accept=".jpeg"
                    type="file"
                    name="Highest"
                    onChange={(e) => {
                      setHighestqualificationname("Highest" + uniqueID2());
                      setDegreecertificate(e.target.files[0]);
                      handleImageUpload(
                        e.target.files[0],
                        "Highest Qualification",
                        "jpeg"
                      );
                    }}
                    style={{ borderRadius: "0px" }}
                  ></Form.Control>
                </div>
              </Form.Group>
            </div>

            <div className="row m-0 p-0 ">
              <Form.Group className="col-6" controlId="image">
                <div className="form-group">
                  <label htmlFor="image">Aadhar Card</label>
                  <Form.Control
                    accept=".jpeg"
                    type="file"
                    name="Aadhar"
                    onChange={(e) => {
                      setAadharname("Aadhar" + uniqueID2());
                      setAadharcard(e.target.files[0]);
                      handleImageUpload(
                        e.target.files[0],
                        "Aadhar Card",
                        "jpeg"
                      );
                    }}
                    style={{ borderRadius: "0px" }}
                  ></Form.Control>
                </div>
              </Form.Group>
              <Form.Group className="col-6" controlId="image">
                <div className="form-group">
                  <label htmlFor="image">Pan Card</label>
                  <Form.Control
                    accept=".jpeg"
                    type="file"
                    name="Pan"
                    onChange={(e) => {
                      setPanname("Pan" + uniqueID2());
                      setPancard(e.target.files[0]);
                      handleImageUpload(e.target.files[0], "Pan Card", "jpeg");
                    }}
                    style={{ borderRadius: "0px" }}
                  ></Form.Control>
                </div>
              </Form.Group>
            </div>

            <div className="row p-0 m-0">
              <Link className="mx-md-3 mx-sm-2 ">
                <button
                  // type="submit"
                  className="btn btn-primary d-flex align-items-center "
                  style={{ height: "2rem", borderRadius: ".4rem" }}
                  onClick={handleSubmit}
                >
                  Submit
                </button>
              </Link>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export const UpdateSalaryModalHR = ({
  show,
  onHide,
  empid,
  getEmployeeList,
}) => {
  const { password, username } = billpunchbasicauth;

  const employeeName = sessionStorage.getItem("employeeName") || "";
  console.log("Retrieved Employee Name:", employeeName);
  const { setTransactionLoaderState } = useContext(GlobalContext);
  const [pk_s_id, setPkSId] = useState("");
  const [salaryData, setSalaryData] = useState({
    basicPay: "",
    hra: "",
    travelAllowance: "",
    medicalAllowance: "",
    otherAllowance: "",
    Total: "",
  });
  const [errors, setErrors] = useState({
    basicPay: "",
    hra: "",
    travelAllowance: "",
    medicalAllowance: "",
    otherAllowance: "",
    Total: "",
  });
  const validate = (e) => {
    e.preventDefault();
    console.log(" validate function");
    console.log(salaryData);
    let errors = {};
    if (salaryData.basicPay.trim() == "") {
      errors.basicPay = "*Please enter basic Pay";
    }
    if (salaryData.hra.trim() == "") {
      errors.hra = "*Please enter house rent Allowance";
    }
    if (salaryData.travelAllowance.trim() == "") {
      errors.travelAllowance = "*Please enter travel Allowance";
    }
    if (salaryData.medicalAllowance.trim() == "") {
      errors.medicalAllowance = "*Please enter medical Allowance";
    }
    if (salaryData.otherAllowance.trim() == "") {
      errors.otherAllowance = "*Please enter other Allowance";
    }
    if (salaryData.Total.trim() == "") {
      errors.Total = "*Please enter Total Salary";
    }
    setErrors(errors);
    if (Object.keys(errors).length == 0) {
      handleSubmit();
    }
  };

  const handleChange = (e) => {
    setSalaryData({
      ...salaryData,
      [e.target.name]: e.target.value.trim(),
    });
  };
  useEffect(() => {
    const parsedBasicPay = parseFloat(salaryData.basicPay) || 0;
    const parsedHRA = parseFloat(salaryData.hra) || 0;
    const parsedTravelAllowance = parseFloat(salaryData.travelAllowance) || 0;
    const parsedMedicalAllowance = parseFloat(salaryData.medicalAllowance) || 0;
    const parsedotherAllowance = parseFloat(salaryData.otherAllowance) || 0;

    const totalSalary =
      parsedBasicPay +
      parsedHRA +
      parsedTravelAllowance +
      parsedMedicalAllowance +
      parsedotherAllowance;

    setSalaryData((prevData) => ({
      ...prevData,
      Total: totalSalary.toString(),
    }));
  }, [
    salaryData.basicPay,
    salaryData.hra,
    salaryData.travelAllowance,
    salaryData.medicalAllowance,
    salaryData.otherAllowance,
  ]);

  const getSalaryDetails = async () => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        empid: empid,
      });
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/getSalaryDetails`,
        requestOptions
      );
      const data = await res.json();

      if (data.status === "0" && data.data.length > 0) {
        const salaryDetails = data.data[0];
        setPkSId(salaryDetails.pk_s_id);

        setSalaryData({
          bankName: salaryDetails.bankname,
          ifsc: salaryDetails.bankifsc,
          accountNumber: salaryDetails.bankaccno,
          basicPay: salaryDetails.basicpay,
          hra: salaryDetails.hra,
          travelAllowance: salaryDetails.travel_allowance,
          medicalAllowance: salaryDetails.medical_allowance,
          otherAllowance: salaryDetails.other_allowance,
          Total: salaryDetails.totalsalary,
        });
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
    }
    setTransactionLoaderState(false);
  };

  const handleSubmit = async (e) => {
    try {
      const requestBody = {
        pk_s_id: pk_s_id,
        fk_emp_id: empid,
        basicpay: salaryData.basicPay,
        hra: salaryData.hra,
        travel_allowance: salaryData.travelAllowance,
        medical_allowance: salaryData.medicalAllowance,
        other_allowance: salaryData.otherAllowance,
        totalsalary: salaryData.Total,
      };

      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify(requestBody);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/updateSalaryDetails`,
        requestOptions
      );

      const data = await res.json();
      if (data.status === "1") {
        swal("Error", data.result, "error");
        onHide();
        getEmployeeList();
      } else {
        swal("Success", data.result, "success");
        onHide();
        getEmployeeList();
      }
      console.log("Form submission completed.");
    } catch (error) {
      console.log("Error:", error);
    }
  };
  useEffect(() => {
    getSalaryDetails();
  }, [empid]);

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>
          <h6>Update Salary for {employeeName}</h6>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={validate}>
          <div className="row m-0 p-0">
            <Form.Group className="col-6" controlId="basicPay">
              <Form.Label>Basic Pay</Form.Label>
              <Form.Control
                type="text"
                name="basicPay"
                value={salaryData.basicPay}
                onChange={handleChange}
                placeholder="Enter Basic pay"
              />
              {errors.basicPay && (
                <div className="text-danger">{errors.basicPay}</div>
              )}
            </Form.Group>{" "}
            <Form.Group className="col-6" controlId="hra">
              <Form.Label>House Rent Allowance</Form.Label>
              <Form.Control
                type="text"
                name="hra"
                value={salaryData.hra}
                onChange={handleChange}
                placeholder="Enter House Rent Allowance"
              />
              {errors.hra && <div className="text-danger">{errors.hra}</div>}
            </Form.Group>
          </div>

          <div className="row m-0 p-0">
            <Form.Group className="col-6" controlId="travelAllowance">
              <Form.Label>Travel Allowance</Form.Label>
              <Form.Control
                type="text"
                name="travelAllowance"
                value={salaryData.travelAllowance}
                onChange={handleChange}
                placeholder="Enter Travel Allowance"
              />
              {errors.travelAllowance && (
                <div className="text-danger">{errors.travelAllowance}</div>
              )}
            </Form.Group>
            <Form.Group className="col-6" controlId="medicalAllowance">
              <Form.Label>Medical Allowance</Form.Label>
              <Form.Control
                type="text"
                name="medicalAllowance"
                value={salaryData.medicalAllowance}
                onChange={handleChange}
                placeholder="Enter Medical Allowance"
              />
              {errors.medicalAllowance && (
                <div className="text-danger">{errors.medicalAllowance}</div>
              )}
            </Form.Group>
          </div>

          <div className="row m-0 p-0">
            <Form.Group className="col-6" controlId="medicalAllowance">
              <Form.Label>Other Allowance</Form.Label>
              <Form.Control
                type="text"
                name="otherAllowance"
                value={salaryData.otherAllowance}
                onChange={handleChange}
                placeholder="Enter Other Allowance"
              />
              {errors.otherAllowance && (
                <div className="text-danger">{errors.otherAllowance}</div>
              )}
            </Form.Group>
            <Form.Group className="col-6" controlId="medicalAllowance">
              <Form.Label>Total Salary</Form.Label>
              <Form.Control
                type="text"
                name="Total"
                value={salaryData.Total}
                onChange={handleChange}
                placeholder="Enter Total Salary"
              />
              {errors.Total && (
                <div className="text-danger">{errors.Total}</div>
              )}
            </Form.Group>
          </div>
          <div className="row p-0 m-0">
            <Link className="mx-md-3 mx-sm-2 ">
              <button
                // type="submit"
                className="btn btn-primary d-flex align-items-center "
                style={{ height: "2rem", borderRadius: ".4rem" }}
                onClick={validate}
              >
                Update
              </button>
            </Link>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export const SalaryExpenseHRModal = ({ isOpen, closeModal }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [expenseCategories, setExpenseCategories] = useState([]);
  const [Project, setProject] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const { password, username } = billpunchbasicauth;
  const initialFormData = {
    project: "",
    Expensename: "",
    category: "",
    expenseDate: "",
    Amount: "",
    description: "",
  };

  const initialErrors = {
    project: "",
    Expensename: "",
    category: "",
    expenseDate: "",
    Amount: "",
    description: "",
  };

  const [formData, setFormData] = useState(initialFormData);
  const [errors, setErrors] = useState(initialErrors);

  const [addcategorymodal, setaddcategorymodal] = useState(false);
  const openExpensecatModal = () => {
    setaddcategorymodal(true);
  };

  const closeExpensecalModal = () => {
    setaddcategorymodal(false);
    setFormData({ ...formData, category: "" });
  };

  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const validate = (e) => {
    e.preventDefault();
    console.log("validate function");
    console.log(formData);

    let errors = {};
    // if (formData.project.trim() === "") {
    //   errors.project = "*Please enter Project Name";
    // }

    if (formData.Expensename.trim() === "") {
      errors.Expensename = "*Please enter Expense Name";
    }

    if (formData.category.trim() === "") {
      errors.category = "*Please select Expense Type";
    }

    if (formData.expenseDate.trim() === "") {
      errors.expenseDate = "*Please enter Expense Date";
    }

    if (formData.Amount.trim() === "") {
      errors.Amount = "*Please enter Amount";
    }

    if (formData.description.trim() === "") {
      errors.description = "*Please enter Description";
    }

    setErrors(errors);
    console.log(errors);

    if (Object.keys(errors).length === 0) {
      handleSubmit();
    }
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "project" && value === "createProject") {
      openaddprojectModal();
      return;
    }
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const fetchExpenseCategories = async () => {
    setIsLoading(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        firmid: user.firm_id,
        empid: "0",
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/GetAllFirmExpenseCategories
          `,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        setExpenseCategories(data.data);
      }
    } catch (error) {
      console.log("error", error);
    }
    setIsLoading(false);
  };
  const fetchproject = async () => {
    setIsLoading(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        firmid: user.firm_id,
        created_by: "Admin",
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/GetFirmProjects
          `,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        setProject(data.data);
      }
    } catch (error) {
      console.log("error", error);
    }
    setIsLoading(false);
  };
  const convertToByteArray = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.onloadend = (evt) => {
        if (evt.target.readyState === FileReader.DONE) {
          const arrayBuffer = evt.target.result;
          const array = new Uint8Array(arrayBuffer);
          const binary = [...array]
            .map((byte) => String.fromCharCode(byte))
            .join("");
          const byteArray = window.btoa(binary);
          resolve(byteArray);
        }
      };
      reader.onerror = reject;
    });
  };

  const handleSubmit = async (cardType) => {
    setTransactionLoaderState(true);
    if (!selectedFile) {
      swal("Error", "Please select a file for upload.", "warning");
      return;
    }

    const uploadedFile = selectedFile;
    const byteArray = await convertToByteArray(uploadedFile);

    let imageType;
    if (uploadedFile.type === "application/pdf") {
      imageType = ".pdf";
    } else if (uploadedFile.type.startsWith("image/jpeg")) {
      imageType = ".jpeg";
    } else {
      swal(
        "Error",
        "Unsupported file type. Please upload a PDF or JPEG file.",
        "warning"
      );
      return;
    }

    setFormData((prevFormData) => ({
      ...prevFormData,
      image_type: imageType,
    }));
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Basic " + Buffer.from(username + ":" + password).toString("base64")
    );
    myHeaders.append("Content-Type", "application/json");

    try {
      const byteArray = await convertToByteArray(uploadedFile);
      const fileData = byteArray;

      const fileType = uploadedFile.type.split("/")[1];

      const requestBody = {
        userid: "BILL PUNCH",
        image_type: "expense",
        file_type: fileType,
        image_data: fileData,
      };

      const response = await fetch(
        "https://bumppy.in/billpunch/api/v1/imageUpload.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        }
      );

      const responseData = await response.json();

      if (responseData.status === "0") {
        const imageUrl = responseData.image_url;
        setFormData((prevFormData) => ({
          ...prevFormData,
          imageUrl: imageUrl,
        }));

        // Now submit the form with imageUrl
        const raw = JSON.stringify({
          firmid: user.firm_id,
          empid: user.userid,
          expense_type: formData.category,
          expense_date: formData.expenseDate,
          expense_amount: formData.Amount,
          approved_by_hr: "P",
          approved_by_admin: "P",
          image_name: formData.Expensename,
          image_type: imageType,
          description: formData.description,
          image_bytedata: byteArray,
          project_id: formData.project,
          baseurl: imageUrl, // Include imageUrl here
        });

        const requestOptions = {
          method: "POST",
          body: raw,
          redirect: "follow",
          headers: myHeaders,
        };

        try {
          const response = await fetch(
            `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/insertExpenseDetails`,
            requestOptions
          );
          const result = await response.json();

          if (result.status === "0") {
            setTransactionLoaderState(false);
            swal("Success", result.msg, "success");
            closeModal(false);
            setFormData(initialFormData);
          } else {
            setTransactionLoaderState(false);
            swal("Failed", result.msg, "error");
            closeModal(false);
          }
        } catch (error) {
          console.log(error);
        }
      } else {
        swal("Error", responseData.msg, "error");
      }
    } catch (error) {
      console.error("Error uploading file:", error);
      swal("Error", "Failed to upload file.", "error");
    }
  };
  const handleFileChange = async (e) => {
    setTransactionLoaderState(true);
    const file = e.target.files[0];

    if (!file) {
      swal("Error", "Please select a file for upload.", "warning");
      return;
    }

    if (file.type !== "image/jpeg" && file.type !== "image/png") {
      swal(
        "Error",
        "Unsupported file type. Please upload a JPEG or PNG file.",
        "error"
      );
      return;
    }

    try {
      const byteArray = await convertToByteArray(file);
      const fileData = byteArray;

      const fileType = file.type.split("/")[1];

      const requestBody = {
        userid: "BILL PUNCH",
        image_type: "expense",
        file_type: fileType,
        image_data: fileData,
      };

      const response = await fetch(
        "https://bumppy.in/billpunch/api/v1/imageUpload.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        }
      );

      const responseData = await response.json();

      if (responseData.status === "0") {
        const imageUrl = responseData.image_url;
        setFormData((prevFormData) => ({
          ...prevFormData,
          imageUrl: imageUrl,
        }));
        setSelectedFile(file);

        swal("Success", responseData.msg, "success");
        setTransactionLoaderState(false);
      } else {
        swal("Error", responseData.msg, "error");
        setTransactionLoaderState(false);
      }
    } catch (error) {
      console.error("Error uploading file:", error);
      swal("Error", "Failed to upload file.", "error");
    }
  };

  useEffect(() => {
    fetchExpenseCategories();
    fetchproject();
  }, []);
  useEffect(() => {
    if (!isOpen) {
      setFormData(initialFormData);
      setErrors(initialErrors);
    }
  }, [isOpen]);
  const [showaddprojectModal, setshowaddprojectModal] = useState(false);
  const openaddprojectModal = () => {
    setshowaddprojectModal(true);
  };

  const closeaddprojectModal = () => {
    setshowaddprojectModal(false);
  };
  return (
    <>
      <Expensecategoryhrmodal
        showModal={addcategorymodal}
        closeModal={closeExpensecalModal}
        fetchExpenseCategories={fetchExpenseCategories}
      />
      <AddProjectAdminModal
        show={showaddprojectModal}
        closeModal={closeaddprojectModal}
        getProjects={fetchproject}
      />
      <Modal show={isOpen} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>Add Expense Manually</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form className="mx-md-3 mx-sm-2">
            <div className="row m-0 p-1">
              {" "}
              <Form.Group className="col">
                <Form.Label> Select Project</Form.Label>
                <select
                  name="project"
                  className="form-control"
                  value={formData.project}
                  onChange={handleInputChange}
                >
                  <option value="">Select a Project</option>
                  <option value="createProject">+ Create Project</option>
                  {Project.map((project) => (
                    <option
                      key={project.pk_project_id}
                      value={project.pk_project_id}
                    >
                      {project.project_name}
                    </option>
                  ))}
                </select>

                {/* {errors.project && (
                <p className="text-danger">{errors.project}</p>
              )} */}
              </Form.Group>
            </div>
            <div className="row m-0 p-1">
              <Form.Group className="col">
                <Form.Label>Expense Name</Form.Label>
                <Form.Control
                  type="text"
                  name="Expensename"
                  value={formData.Expensename}
                  onChange={handleInputChange}
                  placeholder="Expense Name"
                />
                {errors.Expensename && (
                  <p className="text-danger">{errors.Expensename}</p>
                )}
              </Form.Group>{" "}
              <Form.Group className="col">
                <Form.Label>Expense Type</Form.Label>
                <Form.Control
                  as="select"
                  name="category"
                  value={formData.category}
                  onChange={(e) => {
                    handleInputChange(e);
                    if (e.target.value === "Add Category") {
                      openExpensecatModal();
                    }
                  }}
                >
                  <option value="">Category</option>
                  <hr />
                  <option>Add Category</option> <hr />
                  {isLoading ? (
                    <option>Loading...</option>
                  ) : (
                    expenseCategories.map((category) => (
                      <option
                        key={category.pk_expense_category_id}
                        value={category.category_name}
                      >
                        {category.category_name}
                      </option>
                    ))
                  )}
                </Form.Control>

                {errors.category && (
                  <p className="text-danger">{errors.category}</p>
                )}
              </Form.Group>
            </div>
            <div className="row m-0 p-1 ">
              <Form.Group className="col-6">
                <Form.Label> Amount:</Form.Label>
                <Form.Control
                  type="text"
                  name="Amount"
                  value={formData.Amount}
                  onChange={handleInputChange}
                  placeholder="Amount"
                />
                {errors.Amount && (
                  <p className="text-danger">{errors.Amount}</p>
                )}
              </Form.Group>
              <Form.Group className="col ">
                <Form.Label>Expense Date:</Form.Label>
                <Form.Control
                  type="date"
                  name="expenseDate"
                  value={formData.expenseDate}
                  onChange={handleInputChange}
                  placeholder=""
                />
                {errors.expenseDate && (
                  <p className="text-danger">{errors.expenseDate}</p>
                )}
              </Form.Group>
            </div>

            <div className="row m-0 p-1 ">
              <Form.Group className="col">
                <Form.Label>Description</Form.Label>
                <textarea
                  className="form-control"
                  type="textarea"
                  name="description"
                  value={formData.description}
                  onChange={handleInputChange}
                  placeholder="Description"
                />
                {errors.description && (
                  <p className="text-danger">{errors.description}</p>
                )}
              </Form.Group>
            </div>
            <div className="row m-0 p-1 ">
              <Form.Group className="col-8">
                <Form.Label>File Upload (PDF or JPEG)</Form.Label>
                <Form.Control
                  type="file"
                  accept=".pdf, .jpeg, .jpg"
                  onChange={handleFileChange}
                />
              </Form.Group>
            </div>

            <div className="row m-0 p-1 ">
              <div className="col-3">
                <button
                  className="btn btn-success d-flex align-items-center"
                  type="button"
                  style={{
                    height: "2rem",
                    borderRadius: ".3rem",
                    fontSize: ".8rem",
                  }}
                  onClick={validate}
                >
                  Submit
                </button>
              </div>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export const CheckAttandanceHr = ({ show, closeModal, projectId, months }) => {
  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const [data, setData] = useState([]);
  const { password, username } = billpunchbasicauth;

  const [departmentData, setDepartmentData] = useState([]);
  const [department, setDepartment] = useState("");
  const resetModalState = () => {
    setData([]);
  };
  const getProjects = async (e) => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        month: months,
        firmid: user.firm_id,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/GetAttendanceAll`,
        requestOptions
      );
      const data = await res.json();
      if (data.status == "0") {
        setData(data.data);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
    }
    setTransactionLoaderState(false);
  };
  const getDepartmentList = async () => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({ firmid: user.firm_id });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/getEmployeeDepartment`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        setDepartmentData(data.data);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    if (show) {
      resetModalState();
      getProjects();
    }
  }, [show, projectId]);
  useEffect(() => {
    getDepartmentList();
  }, []);

  return (
    <>
      <Modal show={show} onHide={closeModal} className="chechattandance-modal">
        <Modal.Header closeButton>
          <Modal.Title className="">Attendance Monthly Report</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div className=" pb-md-2">
              <select
                style={{
                  width: "9rem",
                  height: "2rem",
                  borderRadius: ".2rem",
                  border: "1px #ced4da solid",
                }}
                value={department}
                onChange={(e) => setDepartment(e.target.value)}
              >
                <option value="">All Department</option>
                {departmentData.map((dept) => (
                  <option key={dept.id} value={dept.cat1}>
                    {dept.cat1}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <table className="table table-bordered m-0 p-0 ">
            <thead>
              <tr style={{ borderBottom: "1px solid lightgrey" }}>
                <th
                  className="text-center"
                  style={{
                    color: "#0074D9",
                    background: "#fff",
                    border: "none",
                  }}
                >
                  ID
                </th>

                <th
                  className="text-center"
                  style={{
                    color: "#0074D9",
                    background: "#fff",
                    border: "none",
                  }}
                >
                  Name
                </th>
                <th
                  className="text-center"
                  style={{
                    color: "#0074D9",
                    background: "#fff",
                    border: "none",
                  }}
                >
                  Month
                </th>

                <th
                  className="text-center"
                  style={{
                    color: "#0074D9",
                    background: "#fff",
                    border: "none",
                  }}
                >
                  Total Present
                </th>
              </tr>
            </thead>
            <tbody>
              {data.length === 0 ? (
                <tr>
                  <td colSpan="4" className="text-center">
                    No data available
                  </td>
                </tr>
              ) : (
                data.map((item, index) => (
                  <tr
                    style={{ borderBottom: "1px solid lightgrey" }}
                    key={index}
                  >
                    <td
                      className="text-center align-middle"
                      style={{ border: "none" }}
                    >
                      {item.employee_id || "NA"}
                    </td>
                    <td
                      className="text-center align-middle"
                      style={{ border: "none" }}
                    >
                      {item.employee_name || "NA"}
                    </td>
                    <td
                      className="text-center align-middle"
                      style={{ border: "none" }}
                    >
                      {item.month || "NA"}
                    </td>
                    <td
                      className="text-center align-middle"
                      style={{ border: "none" }}
                    >
                      {item.total_present || "NA"}
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </Modal.Body>
      </Modal>
    </>
  );
};
export const AdddepartmentHrmodal = ({ show, hide, getDepartmentList }) => {
  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const [category, setCategory] = useState("");
  const [description, setDescription] = useState("");
  const { password, username } = billpunchbasicauth;

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      console.log("submit form");
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");
      var raw = JSON.stringify({
        firmid: user.firm_id,
        cat: category,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/AddEmployeeCategory`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        swal("Success", data.result, "success");
        hide();
        getDepartmentList();
      } else {
        swal("Error", data.result, "error");
        hide();
        getDepartmentList();
      }
      console.log("Form submission completed.");
    } catch (error) {
      console.log("Error:", error);
    }
  };
  return (
    <Modal show={show} onHide={hide}>
      <Modal.Header closeButton>
        <Modal.Title>Add Category</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="mx-md-2" controlId="Category">
            <Form.Control
              type="text"
              name="Category"
              placeholder="Category"
              value={category}
              onChange={(e) => setCategory(e.target.value)}
            />
          </Form.Group>{" "}
          <Form.Group className="mx-md-2" controlId="description">
            <Form.Control
              as="textarea"
              rows={3}
              name="description"
              placeholder="Description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </Form.Group>
          <Modal.Footer className="m-0 p-0">
            <div className="row">
              <div className="col-auto m-0 p-0 ml-auto">
                <Link
                  className="d-flex align-items-center bg-primary p-1"
                  type="submit"
                  style={{
                    height: "2rem",
                    borderRadius: ".3rem",
                    fontSize: ".9rem",
                    color: "#fff",
                  }}
                  onClick={handleSubmit}
                >
                  Submit
                </Link>
              </div>
            </div>
          </Modal.Footer>
        </Form>
      </Modal.Body>
    </Modal>
  );
};
// Employee

export const SalaryExpenseEmployeeModal = ({ isOpen, closeModal }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [expenseCategories, setExpenseCategories] = useState([]);
  const [Project, setProject] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const { password, username } = billpunchbasicauth;
  const initialFormData = {
    project: "",
    Expensename: "",
    category: "",
    expenseDate: "",
    Amount: "",
    description: "",
  };

  const initialErrors = {
    project: "",
    Expensename: "",
    category: "",
    expenseDate: "",
    Amount: "",
    description: "",
  };

  const [formData, setFormData] = useState(initialFormData);
  const [errors, setErrors] = useState(initialErrors);

  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const validate = (e) => {
    e.preventDefault();
    console.log("validate function");
    console.log(formData);

    let errors = {};
    if (formData.project.trim() === "") {
      errors.project = "*Please enter Project Name";
    }

    if (formData.Expensename.trim() === "") {
      errors.Expensename = "*Please enter Expense Name";
    }

    if (formData.category.trim() === "") {
      errors.category = "*Please select Expense Type";
    }

    if (formData.expenseDate.trim() === "") {
      errors.expenseDate = "*Please enter Expense Date";
    }

    if (formData.Amount.trim() === "") {
      errors.Amount = "*Please enter Amount";
    }

    if (formData.description.trim() === "") {
      errors.description = "*Please enter Description";
    }

    setErrors(errors);
    console.log(errors);

    if (Object.keys(errors).length === 0) {
      handleSubmit();
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "project" && value === "createProject") {
      openaddprojectModal();
      return;
    }
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const [addcategorymodal, setaddcategorymodal] = useState(false);
  const openExpensecatModal = () => {
    setaddcategorymodal(true);
  };

  const closeExpensecalModal = () => {
    setaddcategorymodal(false);
    setFormData({ ...formData, category: "" });
  };

  const fetchExpenseCategories = async () => {
    setIsLoading(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        firmid: user.firm_id,
        empid: user.userid,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/GetAllFirmExpenseCategories
          `,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        setExpenseCategories(data.data);
      }
    } catch (error) {
      console.log("error", error);
    }
    setIsLoading(false);
  };

  const convertToByteArray = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.onloadend = (evt) => {
        if (evt.target.readyState === FileReader.DONE) {
          const arrayBuffer = evt.target.result;
          const array = new Uint8Array(arrayBuffer);
          const binary = [...array]
            .map((byte) => String.fromCharCode(byte))
            .join("");
          const byteArray = window.btoa(binary);
          resolve(byteArray);
        }
      };
      reader.onerror = reject;
    });
  };

  const handleSubmit = async (cardType) => {
    setTransactionLoaderState(true);
    if (!selectedFile) {
      swal("Error", "Please select a file for upload.", "warning");
      return;
    }

    const uploadedFile = selectedFile;
    const byteArray = await convertToByteArray(uploadedFile);

    let imageType;
    if (uploadedFile.type === "application/pdf") {
      imageType = ".pdf";
    } else if (uploadedFile.type.startsWith("image/jpeg")) {
      imageType = ".jpeg";
    } else {
      swal(
        "Error",
        "Unsupported file type. Please upload a PDF or JPEG file.",
        "warning"
      );
      return;
    }

    setFormData((prevFormData) => ({
      ...prevFormData,
      image_type: imageType,
    }));
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Basic " + Buffer.from(username + ":" + password).toString("base64")
    );
    myHeaders.append("Content-Type", "application/json");

    try {
      const byteArray = await convertToByteArray(uploadedFile);
      const fileData = byteArray;

      const fileType = uploadedFile.type.split("/")[1];

      const requestBody = {
        userid: "BILL PUNCH",
        image_type: "expense",
        file_type: fileType,
        image_data: fileData,
      };

      const response = await fetch(
        "https://bumppy.in/billpunch/api/v1/imageUpload.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        }
      );

      const responseData = await response.json();

      if (responseData.status === "0") {
        const imageUrl = responseData.image_url;
        setFormData((prevFormData) => ({
          ...prevFormData,
          imageUrl: imageUrl,
        }));

        // Now submit the form with imageUrl
        const raw = JSON.stringify({
          firmid: user.firm_id,
          empid: user.userid,
          expense_type: formData.category,
          expense_date: formData.expenseDate,
          expense_amount: formData.Amount,
          approved_by_hr: "P",
          approved_by_admin: "P",
          image_name: formData.Expensename,
          image_type: imageType,
          description: formData.description,
          image_bytedata: byteArray,
          project_id: formData.project,
          baseurl: imageUrl, // Include imageUrl here
        });

        const requestOptions = {
          method: "POST",
          body: raw,
          redirect: "follow",
          headers: myHeaders,
        };

        try {
          const response = await fetch(
            `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/insertExpenseDetails`,
            requestOptions
          );
          const result = await response.json();

          if (result.status === "0") {
            setTransactionLoaderState(false);
            swal("Success", result.msg, "success");
            closeModal(false);
            setFormData(initialFormData);
          } else {
            setTransactionLoaderState(false);
            swal("Failed", result.msg, "error");
            closeModal(false);
          }
        } catch (error) {
          console.log(error);
        }
      } else {
        swal("Error", responseData.msg, "error");
      }
    } catch (error) {
      console.error("Error uploading file:", error);
      swal("Error", "Failed to upload file.", "error");
    }
  };
  const handleFileChange = async (e) => {
    setTransactionLoaderState(true);
    const file = e.target.files[0];

    if (!file) {
      swal("Error", "Please select a file for upload.", "warning");
      return;
    }

    if (file.type !== "image/jpeg" && file.type !== "image/png") {
      swal(
        "Error",
        "Unsupported file type. Please upload a JPEG or PNG file.",
        "error"
      );
      return;
    }

    try {
      const byteArray = await convertToByteArray(file);
      const fileData = byteArray;

      const fileType = file.type.split("/")[1];

      const requestBody = {
        userid: "BILL PUNCH",
        image_type: "expense",
        file_type: fileType,
        image_data: fileData,
      };

      const response = await fetch(
        "https://bumppy.in/billpunch/api/v1/imageUpload.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        }
      );

      const responseData = await response.json();

      if (responseData.status === "0") {
        const imageUrl = responseData.image_url;
        setFormData((prevFormData) => ({
          ...prevFormData,
          imageUrl: imageUrl,
        }));
        setSelectedFile(file);

        swal("Success", responseData.msg, "success");
        setTransactionLoaderState(false);
      } else {
        swal("Error", responseData.msg, "error");
        setTransactionLoaderState(false);
      }
    } catch (error) {
      console.error("Error uploading file:", error);
      swal("Error", "Failed to upload file.", "error");
    }
  };

  useEffect(() => {
    fetchExpenseCategories();
  }, []);
  useEffect(() => {
    if (!isOpen) {
      setFormData(initialFormData);
      setErrors(initialErrors);
    }
  }, [isOpen]);
  const fetchproject = async () => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        empid: user.userid,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/GetProjectByEmployee
          `,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        setProject(data.data);
      }
    } catch (error) {
      console.log("error", error);
    }
    setTransactionLoaderState(false);
  };
  useEffect(() => {
    fetchproject();
  }, []);
  const [showaddprojectModal, setshowaddprojectModal] = useState(false);
  const openaddprojectModal = () => {
    setshowaddprojectModal(true);
  };

  const closeaddprojectModal = () => {
    setshowaddprojectModal(false);
  };
  return (
    <>
      <Expensecategoryemployeemodal
        showModal={addcategorymodal}
        closeModal={closeExpensecalModal}
        fetchExpenseCategories={fetchExpenseCategories}
      />
      <AddProjectemployeeModal
        show={showaddprojectModal}
        closeModal={closeaddprojectModal}
        getProjects={fetchproject}
      />
      <Modal show={isOpen} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>Add Expense Manually</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form className="mx-md-3 mx-sm-2">
            <div className="row m-0 p-1">
              {" "}
              <Form.Group className="col">
                <Form.Label> Select Project</Form.Label>
                <select
                  name="project"
                  className="form-control"
                  value={formData.project}
                  onChange={handleInputChange}
                >
                  <option value="">Select a Project</option>
                  <option value="createProject">+ Create Project</option>
                  {Project.map((project) => (
                    <option
                      key={project.pk_project_id}
                      value={project.pk_project_id}
                    >
                      {project.project_name}
                    </option>
                  ))}
                </select>

                {errors.project && (
                  <p className="text-danger">{errors.project}</p>
                )}
              </Form.Group>
            </div>
            <div className="row m-0 p-1">
              <Form.Group className="col">
                <Form.Label>Expense Name</Form.Label>
                <Form.Control
                  type="text"
                  name="Expensename"
                  value={formData.Expensename}
                  onChange={handleInputChange}
                  placeholder="Expense Name"
                />
                {errors.Expensename && (
                  <p className="text-danger">{errors.Expensename}</p>
                )}
              </Form.Group>{" "}
              <Form.Group className="col">
                <Form.Label>Expense Type</Form.Label>
                <Form.Control
                  as="select"
                  name="category"
                  value={formData.category}
                  onChange={(e) => {
                    handleInputChange(e);
                    if (e.target.value === "Add Category") {
                      openExpensecatModal();
                    }
                  }}
                >
                  <option value="">Category</option>
                  <hr />
                  <option>Add Category</option> <hr />
                  {isLoading ? (
                    <option>Loading...</option>
                  ) : (
                    expenseCategories.map((category) => (
                      <option
                        key={category.pk_expense_category_id}
                        value={category.category_name}
                      >
                        {category.category_name}
                      </option>
                    ))
                  )}
                </Form.Control>

                {errors.category && (
                  <p className="text-danger">{errors.category}</p>
                )}
              </Form.Group>
            </div>
            <div className="row m-0 p-1 ">
              <Form.Group className="col-6">
                <Form.Label> Amount:</Form.Label>
                <Form.Control
                  type="text"
                  name="Amount"
                  value={formData.Amount}
                  onChange={handleInputChange}
                  placeholder="Amount"
                />
                {errors.Amount && (
                  <p className="text-danger">{errors.Amount}</p>
                )}
              </Form.Group>
              <Form.Group className="col ">
                <Form.Label>Expense Date:</Form.Label>
                <Form.Control
                  type="date"
                  name="expenseDate"
                  value={formData.expenseDate}
                  onChange={handleInputChange}
                  placeholder=""
                />
                {errors.expenseDate && (
                  <p className="text-danger">{errors.expenseDate}</p>
                )}
              </Form.Group>
            </div>

            <div className="row m-0 p-1 ">
              <Form.Group className="col">
                <Form.Label>Description</Form.Label>
                <textarea
                  className="form-control"
                  type="textarea"
                  name="description"
                  value={formData.description}
                  onChange={handleInputChange}
                  placeholder="Description"
                />
                {errors.description && (
                  <p className="text-danger">{errors.description}</p>
                )}
              </Form.Group>
            </div>
            <div className="row m-0 p-1 ">
              <Form.Group className="col-8">
                <Form.Label>File Upload (PDF or JPEG)</Form.Label>
                <Form.Control
                  type="file"
                  accept=".pdf, .jpeg, .jpg"
                  onChange={handleFileChange}
                />
              </Form.Group>
            </div>

            <div className="row m-0 p-1 ">
              <div className="col-3">
                <button
                  className="btn btn-success d-flex align-items-center"
                  type="button"
                  style={{
                    height: "2rem",
                    borderRadius: ".3rem",
                    fontSize: ".8rem",
                  }}
                  onClick={validate}
                >
                  Submit
                </button>
              </div>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};
export const FetchBillModalEmployee = ({
  setFetchBillModal,
  fetchBillModal,
  payBill,
  billDetails,
  billerResponse,
  callback,
  billPayResponse,
  setAmount,
  amount,
  setTpin,
  Tpin,
  config,
}) => {
  const { password, username } = billpunchbasicauth;

  const [tpinShow, setTpinShow] = useState(false);
  const [PayClicked, setPayClicked] = useState(false);
  const openInNewTab = (data) => {
    localStorage.setItem("print", JSON.stringify(data));
    const CLIENT = "";
    const newWindow = window.open(
      `${CLIENT}/pages/bbps/print`,
      "",
      "height=600,width=800'"
    );
    if (newWindow) newWindow.opener = null;
  };

  const showTPinClick = () => {
    setTpinShow(!tpinShow);
  };
  return (
    <Modal show={fetchBillModal} onHide={setFetchBillModal}>
      <Modal.Header closeButton>
        <Modal.Title>Fetch Bill</Modal.Title>
      </Modal.Header>

      <Modal.Body
        style={{ width: "100%", display: "block" }}
        className="account m-0 p-0"
      >
        {billDetails && !billPayResponse && (
          <Card style={{ margin: "10px", padding: "10px" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <img
                src={require("../../../assets/images/BBPSSidebarLogo.png")}
                style={{
                  width: "auto",
                  height: "1.2rem",
                  backgroundColor: "whitesmoke",
                }}
              />
              <img
                onClick={() => {
                  openInNewTab(billDetails);
                }}
                style={{ height: "1.5rem" }}
                src={require("../../../assets/icons/print_1.png")}
              />
            </div>
            <table style={{ border: "none", fontFamily: "-webkit-body" }}>
              <tr style={{ background: "none", border: "none" }}>
                <td
                  style={{
                    paddingLeft: "1rem",
                    border: "none",
                    background: "none",
                    width: "50%",
                    fontSize: "0.8rem",
                  }}
                >
                  {" "}
                  Name
                </td>
                <td
                  style={{
                    paddingLeft: "1rem",
                    border: "none",
                    background: "none",
                    width: "50%",
                    fontSize: "0.8rem",
                  }}
                >
                  {" "}
                  {billDetails.billerResponse.customerName}
                </td>
              </tr>
              <tr style={{ background: "none" }}>
                <td
                  style={{
                    paddingLeft: "1rem",
                    border: "none",
                    background: "none",
                    width: "50%",
                    fontSize: "0.8rem",
                  }}
                >
                  {" "}
                  Bill Number
                </td>
                <td
                  style={{
                    paddingLeft: "1rem",
                    border: "none",
                    background: "none",
                    width: "50%",
                    fontSize: "0.8rem",
                  }}
                >
                  {" "}
                  {billDetails.billerResponse.billNumber}
                </td>
              </tr>
              <tr style={{ background: "none" }}>
                <td
                  style={{
                    paddingLeft: "1rem",
                    border: "none",
                    background: "none",
                    width: "50%",
                    fontSize: "0.8rem",
                  }}
                >
                  {" "}
                  Amount
                </td>
                <td
                  style={{
                    paddingLeft: "1rem",
                    border: "none",
                    background: "none",
                    width: "50%",
                    fontSize: "0.8rem",
                  }}
                >
                  {" "}
                  {amount}
                </td>
              </tr>

              {/* Additional Info */}
              {billDetails.additionalInfo &&
                billDetails.additionalInfo.length > 0 &&
                billDetails.additionalInfo.map((info) => (
                  <tr style={{ background: "none" }}>
                    <td
                      style={{
                        paddingLeft: "1rem",
                        border: "none",
                        background: "none",
                        width: "50%",
                        fontSize: "0.8rem",
                      }}
                    >
                      {info.name}
                    </td>
                    <td
                      style={{
                        paddingLeft: "1rem",
                        border: "none",
                        background: "none",
                        width: "50%",
                        fontSize: "0.8rem",
                      }}
                    >
                      {info.value}
                    </td>
                  </tr>
                ))}

              <tr style={{ background: "none" }}>
                <td
                  style={{
                    paddingLeft: "1rem",
                    border: "none",
                    background: "none",
                    width: "50%",
                    fontSize: "0.8rem",
                  }}
                >
                  {" "}
                  Bill date
                </td>
                <td
                  style={{
                    paddingLeft: "1rem",
                    border: "none",
                    background: "none",
                    width: "50%",
                    fontSize: "0.8rem",
                  }}
                >
                  {" "}
                  {billDetails.billerResponse.billDate}
                </td>
              </tr>

              <tr style={{ background: "none" }}>
                <td
                  style={{
                    paddingLeft: "1rem",
                    border: "none",
                    background: "none",
                    width: "50%",
                    fontSize: "0.8rem",
                  }}
                >
                  {" "}
                  Due Date.
                </td>
                <td
                  style={{
                    paddingLeft: "1rem",
                    border: "none",
                    background: "none",
                    width: "50%",
                    fontSize: "0.8rem",
                  }}
                >
                  {" "}
                  {billDetails.billerResponse.dueDate}
                </td>
              </tr>
              <tr style={{ background: "none" }}>
                <td
                  style={{
                    paddingLeft: "1rem",
                    border: "none",
                    background: "none",
                    width: "50%",
                    fontSize: "0.8rem",
                  }}
                >
                  {" "}
                  Reference Id
                </td>
                <td
                  style={{
                    paddingLeft: "1rem",
                    border: "none",
                    background: "none",
                    width: "50%",
                    fontSize: "0.8rem",
                  }}
                >
                  {" "}
                  {billDetails.refId}
                </td>
              </tr>

              {config.amountEditable && (
                <tr style={{ background: "none" }}>
                  <td
                    style={{
                      paddingLeft: "1rem",
                      border: "none",
                      background: "none",
                      width: "50%",
                      fontSize: "0.8rem",
                    }}
                  >
                    {" "}
                    Amount
                  </td>
                  <td
                    style={{
                      paddingLeft: "1rem",
                      border: "none",
                      background: "none",
                      width: "50%",
                      fontSize: "0.8rem",
                    }}
                  >
                    <Form.Control
                      type="number"
                      className="form-control rounded-0"
                      aria-label="Notes"
                      aria-describedby="basic-addon1"
                      placeholder={`Enter Amount`}
                      onChange={(e) => {
                        setAmount(e.target.value);
                      }}
                      value={amount}
                    />
                  </td>
                </tr>
              )}

              <tr style={{ background: "none" }}>
                <td
                  style={{
                    paddingLeft: "1rem",
                    border: "none",
                    background: "none",
                    width: "50%",
                    fontSize: "0.8rem",
                  }}
                >
                  {" "}
                  T Pin
                </td>
                <td
                  style={{
                    paddingLeft: "1rem",
                    border: "none",
                    background: "none",
                    width: "50%",
                    fontSize: "0.8rem",
                  }}
                >
                  <div className="input-group">
                    <div className="input-group-prepend bg-transparent">
                      <span className="input-group-text bg-transparent border-right-0 no-glow">
                        <i className="mdi mdi-lock-outline text-primary"></i>
                      </span>
                    </div>
                    <input
                      autocomplete="new-password"
                      autoCorrect="off"
                      autoSave="new-password"
                      type={tpinShow ? "text" : "password"}
                      className="form-control form-control-lg border-left-0 border-right-0 no-glow"
                      id="exampleInputPassword"
                      placeholder={`Enter T Pin`}
                      onChange={(e) => {
                        setTpin(e.target.value);
                      }}
                      value={Tpin}
                    />
                    <div className="input-group-prepend bg-transparent">
                      <span
                        className="input-group-text bg-transparent border-left-0 no-glow"
                        onClick={showTPinClick}
                        style={{ borderRadius: "4px" }}
                      >
                        <i
                          className={`mdi text-primary ${
                            tpinShow ? "mdi-eye" : "mdi-eye-off"
                          }`}
                        ></i>
                      </span>
                    </div>
                  </div>
                </td>
              </tr>
            </table>
            <div
              style={{
                padding: "10px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              {PayClicked ? (
                <Button className="rounded-0" variant="success">
                  {" "}
                  Paying ...
                </Button>
              ) : (
                <Button
                  className="rounded-0"
                  variant="success"
                  onClick={callback}
                >
                  {" "}
                  Pay Bill
                </Button>
              )}
            </div>
          </Card>
        )}
        {billDetails && billPayResponse && (
          <Card style={{ margin: "10px", padding: "10px" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <img
                src={require("../../../assets/images/BBPSSidebarLogo.png")}
                style={{
                  width: "auto",
                  height: "1.2rem",
                  backgroundColor: "whitesmoke",
                }}
              />
              <img
                onClick={() => {
                  openInNewTab(billDetails);
                }}
                style={{ height: "1.5rem" }}
                src={require("../../../assets/icons/print_1.png")}
              />
            </div>
            <table style={{ border: "none", fontFamily: "-webkit-body" }}>
              <tr style={{ background: "none", border: "none" }}>
                <td
                  style={{
                    paddingLeft: "1rem",
                    border: "none",
                    background: "none",
                    width: "50%",
                    fontSize: "0.8rem",
                  }}
                >
                  {" "}
                  Name
                </td>
                <td
                  style={{
                    paddingLeft: "1rem",
                    border: "none",
                    background: "none",
                    width: "50%",
                    fontSize: "0.8rem",
                  }}
                >
                  {" "}
                  {billDetails.billerResponse.customerName}
                </td>
              </tr>

              {billDetails.billerResponse.billNumber && (
                <tr style={{ background: "none" }}>
                  <td
                    style={{
                      paddingLeft: "1rem",
                      border: "none",
                      background: "none",
                      width: "50%",
                      fontSize: "0.8rem",
                    }}
                  >
                    {" "}
                    Bill Number
                  </td>
                  <td
                    style={{
                      paddingLeft: "1rem",
                      border: "none",
                      background: "none",
                      width: "50%",
                      fontSize: "0.8rem",
                    }}
                  >
                    {" "}
                    {billDetails.billerResponse.billNumber}
                  </td>
                </tr>
              )}

              <tr style={{ background: "none" }}>
                <td
                  style={{
                    paddingLeft: "1rem",
                    border: "none",
                    background: "none",
                    width: "50%",
                    fontSize: "0.8rem",
                  }}
                >
                  {" "}
                  Amount
                </td>
                <td
                  style={{
                    paddingLeft: "1rem",
                    border: "none",
                    background: "none",
                    width: "50%",
                    fontSize: "0.8rem",
                  }}
                >
                  {" "}
                  {amount}
                </td>
              </tr>

              <tr style={{ background: "none" }}>
                <td
                  style={{
                    paddingLeft: "1rem",
                    border: "none",
                    background: "none",
                    width: "50%",
                    fontSize: "0.8rem",
                  }}
                >
                  {" "}
                  Reference Id
                </td>
                <td
                  style={{
                    paddingLeft: "1rem",
                    border: "none",
                    background: "none",
                    width: "50%",
                    fontSize: "0.8rem",
                  }}
                >
                  {" "}
                  {billPayResponse.refId}
                </td>
              </tr>
              <tr style={{ background: "none" }}>
                <td
                  style={{
                    paddingLeft: "1rem",
                    border: "none",
                    background: "none",
                    width: "50%",
                    fontSize: "0.8rem",
                  }}
                >
                  Transaction Reference Id
                </td>
                <td
                  style={{
                    paddingLeft: "1rem",
                    border: "none",
                    background: "none",
                    width: "50%",
                    fontSize: "0.8rem",
                  }}
                >
                  {" "}
                  {billPayResponse.txnReferenceId}
                </td>
              </tr>

              <tr style={{ background: "none" }}>
                <td
                  style={{
                    paddingLeft: "1rem",
                    border: "none",
                    background: "none",
                    width: "50%",
                    fontSize: "0.8rem",
                  }}
                >
                  {" "}
                  Status
                </td>
                <td
                  style={{
                    paddingLeft: "1rem",
                    border: "none",
                    background: "none",
                    width: "50%",
                    fontSize: "0.8rem",
                  }}
                >
                  <span className="badge badge-pill badge-success p-3">
                    {billPayResponse.responseReason}
                  </span>
                </td>
              </tr>
            </table>
          </Card>
        )}
      </Modal.Body>
    </Modal>
  );
};
export const AddBillFormEmployee = ({
  showModal,
  handleCloseModal,
  getAllPropertyBills,
}) => {
  const { password, username } = billpunchbasicauth;

  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const servicesOption = [
    {
      value: 1,
      label: "Broadband Postpaid",
    },
    {
      value: 2,
      label: "Cable TV",
    },
    {
      value: 3,
      label: "DTH",
    },
    {
      value: 4,
      label: "Education Fees",
    },
    {
      value: 5,
      label: "Electricity",
    },
    {
      value: 6,
      label: "Fastag",
    },
    {
      value: 7,
      label: "Gas",
    },
    {
      value: 8,
      label: "Health Insurance",
    },
    {
      value: 9,
      label: "Hospital",
    },
    {
      value: 10,
      label: "Housing Society",
    },
    {
      value: 11,
      label: "Insurance",
    },
    {
      value: 12,
      label: "Landline Postpaid",
    },
    {
      value: 13,
      label: "Life Insurance",
    },
    {
      value: 14,
      label: "Loan Repayment",
    },
    {
      value: 15,
      label: "LPG Gas",
    },
    {
      value: 16,
      label: "Mobile Postpaid",
    },
    {
      value: 17,
      label: "Municipal Services",
    },
    {
      value: 18,
      label: "Municipal Taxes",
    },
    {
      value: 19,
      label: "Subscription",
    },
    {
      value: 20,
      label: "Water",
    },
    {
      value: 21,
      label: "Clubs and Associations",
    },
    {
      value: 22,
      label: "Credit Card",
    },
    {
      value: 23,
      label: "Hospital and Pathology",
    },
    {
      value: 24,
      label: "Mobile Prepaid",
    },
    {
      value: 25,
      label: "Recurring Deposit",
    },
    {
      value: 26,
      label: "Travel Agency",
    },
  ];

  console.log("Service options:", servicesOption);

  const [service, setService] = useState("1");

  console.log("Service state:", service);
  const [CustP, setCustP] = useState([]);

  console.log("CustP state:", CustP);

  const [providers, setProviders] = useState([]);
  console.log("Providers state:", providers);

  const [formData, setFormData] = useState({
    Service: "1",
    Provider: "",
    ConsumerId: "",
    Mobile: "",
    Amount: "",
  });
  console.log("FormData state:", formData);

  const [billerResponse, setBillerResponse] = useState({
    msg: "",
    success: "",
    error: [],
  });
  console.log("BillerResponse state:", billerResponse);

  const [state, setState] = useState({
    provider: "",
    consumer: "",
    mobileNumber: "",
    custparams: "",
  });
  console.log("State state:", state);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    if (name === "Service") {
      setService(value);
    }

    console.log("Form data after change:", formData);
  };
  const { Profileinfo } = useContext(GlobalContext);
  console.log("User from context:", user);
  console.log("Profileinfo from context:", Profileinfo);

  const handleSubmit = async (e) => {
    console.log("Submit button clicked");
    setTransactionLoaderState(true);
    try {
      console.log("hellp");
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");
      var raw = JSON.stringify({
        firmid: user.firm_id,
        service: formData.Service.label,
        provider: state.provider.value,
        consumerid: CustP[0].value,
        paramsList: JSON.stringify(CustP),
        refno: "string",
        mobile: Profileinfo?.mobile,
        month: new Date().toISOString(),
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/addfirmBill`,
        requestOptions
      );

      const data = await res.json();
      console.log("nishant", data);
      if (data.status === "1") {
        swal("Error", data.msg, "error");
        handleCloseModal();
        getAllPropertyBills();
      } else {
        sessionStorage.setItem("params", JSON.stringify(CustP));
        swal("Success", data.msg, "success");
        handleCloseModal();
        getAllPropertyBills();
      }
      setTransactionLoaderState(false);
      console.log("Form submission completed.");
    } catch (error) {
      console.log("Error:", error);
    }
    setTransactionLoaderState(false);
  };

  const fetchAllProviders = async () => {
    console.log("Fetching providers started");
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic IHJham5lZXNoQGV4YW1wbGUuY29tIDogVGVzdEAxMjM0NQ=="
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        userName: "string",
        categoryId: formData.Service.value,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        "https://apitranxt.paynnow.com/api/Operator/GetAllOperators",
        requestOptions
      );
      const data = await res.json();
      if (data.responseCode === "00") {
        const modifiedProviders = data.data.map((e) => {
          return { ...e, label: e.billerName, value: e.billerId };
        });
        console.log("Fetched providers data:", data.data);
        console.log("Modified providers:", modifiedProviders);

        setProviders(modifiedProviders);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("Error fetching providers:", error);
    }
    setTransactionLoaderState(false);
  };
  console.log("Providers:", providers);

  console.log(providers);

  const fetchAllProviders2 = async () => {
    setTransactionLoaderState(true);
    var myHeaders = new Headers();

    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Cookie",
      ".AspNetCore.Identity.Application=CfDJ8PA2Dduz6XVGpbQn-N2Kqq5nxn_jDhpPRlBHLmNVLCibYXbTK71aALOCGXbw4fHmGhYSemNPyrCo-W6rHg7K58J-En6cgJpZ_RlVeTHZjRO-FBKIs4zFAbZbeYrgCNKW34Wbm5lDeVV2H497JoGEWo0O4ar5aLanS1NTFH0LmOimscy41s2tPRyPfVtXiPSkgXialxacSHvLEna7HTGsDYgNPejg9kViVhahKHVV_dmsspRhxuUQ8yLOGeamszLCs_o-lMC9Lj3AHZaIyu-vfV4cZpESP9oj4s69Wl0Yc85c6o_NTuttKXDgIJrem_5W1KRitMel6aSEdsmqpEiuNho56pF8iIOkxL6bb9S6CjYK-dhGj14Q8ZZDzCDnxdHOTIU3iCx0i_qitJLvxhH5kSo9kigZlsxkVspvOtSp-NMDLPHeM3pOeeY5rY5pjLlNp2IUT9fKiJR1IRxvOHf4O93Gho2vl6J8QpS_5fsI0c2yN5o_UwssJ57vdkWxtUonmx_mDPb0bO6z48lcGvJiyp-aZx77HCulXFRaiyqJAD5mmjhDMf4KGYyjc-cK4j1VwJRLu_6pCN6YdfrY5B6sjmLnWo5rV4kTHNulkdJKAtekwySG6WI0l9wjyEVZNVmdioWaFXJ4p6gjNecN3lHJsEi7n24V6IXJ8zdm1Rz3jztK"
    );

    var raw = JSON.stringify({
      userid: user.ReferId,
      ccategoryId: formData.Service.value,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    try {
      const res = await fetch(
        `${BASE_URL_APISERVICES}/api/Payment/bbps/getAllOnlineOperators`,
        requestOptions
      );
      const raw = await res.text();
      const data = JSON.parse(raw);
      console.log(data);
      if (data.data != null) {
        setProviders(
          data.data.map((pro) => {
            return { ...pro, value: pro.billerName, label: pro.billerName };
          })
        );
      }
      setTransactionLoaderState(false);
    } catch (error) {
      setTransactionLoaderState(false);
      console.log("error", error);
    }
  };

  useEffect(() => {
    fetchAllProviders();
  }, [formData.Service]);

  useEffect(() => {
    if (state.provider) {
      setCustP(
        state.provider.customerParams.map((param, i) => {
          return { ...param, id: i, value: "" };
        })
      );
    }

    console.log("CustP after state.provider change:", CustP);
  }, [state]);

  console.log("CustP after useEffect:", CustP);

  console.log("CustP JSON:", JSON.stringify(CustP));

  return (
    <>
      <div>
        <Modal show={showModal} onHide={handleCloseModal}>
          <Modal.Header closeButton>
            <Modal.Title className="mx-md-3">Add Bill</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form className="m-md-2 ">
              <div className="row">
                <Form.Group className="col-6">
                  <Form.Label style={{ color: "black" }}> Services</Form.Label>
                  <Select
                    options={servicesOption}
                    value={formData.Service}
                    onChange={(e) => {
                      setFormData({ ...formData, Service: e });
                    }}
                  ></Select>
                </Form.Group>

                <Form.Group className="col-6">
                  <Form.Label style={{ color: "black" }}>Provider</Form.Label>
                  <Select
                    options={providers}
                    value={state.provider}
                    onChange={(e) => {
                      setState({
                        ...state,
                        provider: e,
                      });
                      setFormData({
                        ...formData,
                        Provider: e,
                      });
                    }}
                  />
                </Form.Group>
              </div>
              <div className="row">
                {CustP.map((cust, i) => (
                  <Form.Group key={cust.id} className="col-6 px-md-3 m-0 px-0">
                    {cust.optional == "true" ? (
                      <Form.Label>{`${cust.paramName} (optional)`}</Form.Label>
                    ) : (
                      <Form.Label>
                        <span style={{ color: "red" }}>*</span>
                        {` ${cust.paramName}`}
                      </Form.Label>
                    )}
                    <Form.Control
                      type="text"
                      className="form-control rounded-0"
                      aria-label="Notes"
                      aria-describedby="basic-addon1"
                      placeholder={`Enter ${cust.paramName}`}
                      onChange={(e) => {
                        setBillerResponse({
                          ...billerResponse,
                          error: [],
                          msg: "",
                        });
                        const copy = [...CustP];
                        copy.forEach((ele) => {
                          if (ele.id == cust.id) {
                            ele.value = e.target.value;
                          }
                        });
                        setCustP(copy);
                      }}
                      value={cust.value}
                    />
                  </Form.Group>
                ))}
              </div>
              <div className="row">
                <Form.Group className="col-6">
                  <Form.Label style={{ color: "black" }}>Amount</Form.Label>
                  <input
                    type="text"
                    className="form-control "
                    id="Amount"
                    name="Amount"
                    value={formData.Amount}
                    onChange={handleChange}
                    placeholder="Enter Amount"
                  />
                </Form.Group>
                <div className="col-4  pt-md-3">
                  <button
                    // type="submit"
                    type="button"
                    className="btn btn-primary btn-block d-flex justify-content-center align-items-center"
                    style={{ height: "2.5rem", borderRadius: ".3rem" }}
                    onClick={handleSubmit}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </form>{" "}
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};
export const AddTaskemployeeModal = ({ show, closeModal, getTask }) => {
  const [Member, setMember] = useState([]);
  const [Employeeid, setEmployeeid] = useState(-1);
  const { password, username } = billpunchbasicauth;

  const [selectedEmployee, setselectedEmployee] = useState("");
  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const [projectfield, setProjectfields] = useState({
    Taskname: "",
    Taskassign: "",
    Taskdesc: "",
    Taskstrt: "",
    Taskend: "",
    TaskPrio: "",
  });
  const [projecterror, setProjecterror] = useState({
    Taskname: "",
    Taskassign: "",
    Taskdesc: "",
    Taskstrt: "",
    Taskend: "",
  });

  const validate = (e) => {
    e.preventDefault();
    let errors = {};
    if (projectfield.Taskname.trim() == "") {
      errors.Taskname = "*Please enter Task Name";
    }

    if (projectfield.Taskassign.trim() == "") {
      errors.Taskassign = "*Please asign employee";
    }

    if (projectfield.Taskdesc.trim() == "") {
      errors.Taskdesc = "*Please enter task Description";
    }
    if (projectfield.Taskstrt.trim() == "") {
      errors.Taskstrt = "*Please enter start date";
    }
    if (projectfield.Taskend.trim() == "") {
      errors.Taskend = "*Please enter end date";
    }
    setProjecterror(errors);
    if (Object.keys(errors).length == 0) {
      handleSubmit();
    }
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setProjectfields((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };
  const handleEmployeeSelection = (selectedOption) => {
    setselectedEmployee(selectedOption);
    setEmployeeid(selectedOption ? selectedOption.value : -1);
    setProjectfields((prevFormData) => ({
      ...prevFormData,
      Taskassign: selectedOption ? selectedOption.label : "",
      task_empid: selectedOption ? selectedOption.value : "string",
    }));
  };

  const GetFirmEmployee = async (e) => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        firmId: user.firm_id,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/getEmployee`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        const mappedEmployees = data.data.map((employee) => ({
          value: employee.empid,
          label: `${employee.firstname} ${employee.lastname}`,
        }));
        setMember(mappedEmployees);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
    }
    setTransactionLoaderState(false);
  };
  useEffect(() => {
    GetFirmEmployee();
  }, []);
  const options = [
    {
      value: "Urgent",
      label: (
        <div>
          <FaFlag style={{ marginRight: "5px", color: "maroon" }} />
          Urgent
        </div>
      ),
    },
    {
      value: "High",
      label: (
        <div>
          <FaFlag style={{ marginRight: "5px", color: "#f4c430" }} />
          High
        </div>
      ),
    },
    {
      value: "Normal",
      label: (
        <div>
          <FaFlag style={{ marginRight: "5px", color: "blue" }} />
          Normal
        </div>
      ),
    },
    {
      value: "Low",
      label: (
        <div>
          <FaFlag style={{ marginRight: "5px", color: "grey" }} />
          Low
        </div>
      ),
    },
    {
      value: "Clear",
      label: (
        <div>
          {" "}
          <img
            src={require("../../../assets/images/flag_1.png")}
            style={{ marginRight: "5px", width: ".8rem" }}
          />
          Clear
        </div>
      ),
    },
  ];
  const propertyobject = JSON.parse(sessionStorage.getItem("property"));
  const handleSubmit = async (e) => {
    console.log("abhi", handleSubmit);
    try {
      setTransactionLoaderState(true);

      const selectedEmployee = Member.find(
        (employee) => employee.value === Employeeid
      );

      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");
      var raw = JSON.stringify({
        spaceid: propertyobject.pk_work_id,
        taskname: projectfield.Taskname,
        task_assignee: selectedEmployee.label,
        task_empid: Employeeid,
        task_desc: projectfield.Taskdesc,
        task_startdate: projectfield.Taskstrt,
        task_enddate: projectfield.Taskend,
        task_status: "P",
        task_extra: "string",
        task_priority: projectfield.TaskPrio,
      });

      console.log("Request sending:", raw);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
      console.log("sending data to abhijeet");
      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/AddTasks`,
        requestOptions
      );
      console.log("Api url");
      const data = await res.json();
      console.log("nishant", data);
      if (data.status === "1") {
        swal("Error", data.result, "error");
        setTransactionLoaderState(false);
        getTask();
        closeModal();
      } else {
        swal("Success", data.result, "success");
        setTransactionLoaderState(false);
        getTask();
        closeModal();
      }
      console.log("Form submission completed.");
    } catch (error) {
      console.log("Error:", error);
    }
  };
  const today = new Date().toISOString().split("T")[0];
  return (
    <>
      <Modal show={show} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title className="">Add Task</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={validate}>
            <div className="row">
              {" "}
              <Form.Group className="col-6 m-0">
                <Form.Label> Task Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Task Name"
                  id="Taskname"
                  name="Taskname"
                  value={projectfield.Taskname}
                  onChange={handleChange}
                />
                {projecterror.Taskname && (
                  <p className="text-danger">{projecterror.Taskname}</p>
                )}
              </Form.Group>
              <Form.Group className="col-6 m-0">
                <Form.Label> Name</Form.Label>
                <Select
                  options={Member}
                  value={selectedEmployee}
                  onChange={handleEmployeeSelection}
                  placeholder="Select Name"
                />
                {projecterror.Taskassign && (
                  <p className="text-danger">{projecterror.Taskassign}</p>
                )}
              </Form.Group>
            </div>
            <div className="row">
              {" "}
              <Form.Group className="col-6 m-0">
                <Form.Label> Start Date</Form.Label>
                <Form.Control
                  type="date"
                  placeholder="Start Date"
                  id="Taskstrt"
                  name="Taskstrt"
                  value={projectfield.Taskstrt}
                  onChange={handleChange}
                />
                {projecterror.Taskstrt && (
                  <p className="text-danger">{projecterror.Taskstrt}</p>
                )}
              </Form.Group>
              <Form.Group className="col-6 m-0">
                <Form.Label> End Date</Form.Label>
                <Form.Control
                  type="date"
                  placeholder="End Date"
                  id="Taskend"
                  name="Taskend"
                  value={projectfield.Taskend}
                  onChange={handleChange}
                  min={today}
                />
                {projecterror.Taskend && (
                  <p className="text-danger">{projecterror.Taskend}</p>
                )}
              </Form.Group>
            </div>
            <div className="row">
              <Form.Group className="col">
                <Form.Label>Priority</Form.Label>
                <Select
                  options={options}
                  onChange={(option) =>
                    setProjectfields((prevFormData) => ({
                      ...prevFormData,
                      TaskPrio: option.value, // Update TaskPrio here
                    }))
                  }
                  placeholder="Select Priority"
                />
              </Form.Group>
            </div>
            <div className="row m-0 p-0">
              <Form.Group className="col">
                <Form.Label>Description</Form.Label>
                <textarea
                  className="form-control"
                  type="textarea"
                  name="Taskdesc"
                  value={projectfield.Taskdesc}
                  onChange={handleChange}
                  placeholder="Project Description"
                />
                {projecterror.Taskdesc && (
                  <p className="text-danger">{projecterror.Taskdesc}</p>
                )}
              </Form.Group>
            </div>

            <div className="col-4 ">
              <button
                // type="submit"
                type="button"
                className="btn btn-primary btn-block d-flex justify-content-center align-items-center"
                style={{ height: "2rem", borderRadius: ".3rem" }}
                onClick={handleSubmit}
              >
                Add Task
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export const AddsubTaskemployeeModal = ({
  show,
  closeModal,
  getSubsubTask,
  setshowaddsubtaskModal,
}) => {
  const [Member, setMember] = useState([]);
  const [Category, setCategory] = useState([]);
  const [selectedplaformid, setSelectedplaformid] = useState([]);
  const [selectedCategoryId, setSelectedCategoryId] = useState([]);
  const { password, username } = billpunchbasicauth;

  const [Platform, setPlatform] = useState([]);
  const [Employeeid, setEmployeeid] = useState(-1);
  const [selectedEmployee, setselectedEmployee] = useState("");
  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const [projectfield, setProjectfields] = useState({
    Taskname: "",
    Taskassign: "",
    Taskdesc: "",
    Taskstrt: "",
    Taskend: "",
    TaskPrio: "",
  });
  const [projecterror, setProjecterror] = useState({
    Taskname: "",
    Taskassign: "",
    Taskdesc: "",
    Taskstrt: "",
    Taskend: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProjectfields((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };
  const handleEmployeeSelection = (selectedOption) => {
    setselectedEmployee(selectedOption);
    setEmployeeid(selectedOption ? selectedOption.value : -1);
    setProjectfields((prevFormData) => ({
      ...prevFormData,
      Taskassign: selectedOption ? selectedOption.label : "",
      task_empid: selectedOption ? selectedOption.value : "string",
    }));
  };
  const WorkDashID = JSON.parse(sessionStorage.getItem("property"));

  const GetFirmEmployee = async (e) => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        workid: WorkDashID.pk_work_id,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/GetWorkEmployee`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        const mappedEmployees = data.data.map((employee) => ({
          value: employee.empid,
          label: employee.fullname,
        }));
        setMember(mappedEmployees);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
    }
    setTransactionLoaderState(false);
  };
  const Getcatogory = async () => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({
          taskid: "string",
        }),
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/GetCatTask`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        const categories = data.data.map((cat) => ({
          value: cat.pk_catid,
          label: cat.catname,
        }));
        setCategory(categories);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.error("error", error);
      setTransactionLoaderState(false);
    }
  };
  const Getplatform = async () => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({
          taskid: "string",
        }),
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/GetTaskPlatform`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        const platformdata = data.data.map((device) => ({
          value: device.pk_platformid,
          label: device.platform_name,
        }));
        setPlatform(platformdata);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.error("error", error);
      setTransactionLoaderState(false);
    }
  };
  const handleCategorySelection = (selectedOption) => {
    setSelectedCategoryId(selectedOption ? selectedOption.value : "");
  };
  const handlePlatformSelection = (selectedOption) => {
    setSelectedplaformid(selectedOption ? selectedOption.value : "");
  };
  useEffect(() => {
    GetFirmEmployee();
    Getcatogory();
    Getplatform();
  }, []);

  const options = [
    {
      value: "Urgent",
      label: (
        <div>
          <FaFlag style={{ marginRight: "5px", color: "maroon" }} />
          Urgent
        </div>
      ),
    },
    {
      value: "High",
      label: (
        <div>
          <FaFlag style={{ marginRight: "5px", color: "#f4c430" }} />
          High
        </div>
      ),
    },
    {
      value: "Normal",
      label: (
        <div>
          <FaFlag style={{ marginRight: "5px", color: "blue" }} />
          Normal
        </div>
      ),
    },
    {
      value: "Low",
      label: (
        <div>
          <FaFlag style={{ marginRight: "5px", color: "grey" }} />
          Low
        </div>
      ),
    },
    {
      value: "Clear",
      label: (
        <div>
          {" "}
          <img
            src={require("../../../assets/images/flag_1.png")}
            style={{ marginRight: "5px", width: ".8rem" }}
          />
          Clear
        </div>
      ),
    },
  ];
  useEffect(() => {
    if (!show) {
      resetFormFields();
    }
  }, [show]);

  const resetFormFields = () => {
    setProjectfields({
      Taskname: "",
      Taskassign: "",
      Taskdesc: "",
      Taskstrt: "",
      Taskend: "",
      TaskPrio: "",
    });
    setProjecterror({
      Taskname: "",
      Taskassign: "",
      Taskdesc: "",
      Taskstrt: "",
      Taskend: "",
    });
    setselectedEmployee("");
    setEmployeeid(-1);
    setSelectedplaformid([]);
    setSelectedCategoryId([]);
  };
  const taskdetails = JSON.parse(sessionStorage.getItem("taskdetails"));
  const handleSubmit = async (e) => {
    console.log(handleSubmit, "selectedEmployee");

    e.preventDefault();
    try {
      setTransactionLoaderState(true);

      const selectedEmployee = Member.find(
        (employee) => employee.value === Employeeid
      );
      console.log(selectedEmployee, "selectedEmployee");
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");
      var raw = JSON.stringify({
        fk_taskid: taskdetails.pk_taskid,
        sub_name: projectfield.Taskname,
        sub_empname: selectedEmployee.label,
        sub_empid: Employeeid,
        subtask_desc: projectfield.Taskdesc,
        sub_startdate: projectfield.Taskstrt,
        sub_enddate: projectfield.Taskend,
        status: "P",
        extra: "string",
        priority: projectfield.TaskPrio,
        platform_id: selectedplaformid,
        category_id: selectedCategoryId,
      });

      console.log("Request sending:", raw);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
      console.log("sending data to abhijeet");
      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/AddSubTasks`,
        requestOptions
      );
      console.log("Api url");
      const data = await res.json();
      console.log("nishant", data);
      if (data.status === "1") {
        swal("Error", data.result, "error");
        setTransactionLoaderState(false);
        // getSubsubTask();
        setshowaddsubtaskModal(false);
      } else {
        swal("Success", data.result, "success");
        setTransactionLoaderState(false);
        // getSubsubTask();
        setshowaddsubtaskModal(false);
        resetFormFields();
      }
      console.log("Form submission completed.");
    } catch (error) {
      console.log("Error:", error);
    }
  };

  return (
    <>
      <Modal show={show} onHide={() => setshowaddsubtaskModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title className="">Add Subtask</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="row">
              {" "}
              <Form.Group className="col-6 m-0">
                <Form.Label> Task Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Task Name"
                  id="Taskname"
                  name="Taskname"
                  value={projectfield.Taskname}
                  onChange={handleChange}
                />
                {projecterror.Taskname && (
                  <p className="text-danger">{projecterror.Taskname}</p>
                )}
              </Form.Group>
              <Form.Group className="col-6 m-0">
                <Form.Label> Name</Form.Label>
                <Select
                  options={Member}
                  value={selectedEmployee}
                  onChange={handleEmployeeSelection}
                  placeholder="Select Name"
                />
                {projecterror.Taskassign && (
                  <p className="text-danger">{projecterror.Taskassign}</p>
                )}
              </Form.Group>
            </div>
            <div className="row">
              {" "}
              <Form.Group className="col-6 m-0">
                <Form.Label> Start Date</Form.Label>
                <Form.Control
                  type="date"
                  placeholder="Start Date"
                  id="Taskstrt"
                  name="Taskstrt"
                  value={projectfield.Taskstrt}
                  onChange={handleChange}
                />
                {projecterror.Taskstrt && (
                  <p className="text-danger">{projecterror.Taskstrt}</p>
                )}
              </Form.Group>
              <Form.Group className="col-6 m-0">
                <Form.Label> End Date</Form.Label>
                <Form.Control
                  type="date"
                  placeholder="End Date"
                  id="Taskend"
                  name="Taskend"
                  value={projectfield.Taskend}
                  onChange={handleChange}
                />
                {projecterror.Taskend && (
                  <p className="text-danger">{projecterror.Taskend}</p>
                )}
              </Form.Group>
            </div>

            <div className="row ">
              <Form.Group className="col">
                <Form.Label>Priority</Form.Label>
                <Select
                  options={options}
                  onChange={(option) =>
                    setProjectfields((prevFormData) => ({
                      ...prevFormData,
                      TaskPrio: option.value,
                    }))
                  }
                  placeholder=" Priority"
                />
              </Form.Group>
              <Form.Group className="col">
                <Form.Label>Category</Form.Label>
                <Select
                  options={Category}
                  onChange={handleCategorySelection}
                  placeholder="Category"
                />
              </Form.Group>
              <Form.Group className="col">
                <Form.Label>Platform</Form.Label>
                <Select
                  onChange={handlePlatformSelection}
                  options={Platform}
                  placeholder=" Platform"
                />
              </Form.Group>
            </div>
            <div className="row m-0 p-0">
              <Form.Group className="col">
                <Form.Label>Description</Form.Label>
                <textarea
                  className="form-control"
                  type="textarea"
                  name="Taskdesc"
                  value={projectfield.Taskdesc}
                  onChange={handleChange}
                  placeholder="Project Description"
                />
                {projecterror.Taskdesc && (
                  <p className="text-danger">{projecterror.Taskdesc}</p>
                )}
              </Form.Group>
            </div>
            <div className="col-4 ">
              <button
                type="submit"
                // type="button"
                className="btn btn-primary btn-block d-flex justify-content-center align-items-center"
                style={{ height: "2rem", borderRadius: ".3rem" }}
                onClick={handleSubmit}
              >
                Add Task
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export const AddsubTask1employeeModal = ({
  show,
  closeModal,
  getSubsubTask,
  pk_work_id,
  pk_project_id,
}) => {
  const [Member, setMember] = useState([]);
  const [Category, setCategory] = useState([]);
  const [Platform, setPlatform] = useState([]);
  const [Employeeid, setEmployeeid] = useState(-1);
  const [platformId, setPlatformId] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const { password, username } = billpunchbasicauth;

  const [platformName, setPlatformName] = useState("");
  const [categoryName, setCategoryName] = useState("");
  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const [projectfield, setProjectfields] = useState({
    Taskname: "",
    Taskassign: "",
    Taskdesc: "",
    Taskstrt: "",
    Taskend: "",
    TaskPrio: "",
    Resource: "",
  });
  const [projecterror, setProjecterror] = useState({
    Taskname: "",
    Taskassign: "",
    Taskdesc: "",
    Taskstrt: "",
    Taskend: "",
    Resource: "",
  });
  const validateForm = (e) => {
    e.preventDefault();
    let errors = {};
    let formIsValid = true;

    if (!projectfield.Taskname.trim()) {
      errors.Taskname = "*Task name is required";
      formIsValid = false;
    }

    if (!projectfield.Taskdesc.trim()) {
      errors.Taskdesc = "*Task description is required";
      formIsValid = false;
    }

    if (!projectfield.Taskstrt) {
      errors.Taskstrt = "*Start date is required";
      formIsValid = false;
    }

    if (!projectfield.Taskend) {
      errors.Taskend = "*End date is required";
      formIsValid = false;
    }

    if (projectfield.Taskstrt && projectfield.Taskend) {
      const startDate = new Date(projectfield.Taskstrt);
      const endDate = new Date(projectfield.Taskend);
      if (startDate > endDate) {
        errors.Taskend = "*End date cannot be before start date";
        formIsValid = false;
      }
    }

    if (!taskEmpId) {
      errors.Taskassign = "*A task assignee must be selected";
      formIsValid = false;
    }

    if (!selectedMember) {
      errors.Resource = "*Resource is required";
      formIsValid = false;
    }

    if (!projectfield.Taskname.trim()) {
      errors.Taskname = "*Task name is required";
      formIsValid = false;
    }

    if (!projectfield.Taskdesc.trim()) {
      errors.Taskdesc = "*Task description is required";
      formIsValid = false;
    }

    if (!projectfield.TaskPrio) {
      errors.TaskPrio = "*Priority is required";
      formIsValid = false;
    }

    if (!categoryId) {
      errors.categoryName = "*Category is required";
      formIsValid = false;
    }

    if (!platformId) {
      errors.platformName = "*Platform is required";
      formIsValid = false;
    }
    setProjecterror(errors);
    if (Object.keys(errors).length == 0) {
      handleSubmit();
    }
  };
  const handleChange = async (e) => {
    const { name, value } = e.target;
    setProjectfields((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };
  const [members, setMembers] = useState([]);
  const [selectedMember, setSelectedMember] = useState("");
  const [selectedProjectId, setSelectedProjectId] = useState("");
  const [taskAssignee, setTaskAssignee] = useState("");
  const [taskEmpId, setTaskEmpId] = useState("");

  const fetchMembers = async () => {
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      const raw = JSON.stringify({
        workid: pk_work_id,
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/GetWorkEmployee`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0" && data.data) {
        const membersData = data.data.map((emp) => ({
          value: emp.empid,
          label: emp.fullname,
        }));
        setMembers(membersData);
      } else {
        setMembers([]);
      }
    } catch (error) {
      console.log("error", error);
      setMembers([]); // Ensure reset on error too
    }
  };

  const handleMemberChange = (selectedOption) => {
    setTaskAssignee(selectedOption.label);
    setTaskEmpId(selectedOption.value);
    setSelectedMember(selectedOption);
  };

  const options = [
    {
      value: "Urgent",
      label: (
        <div>
          <FaFlag style={{ marginRight: "5px", color: "maroon" }} />
          Urgent
        </div>
      ),
    },
    {
      value: "High",
      label: (
        <div>
          <FaFlag style={{ marginRight: "5px", color: "#f4c430" }} />
          High
        </div>
      ),
    },
    {
      value: "Normal",
      label: (
        <div>
          <FaFlag style={{ marginRight: "5px", color: "blue" }} />
          Normal
        </div>
      ),
    },
    {
      value: "Low",
      label: (
        <div>
          <FaFlag style={{ marginRight: "5px", color: "grey" }} />
          Low
        </div>
      ),
    },
    {
      value: "Clear",
      label: (
        <div>
          {" "}
          <img
            src={require("../../../assets/images/flag_1.png")}
            style={{ marginRight: "5px", width: ".8rem" }}
          />
          Clear
        </div>
      ),
    },
  ];

  const handlePlatformSelection = (selectedPlatform) => {
    setPlatformName(selectedPlatform.label);
    setPlatformId(selectedPlatform.value);
  };

  const handleCategorySelection = (selectedCategory) => {
    setCategoryName(selectedCategory.label);
    setCategoryId(selectedCategory.value);
  };
  const propertyobject = JSON.parse(sessionStorage.getItem("property"));
  const handleSubmit = async (e) => {
    try {
      setTransactionLoaderState(true);

      const selectedEmployee = Member.find(
        (employee) => employee.value === Employeeid
      );
      console.log(selectedEmployee, "selectedEmployee");
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");
      var raw = JSON.stringify({
        spaceid: pk_work_id,
        projectid: pk_project_id,
        firmid: user.firm_id,
        taskname: projectfield.Taskname,
        taskpriority: projectfield.TaskPrio,
        taskdesc: projectfield.Taskdesc,
        taskassigne: taskAssignee,
        task_empid: taskEmpId,
        task_startdate: projectfield.Taskstrt,
        task_endate: projectfield.Taskend,
        status: "P",
        dependency_status: "N",
        platform: platformName,
        category: categoryName,
      });

      console.log("Request sending:", raw);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
      console.log("sending data to abhijeet");
      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/AddTaskEmployee`,
        requestOptions
      );
      console.log("Api url");
      const data = await res.json();
      console.log("nishant", data);
      if (data.status === "1") {
        swal("Error", data.result, "error");
        setTransactionLoaderState(false);
        getSubsubTask();
        closeModal();
      } else {
        swal("Success", data.result, "success");
        setTransactionLoaderState(false);
        getSubsubTask();
        handleClose();
      }
      console.log("Form submission completed.");
    } catch (error) {
      console.log("Error:", error);
    }
  };
  const Getcatogory = async () => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({
          taskid: "string",
        }),
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/GetCatTask`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        const categories = data.data.map((cat) => ({
          value: cat.pk_catid,
          label: cat.catname,
        }));
        setCategory(categories); // Set the categories into state
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.error("error", error);
      setTransactionLoaderState(false);
    }
  };

  const Getplatform = async () => {
    setTransactionLoaderState(true);
    try {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          taskid: "string",
        }),
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/GetTaskPlatform`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        const platformdata = data.data.map((device) => ({
          value: device.pk_platformid,
          label: device.platform_name,
        }));
        setPlatform(platformdata);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.error("error", error);
      setTransactionLoaderState(false);
    }
  };
  const handleClose = () => {
    setProjectfields({
      Taskname: "",
      Taskassign: "",
      Taskdesc: "",
      Taskstrt: "",
      Taskend: "",
      TaskPrio: "",
      Resource: "",
    });
    setSelectedMember(null);
    setTaskAssignee("");
    setTaskEmpId("");
    setPlatformId("");
    setCategoryId("");
    setPlatformName("");
    setCategoryName("");
    closeModal();
  };
  useEffect(() => {
    Getcatogory();
    Getplatform();
    fetchMembers();
  }, []);
  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="">Add Task</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="row m-0 p-0">
              <Form.Group className="col">
                <Form.Label> Select Resource</Form.Label>
                <Select
                  id="memberDropdown"
                  value={selectedMember}
                  onChange={handleMemberChange}
                  options={members}
                  placeholder="Select Member"
                />
                {projecterror.Resource && (
                  <div className="text-danger">{projecterror.Resource}</div>
                )}
              </Form.Group>
            </div>
            <div className="row m-0 p-0">
              <Form.Group className="col-6 m-0">
                <Form.Label> Task Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Task Name"
                  id="Taskname"
                  name="Taskname"
                  value={projectfield.Taskname}
                  onChange={handleChange}
                />
                {projecterror.Taskname && (
                  <div className="text-danger">{projecterror.Taskname}</div>
                )}
              </Form.Group>
              <Form.Group className="col">
                <Form.Label>Priority</Form.Label>
                <Select
                  options={options}
                  onChange={(option) =>
                    setProjectfields((prevFormData) => ({
                      ...prevFormData,
                      TaskPrio: option.value,
                    }))
                  }
                  placeholder=" Priority"
                />
                {projecterror.TaskPrio && (
                  <div className="text-danger">{projecterror.TaskPrio}</div>
                )}
              </Form.Group>
            </div>
            <div className="row m-0 p-0">
              <Form.Group className="col">
                <Form.Label>Category</Form.Label>
                <Select
                  options={Category}
                  onChange={handleCategorySelection}
                  placeholder="Category"
                />
                {projecterror.categoryName && (
                  <div className="text-danger">{projecterror.categoryName}</div>
                )}
              </Form.Group>
              <Form.Group className="col">
                <Form.Label>Platform</Form.Label>
                <Select
                  onChange={handlePlatformSelection}
                  options={Platform}
                  placeholder=" Platform"
                />
                {projecterror.platformName && (
                  <div className="text-danger">{projecterror.platformName}</div>
                )}
              </Form.Group>
            </div>
            <div className="row m-0 p-0 mb-3">
              {" "}
              <Form.Group className="col-6 m-0">
                <Form.Label> Start Date</Form.Label>
                <Form.Control
                  type="date"
                  placeholder="Start Date"
                  id="Taskstrt"
                  name="Taskstrt"
                  value={projectfield.Taskstrt}
                  onChange={handleChange}
                />
                {projecterror.Taskstrt && (
                  <p className="text-danger">{projecterror.Taskstrt}</p>
                )}
              </Form.Group>
              <Form.Group className="col-6 m-0">
                <Form.Label> End Date</Form.Label>
                <Form.Control
                  type="date"
                  placeholder="End Date"
                  id="Taskend"
                  name="Taskend"
                  value={projectfield.Taskend}
                  onChange={handleChange}
                />
                {projecterror.Taskend && (
                  <p className="text-danger">{projecterror.Taskend}</p>
                )}
              </Form.Group>
            </div>

            <div className="row m-0 p-0">
              <Form.Group className="col">
                <Form.Label>Description</Form.Label>
                <textarea
                  className="form-control"
                  type="textarea"
                  name="Taskdesc"
                  value={projectfield.Taskdesc}
                  onChange={handleChange}
                  placeholder="Project Description"
                />
                {projecterror.Taskdesc && (
                  <div className="text-danger">{projecterror.Taskdesc}</div>
                )}
              </Form.Group>
            </div>
            <div className="col-4 ">
              <button
                type="submit"
                // type="button"
                className="btn btn-primary btn-block d-flex justify-content-center align-items-center"
                style={{ height: "2rem", borderRadius: ".3rem" }}
                onClick={validateForm}
              >
                Add Task
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export const Addprivacypolicy = ({ show, onhide, Policy }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const { password, username } = billpunchbasicauth;

  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const [formData, setFormData] = useState({
    Policyname: "",
    description: "",
  });
  const [errors, setErrors] = useState({
    Policyname: "",
    description: "",
  });
  const validate = (e) => {
    e.preventDefault();
    console.log("validate function");
    console.log(formData);

    let errors = {};

    if (
      !formData.Policyname.trim() ||
      !/^[a-z0-9 ]+$/i.test(formData.Policyname.trim())
    ) {
      errors.Policyname =
        "*Please enter a valid Policy Name (alphanumeric only)";
    }

    if (
      !formData.description.trim() ||
      !/^[a-z0-9 ,.!?]+$/i.test(formData.description.trim())
    ) {
      errors.description =
        "*Please enter a valid Description (alphanumeric and basic punctuation only)";
    }

    setErrors(errors);
    console.log(errors);

    if (Object.keys(errors).length === 0) {
      handleSubmit();
    }
  };

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    console.table({ name, value });

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const convertToByteArray = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.onloadend = (evt) => {
        if (evt.target.readyState === FileReader.DONE) {
          const arrayBuffer = evt.target.result;
          const array = new Uint8Array(arrayBuffer);
          const binary = [...array]
            .map((byte) => String.fromCharCode(byte))
            .join("");
          const byteArray = window.btoa(binary);
          resolve(byteArray);
        }
      };
      reader.onerror = reject;
    });
  };

  const handleSubmit = async (cardType) => {
    setTransactionLoaderState(true);
    if (!selectedFile) {
      swal("Error", "Please select a file for upload.", "warning");
      return;
    }

    const uploadedFile = selectedFile;
    const byteArray = await convertToByteArray(uploadedFile);

    let imageType;
    if (uploadedFile.type === "application/pdf") {
      imageType = ".pdf";
      // swal("Success", "PDF file uploaded successfully.", "success");
    } else if (uploadedFile.type.startsWith("image/jpeg")) {
      imageType = ".jpeg";
      // swal("Success", "JPEG file uploaded successfully.", "success");
    } else {
      // swal(
      //   "Error",
      //   "Unsupported file type. Please upload a PDF or JPEG file.",
      //   "warning"
      // );
      return;
    }

    setFormData((prevFormData) => ({
      ...prevFormData,
      image_type: imageType,
    }));

    const raw = JSON.stringify({
      firmid: user.firm_id,
      name: formData.Policyname,
      type: imageType,
      desc: formData.description,
      image_byte: byteArray,
    });
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Basic " + Buffer.from(username + ":" + password).toString("base64")
    );
    myHeaders.append("Content-Type", "application/json");

    const requestOptions = {
      method: "POST",
      body: raw,
      redirect: "follow",
      headers: myHeaders,
    };

    try {
      const response = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/AddFirmPolicy`,
        requestOptions
      );
      const result = await response.json();

      if (result.status === "0") {
        setTransactionLoaderState();
        swal("Success", result.msg, "success");
        onhide(false);
        Policy();
      } else {
        setTransactionLoaderState();
        swal("Failed", result.msg, "error");
        onhide(false);
        Policy();
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <Modal show={show} onHide={onhide}>
        <Modal.Header closeButton>
          <Modal.Title className=""> Add Policy</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form className="mx-md-3 mx-sm-2">
            <div className="row"></div>
            <div className="row">
              <Form.Group className="col">
                <Form.Label>Policy Name</Form.Label>
                <Form.Control
                  type="text"
                  name="Policyname"
                  value={formData.Policyname}
                  onChange={handleInputChange}
                  placeholder="Policy Name"
                />
                {errors.Policyname && (
                  <p className="text-danger">{errors.Policyname}</p>
                )}
              </Form.Group>{" "}
            </div>

            <div className="row ">
              <Form.Group className="col">
                <Form.Label>Description</Form.Label>
                <textarea
                  className="form-control"
                  type="textarea"
                  name="description"
                  value={formData.description}
                  onChange={handleInputChange}
                  placeholder="Description"
                />
                {errors.description && (
                  <p className="text-danger">{errors.description}</p>
                )}
              </Form.Group>
            </div>
            <div className="row ">
              <Form.Group className="col-8">
                <Form.Label>File Upload (PDF or JPEG)</Form.Label>
                <Form.Control
                  type="file"
                  accept=".pdf, .jpeg, .jpg"
                  onChange={handleFileChange}
                />
              </Form.Group>
            </div>

            <div className="row ">
              <div className="col-3">
                <button
                  className="btn btn-success d-flex align-items-center"
                  type="button"
                  style={{
                    height: "2rem",
                    borderRadius: ".3rem",
                    fontSize: ".8rem",
                  }}
                  onClick={validate}
                >
                  Submit
                </button>
              </div>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};
export const Addcategoryemployeemodal = ({
  show,
  hide,
  fetchExpenseCategories,
}) => {
  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const [category, setCategory] = useState("");
  const [description, setDescription] = useState("");
  const { password, username } = billpunchbasicauth;
  const [errors, setErrors] = useState({});

  const validate = (e) => {
    e.preventDefault();
    const newErrors = {};
    if (!category) newErrors.category = "Category is required";
    if (!description) newErrors.description = "Description is required";

    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      handleSubmit();
    }
  };

  const handleSubmit = async () => {
    setTransactionLoaderState(true);
    try {
      console.log("submit form");
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");
      var raw = JSON.stringify({
        empid: user.userid,
        category: category,
        discription: description,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/AddExpenseCategory`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        swal("Success", data.result, "success");
        hide();
        fetchExpenseCategories();
        setTransactionLoaderState(false);
      } else {
        swal("Error", data.result, "error");
        hide();
        fetchExpenseCategories();
      }
      console.log("Form submission completed.");
    } catch (error) {
      console.log("Error:", error);
    }
  };

  return (
    <Modal show={show} onHide={hide}>
      <Modal.Header closeButton>
        <Modal.Title>Add Category</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={validate}>
          <Form.Group className="mx-md-2" controlId="Category">
            <Form.Control
              type="text"
              name="Category"
              placeholder="Category"
              value={category}
              onChange={(e) => setCategory(e.target.value)}
              isInvalid={!!errors.category}
            />
            <Form.Control.Feedback type="invalid">
              {errors.category}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mx-md-2" controlId="description">
            <Form.Control
              as="textarea"
              rows={3}
              name="description"
              placeholder="Description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              isInvalid={!!errors.description}
            />
            <Form.Control.Feedback type="invalid">
              {errors.description}
            </Form.Control.Feedback>
          </Form.Group>
          <Modal.Footer className="m-0 p-0">
            <div className="row">
              <div className="col-auto m-0 p-0 ml-auto">
                <button
                  className="d-flex align-items-center bg-primary p-1"
                  type="submit"
                  style={{
                    height: "2rem",
                    borderRadius: ".3rem",
                    fontSize: ".9rem",
                    color: "#fff",
                  }}
                  onClick={validate}
                >
                  Submit
                </button>
              </div>
            </div>
          </Modal.Footer>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export const Adddataemployeemodal = ({ show, hide, fetchMoneymanagement }) => {
  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const [name, setName] = useState("");
  const [date, setDate] = useState("");
  const [amount, setAmount] = useState("");
  const { password, username } = billpunchbasicauth;
  const [errors, setErrors] = useState({});

  const validate = (e) => {
    e.preventDefault();
    const newErrors = {};
    if (!name) newErrors.name = "Name is required";
    if (!date) newErrors.date = "Date is required";
    if (!amount) newErrors.amount = "Amount is required";
    if (amount && isNaN(amount)) newErrors.amount = "Amount must be a number";

    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      handleSubmit();
    }
  };

  const handleSubmit = async () => {
    setTransactionLoaderState(true);
    try {
      console.log("submit form");
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");
      var raw = JSON.stringify({
        empid: user.userid,
        name: name,
        date: date,
        amount: amount,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/AddMoneyManagement`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        swal("Success", data.result, "success");
        hide();
        fetchMoneymanagement();
        setTransactionLoaderState(false);
      } else {
        swal("Error", data.result, "error");
        hide();
        fetchMoneymanagement();
      }
      console.log("Form submission completed.");
    } catch (error) {
      console.log("Error:", error);
    }
  };

  return (
    <Modal show={show} onHide={hide}>
      <Modal.Header closeButton>
        <Modal.Title>Money Management</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={validate}>
          <div className="row">
            <Form.Group className="col" controlId="name">
              <Form.Control
                type="text"
                name="name"
                placeholder="Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                isInvalid={!!errors.name}
              />
              <Form.Control.Feedback type="invalid">
                {errors.name}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="col" controlId="date">
              <Form.Control
                type="date"
                name="date"
                placeholder="Date"
                value={date}
                onChange={(e) => setDate(e.target.value)}
                isInvalid={!!errors.date}
              />
              <Form.Control.Feedback type="invalid">
                {errors.date}
              </Form.Control.Feedback>
            </Form.Group>
          </div>
          <div className="row">
            <Form.Group className="col-6" controlId="amount">
              <Form.Control
                type="text"
                name="amount"
                placeholder="Amount"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
                isInvalid={!!errors.amount}
              />
              <Form.Control.Feedback type="invalid">
                {errors.amount}
              </Form.Control.Feedback>
            </Form.Group>
            <div className="col-4">
              <button
                className="d-flex align-items-center bg-primary p-1"
                type="submit"
                style={{
                  height: "2rem",
                  borderRadius: ".3rem",
                  fontSize: ".9rem",
                  color: "#fff",
                }}
                onClick={validate}
              >
                Submit
              </button>
            </div>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export const Addfixedexpenseemployeemodal = ({
  show,
  hide,
  fetchExpenseCategories,
  expenseCategories,
  fetchFixedExpense,
}) => {
  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const [category, setCategory] = useState("");
  const [amount, setAmount] = useState("");
  const { password, username } = billpunchbasicauth;
  const [errors, setErrors] = useState({});

  const validate = (e) => {
    e.preventDefault();
    const newErrors = {};
    if (!category) newErrors.category = "Category is required";
    if (!amount) newErrors.amount = "Amount is required";
    if (amount && isNaN(amount)) newErrors.amount = "Amount must be a number";

    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      handleSubmit();
    }
  };

  const handleSubmit = async () => {
    setTransactionLoaderState(true);
    try {
      console.log("submit form");
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");
      var raw = JSON.stringify({
        empid: user.userid,
        expensetype: category,
        amount: amount,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/AddMonthlyFixedExpense`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        swal("Success", data.result, "success");
        hide();
        fetchFixedExpense();
        setTransactionLoaderState(false);
      } else {
        swal("Error", data.result, "error");
        hide();
        fetchFixedExpense();
      }
      console.log("Form submission completed.");
    } catch (error) {
      console.log("Error:", error);
    }
  };

  return (
    <Modal show={show} onHide={hide}>
      <Modal.Header closeButton>
        <Modal.Title>Add Category</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={validate}>
          <Form.Group className="col" controlId="category">
            <Form.Label>Category</Form.Label>
            <Form.Control
              as="select"
              value={category}
              onChange={(e) => setCategory(e.target.value)}
              isInvalid={!!errors.category}
            >
              <option value="">Select Category</option>
              {expenseCategories.map((category) => (
                <option key={category.cat_id} value={category.category}>
                  {category.category}
                </option>
              ))}
            </Form.Control>
            <Form.Control.Feedback type="invalid">
              {errors.category}
            </Form.Control.Feedback>
          </Form.Group>
          <div className="row">
            <Form.Group className="col-6" controlId="amount">
              <Form.Control
                type="text"
                name="amount"
                placeholder="Amount"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
                isInvalid={!!errors.amount}
              />
              <Form.Control.Feedback type="invalid">
                {errors.amount}
              </Form.Control.Feedback>
            </Form.Group>
            <div className="col-4">
              <button
                className="d-flex align-items-center bg-primary p-1"
                type="submit"
                style={{
                  borderRadius: ".3rem",
                  color: "#fff",
                }}
                onClick={validate}
              >
                Submit
              </button>
            </div>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export const AddtaskAdminModal = ({ show, closeModal, getProjects }) => {
  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const { password, username } = billpunchbasicauth;

  return (
    <>
      <Modal show={show} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title className="">Add Subtask</Modal.Title>
        </Modal.Header>
        <Modal.Body></Modal.Body>
      </Modal>
    </>
  );
};

export const DependencyModal = ({ show, closeModal, getTask }) => {
  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const [Employee, setEmployee] = useState();
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    date: "",
    name: "",
  });
  const { password, username } = billpunchbasicauth;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const GetFirmEmployee = async () => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        firmid: user.firm_id,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/getEmployee`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        setEmployee(
          data.data.map((emp) => ({
            value: emp.firstname,
            label: emp.firstname,
          }))
        );
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
      setTransactionLoaderState(false);
    }
  };

  const dependencytaskid = JSON.parse(
    sessionStorage.getItem("dependencytaskid")
  );
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setTransactionLoaderState(true);

      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");
      var raw = JSON.stringify({
        empid: user.userid,
        taskid: dependencytaskid.pk_taskid,
        tittle: formData.title,
        desc: formData.description,
        dependency_on: formData.name.value,
        date: formData.date,
      });

      console.log("Request sending:", raw);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
      console.log("sending data to abhijeet");
      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/AddTaskDependency`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "1") {
        swal("Error", data.result, "error");
        setTransactionLoaderState(false);
        getTask();
        closeModal();
      } else {
        swal("Success", data.result, "success");
        setTransactionLoaderState(false);
        getTask();
        closeModal();
      }
      console.log("Form submission completed.");
    } catch (error) {
      console.log("Error:", error);
    }
  };
  useEffect(() => {
    GetFirmEmployee();
  }, []);
  return (
    <>
      <Modal show={show} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title className="">Add Dependency</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <div className="row m-0 p-0">
              <Form.Group className="col" controlId="title">
                <Form.Label>Title</Form.Label>
                <Form.Control
                  type="text"
                  name="title"
                  value={formData.title}
                  onChange={handleChange}
                  placeholder="Enter title"
                />
              </Form.Group>
            </div>
            <div className="row m-0 p-0">
              <Form.Group className="col" controlId="name">
                <Form.Label>Name</Form.Label>
                <Select
                  value={formData.name}
                  onChange={(selectedOption) =>
                    handleChange({
                      target: { name: "name", value: selectedOption },
                    })
                  }
                  options={Employee}
                  placeholder="Select Employee"
                />
              </Form.Group>

              <Form.Group className="col" controlId="date">
                <Form.Label>Date</Form.Label>
                <Form.Control
                  type="date"
                  name="date"
                  value={formData.date}
                  onChange={handleChange}
                />
              </Form.Group>
            </div>
            <div className="row m-0 p-0">
              <Form.Group className="col" controlId="description">
                <Form.Label>Description</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  name="description"
                  value={formData.description}
                  onChange={handleChange}
                  placeholder="Enter description"
                />
              </Form.Group>
            </div>
            <Button variant="primary" type="submit">
              Submit
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};
export const Dependencytable = ({ show, closeModal, taskId }) => {
  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const [dependencydata, setDependencyData] = useState([]);
  const { password, username } = billpunchbasicauth;

  const getDependency = async () => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        taskid: taskId,
      });
      console.log(taskId, "dependencytaskmodal");
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/GetTaskDependency`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        setDependencyData(data.data);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
      setTransactionLoaderState(false);
    }
  };
  useEffect(() => {
    console.log("New taskId:", taskId);
    getDependency();
  }, [taskId]);
  const [Removedependency, setRemovedependency] = useState(null);

  const RemoveTaskDependency = async (pk_did) => {
    const confirmed = await swal({
      title: "Are you sure?",
      text: "Are you sure to remove this dependency!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    });

    if (confirmed) {
      setTransactionLoaderState(true);
      try {
        var myHeaders = new Headers();
        myHeaders.append(
          "Authorization",
          "Basic " + Buffer.from(username + ":" + password).toString("base64")
        );
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
          empid: user.userid,
          did: pk_did,
          taskid: taskId,
        });

        var requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: raw,
          redirect: "follow",
        };

        const res = await fetch(
          `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/RemoveTaskDependency`,
          requestOptions
        );
        const data = await res.json();
        if (data.status === "0") {
          swal("Success", data.result, "success");
          setRemovedependency(data.data);
          getDependency();
        }
        setTransactionLoaderState(false);
      } catch (error) {
        console.log("error", error);
        setTransactionLoaderState(false);
        getDependency();
      }
    }
  };
  const { accessData } = useContext(GlobalContext);
  useEffect(() => {
    console.log("Access Data:", accessData);
  }, [accessData]);

  return (
    <>
      <Modal show={show} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title className="">Dependency</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <table className="table">
            <thead>
              <tr>
                <th
                  style={{
                    borderBottom: "1px solid #f2f4f2",
                    color: "#000",
                    padding: "8px",
                    textAlign: "left",
                    background: "#fff",
                  }}
                >
                  Title
                </th>
                <th
                  style={{
                    borderBottom: "1px solid #f2f4f2",
                    color: "#000",
                    padding: "8px",
                    textAlign: "left",
                    background: "#fff",
                  }}
                >
                  Desc.
                </th>
                <th
                  style={{
                    borderBottom: "1px solid #f2f4f2",
                    color: "#000",
                    padding: "8px",
                    textAlign: "left",
                    background: "#fff",
                  }}
                >
                  Dependency
                </th>
                <th
                  style={{
                    borderBottom: "1px solid #f2f4f2",
                    color: "#000",
                    padding: "8px",
                    textAlign: "left",
                    background: "#fff",
                  }}
                >
                  Date
                </th>
                <th
                  style={{
                    borderBottom: "1px solid #f2f4f2",
                    color: "#000",
                    padding: "8px",
                    textAlign: "left",
                    background: "#fff",
                  }}
                >
                  Status
                </th>
              </tr>
            </thead>
            <tbody>
              {dependencydata.length === 0 ? (
                <tr>
                  <td colSpan="5" style={{ textAlign: "center" }}>
                    No dependencies found.
                  </td>
                </tr>
              ) : (
                dependencydata.map((dependency) => (
                  <tr key={dependency.pk_did}>
                    <td
                      style={{
                        textAlign: "left",
                        border: "none",
                        fontSize: ".9rem",
                      }}
                    >
                      {dependency.dependency_tittle}
                    </td>
                    <td
                      style={{
                        textAlign: "left",
                        border: "none",
                        fontSize: ".9rem",
                      }}
                    >
                      {dependency.dependency_desc}
                    </td>
                    <td
                      style={{
                        textAlign: "left",
                        border: "none",
                        fontSize: ".9rem",
                      }}
                    >
                      {dependency.dependency_on}
                    </td>
                    <td
                      style={{
                        textAlign: "left",
                        border: "none",
                        fontSize: ".9rem",
                      }}
                    >
                      {new Date(dependency.date).toLocaleDateString("en-US")}
                    </td>
                    <td
                      style={{
                        textAlign: "left",
                        border: "none",
                        fontSize: ".9rem",
                      }}
                    >
                      {accessData &&
                        accessData.map(
                          (module) =>
                            module.data &&
                            module.data.map((role) =>
                              role.role_name === "Remove Dependency" ? (
                                <span key={role.pk_role_id}>
                                  {role.access === "Y" ? (
                                    <>
                                      {dependency.status === "Y" && (
                                        <div
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                          }}
                                        >
                                          <span
                                            className="p-1"
                                            style={{
                                              backgroundColor:
                                                "rgba(43, 193, 85, 0.1)",
                                              color: "#2bc155",
                                              border: "1px solid #2bc155",
                                              borderRadius: ".3rem",
                                            }}
                                          >
                                            Open
                                          </span>
                                          &nbsp;{" "}
                                          <FaTimes
                                            onClick={() =>
                                              RemoveTaskDependency(
                                                dependency.pk_did
                                              )
                                            }
                                          />
                                        </div>
                                      )}

                                      {dependency.status === "N" && (
                                        <span
                                          className=" p-1 "
                                          style={{
                                            backgroundColor:
                                              "rgba(255, 46, 46, 0.1)",
                                            color: "#ff2e2e",
                                            border: "1px solid #ff2e2e",
                                            borderRadius: ".3rem",
                                          }}
                                        >
                                          Close
                                        </span>
                                      )}
                                    </>
                                  ) : (
                                    <>
                                      {dependency.status === "Y" && (
                                        <div
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                          }}
                                        >
                                          <span
                                            className="p-1"
                                            style={{
                                              backgroundColor:
                                                "rgba(43, 193, 85, 0.1)",
                                              color: "#2bc155",
                                              border: "1px solid #2bc155",
                                              borderRadius: ".3rem",
                                              pointerEvents: "none", // Disable pointer events
                                              opacity: "0.5",
                                            }}
                                          >
                                            Open
                                          </span>
                                          &nbsp;{" "}
                                          <FaTimes
                                            onClick={() =>
                                              RemoveTaskDependency(
                                                dependency.pk_did
                                              )
                                            }
                                          />
                                        </div>
                                      )}

                                      {dependency.status === "N" && (
                                        <span
                                          className=" p-1 "
                                          style={{
                                            backgroundColor:
                                              "rgba(255, 46, 46, 0.1)",
                                            color: "#ff2e2e",
                                            border: "1px solid #ff2e2e",
                                            borderRadius: ".3rem",
                                            pointerEvents: "none", // Disable pointer events
                                            opacity: "0.5",
                                          }}
                                        >
                                          Close
                                        </span>
                                      )}
                                    </>
                                  )}
                                </span>
                              ) : null
                            )
                        )}
                    </td>
                    {/* <td
                      style={{
                        textAlign: "left",
                        border: "none",
                        fontSize: ".9rem",
                      }}
                    >
                      {dependency.status === "Y" && (
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <span
                            className="p-1"
                            style={{
                              backgroundColor: "rgba(43, 193, 85, 0.1)",
                              color: "#2bc155",
                              border: "1px solid #2bc155",
                              borderRadius: ".3rem",
                            }}
                          >
                            Open
                          </span>
                          &nbsp;{" "}
                          <FaTimes
                            onClick={() =>
                              RemoveTaskDependency(dependency.pk_did)
                            }
                          />
                        </div>
                      )}

                      {dependency.status === "N" && (
                        <span
                          className=" p-1 "
                          style={{
                            backgroundColor: "rgba(255, 46, 46, 0.1)",
                            color: "#ff2e2e",
                            border: "1px solid #ff2e2e",
                            borderRadius: ".3rem",
                          }}
                        >
                          Close
                        </span>
                      )}
                    </td> */}
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </Modal.Body>
      </Modal>
    </>
  );
};

export const EmployeeworspaceModal = ({
  isOpen,
  onClose,
  selectedWorkspaceId,
  getWorkspacedata,
  selectedProject,
}) => {
  const { password, username } = billpunchbasicauth;

  const [employees, setEmployees] = useState([]);
  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const [selectedEmployeeId, setSelectedEmployeeId] = useState(null);
  const [employeetable, setemployeetable] = useState([]);

  const handleEmployeeChange = (selectedOption) => {
    setSelectedEmployeeId(selectedOption.value);
  };

  const getEmployees = async () => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({
          firmId: user.firm_id,
        }),
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/getEmployee`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        setEmployees(
          data.data.map((employee) => ({
            value: employee.empid,
            label: `${employee.firstname} ${employee.lastname}`,
          }))
        );
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
      setTransactionLoaderState(false);
    }
  };
  const Getemployeetable = async () => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({ workid: selectedWorkspaceId }),
      };
      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/GetEmployeeWorkspace`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        setemployeetable(data.data);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
      setTransactionLoaderState(false);
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!selectedEmployeeId) {
      swal("Error", "No employee selected", "error");
      return;
    }

    try {
      setTransactionLoaderState(true);
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({
          workid: selectedWorkspaceId,
          empid: selectedEmployeeId,
          projectid: selectedProject,
        }),
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/AddEmployeeWorkspace`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "1") {
        swal("Error", data.result, "error");
        Getemployeetable();
        getWorkspacedata();
      } else {
        swal("Success", data.result, "success");
        Getemployeetable();
        getWorkspacedata();
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setTransactionLoaderState(false);
    }
  };
  useEffect(() => {
    if (isOpen) {
      Getemployeetable();
    }
  }, [isOpen, selectedWorkspaceId]);
  useEffect(() => {
    getEmployees();
    Getemployeetable();
  }, []);

  return (
    <Modal show={isOpen} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Select Employee</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <Form.Group className="col" controlId="Employeename">
            <Form.Label>Select Employee</Form.Label>
            <Select options={employees} onChange={handleEmployeeChange} />
          </Form.Group>
          <div className="col d-flex align-items-center">
            {" "}
            <Button variant="success" onClick={handleSubmit}>
              Submit
            </Button>
          </div>
        </div>
        <div>
          <table className="table">
            <thead>
              <tr>
                <th
                  style={{
                    borderBottom: "1px solid #f2f4f2",
                    color: "#000",
                    padding: "8px",
                    textAlign: "left",
                    background: "#fff",
                  }}
                >
                  ID
                </th>
                <th
                  style={{
                    borderBottom: "1px solid #f2f4f2",
                    color: "#000",
                    padding: "8px",
                    textAlign: "left",
                    background: "#fff",
                  }}
                >
                  Employee
                </th>
              </tr>
            </thead>
            <tbody>
              {employeetable.length === 0 ? (
                <tr>
                  <td colSpan="5" style={{ textAlign: "center" }}>
                    No Employee found.
                  </td>
                </tr>
              ) : (
                employeetable.map((empdata) => (
                  <tr key={empdata.empid}>
                    <td
                      style={{
                        textAlign: "left",
                        border: "none",
                        fontSize: ".9rem",
                      }}
                    >
                      {empdata.empid}
                    </td>
                    <td
                      style={{
                        textAlign: "left",
                        border: "none",
                        fontSize: ".9rem",
                      }}
                    >
                      {empdata.full_name}
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
        {/* Additional buttons */}
      </Modal.Footer>
    </Modal>
  );
};
export const Viewsalaryslipemployee = ({ show, handleClose, employee }) => {
  console.log(employee);
  const downloadPDF = () => {
    const input = document.getElementById("salaryslip-content");
    html2canvas(input, { scale: 2 }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");

      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

      const totalPageHeight = 297;
      const spaceToLeaveBelow = totalPageHeight * 0.25;
      const spaceToLeaveAbove = 10;
      const adjustedHeight =
        totalPageHeight - spaceToLeaveBelow - spaceToLeaveAbove;

      pdf.addImage(
        imgData,
        "PNG",
        0,
        spaceToLeaveAbove,
        pdfWidth,
        adjustedHeight
      );

      pdf.save("salary_slip.pdf");
    });
  };

  const totalDeduction = () => {
    console.log("Employee EPF:", employee?.epf, typeof employee?.epf);
    console.log("Employee ESIC:", employee?.esic, typeof employee?.esic);

    const epfAmount = parseFloat(employee?.epf);
    if (!isNaN(epfAmount)) {
      const epfDeduction = epfAmount * 2;
      let totalDeductionAmount = epfDeduction;

      const esicAmount = parseFloat(employee?.esic);
      if (!isNaN(esicAmount)) {
        totalDeductionAmount += esicAmount;
      }

      return totalDeductionAmount;
    }

    return 0;
  };

  console.log(totalDeduction());

  return (
    <Modal show={show} onHide={handleClose} className="salaryslip-modal">
      <Modal.Body>
        <div id="salaryslip-content">
          <form className="m-5">
            <div className="card p-0" style={{ border: "1px solid" }}>
              <div className="d-flex justify-content-end p-2">
                <img
                  alt=""
                  src={require("../../../assets/images/pms/salary_logo.png")}
                  style={{ height: "2rem" }}
                />
              </div>
              <div>
                <h5 className="d-flex justify-content-center">
                  Bumppy Media Private Limited
                </h5>
                <h6 className="d-flex justify-content-center">
                  B-218,ITHUM Tower-B,2nd Floor, Sec-62, Noida, U.p-201301
                </h6>
              </div>
              <hr className="p-0 m-0" style={{ background: "#000" }} />
              <h6 className="d-flex justify-content-center">Salary Slip</h6>
              <hr className="p-0 m-0" style={{ background: "#000" }} />
              <div className="row">
                <div className="col">
                  <div
                    className="row m-0 p-0"
                    style={{ color: "#000", height: "1.7rem" }}
                  >
                    <p className="col px-0" style={{ color: "#000" }}>
                      Emp Code:
                    </p>
                    <p className="col  " style={{ color: "#000" }}>
                      {" "}
                      {employee?.emp_code || "NA"}
                    </p>
                  </div>
                  <div className="row m-0 p-0" style={{ color: "#000" }}>
                    <p className="col px-0" style={{ color: "#000" }}>
                      Name :
                    </p>
                    <p className="col  m-0 p-0" style={{ color: "#000" }}>
                      {" "}
                      {employee?.name || "NA"}
                    </p>
                  </div>
                  <div
                    className="row m-0 p-0"
                    style={{ color: "#000", height: "1.7rem" }}
                  >
                    <p className="col px-0" style={{ color: "#000" }}>
                      Designation:
                    </p>
                    <p className="col  " style={{ color: "#000" }}>
                      {" "}
                      {employee?.designation || "NA"}{" "}
                    </p>
                  </div>
                  <div
                    className="row m-0 p-0"
                    style={{ color: "#000", height: "1.7rem" }}
                  >
                    <p className="col px-0" style={{ color: "#000" }}>
                      Department:
                    </p>
                    <p className="col  " style={{ color: "#000" }}>
                      {" "}
                      {employee?.department || "NA"}
                    </p>
                  </div>
                  <div
                    className="row m-0 p-0"
                    style={{ color: "#000", height: "1.7rem" }}
                  >
                    <p className="col px-0" style={{ color: "#000" }}>
                      Bank Account No:
                    </p>
                    <p className="col  m-0 p-0" style={{ color: "#000" }}>
                      {" "}
                      {employee?.bank_acc_no || "NA"}{" "}
                    </p>
                  </div>
                </div>
                <div className="col">
                  <div
                    className="row m-0 p-0"
                    style={{ color: "#000", height: "1.7rem" }}
                  >
                    <p className="col " style={{ color: "#000" }}>
                      Month:
                    </p>
                    <p className="col  " style={{ color: "#000" }}>
                      {" "}
                      {employee?.current_month || "NA"}
                    </p>
                  </div>
                  <div
                    className="row m-0 p-0"
                    style={{ color: "#000", height: "1.7rem" }}
                  >
                    <p className="col " style={{ color: "#000" }}>
                      {" "}
                      ESI No:
                    </p>
                    <p className="col  " style={{ color: "#000" }}>
                      {" "}
                      {employee?.esic || "NA"}{" "}
                    </p>
                  </div>
                  <div className="row m-0 p-0" style={{ color: "#000" }}>
                    <p className="col " style={{ color: "#000" }}>
                      UAN:
                    </p>
                    <p className="col  " style={{ color: "#000" }}>
                      {" "}
                      {employee?.uan || "NA"}{" "}
                    </p>
                  </div>
                  <div
                    className="row m-0 p-0"
                    style={{ color: "#000", height: "1.7rem" }}
                  >
                    <p className="col " style={{ color: "#000" }}>
                      Salary Mode:
                    </p>
                    <p className="col  " style={{ color: "#000" }}>
                      {" "}
                      Bank Transfer{" "}
                    </p>
                  </div>
                  <div
                    className="row m-0 p-0"
                    style={{ color: "#000", height: "1.7rem" }}
                  >
                    <p className="col " style={{ color: "#000" }}>
                      Bank Name:
                    </p>
                    <p className="col  " style={{ color: "#000" }}>
                      {" "}
                      {employee?.bank_name || "NA"}{" "}
                    </p>
                  </div>
                </div>
              </div>
              <hr className="m-0 p-0" style={{ background: "#000" }} />
              <table className="m-0" style={{ border: "none" }}>
                <thead>
                  <tr>
                    <th
                      style={{
                        background: "#fff",
                        color: "#000",
                        fontWeight: "400",
                        borderRight: "1px solid black",
                      }}
                      colSpan={2}
                    >
                      Standard Wages Amount
                    </th>
                    {/* <th style={{background:"#fff", color:"#000", fontWeight:"400", borderRight:"1px solid black"}}></th> */}
                    <th
                      style={{
                        background: "#fff",
                        color: "#000",
                        fontWeight: "400",
                        borderRight: "1px solid black",
                      }}
                    >
                      Earned Wages Amount
                    </th>
                    <th
                      style={{
                        background: "#fff",
                        color: "#000",
                        fontWeight: "400",
                        borderRight: "1px solid black",
                      }}
                    >
                      Deduction
                    </th>
                    <th
                      style={{
                        background: "#fff",
                        color: "#000",
                        fontWeight: "400",
                      }}
                    >
                      Amount
                    </th>
                  </tr>
                </thead>
                <tbody style={{ borderRight: "none", borderLeft: "none" }}>
                  <tr>
                    <td
                      style={{
                        border: "1px solid black",
                        fontSize: ".9rem",
                        borderLeft: "none",
                      }}
                    >
                      Basic
                    </td>
                    <td
                      style={{
                        fontSize: ".9rem",
                        border: "none",
                        borderTop: "1px solid",
                        borderRight: "1px solid ",
                      }}
                    >
                      {twoDecimalPlaces(employee?.basic || "NA")}
                    </td>
                    <td
                      style={{
                        border: "1px solid black",
                        fontSize: ".9rem",
                        borderTop: "1px solid",
                      }}
                    >
                      {twoDecimalPlaces(employee?.basic || "NA")}
                    </td>
                    <td
                      style={{
                        border: "1px solid black",
                        verticalAlign: "top",
                        fontSize: ".9rem",
                      }}
                    >
                      EPF
                    </td>
                    <td
                      style={{
                        border: "1px solid black",
                        fontSize: ".9rem",
                        borderRight: "none",
                      }}
                    >
                      {twoDecimalPlaces(employee?.epf)}
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        border: "1px solid black",
                        fontSize: ".9rem",
                        borderLeft: "none",
                      }}
                    >
                      HRA
                    </td>
                    <td
                      style={{ border: "1px solid black", fontSize: ".9rem" }}
                    >
                      {twoDecimalPlaces(employee?.hra || "NA")}
                    </td>
                    <td
                      style={{ border: "1px solid black", fontSize: ".9rem" }}
                    >
                      {twoDecimalPlaces(employee?.hra || "NA")}
                    </td>
                    <td
                      style={{
                        border: "1px solid black",
                        verticalAlign: "top",
                        fontSize: ".9rem",
                      }}
                    >
                      ESIC
                    </td>
                    <td
                      style={{
                        border: "1px solid black",
                        fontSize: ".9rem",
                        borderRight: "none",
                      }}
                    >
                      {employee?.esic || "NA"}
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        border: "1px solid black",
                        fontSize: ".9rem",
                        borderLeft: "none",
                      }}
                    >
                      Travel Allowance
                    </td>
                    <td
                      style={{ border: "1px solid black", fontSize: ".9rem" }}
                    >
                      {twoDecimalPlaces(employee?.t_allowance || "NA")}
                    </td>
                    <td
                      style={{ border: "1px solid black", fontSize: ".9rem" }}
                    >
                      {twoDecimalPlaces(employee?.t_allowance || "NA")}
                    </td>
                    <td
                      style={{
                        border: "1px solid black",
                        verticalAlign: "top",
                        fontSize: ".9rem",
                      }}
                    >
                      TDS
                    </td>
                    <td
                      style={{
                        border: "1px solid black",
                        fontSize: ".9rem",
                        borderRight: "none",
                      }}
                    >
                      {0}
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        border: "1px solid black",
                        fontSize: ".9rem",
                        borderLeft: "none",
                      }}
                    >
                      Med. Allowance
                    </td>
                    <td
                      style={{ border: "1px solid black", fontSize: ".9rem" }}
                    >
                      {" "}
                      {twoDecimalPlaces(employee?.m_allowance || "NA")}
                    </td>
                    <td
                      style={{ border: "1px solid black", fontSize: ".9rem" }}
                    >
                      {twoDecimalPlaces(employee?.m_allowance || "NA")}
                    </td>
                    <td
                      style={{
                        border: "1px solid black",
                        verticalAlign: "top",
                        fontSize: ".9rem",
                      }}
                    ></td>
                    <td
                      style={{
                        border: "1px solid black",
                        fontSize: ".9rem",
                        borderRight: "none",
                      }}
                    >
                      {}
                    </td>
                  </tr>

                  <tr>
                    <td
                      style={{
                        border: "1px solid black",
                        fontSize: ".9rem",
                        borderLeft: "none",
                      }}
                    >
                      Oth. Allowance
                    </td>
                    <td
                      style={{ border: "1px solid black", fontSize: ".9rem" }}
                    >
                      {twoDecimalPlaces(employee?.other_allowance || "NA")}
                    </td>
                    <td
                      style={{ border: "1px solid black", fontSize: ".9rem" }}
                    >
                      {twoDecimalPlaces(employee?.other_allowance || "NA")}
                    </td>
                    <td
                      style={{
                        border: "1px solid black",
                        verticalAlign: "top",
                        fontSize: ".9rem",
                      }}
                    ></td>
                    <td
                      style={{
                        border: "1px solid black",
                        fontSize: ".9rem",
                        borderRight: "none",
                      }}
                    ></td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        border: "1px solid black",
                        fontSize: ".9rem",
                        borderLeft: "none",
                      }}
                    >
                      Total
                    </td>
                    <td
                      style={{ border: "1px solid black", fontSize: ".9rem" }}
                    >
                      {twoDecimalPlaces(employee?.totalSalarywithpf || "NA")}
                    </td>
                    <td
                      style={{ border: "1px solid black", fontSize: ".9rem" }}
                    >
                      {twoDecimalPlaces(employee?.totalSalarywithpf || "NA")}
                    </td>
                    <td
                      style={{
                        border: "1px solid black",
                        verticalAlign: "top",
                        fontSize: ".9rem",
                      }}
                    >
                      Total Deduction
                    </td>
                    <td
                      style={{
                        border: "1px solid black",
                        fontSize: ".9rem",
                        borderRight: "none",
                      }}
                    >
                      {twoDecimalPlaces(totalDeduction())}
                    </td>
                  </tr>
                </tbody>

                <tfoot className="mb-md-2">
                  <tr>
                    <td
                      colspan="5"
                      style={{
                        border: "1px solid black",
                        borderRight: "none",
                        borderLeft: "none",
                      }}
                    >
                      <p
                        className="mb-2"
                        style={{ color: "#000", fontSize: ".8rem" }}
                      >
                        Total Payable Amount :{" "}
                        {twoDecimalPlaces(employee?.totalSalary || "NA")}
                      </p>
                      <p
                        className="mb-2"
                        style={{ color: "#000", fontSize: ".8rem" }}
                      >
                        Payable Amount in Words :{" "}
                        {employee?.totalSalary
                          ? inWords(Math.round(Number(employee.totalSalary)))
                          : "NA"}
                      </p>
                    </td>
                  </tr>
                </tfoot>
              </table>
              <hr className="mt-md-3" style={{ background: "#000" }} />
              <p className="px-1" style={{ color: "#000", fontSize: ".8rem" }}>
                This is a computer generated pay slip and does not required any
                signature or any company seal.
              </p>
            </div>
          </form>
        </div>
        <Button className="btn-sm mt-2" onClick={downloadPDF}>
          Download PDF
        </Button>
      </Modal.Body>
    </Modal>
  );
};
// Old Flow

export const AttendanceModal = ({ show, handleClose, item }) => {
  const [AddsalaryData, setAddSalaryData] = useState({
    present: "",
    Absent: "",
    Sick: "",
    Paid: "",
  });
  const { password, username } = billpunchbasicauth;

  const [errors, setErrors] = useState({
    present: "",
    Absent: "",
    Sick: "",
    Paid: "",
  });
  const { setTransactionLoaderState } = useContext(GlobalContext);

  const validate = (e) => {
    e.preventDefault();
    console.log(" validate function");
    let errors = {};
    if (AddsalaryData.present.trim() == "") {
      errors.present = "*Please enter Present days";
    }
    if (AddsalaryData.Absent.trim() == "") {
      errors.Absent = "*Please enter Absent days";
    }

    setErrors(errors);
    if (Object.keys(errors).length == 0) {
      handleEmployeeleave();
    }
  };
  const handleChange = (e) => {
    setAddSalaryData({
      ...AddsalaryData,
      [e.target.name]: e.target.value.trim(),
    });
  };
  const handleEmployeeleave = async (empid) => {
    setTransactionLoaderState(true);
    try {
      console.log("submit firm");
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");
      var raw = JSON.stringify({
        id: item,
        present_days: AddsalaryData.present,
        absent_days: AddsalaryData.Absent,
        sick_leave: AddsalaryData.Sick,
        paid_leave: AddsalaryData.Paid,
      });

      console.log("Request sending:", raw);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
      console.log("sending data to abhijeet");
      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/addEmployeeLeave`,
        requestOptions
      );
      console.log("Api url");
      const data = await res.json();
      console.log("nishant", data);
      if (data.status === "1") {
        swal("Error", data.result, "error");
      } else {
        swal("Success", data.result, "success");
      }
      setTransactionLoaderState(false);
      console.log("Form submission completed.");
    } catch (error) {
      console.log("Error:", error);
    }
    setTransactionLoaderState(false);
  };
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Add Attendance</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form>
          <div className="row">
            <Form.Group className="col-6" controlId="present">
              <Form.Label>Present Days</Form.Label>
              <Form.Control
                type="text"
                name="present"
                value={AddsalaryData.present}
                onChange={handleChange}
                placeholder="Enter Present days"
              />
              {errors.present && (
                <div className="text-danger">{errors.present}</div>
              )}
            </Form.Group>
            <Form.Group className="col-6" controlId="Absent">
              <Form.Label>Absent Days</Form.Label>
              <Form.Control
                type="text"
                name="Absent"
                value={AddsalaryData.Absent}
                onChange={handleChange}
                placeholder="Enter Absent Days"
              />
              {errors.Absent && (
                <div className="text-danger">{errors.Absent}</div>
              )}
            </Form.Group>
          </div>

          <div className="row">
            <Form.Group className="col-6" controlId="Sick">
              <Form.Label>Sick Leave</Form.Label>
              <Form.Control
                type="text"
                name="Sick"
                value={AddsalaryData.Sick}
                onChange={handleChange}
                placeholder="Enter Sick Leaves"
              />
            </Form.Group>
            <Form.Group className="col-6" controlId="Paid">
              <Form.Label> Paid leave</Form.Label>
              <Form.Control
                type="text"
                name="Paid"
                value={AddsalaryData.Paid}
                onChange={handleChange}
                placeholder="Enter Paid leaves"
              />
            </Form.Group>
          </div>

          <Link className="mx-md-2 mx-sm-2 ">
            <button
              type="submit"
              className="btn btn-primary "
              style={{ fontWeight: "bold" }}
              onClick={validate}
            >
              submit
            </button>
          </Link>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export const Addholidaymodal = ({
  formData,
  showModal,
  closeModal,
  handleFormChange,
  handleSubmit,
  setFormData,
  errors,
}) => {
  const handleCategoryChange = (event) => {
    setFormData({
      ...formData,
      category: event.target.value,
    });
  };
  const { password, username } = billpunchbasicauth;

  const handleCloseModal = () => {
    closeModal();

    setFormData({
      category: "",
      fromDate: "",
      toDate: "",
      discription: "",
    });
  };

  return (
    <Modal show={showModal} onHide={handleCloseModal}>
      <Modal.Header closeButton>
        <Modal.Title>Add Leave </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <div className="row ">
            <Form.Group className="col" controlId="category">
              <Form.Label>Category</Form.Label>
              <select
                className="form-control"
                value={formData.category}
                onChange={handleCategoryChange}
              >
                <option value="">All Category</option>
                <option value="Casual"> Casual</option>
                <option value="Sick"> Sick</option>
                <option value="Out of station"> Out of station</option>
              </select>
              {errors.category && (
                <div className="text-danger">{errors.category}</div>
              )}
            </Form.Group>
            <Form.Group className="col" controlId="fromDate">
              <Form.Label>From Date</Form.Label>
              <Form.Control
                type="date"
                name="fromDate"
                value={formData.fromDate}
                onChange={handleFormChange}
              />
              {errors.fromDate && (
                <div className="text-danger">{errors.fromDate}</div>
              )}
            </Form.Group>
            <Form.Group className="col" controlId="toDate">
              <Form.Label>To Date</Form.Label>
              <Form.Control
                type="date"
                name="toDate"
                value={formData.toDate}
                onChange={handleFormChange}
              />
              {errors.toDate && (
                <div className="text-danger">{errors.toDate}</div>
              )}
            </Form.Group>
          </div>

          <Form.Group className="mx-md-2" controlId="discription">
            <Form.Label>Description</Form.Label>
            <Form.Control
              as="textarea"
              rows={2}
              name="discription"
              value={formData.discription}
              placeholder="Reason for leave"
              onChange={handleFormChange}
            />
            {errors.discription && (
              <div className="text-danger">{errors.discription}</div>
            )}
          </Form.Group>
          <div className="mx-md-2">
            <Button
              className="d-flex align-items-center"
              type="submit"
              style={{
                height: "2rem",
                borderRadius: ".3rem",
                fontSize: ".9rem",
              }}
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};
export const Addworkfromhome = ({
  formData,
  showModal,
  closeModal,
  handleFormChange,
  handleSubmit,
  setFormData,
  errors,
  employees,
}) => {
  const handleCloseModal = () => {
    closeModal();

    setFormData({
      category: "",
      fromDate: "",
      discription: "",
    });
  };

  return (
    <Modal show={showModal} onHide={handleCloseModal}>
      <Modal.Header closeButton>
        <Modal.Title>Add Work From Home </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <div className="row ">
            <Form.Group className="col" controlId="fromDate">
              <Form.Label>From Date</Form.Label>
              <Form.Control
                type="date"
                name="fromDate"
                value={formData.fromDate}
                onChange={handleFormChange}
              />{" "}
              {errors.fromDate && (
                <div className="text-danger">{errors.fromDate}</div>
              )}
            </Form.Group>
            <Form.Group className="col" controlId="toDate">
              <Form.Label>Reporting Person</Form.Label>
              <Select
                options={employees}
                value={employees.find(
                  (emp) => emp.value === formData.Reportingperson
                )}
                onChange={(selectedOption) =>
                  setFormData({
                    ...formData,
                    Reportingperson: selectedOption.value,
                  })
                }
                placeholder="Select reporting person"
                isClearable
                isSearchable
              />
            </Form.Group>
          </div>

          <Form.Group className="mx-md-2" controlId="discription">
            <Form.Label>Description</Form.Label>
            <Form.Control
              as="textarea"
              rows={2}
              name="discription"
              value={formData.discription}
              placeholder="Reason for leave"
              onChange={handleFormChange}
            />{" "}
            {errors.discription && (
              <div className="text-danger">{errors.discription}</div>
            )}
          </Form.Group>
          <div className="mx-md-2">
            <Button
              className="d-flex align-items-center"
              type="submit"
              style={{
                height: "2rem",
                borderRadius: ".3rem",
                fontSize: ".9rem",
              }}
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};
export const EditHolidayModal = ({
  show,
  onHide,
  holiday,
  selectedId,
  selectedEmpid,
  getHolidayReport,
}) => {
  const [formData, setFormData] = useState({
    department: holiday.department,
    selectedEmployee: holiday.name,
    fromDate: holiday.fromdate,
    toDate: holiday.todate,
    reason: holiday.reason,
  });

  const [departmentData, setDepartmentData] = useState([]);
  const [employeeData, setEmployeeData] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState(
    holiday.department
  );
  const { password, username } = billpunchbasicauth;

  const { user, setTransactionLoaderState } = useContext(GlobalContext);

  const getDepartmentList = async () => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({ firmid: user.firm_id });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/getEmployeeDepartment`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        setDepartmentData(data.data.map((dept) => dept.cat1));
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
      setTransactionLoaderState(false);
    }
  };

  const getEmployeeList = async (selectedDepartment) => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        firmId: user.firm_id,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/getEmployee`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        // Assuming data.data is an array of employees
        const filteredData = data.data.filter(
          (employee) => employee.department === selectedDepartment
        );

        setEmployeeData(
          filteredData.map(
            (employee) => employee.firstname + " " + employee.lastname
          )
        );
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
      setTransactionLoaderState(false);
    }
  };

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        id: selectedId,
        empid: selectedEmpid,
        name: formData.selectedEmployee,
        department: formData.department,
        fromdate: formData.fromDate,
        todate: formData.toDate,
        reason: formData.reason,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/editEmployeeLeave `,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        getHolidayReport();
        swal("Success", data.result, "success");
        onHide();
      } else {
        getHolidayReport();
        onHide();
        swal("Error", data.result, "warning");
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getDepartmentList();
  }, []);

  useEffect(() => {
    if (selectedDepartment) {
      getEmployeeList(selectedDepartment);
    }
  }, [selectedDepartment]);

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Edit Holiday</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <div className="row">
            <Form.Group className="col" controlId="department">
              <Form.Label>Department</Form.Label>
              <Form.Control
                as="select"
                name="department"
                value={formData.department}
                onChange={(e) => {
                  handleFormChange(e);
                  setSelectedDepartment(e.target.value);
                  getEmployeeList(e.target.value);
                }}
              >
                <option value="">Select Department</option>
                {departmentData.map((dept) => (
                  <option key={dept} value={dept}>
                    {dept}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group className="col" controlId="selectedEmployee">
              <Form.Label>Select Employee</Form.Label>
              <Form.Control
                as="select"
                name="selectedEmployee"
                value={formData.selectedEmployee}
                onChange={handleFormChange}
              >
                <option value="">Select Employee</option>
                {employeeData.map((employee) => (
                  <option key={employee} value={employee}>
                    {employee}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </div>
          <div className="row">
            <Form.Group className="col" controlId="fromDate">
              <Form.Label>From Date</Form.Label>
              <Form.Control
                type="date"
                name="fromDate"
                value={formData.fromDate}
                onChange={handleFormChange}
              />
            </Form.Group>
            <Form.Group className="col" controlId="toDate">
              <Form.Label>To Date</Form.Label>
              <Form.Control
                type="date"
                name="toDate"
                value={formData.toDate}
                onChange={handleFormChange}
              />
            </Form.Group>
          </div>
          <Form.Group className="mx-md-2" controlId="reason">
            <Form.Label>Reason</Form.Label>
            <Form.Control
              as="textarea"
              rows={2}
              name="reason"
              value={formData.reason}
              placeholder="Reason for leave"
              onChange={handleFormChange}
            />
          </Form.Group>
          <div className="mx-md-1">
            <Button
              className="d-flex align-items-center"
              type="submit"
              style={{
                height: "2rem",
                borderRadius: ".3rem",
                fontSize: ".9rem",
              }}
              onClick={handleSubmit}
            >
              Update
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export const ComposeMailAdmin = ({ showModal, closeModal }) => {
  return (
    <Modal show={showModal} onHide={closeModal}>
      <Modal.Header closeButton>
        <Modal.Title>Mail</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="mx-md-2" controlId="To">
            <Form.Control type="text" name="To" placeholder="To" />
          </Form.Group>{" "}
          <Form.Group className="mx-md-2" controlId="Subject">
            <Form.Control type="text" name="Subject" placeholder="Subject" />
          </Form.Group>
          <Form.Group className="mx-md-2" controlId="discription">
            <Form.Control
              as="textarea"
              rows={5}
              name="discription"
              placeholder="Type your query"
            />
          </Form.Group>
          <Modal.Footer className="m-0 p-0">
            <div className="row">
              <div className="col-auto m-0 p-0 ml-auto">
                <Button
                  className="d-flex align-items-center"
                  type="submit"
                  style={{
                    height: "2rem",
                    borderRadius: ".3rem",
                    fontSize: ".9rem",
                  }}
                >
                  Send
                </Button>
              </div>
              <div className="col-auto m-0 p-0 ">
                <Button
                  className="d-flex align-items-center justify-content-end"
                  type="submit"
                  style={{
                    borderRadius: ".3rem",
                    fontSize: ".9rem",
                    background: "aliceblue",
                    borderRadius: ".4em",
                    height: "2rem",
                    border: "1px solid #0074D9",
                    color: "#0074D9",
                  }}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </Modal.Footer>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export const ComposeMailHR = ({ showModal, closeModal }) => {
  return (
    <Modal show={showModal} onHide={closeModal}>
      <Modal.Header closeButton>
        <Modal.Title>Mail</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="mx-md-2" controlId="To">
            <Form.Control type="text" name="To" placeholder="To" />
          </Form.Group>{" "}
          <Form.Group className="mx-md-2" controlId="Subject">
            <Form.Control type="text" name="Subject" placeholder="Subject" />
          </Form.Group>
          <Form.Group className="mx-md-2" controlId="discription">
            <Form.Control
              as="textarea"
              rows={5}
              name="discription"
              placeholder="Type your query"
            />
          </Form.Group>
          <Modal.Footer className="m-0 p-0">
            <div className="row">
              <div className="col-auto m-0 p-0 ml-auto">
                <Button
                  className="d-flex align-items-center"
                  type="submit"
                  style={{
                    height: "2rem",
                    borderRadius: ".3rem",
                    fontSize: ".9rem",
                  }}
                >
                  Send
                </Button>
              </div>
              <div className="col-auto m-0 p-0 ">
                <Button
                  className="d-flex align-items-center justify-content-end"
                  type="submit"
                  style={{
                    borderRadius: ".3rem",
                    fontSize: ".9rem",
                    background: "aliceblue",
                    borderRadius: ".4em",
                    height: "2rem",
                    border: "1px solid #0074D9",
                    color: "#0074D9",
                  }}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </Modal.Footer>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export const Manageloanmodaladmin = ({ show, handleClose }) => {
  const [newLoanName, setNewLoanName] = useState("");
  const [newPerquisiteRate, setNewPerquisiteRate] = useState("");
  const [creatingNewLoan, setCreatingNewLoan] = useState(false);
  const [loancategorydata, setLoancategorydata] = useState([]);
  const [editingLoanId, setEditingLoanId] = useState(null);

  const { password, username } = billpunchbasicauth;
  const { user, setTransactionLoaderState } = useContext(GlobalContext);

  const handleSaveNewLoan = async (e) => {
    e.preventDefault();

    try {
      setTransactionLoaderState(true);
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({
          firmid: user.firm_id,
          loan_name: newLoanName,
          perquiste_rate: newPerquisiteRate,
        }),
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/SuperAdmin/AddLoanRecordCategory`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "1") {
        swal("Error", data.result, "error");
        Getloancategory();
      } else {
        swal("Success", data.result, "success");
        Getloancategory();
        setCreatingNewLoan(false);
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setTransactionLoaderState(false);
    }
  };

  const handleCancelNewLoan = () => {
    setNewLoanName("");
    setNewPerquisiteRate("");
    setCreatingNewLoan(false);
    setEditingLoanId(null);
  };

  const Getloancategory = async () => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");
      var raw = JSON.stringify({
        firmid: user.firm_id,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/SuperAdmin/GetLoanRecordCategory`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        setLoancategorydata(data.data);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log(error);
      setTransactionLoaderState(false);
    }
  };

  const handleEditLoan = (loan) => {
    setNewLoanName(loan.loan_name);
    setNewPerquisiteRate(loan.perquiste_rate);
    setEditingLoanId(loan.pk_cat_id);
  };

  const handleSaveEditedLoan = async (e) => {
    e.preventDefault();

    try {
      setTransactionLoaderState(true);
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({
          cat_id: editingLoanId,
          firmid: user.firm_id,
          loan_name: newLoanName,
          rate: newPerquisiteRate,
        }),
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/SuperAdmin/EditLoanCategory`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "1") {
        swal("Error", data.result, "error");
      } else {
        swal("Success", data.result, "success");
        Getloancategory();
        handleCancelNewLoan();
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setTransactionLoaderState(false);
    }
  };

  const handleDeleteLoan = async (loanId) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this loan category!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        try {
          setTransactionLoaderState(true);
          var myHeaders = new Headers();
          myHeaders.append(
            "Authorization",
            "Basic " + Buffer.from(username + ":" + password).toString("base64")
          );
          myHeaders.append("Content-Type", "application/json");

          var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: JSON.stringify({ id: loanId }),
            redirect: "follow",
          };

          const res = await fetch(
            `${BASE_URL_API_TRANXT_UAT_API}/api/SuperAdmin/DeleteLoanCategory`,
            requestOptions
          );
          const data = await res.json();
          if (data.status === "1") {
            swal("Error", data.result, "error");
          } else {
            swal("Success", data.result, "success");
            Getloancategory();
          }
        } catch (error) {
          console.error("Error:", error);
        } finally {
          setTransactionLoaderState(false);
        }
      }
    });
  };

  useEffect(() => {
    Getloancategory();
  }, []);

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Manage Loan</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="table-responsive">
          <table className="table">
            <colgroup>
              <col style={{ width: "15%" }} />
              <col style={{ width: "10%" }} />
              <col style={{ width: "15%" }} />
            </colgroup>
            <thead>
              <tr style={{ borderBottom: "1px solid lightgrey" }}>
                <th
                  // className="text-center"
                  style={{
                    color: "#0074D9",
                    background: "#fff",
                    border: "none",
                  }}
                >
                  LOAN NAME
                </th>
                <th
                  // className="text-center"
                  style={{
                    color: "#0074D9",
                    background: "#fff",
                    border: "none",
                  }}
                >
                  PERQUISITE RATE
                </th>
                <th
                  className="text-center"
                  style={{
                    color: "#0074D9",
                    background: "#fff",
                    border: "none",
                  }}
                ></th>
              </tr>
            </thead>
            <tbody>
              {loancategorydata.length === 0 ? (
                <tr>
                  <td colSpan="5" style={{ textAlign: "center" }}>
                    No Data found.
                  </td>
                </tr>
              ) : (
                loancategorydata.map((loandata) => (
                  <tr key={loandata.pk_cat_id}>
                    {editingLoanId === loandata.pk_cat_id ? (
                      <>
                        <td style={{ border: "none" }} className="align-middle">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Loan Name"
                            value={newLoanName}
                            onChange={(e) => setNewLoanName(e.target.value)}
                          />
                        </td>
                        <td style={{ border: "none" }} className="align-middle">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Perquisite Rate"
                            value={newPerquisiteRate}
                            onChange={(e) =>
                              setNewPerquisiteRate(e.target.value)
                            }
                          />
                        </td>
                        <td
                          style={{ border: "none" }}
                          className="text-center align-middle"
                        >
                          <Button
                            className="btn-sm"
                            variant="success"
                            onClick={handleSaveEditedLoan}
                          >
                            Save
                          </Button>{" "}
                          <Button
                            className="btn-sm"
                            variant="danger"
                            onClick={handleCancelNewLoan}
                          >
                            Cancel
                          </Button>
                        </td>
                      </>
                    ) : (
                      <>
                        <td
                          style={{
                            textAlign: "left",
                            border: "none",
                            fontSize: ".9rem",
                          }}
                        >
                          {loandata.loan_name}
                        </td>
                        <td
                          style={{
                            textAlign: "left",
                            border: "none",
                            fontSize: ".9rem",
                          }}
                        >
                          {loandata.perquiste_rate + "%"}
                        </td>
                        <td
                          className="text-center align-middle"
                          style={{
                            border: "none",
                            background: "#fff",
                            fontWeight: "500",
                          }}
                        >
                          <MdEdit
                            style={{ fontSize: "1.1rem" }}
                            onClick={() => handleEditLoan(loandata)}
                          />
                          <MdOutlineDeleteForever
                            style={{ fontSize: "1.1rem", cursor: "pointer" }}
                            onClick={() => handleDeleteLoan(loandata.pk_cat_id)}
                          />
                        </td>
                      </>
                    )}
                  </tr>
                ))
              )}

              {creatingNewLoan && (
                <tr style={{ border: "none" }}>
                  <td style={{ border: "none" }} className="align-middle">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Loan Name"
                      value={newLoanName}
                      onChange={(e) => setNewLoanName(e.target.value)}
                    />
                  </td>
                  <td style={{ border: "none" }} className="align-middle">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Perquisite Rate"
                      value={newPerquisiteRate}
                      onChange={(e) => setNewPerquisiteRate(e.target.value)}
                    />
                  </td>
                  <td
                    style={{ border: "none" }}
                    className="text-center align-middle"
                  >
                    <Button
                      className="btn-sm"
                      variant="success"
                      onClick={handleSaveNewLoan}
                    >
                      Save
                    </Button>{" "}
                    <Button
                      className="btn-sm"
                      variant="danger"
                      onClick={handleCancelNewLoan}
                    >
                      Cancel
                    </Button>
                  </td>
                </tr>
              )}

              <div style={{ border: "none" }}>
                {!creatingNewLoan && (
                  <Link
                    variant="primary"
                    onClick={() => setCreatingNewLoan(true)}
                  >
                    + Create New Loan
                  </Link>
                )}
              </div>
            </tbody>
          </table>
          <p className="p-2" style={{ background: "#FEFFD2" }}>
            <IoMdInformationCircleOutline
              style={{ fontSize: "1rem", marginRight: ".6rem" }}
            />
            Prerequisite Rate applies to all loans over ₹20,000, except Medical
            Loans. Any rate changes will impact all employees with these loans.
          </p>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export const Recordrepaymentadmin = ({
  show,
  handleClose,
  activeLoan,
  Getrepaymentrecord,
}) => {
  console.log("Active Loan:", activeLoan);

  const { password, username } = billpunchbasicauth;
  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const [formData, setFormData] = useState({
    repaymentAmount: "",
    repaymentDate: "",
    paymentMode: "",
    remainingAmount: activeLoan ? activeLoan.loan_amount : "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => {
      let newRemainingAmount = prevState.remainingAmount;

      if (name === "repaymentAmount") {
        const repaymentAmount = parseFloat(value) || 0;
        newRemainingAmount = activeLoan.loan_amount - repaymentAmount;
      }

      return {
        ...prevState,
        [name]: value,
        remainingAmount: newRemainingAmount,
      };
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setTransactionLoaderState(true);
      const myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      const requestBody = {
        firmid: user.firm_id,
        empid: activeLoan.empid,
        empname: activeLoan.emp_name,
        pk_loan_id: activeLoan.pk_loan_id,
        loan_number: activeLoan.loan_number,

        repay_amount: formData.repaymentAmount,
        repay_date: formData.repaymentDate,
        pay_mode: formData.paymentMode,
        remainig_amount: formData.remainingAmount,
      };

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify(requestBody),
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/SuperAdmin/AddRepaymentLoanRecord`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "1") {
        swal("Error", data.result, "error");
      } else {
        swal("Success", data.result, "success");
        handleClose();
        Getrepaymentrecord(activeLoan.pk_loan_id);
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setTransactionLoaderState(false);
    }
  };

  useEffect(() => {
    if (activeLoan) {
      setFormData({
        repaymentAmount: "",
        repaymentDate: "",
        paymentMode: "",
        remainingAmount: activeLoan.loan_amount,
      });
    }
  }, [activeLoan]);

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Record Repayment</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Row className="align-items-center">
            <Col md={6}>
              <Form.Group controlId="repaymentAmount">
                <Form.Label>Repayment Amount*</Form.Label>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="repaymentAmountInput">
                <Form.Control
                  type="text"
                  name="repaymentAmount"
                  value={formData.repaymentAmount}
                  onChange={handleChange}
                  placeholder="₹"
                  required
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="align-items-center">
            <Col md={6}>
              <Form.Group controlId="repaymentDate">
                <Form.Label>Repayment Date*</Form.Label>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="repaymentDateInput">
                <Form.Control
                  type="date"
                  name="repaymentDate"
                  value={formData.repaymentDate}
                  onChange={handleChange}
                  placeholder="dd/MM/yyyy"
                  required
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="align-items-center">
            <Col md={6}>
              <Form.Group controlId="paymentMode">
                <Form.Label>Payment Mode*</Form.Label>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="paymentModeInput">
                <Form.Control
                  as="select"
                  name="paymentMode"
                  value={formData.paymentMode}
                  onChange={handleChange}
                  required
                >
                  <option value="">Select Payment Mode</option>
                  <option value="Cash">Cash</option>
                  <option value="Card">Card</option>
                  <option value="Bank Transfer">Bank Transfer</option>
                  <option value="Online">Online</option>
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>
          <Row className="align-items-center">
            <Col md={6}>
              <Form.Group controlId="remainingAmount">
                <Form.Label>Remaining Amount</Form.Label>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="remainingAmountInput">
                <Form.Control
                  type="text"
                  name="remainingAmount"
                  value={formData.remainingAmount}
                  onChange={handleChange}
                  readOnly
                />
              </Form.Group>
            </Col>
          </Row>
          <Modal.Footer>
            <Button variant="primary" type="submit">
              Record Repayment
            </Button>
          </Modal.Footer>
        </Form>
      </Modal.Body>
    </Modal>
  );
};
export const Viewsepfspilit = ({ show, handleClose }) => {
  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title> Contribution Rate</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="col">
            <p>
              <strong>SUB COMPONENTS</strong>
            </p>
            <p>Employees' Provident Fund</p>
            <p>Employees' Pension Scheme</p>
          </div>
          <div className="col">
            <p>
              <strong>Employer's Contribution:</strong>
            </p>
            <p>3.67% of PF Wage</p>
            <p>
              8.33% of PF Wage
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id="tooltip-top">
                    Maximum Employer Contribution for EPS is ₹1250
                  </Tooltip>
                }
              >
                <IoInformationCircleOutline style={{ fontSize: "1rem" }} />
              </OverlayTrigger>
            </p>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export const Viewprviewepfcalcilation = ({ show, handleClose }) => {
  const [showConfig, setShowConfig] = useState(false);
  const handleToggleConfig = () => setShowConfig(!showConfig);

  return (
    <Modal show={show} onHide={handleClose} className="EPFmodal">
      <Modal.Header closeButton>
        <Modal.Title style={{ fontSize: "1.3rem" }}>
          EPF Sample Calculation
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          Let's assume the salary packages considered for EPF is as shown as
          below, the calculation is based on the settings we’ve configured
        </p>
        <div className="mt-3 mb-3">
          <Link style={{ color: "cornflower" }} onClick={handleToggleConfig}>
            Show current configuration{" "}
            {showConfig ? <FaAngleUp /> : <FaAngleDown />}
          </Link>
          {showConfig && (
            <div
              className="card-body card-body-padding"
              style={{ background: "#F9F9FB" }}
            >
              <p style={{ color: "cornflowerblue" }}>
                <b>PF CONTRIBUTION SETTINGS</b>
              </p>
              <p>
                Employer Contribution &nbsp;&nbsp; :&nbsp;&nbsp;
                <b>Restrict Contribution to ₹15,000 of PF Wage</b>{" "}
              </p>
              <p>
                Employee Contribution&nbsp;&nbsp;:&nbsp;&nbsp;{" "}
                <b>Restrict Contribution to ₹15,000 of PF Wage</b>
              </p>
              <p style={{ color: "cornflowerblue" }}>
                <b>LOP CONTRIBUTION</b>
              </p>
              <p>
                Pro-rate Restricted PF Wage&nbsp;&nbsp;:&nbsp;&nbsp;{" "}
                <b style={{ color: "#00B050" }}>Enabled</b>
              </p>
              <p>
                Consider all components when PF wage {"<"} ₹15,000 after
                LOP&nbsp;&nbsp;: &nbsp;&nbsp;<b>Disabled</b>
              </p>
            </div>
          )}
        </div>

        <p style={{ fontSize: "1.1rem" }}>Salary and EPF Calculation </p>
        <table className="table table-bordered m-0 p-0">
          <colgroup>
            <col style={{ width: "40%" }} />
          </colgroup>
          <thead>
            <tr style={{ borderBottom: "1px solid lightgrey" }}>
              <th
                style={{
                  fontSize: ".8rem",
                  color: "#000",
                  background: "#fff",
                  border: "none",
                }}
              >
                SALARY COMPONENTS
              </th>
              <th
                style={{
                  fontSize: ".8rem",
                  color: "#000",
                  background: "#fff",
                  border: "none",
                }}
              >
                PACKAGE 1
              </th>
              <th
                style={{
                  fontSize: ".8rem",
                  color: "#000",
                  background: "#fff",
                  border: "none",
                }}
              >
                PACKAGE 2
              </th>
              <th
                style={{
                  fontSize: ".8rem",
                  color: "#000",
                  background: "#fff",
                  border: "none",
                }}
              >
                PACKAGE 3
              </th>
            </tr>
          </thead>
          <tbody>
            <tr style={{ borderBottom: "1px solid lightgrey" }}>
              <td style={{ border: "none" }}>
                Basic <br />
                <span style={{ fontSize: ".8rem", color: "grey" }}>
                  (Always considered for EPF)
                </span>
              </td>
              <td style={{ border: "none" }}>₹ 12500</td>
              <td style={{ border: "none" }}>₹ 9000</td>
              <td style={{ border: "none" }}>₹ 6000</td>
            </tr>
            <tr style={{ borderBottom: "1px solid lightgrey" }}>
              <td style={{ border: "none" }}>
                Transport Allowance <br />
                <span style={{ fontSize: ".8rem", color: "grey" }}>
                  {" "}
                  (Considered for EPF only when PF wage {"<"} ₹ 15,000)
                </span>
              </td>
              <td style={{ border: "none" }}>₹ 2000</td>
              <td style={{ border: "none" }}>₹ 1000</td>
              <td style={{ border: "none" }}>₹ 750</td>
            </tr>
            <tr style={{ borderBottom: "1px solid lightgrey" }}>
              <td style={{ border: "none" }}>
                Telephone Allowance <br />{" "}
                <span style={{ fontSize: ".8rem", color: "grey" }}>
                  (Considered for EPF only when PF wage {"<"} ₹ 15,000)
                </span>
              </td>
              <td style={{ border: "none" }}>₹ 1750</td>
              <td style={{ border: "none" }}>₹ 1500</td>
              <td style={{ border: "none" }}>₹ 500</td>
            </tr>
            <tr>
              <td style={{ border: "none" }}>
                <strong>EPF Contribution</strong>
              </td>
              <td style={{ border: "none" }}>
                ₹ 900
                <br /> (12% of 7500)
              </td>
              <td style={{ border: "none" }}>
                ₹ 900 <br />
                (12% of 7500)
              </td>
              <td style={{ border: "none" }}>
                ₹ 870 <br />
                (12% of 7250)
              </td>
            </tr>
          </tbody>
        </table>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="light" onClick={handleClose}>
          Okay, got it!
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export const Holdsalarymodaladmin = ({ show, handleClose }) => {
  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const { password, username } = billpunchbasicauth;
  const [Member, setMember] = useState([]);
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  const GetFirmEmployee = async () => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        firmId: user.firm_id,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/getEmployee`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        const mappedEmployees = data.data.map((employee) => ({
          value: employee.empid,
          label: `${employee.firstname} ${employee.lastname}`,
        }));
        setMember(mappedEmployees);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
      setTransactionLoaderState(false);
    }
  };

  useEffect(() => {
    GetFirmEmployee();
  }, []);

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      const allEmployeeIds = Member.map((employee) => employee.value);
      setSelectedEmployees(allEmployeeIds);
    } else {
      setSelectedEmployees([]);
    }
  };

  const handleCheckboxChange = (e, empId) => {
    if (e.target.checked) {
      setSelectedEmployees((prev) => [...prev, empId]);
    } else {
      setSelectedEmployees((prev) => prev.filter((id) => id !== empId));
    }
  };
  const filteredMembers = Member.filter((member) =>
    member.label.toLowerCase().includes(searchQuery.toLowerCase())
  );
  const [generate, setGenerate] = useState();
  const Holdsalary = async () => {
    setTransactionLoaderState(true);
    try {
      const myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      const holdRequests = selectedEmployees.map((empId) => ({
        firmid: user.firm_id,
        month: months[parseInt(selectedMonth) - 1].name,
        empid: empId,
        status: "Y",
      }));

      const requestBody = {
        requests: holdRequests,
      };

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify(requestBody),
        redirect: "follow",
      };

      console.log("Making API call with:", requestOptions);

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/SuperAdmin/HoldSalaryAmount`,
        requestOptions
      );

      const responseData = await res.json();
      console.log("API Response:", responseData);

      if (responseData.status === "0") {
        swal("Success", responseData.result, "success");
        setGenerate(responseData.data);
        handleClose();
      } else {
        swal("Error", responseData.msg, "error");
      }

      setTransactionLoaderState(false);
    } catch (error) {
      console.log("Error generating salary:", error);
      setTransactionLoaderState(false);
    }
  };

  const [selectedMonth, setSelectedMonth] = useState("01");
  const handleMonthChange = (e) => {
    setSelectedMonth(e.target.value);
  };
  const months = [
    { name: "January", value: "1" },
    { name: "February", value: "2" },
    { name: "March", value: "3" },
    { name: "April", value: "4" },
    { name: "May", value: "5" },
    { name: "June", value: "6" },
    { name: "July", value: "7" },
    { name: "August", value: "8" },
    { name: "September", value: "9" },
    { name: "October", value: "10" },
    { name: "November", value: "11" },
    { name: "December", value: "12" },
  ];
  const inputStyle = {
    border: "none",
    outline: "none",
    backgroundColor: "transparent",
    fontSize: "16px",
    marginLeft: "8px",
    width: "100%",
    fontFamily: "Poppins",
    fontWeight: "400",
    height: "30px",
  };
  const searchBarStyle = {
    display: "flex",
    alignItems: "center",
    border: "1px solid #ccc",
    borderRadius: "6px",
    padding: "0px",
    backgroundColor: "#fff",
  };
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Hold Employee Salary</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row ">
          <div className=" col-3 px-2 ">
            <select
              style={{
                width: "5rem",
                height: "30px",
                borderRadius: ".4rem",
                border: "1px #ced4da solid",
              }}
              value={selectedMonth}
              onChange={handleMonthChange}
            >
              {months.map((month) => (
                <option key={month.value} value={month.value}>
                  {month.name}
                </option>
              ))}
            </select>
          </div>
          <div className="col" style={searchBarStyle}>
            <input
              type="text"
              className="form-control"
              placeholder="Search Employee"
              value={searchQuery}
              style={inputStyle}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>

          <div className="col d-flex justify-content-end">
            <Button
              className="d-flex btn-sm align-items-center"
              style={{ height: "2rem" }}
              onClick={Holdsalary}
            >
              Hold Salary
            </Button>
          </div>
        </div>
        {filteredMembers.length > 0 ? (
          <table
            className="my-table transparent-table text-center border-bottom"
            style={{
              width: "100%",
              overflowX: "auto",
              border: "0px",
              overflow: "auto",
            }}
          >
            <thead>
              <tr>
                <th
                  className="my-table transparent-table text-center border-bottom "
                  style={{
                    textAlign: "center",
                  }}
                >
                  <input
                    type="checkbox"
                    onChange={handleSelectAll}
                    checked={
                      selectedEmployees.length === filteredMembers.length
                    }
                  />
                </th>
                <th
                  className="my-table transparent-table text-center border-bottom "
                  style={{
                    textAlign: "center",
                  }}
                >
                  Emp id
                </th>
                <th
                  className="my-table transparent-table text-center border-bottom "
                  style={{
                    textAlign: "center",
                  }}
                >
                  Employee Name
                </th>
              </tr>
            </thead>
            <tbody>
              {filteredMembers.map((employee) => (
                <tr key={employee.value}>
                  <td
                    className="text-center border-bottom"
                    style={{
                      fontSize: "0.8rem",
                      fontFamily: "Poppins",
                      fontWeight: "400",
                      lineHeight: "24px",
                      letterSpacing: "0em",
                      textAlign: "center",
                    }}
                  >
                    <input
                      type="checkbox"
                      checked={selectedEmployees.includes(employee.value)}
                      onChange={(e) => handleCheckboxChange(e, employee.value)}
                    />
                  </td>
                  <td
                    className="text-center border-bottom"
                    style={{
                      fontSize: "0.8rem",
                      fontFamily: "Poppins",
                      fontWeight: "400",
                      lineHeight: "24px",
                      letterSpacing: "0em",
                      textAlign: "center",
                    }}
                  >
                    {employee.value}
                  </td>
                  <td
                    className="text-center border-bottom"
                    style={{
                      fontSize: "0.8rem",
                      fontFamily: "Poppins",
                      fontWeight: "400",
                      lineHeight: "24px",
                      letterSpacing: "0em",
                      textAlign: "center",
                    }}
                  >
                    {employee.label}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <div style={{ textAlign: "center", fontSize: "1rem" }}>
            No Employees Available
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

export const KeyStatusModal = ({ showModal, setShowModal }) => {
  const { user, userparent, setTransactionLoaderState } =
    useContext(GlobalContext);
  const { password, username } = billpunchbasicauth;
  const [keyId, setKeyId] = useState("");
  const [keySecret, setKeySecret] = useState("");
  const [showForm, setShowForm] = useState(false);
  const [showKeySecret, setShowKeySecret] = useState(false);

  const handleGenerateKeys = async () => {
    setTransactionLoaderState(true);

    try {
      const myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      const raw = JSON.stringify({
        firmid: user.firm_id,
        empid: user.userid,
        mobile: user.mobile,
        query_id: "string",
      });
      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/SuperAdmin/GenerateKeys`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        swal({
          text: data.result,
          icon: "success",
          buttons: {
            confirm: {
              text: "OK",
              value: true,
              visible: true,
              className: "",
              closeModal: true,
            },
          },
        }).then((willConfirm) => {
          if (willConfirm) {
            setShowForm(true);
          }
        });
      } else {
        setShowForm(true);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
      setTransactionLoaderState(false);
    }
  };

  const Getkey = async () => {
    setTransactionLoaderState(true);
    try {
      const myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      const raw = JSON.stringify({
        firmid: user.firm_id,
        empid: user.userid,
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/SuperAdmin/GetGeneratedKeys`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0" && data.data.length > 0) {
        const { keyid, keysecret } = data.data[0];
        setKeyId(keyid);
        setKeySecret(keysecret);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
      setTransactionLoaderState(false);
    }
  };

  useEffect(() => {
    Getkey();
  }, []);

  const downloadCSV = () => {
    const csvContent = `data:text/csv;charset=utf-8,Key ID,Key Secret\n${keyId},${keySecret}`;
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "key_id_and_key_secret.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const [firmDetails, setFirmDetails] = useState(null);
  const basicdetail = async () => {
    setTransactionLoaderState(true);

    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        firmid: user.firm_id,
        parentid: userparent.parentAccountId,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/SuperAdmin/GetFirmBasicDetails`,
        requestOptions
      );
      const data = await res.json();

      if (data.status === "0" && data.msg === "Success") {
        setFirmDetails(data.data[0]);
      } else {
        console.log("Error: ", data.msg);
      }

      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
      setTransactionLoaderState(false);
    }
  };
  const handleClose = () => {
    setShowModal(false);
    setflowmodal(true);
    basicdetail();
  };
  const [flowmodal, setflowmodal] = useState(false);
  return (
    <>
      <Adminflowmodal showModal={flowmodal} setShowModal={setflowmodal} />
      <Modal
        show={showModal}
        onHide={() => {}}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title>Generate Key </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            style={{ width: "100%", textAlign: "center", paddingTop: "20px" }}
          >
            <h3 className="" style={{ color: "#000" }}>
              Key ID & Key Secret
            </h3>
          </div>
          <div className="d-flex justify-content-center mt-md-3 mt-4">
            <div>
              <div className="d-flex justify-content-center">
                <img
                  alt=""
                  src={require("../../../assets/images/logoblack.png")}
                  style={{ height: "2rem" }}
                />
              </div>
              {!showForm && (
                <>
                  <p
                    className="text-center mt-3"
                    style={{ fontSize: "1.1rem" }}
                  >
                    To proceed further, please generate your Key ID and Key
                    Secret:
                  </p>
                  <div className="mt-3 d-flex justify-content-center">
                    <img
                      alt=""
                      src={require("../../../assets/images/appid.gif")}
                    />
                  </div>
                  <div className="mt-3 d-flex justify-content-center">
                    <Button className="btn-sm" onClick={handleGenerateKeys}>
                      Generate Your Key
                    </Button>
                  </div>
                </>
              )}

              {showForm && (
                <Form className="mt-4">
                  <Form.Group controlId="formKeyId">
                    <Form.Label>Key ID</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Key ID"
                      value={keyId}
                      onChange={(e) => setKeyId(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group controlId="formKeySecret">
                    <Form.Label>Key Secret</Form.Label>
                    <InputGroup>
                      <Form.Control
                        type={showKeySecret ? "text" : "password"}
                        placeholder="Enter Key Secret"
                        value={keySecret}
                        onChange={(e) => setKeySecret(e.target.value)}
                      />
                      <Button
                        variant="outline-secondary"
                        onClick={() => setShowKeySecret(!showKeySecret)}
                      >
                        {showKeySecret ? "Hide" : "Show"}
                      </Button>
                    </InputGroup>
                    <Link
                      variant="secondary"
                      onClick={downloadCSV}
                      className="mt-2"
                    >
                      Download ID & Secret
                    </Link>
                  </Form.Group>
                  <Button onClick={handleClose} variant="primary">
                    Close
                  </Button>{" "}
                </Form>
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export const Adminflowmodal = ({ showModal, setShowModal }) => {
  const [stepIndex, setStepIndex] = useState(0);

  const steps = [
    {
      title: "Step 1:  Employee Management",
      description:
        "Click on the Employee Management to begin managing your employees.",
      images: [step1Img],
    },
    {
      title: "Step 2: Add Department",
      description: (
        <>
          <div className="row">
            <div className="col">
              <img
                src={step2Img}
                alt="Step 2 Image 1"
                className="d-block w-100"
                style={{}}
              />
              <p className="text-center">Click on 'Add Department'.</p>
            </div>
          </div>
          <div className="d-flex justify-content-center">
            <img
              src={step5Img}
              alt="Step 2 Image 1"
              className="d-block "
              style={{ width: "4rem" }}
            />
          </div>

          <div className="row">
            <div className="col">
              <p className="text-center">Fill to add department.</p>

              <img
                src={Adddept}
                alt="Step 2 Image 3"
                className="d-block w-50"
              />
            </div>
          </div>
        </>
      ),
      images: [],
    },
    {
      title: "Step 3: Add Employee",
      description: (
        <>
          <div className="row">
            <div className="col">
              <img
                src={step3Img}
                alt="Step 2 Image 1"
                className="d-block w-100"
                style={{}}
              />
              <p className="text-center">Click on 'Add Employee'.</p>
            </div>
          </div>
          <div className="d-flex justify-content-center">
            <img
              src={step5Img}
              alt="Step 2 Image 1"
              className="d-block "
              style={{ width: "4rem" }}
            />
          </div>

          <div className="row">
            <div className="col">
              <p className="text-center">
                Fill out this form to add employees to your firm.
              </p>

              <img
                src={step4Img}
                alt="Step 2 Image 3"
                className="d-block w-50"
              />
            </div>
          </div>
        </>
      ),
      images: [],
    },
  ];

  const handleNext = () => {
    if (stepIndex < steps.length - 1) {
      setStepIndex(stepIndex + 1);
    } else {
      handleClose();
    }
  };

  const handlePrevious = () => {
    if (stepIndex > 0) {
      setStepIndex(stepIndex - 1);
    }
  };

  const handleClose = () => {
    setShowModal(false);
  };

  return (
    <Modal
      show={showModal}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      className="Adminflowmodal"
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>Flow to Manage Your Employee</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h5>{steps[stepIndex].title}</h5>
        <div className="step-content">
          {steps[stepIndex].images.map((imgSrc, index) => (
            <img
              key={index}
              className="d-block w-100"
              src={imgSrc}
              alt={`Step ${stepIndex + 1} Image ${index + 1}`}
              style={{ marginBottom: "20px" }}
            />
          ))}
          <p>{steps[stepIndex].description}</p>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          size="sm"
          variant="secondary"
          onClick={handlePrevious}
          disabled={stepIndex === 0}
        >
          Previous
        </Button>
        <Button variant="primary" onClick={handleNext} size="sm">
          {stepIndex === steps.length - 1 ? "Finish" : "Next"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export const Newusermodal = ({ showModal, setShowModal, basicdetail }) => {
  const { user, userparent, setTransactionLoaderState } =
    useContext(GlobalContext);
  const { password, username } = billpunchbasicauth;
  const [showkeyModal, setShowkeyModal] = useState(false);
  const [formData, setFormData] = useState({
    Firmname: "",
    Firmstrdate: "",
    Firmgst: "",
    Firmtype: "",
    Pan: "",
    Firmmember: "",
    Firmmail: "",
    Aadhar: "",
    FirmAddress: "",
  });
  const [errors, setErrors] = useState({});
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (location.state) {
      const { user_name, user_phone, timestamp } = location.state;
      setFormData((prevFormData) => ({
        ...prevFormData,
        Firmowner: user_name,
        Firmmobile: user_phone,
      }));
    }
  }, [location.state]);

  useEffect(() => {
    fetchRecentNewFirm();
  }, []);
  const formatDate = (dateStr) => {
    const [month, day, year] = dateStr.split("/");
    return `${day}/${month}/${year}`;
  };
  const fetchRecentNewFirm = async () => {
    try {
      const myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      const raw = JSON.stringify({
        mobile: location.state.user_phone,
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const response = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/SuperAdmin/GetRecentNewFirm`,
        requestOptions
      );

      // if (!response.ok) {
      //   throw new Error("Failed to fetch recent new firm data");
      // }

      const data = await response.json();
      if (data.status === "0" && data.data.length > 0) {
        const {
          firm_name,
          firm_startdate,
          firm_gst,
          firm_type,
          firm_members,
          firm_email,
          owner_pan,
          owner_aadhar,
          firm_address,
        } = data.data[0];

        const formattedStartDate = formatDate(firm_startdate);
        setFormData({
          Firmname: firm_name,
          Firmstrdate: formattedStartDate,
          Firmgst: firm_gst,
          Firmtype: firm_type,
          Firmmember: firm_members,
          Firmmail: firm_email,
          Pan: owner_pan,
          Aadhar: owner_aadhar,
          FirmAddress: firm_address,
        });
      } else {
      }
    } catch (error) {
      console.error("Error fetching recent new firm data:", error);
      // swal("Error", "Failed to fetch recent new firm data", "error");
    }
  };

  const validateForm = (e) => {
    e.preventDefault();
    let isValid = true;
    const errors = {};

    // Validation rules
    if (!formData.Firmname) {
      errors.Firmname = "Firm name is required";
      isValid = false;
    } else if (formData.Firmname.trim() !== formData.Firmname) {
      errors.Firmname = "Firm name should not start with spaces";
      isValid = false;
    }

    if (!formData.Firmstrdate) {
      errors.Firmstrdate = "Firm start date is required";
      isValid = false;
    }

    if (!formData.Firmmember) {
      errors.Firmmember = "Firm size required";
      isValid = false;
    }

    if (!formData.Firmtype) {
      errors.Firmtype = "Firm type is required";
      isValid = false;
    }

    if (!formData.Firmmail) {
      errors.Firmmail = "Firm email is required";
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(formData.Firmmail)) {
      errors.Firmmail = "Invalid email address";
      isValid = false;
    }
    if (!formData.FirmAddress) {
      errors.FirmAddress = "Firm address is required";
      isValid = false;
    }

    setErrors(errors);
    if (Object.keys(errors).length === 0 && isValid) {
      handleSubmit();
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    setTransactionLoaderState(true);

    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        firmid: user.firm_id,
        parentid: userparent.parentAccountId,
        firm_name: formData.Firmname,
        firm_owner_name: userparent.user_name,
        firm_startdate: formData.Firmstrdate,
        firm_gst: formData.Firmgst,
        firm_type: formData.Firmtype,
        firm_members: formData.Firmmember,
        firm_email: formData.Firmmail,
        firm_mobile: userparent.user_phone,
        owner_pan: formData.Pan,
        owner_aadhar: formData.Aadhar,
        firm_address: formData.FirmAddress,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/SuperAdmin/AddFirmQueryNew`,
        requestOptions
      );
      const data = await res.json();

      if (data.status === "0") {
        swal({
          title: "Success",
          text: data.result || "Firm Created Successfully",
          icon: "success",
          buttons: "OK",
        }).then((ok) => {
          if (ok) {
            handleClose();
          }
        });
      } else {
        swal("Error", data.msg, "warning");
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
      swal("Error", "Failed to submit form", "error");
      setTransactionLoaderState(false);
    }
  };

  const handleClose = () => {
    setShowModal(false);
    setShowkeyModal(true);
  };

  return (
    <>
      <KeyStatusModal showModal={showkeyModal} setShowModal={setShowkeyModal} />
      <Modal show={showModal} backdrop="static" keyboard={false}>
        <Modal.Header>
          <Modal.Title className="text-center" style={{ color: "blue" }}>
            Welcome To BillPunch
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Form className="mt-4" onSubmit={validateForm}>
              <div className="row m-0 p-0">
                <Form.Group className="col" controlId="Firmname">
                  <Form.Label>Firm Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter firm name"
                    name="Firmname"
                    value={formData.Firmname}
                    onChange={handleChange}
                    isInvalid={!!errors.Firmname}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.Firmname}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="col" controlId="Firmtype">
                  <Form.Label>Firm Type</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter firm type"
                    name="Firmtype"
                    value={formData.Firmtype}
                    onChange={handleChange}
                    isInvalid={!!errors.Firmtype}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.Firmtype}
                  </Form.Control.Feedback>
                </Form.Group>
              </div>

              <div className="row m-0 p-0">
                <Form.Group className="col" controlId="Firmstrdate">
                  <Form.Label>Firm Start Date</Form.Label>
                  <Form.Control
                    type="date"
                    name="Firmstrdate"
                    value={formData.Firmstrdate}
                    onChange={handleChange}
                    isInvalid={!!errors.Firmstrdate}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.Firmstrdate}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="col" controlId="Firmgst">
                  <Form.Label>Firm GST (Optional)</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter firm GST"
                    name="Firmgst"
                    value={formData.Firmgst}
                    onChange={handleChange}
                    isInvalid={!!errors.Firmgst}
                  />
                </Form.Group>
              </div>

              <div className="row m-0 p-0">
                <Form.Group className="col" controlId="Firmmember">
                  <Form.Label>Firm Members</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter firm members"
                    name="Firmmember"
                    value={formData.Firmmember}
                    onChange={handleChange}
                    isInvalid={!!errors.Firmmember}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.Firmmember}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="col" controlId="Firmmail">
                  <Form.Label>Firm Email</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter firm email"
                    name="Firmmail"
                    value={formData.Firmmail}
                    onChange={handleChange}
                    isInvalid={!!errors.Firmmail}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.Firmmail}
                  </Form.Control.Feedback>
                </Form.Group>
              </div>

              <div className="row m-0 p-0">
                <Form.Group className="col" controlId="Pan">
                  <Form.Label>Firm Pan (Optional)</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter firm Pan"
                    name="Pan"
                    value={formData.Pan}
                    onChange={handleChange}
                    isInvalid={!!errors.Pan}
                  />
                </Form.Group>
                <Form.Group className="col" controlId="Aadhar">
                  <Form.Label>Firm Aadhaar (Optional)</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter firm Aadhaar"
                    name="Aadhar"
                    value={formData.Aadhar}
                    onChange={handleChange}
                    isInvalid={!!errors.Aadhar}
                  />
                </Form.Group>
              </div>
              <div className="row m-0 p-0">
                <Form.Group className="col" controlId="FirmAddress">
                  <Form.Label>Firm Address</Form.Label>
                  <Form.Control
                    as="textarea"
                    placeholder="Enter firm address"
                    name="FirmAddress"
                    value={formData.FirmAddress}
                    onChange={handleChange}
                    isInvalid={!!errors.FirmAddress}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.FirmAddress}
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
              <div className="px-3">
                <Button className="btn-sm" variant="primary" type="submit">
                  Submit
                </Button>
              </div>
            </Form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
