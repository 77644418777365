import React, { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../../Context/GlobalState";
import { BASE_URL_API_TRANXT_UAT_API_GOSEO } from "../../utils/Constants";
import { Card, Col, Row, Spinner } from "react-bootstrap"; 
import { FaUserCircle } from "react-icons/fa";

const Blogauthor = () => {
  const { setTransactionLoaderState } = useContext(GlobalContext);
  const [authors, setAuthors] = useState([]);
  const [loading, setLoading] = useState(true); 

  const GetAuthorsBlog = async () => {
    setTransactionLoaderState(true);
    try {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({
          slug: "all"
        }),
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API_GOSEO}/api/Seo/GetAuthorsBlog`,
        requestOptions
      );
      const data = await res.json();
      console.log("Geteditloadblog response:", data);

      if (data.status === "0") {
        setAuthors(data.data); 
      } else {
        console.error("Failed to fetch blogs:", data.msg);
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setTransactionLoaderState(false);
      setLoading(false); 
    }
  };

  useEffect(() => {
    GetAuthorsBlog();
  }, []);

  return (
    <>
      {loading ? (
        <div className="text-center">
          <Spinner animation="border" />
        </div>
      ) : (
        <Row className="g-4">
          {authors.map((author) => (
            <Col key={author.id} md={2} sm={4}> 
              <Card className="p-2">
              <div
                  style={{
                    width: "100%",
                    height: "200px", 
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "#f0f0f0", 
                  }}
                >
                  {author.profile_image ? (
                    <Card.Img
                      variant="top"
                      src={author.profile_image}
                      style={{
                        width: "100%",  
                        height: "100%", 
                        objectFit: "cover", 
                      }}
                    />
                  ) : (
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "#888", 
                      }}
                    >
                      <FaUserCircle size={100} /> 
                    </div>
                  )}
                </div>
                <Card.Body className="p-1">
                <strong>Username:</strong> {author.fullname}
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      )}
    </>
  );
};

export default Blogauthor;
