import React, { useState, useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";
import { GlobalContext } from "../../Context/GlobalState";
import { BASE_URL_API_TRANXT_UAT_API_GOSEO } from "../../utils/Constants";
import Nabvar from "./Navbar";
import Footer from "./footer";
import { Badge } from "react-bootstrap";
import { Link } from "react-router-dom";
import { MdOutlineDateRange } from "react-icons/md";
import { FaUserTie } from "react-icons/fa";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
const Slug = () => {
  const [blogData, setBlogData] = useState(null);
  const [postdata, setpostdata] = useState([]);
  const { post_slug } = useParams();
  useEffect(() => {
    console.log(post_slug);
    if (post_slug) {
      GetAuthorsBlog(post_slug);
    }
  }, [post_slug]);

  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const tags = [
    "Business",
    "Corporate",
    "Finance",
    "Invest",
    "Planning",
    "Sport",
    "News",
  ];
  const GetAuthorsBlog = async (slug) => {
    setTransactionLoaderState(true);
    try {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({ p_slug: slug }),
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API_GOSEO}/api/Seo/GetPublishedBlogPost`,
        requestOptions
      );
      const data = await res.json();
      console.log("Geteditloadblog response:", data);

      if (data.status === "0") {
        setBlogData(data.data[0]);
      } else {
        console.error("Failed to fetch blogs:", data.msg);
      }
    } catch (error) {
      console.error("Error fetching blog:", error);
    } finally {
      setTransactionLoaderState(false);
    }
  };

  const Getproductblogs = async () => {
    setTransactionLoaderState(true);
    try {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({
          product_name: "billpunch",
        }),
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API_GOSEO}/api/Seo/GetProductBlogs`,
        requestOptions
      );
      const data = await res.json();

      if (data.status === "0") {
        setpostdata(data.data || []);
      } else {
        console.error("Failed to fetch blogs:", data.msg);
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setTransactionLoaderState(false);
    }
  };

  useEffect(() => {
    if (blogData) {
      const images = document.querySelectorAll(".blog-content img");
      images.forEach((img) => {
        img.style.borderRadius = "1rem";
        img.style.boxShadow = "0 4px 8px rgba(0, 0, 0, 0.1)";
        img.style.width = "100%";
        img.style.height = "auto";
      });
    }
  }, [blogData]);

  useEffect(() => {
    Getproductblogs();
  }, []);

  if (!blogData) {
    return <div>No data available</div>;
  }

  return (
    <>
         <Helmet>
        {/* SEO Meta Tags */}
        <title>{blogData.post_title}</title>
        <meta name="description" content={blogData.post_excerpt} />
        <meta name="keywords" content={blogData.post_keywords} />
        <link rel="canonical" href={`http://localhost:3000/blog/${post_slug}`} />

        {/* Open Graph Meta Tags */}
        <meta property="og:title" content={blogData.post_title} />
        <meta property="og:description" content={blogData.post_excerpt} />
        <meta property="og:image" content={blogData.post_featured_image} />
        <meta property="og:url" content={`http://localhost:3000/blog/${post_slug}`} />
        <meta property="og:type" content="article" />

        {/* Twitter Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={blogData.post_title} />
        <meta name="twitter:description" content={blogData.post_excerpt} />
        <meta name="twitter:image" content={blogData.post_featured_image} />

        {/* Structured Data (Schema.org) */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "BlogPosting",
            headline: blogData.post_title,
            image: blogData.post_featured_image,
            author: {
              "@type": "Person",
              name: blogData.post_author,
            },
            datePublished: blogData.created_at,
            description: blogData.post_excerpt,
            articleBody: blogData.post_content,
            keywords: blogData.post_keywords,
          })}
        </script>
      </Helmet>

      <Nabvar />

      <div className="row">
        <div className="col-md-1"></div>
        <div className="col-10 m-md-0 m-3">
          <div className="row " style={{ overflow: "hidden", height: "auto" }}>
            <div className="col-md-9 col-12 m-0 p-0 ">
              <div style={{ width: "90%" }}>
                <div className="d-flex flex-wrap">
                  {blogData.post_keywords.split(",").map((keyword, index) => (
                    <Badge
                      key={index}
                      className="btn btn-outline-secondary mx-1 mt-2 p-1"
                      style={{ borderRadius: ".2rem", fontSize: ".8rem" }}
                    >
                      {keyword.trim()}
                    </Badge>
                  ))}
                  {blogData.post_category.split(",").map((keyword, index) => (
                    <Badge
                      key={index}
                      className="btn btn-outline-secondary mx-1 mt-2 p-1"
                      style={{ borderRadius: ".2rem", fontSize: ".8rem" }}
                    >
                      {keyword.trim()}
                    </Badge>
                  ))}
                </div>

                <h2>{blogData.post_title}</h2>
                <div className="row ">
                  <p className="col px-1" style={{ fontSize: ".9rem" }}>
                    <FaUserTie style={{ fontSize: "1.3rem", color: "grey" }} />
                    &nbsp; {blogData.post_author}
                  </p>
                  <p
                    className="col d-flex justify-content-end p-0"
                    style={{ fontSize: ".9rem" }}
                  >
                    <MdOutlineDateRange
                      style={{ fontSize: "1.3rem", color: "grey" }}
                    />{" "}
                    &nbsp;{blogData.created_at}
                  </p>
                </div>

                <div>
                  <img
                    src={blogData.post_featured_image}
                    alt={blogData.post_featured_image_alt}
                    title={blogData.post_featured_image_title}
                    style={{
                      width: "100%",
                      height: "auto",
                      borderRadius: "1rem",
                    }}
                  />
                </div>
                <p className="pt-3" style={{ fontSize: "1rem" }}>
                  {blogData.post_excerpt}
                </p>
                <div
                  className="blog-content"
                  dangerouslySetInnerHTML={{ __html: blogData.post_content }}
                />
              </div>
            </div>
            <div className="col-md-3 col-12 p-md-0 p-5 ">
              <div className="d-flex flex-column justify-content-start align-items-center mt-3 ">
                <h6
                  style={{
                    fontFamily: "Roboto, sans-serif",
                    fontWeight: "500",
                  }}
                >
                  Related Tags
                </h6>
                <div
                  className="d-flex flex-wrap"
                  style={{ textAlign: "start", justifyContent: "center" }}
                >
                  {tags.map((tag, index) => (
                    <Badge
                      key={index}
                      className="btn btn-outline-secondary mx-2 mt-2"
                      style={{ borderRadius: ".4rem" }}
                    >
                      {tag}
                    </Badge>
                  ))}
                </div>
              </div>

              <div className="d-flex flex-column justify-content-start align-items-center mt-4">
                {postdata.map((blog) => (
                  <div
                    key={blog.post_id}
                    className="d-flex align-items-center mb-3"
                    style={{ textAlign: "start" }}
                  >
                    <img
                      src={blog.post_featured_image}
                      alt="Author"
                      style={{
                        width: "60px",
                        height: "60px",
                        borderRadius: "50%",
                        marginRight: "1rem",
                      }}
                    />
                    <Link
                      to={`/blog/${blog.post_slug}`}
                      onClick={() => {
                        console.log("click hua");
                        sessionStorage.setItem("Slug", JSON.stringify(blog));
                      }}
                      style={{ textDecoration: "none" }}
                    >
                      <div className="ms-3">
                        <p
                          style={{
                            fontSize: ".8rem",
                            margin: 0,
                            color: "black",
                          }}
                        >
                          {blog.post_title}
                        </p>
                        <p
                          style={{
                            fontSize: ".8rem",
                            color: "#686D76",
                            margin: 0,
                            fontWeight: "600",
                          }}
                        >
                          <FaUserTie
                            style={{ fontSize: ".9rem", color: "grey" }}
                          />
                          &nbsp; {blog.post_author}
                        </p>
                      </div>
                    </Link>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-1"></div>
      </div>
      <Footer />
    </>
  );
};

export default Slug;
