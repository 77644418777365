import React, { useContext, useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Footer from "./footer";
import Slider from "./slider";
import Successcard from "./Successcard";
import TalkExpert from "./TalkExpert";
import Main from "./Main";
import Nabvar from "./Navbar";
import ReviewCard from "./Reviewcard";
import Solution from "./Solution";
import Synchronize from "./Synchronize";
import ScheduleDemo from "./Scheduledemo";
import trackPageView from "../../../trackPageView";
import ActiveUserCount from "../../../ActiveUserCount";
import { GlobalContext } from "../../Context/GlobalState";
import { BASE_URL_API_TRANXT_UAT_API_GOSEO } from "../../utils/Constants";
import { billpunchbasicauth } from "../../utils/Credentials";

const LandingPage = () => {
  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const [placeName, setPlaceName] = useState("");
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const { password, username } = billpunchbasicauth;

  useEffect(() => {
    trackPageView();
    getLocation();

    // Add event listener for button clicks
    const handleButtonClick = (event) => {
      const { clientX, clientY, target } = event;
      const element = target.tagName.toLowerCase();
      const buttonName = target.innerText || target.value || "N/A";

      console.log(`Button clicked at X: ${clientX}, Y: ${clientY}`);
      console.log(`Element: ${element}, Button: ${buttonName}`);

      sendClickData(
        clientX,
        clientY,
        element,
        buttonName,
        window.location.href
      );
    };

    // Add event listeners for links
    const handleLinkClick = (event) => {
      const { clientX, clientY, target } = event;
      const element = target.tagName.toLowerCase();
      const buttonName = target.innerText || target.value || "N/A";

      console.log(`Link clicked at X: ${clientX}, Y: ${clientY}`);
      console.log(`Element: ${element}, Button: ${buttonName}`);

      sendClickData(
        clientX,
        clientY,
        element,
        buttonName,
        window.location.href
      );
    };

    // Add event listeners to buttons and links
    const buttons = document.querySelectorAll("button");
    const links = document.querySelectorAll("a");

    buttons.forEach((button) =>
      button.addEventListener("click", handleButtonClick)
    );
    links.forEach((link) => link.addEventListener("click", handleLinkClick));

    return () => {
      // Clean up event listeners
      buttons.forEach((button) =>
        button.removeEventListener("click", handleButtonClick)
      );
      links.forEach((link) =>
        link.removeEventListener("click", handleLinkClick)
      );
    };
  }, []);

  const sendClickData = (x, y, element, buttonName, redirectionUrl) => {
    const urlParams = new URLSearchParams(window.location.search);
    const Coordinatedata = {
      campaignid: urlParams.get("campaignid") || "N/A",
      sessionid: urlParams.get("sessionid") || "N/A",
      x_cord: x ? x.toString() : "N/A",
      y_cord: y ? y.toString() : "N/A",
      element: element || "N/A",
      button_name: buttonName || "N/A",
      pageurl: window.location.href,
      pagename: document.title,
      product: "BillPunch",
      redirection_url: redirectionUrl || "N/A",
    };

    console.log("Sending click data:", Coordinatedata);

    fetch(`${BASE_URL_API_TRANXT_UAT_API_GOSEO}/api/Seo/trackClickHeatMaps`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(Coordinatedata),
    })
      .then((response) => response.json())
      .then((data) => console.log("Click tracked successfully:", data))
      .catch((error) => console.error("Error tracking click:", error));
  };

  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showPosition);
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  };

  const showPosition = async (position) => {
    console.log("Received position data:", position);
    setLatitude(position.coords.latitude);
    setLongitude(position.coords.longitude);
    setTransactionLoaderState(true);

    try {
      const response = await fetch(
        `https://nominatim.openstreetmap.org/reverse?lat=${position.coords.latitude}&lon=${position.coords.longitude}&format=json`
      );
      const data = await response.json();
      console.log("Geocoding Response:", data);

      if (data && data.address && data.address.country) {
        setPlaceName(data.address.country);
      } else {
        console.log("No country found in geocoding response");
      }
    } catch (error) {
      console.error("Error fetching place name:", error);
    } finally {
      setTransactionLoaderState(false);
    }
  };

  const Insertproducrlocation = async () => {
    if (!latitude || !longitude || !placeName || placeName === "0") {
      console.log(
        "Latitude, longitude, or country is not available. Aborting API call."
      );
      return;
    }
    setTransactionLoaderState(true);
    try {
      const myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      const raw = JSON.stringify({
        latitude: latitude || "NA",
        longitude: longitude || "NA",
        country: placeName,
        product: "BillPunch",
      });
      console.log(raw);
      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API_GOSEO}/api/Seo/InsertProductLocation`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        // Handle success
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
      setTransactionLoaderState(false);
    }
  };

  useEffect(() => {
    if (placeName && placeName !== "0") {
      console.log("Calling Insertproducrlocation with country:", placeName);
      Insertproducrlocation();
    }
  }, [placeName]);

  const generateUUID = () => {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
      (
        c ^
        (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
      ).toString(16)
    );
  };

  // useEffect(() => {
  //   const sessionId = generateUUID();
  //   const urlParams = new URLSearchParams(window.location.search);
  //   if (!urlParams.has("sessionid")) {
  //     urlParams.set("sessionid", sessionId);

  //     const newUrl = `${window.location.pathname}?${urlParams.toString()}`;

  //     window.history.replaceState(null, "", newUrl);
  //   }
  // }, []);

  return (
    <>
      <ActiveUserCount />
      <Nabvar />
      <Main />
      <Slider />
      <Solution />
      <TalkExpert />
      <Synchronize />
      <Successcard />
      <ReviewCard />
      <ScheduleDemo />
      <Footer />
    </>
  );
};

export default LandingPage;
